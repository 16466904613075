import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";

interface ExportToCsvProps {
  data: any[]; // 데이터 배열
  name: string;
}

const ExportToCsv: React.FC<ExportToCsvProps> = ({ data, name }) => {
  const convertToCsv = (data: any[]) => {
    if (data.length === 0) return "";

    const excludeKeys = ["isDeleted", "surgeryUuid"];

    // 헤더 생성
    const headers =
      Object.keys(data[0])
        .filter((key) => !excludeKeys.includes(key))
        .join(",") + "\n";

    // 데이터 생성
    const rows = data
      .map((row) =>
        Object.entries(row)
          .filter(([key]) => !excludeKeys.includes(key)) // 제외할 필드를 필터링
          .map(([_, value]) => `"${value}"`) // 값에 따옴표 추가
          .join(",")
      )
      .join("\n");

    return headers + rows;
  };

  const onClickExport = () => {
    const csvData = convertToCsv(data);

    // Blob 생성
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const getFormattedDate = () => {
      const now = new Date();
      const year = now.getFullYear(); // 연도
      const month = String(now.getMonth() + 1).padStart(2, "0"); // 월 (1월은 0이므로 +1)
      const day = String(now.getDate()).padStart(2, "0"); // 일

      return `${year}${month}${day}`;
    };

    // URL 생성 및 다운로드 트리거
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;

    link.setAttribute("download", `${name}_${getFormattedDate()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <IconButton onClick={onClickExport} style={{ padding: "5px", border: "none" }}>
      <DownloadIcon sx={{ fontSize: 15 }} />
    </IconButton>
  );
};

export default ExportToCsv;
