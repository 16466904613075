import React, { ReactNode } from "react";
import { DoctorProvider } from "../DoctorsContext";
import { ProductProvider } from "../ProductContext";
import { DeviceProvider } from "../DevicesContext";
import { SseContext } from "./SseContext";
import { CounselorProvider } from "../CounselorContext";
import Surgery from "../../page/Surgery/Surgery";
import { SurgeryProvider } from "../SurgeryContext";

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  return (
    <DoctorProvider>
      <SurgeryProvider>
        <ProductProvider>
          <CounselorProvider>
            <DeviceProvider>
              <SseContext>{children}</SseContext>
            </DeviceProvider>
          </CounselorProvider>
        </ProductProvider>
      </SurgeryProvider>
    </DoctorProvider>
  );
};
