import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { WorkSchedule } from "./Model";
import { localDoctorController } from "./Controller";
import { useDoctor } from "../../context/DoctorsContext";
import { isSameDay } from "../../util/Function";
import { formatDate } from "../../util/Function";
import { Doctor } from "../../models/DoctorModel";

interface ContextType {
  weekWorkSchedule: WorkSchedule[];
  fetchDoctorWeekSchedule: (date: Date) => void;
  updateDoctorWeekSchedule: (changedDoctorSchedule: WorkSchedule[]) => void;
  doctorsColorList: DoctorColor[];
  setDoctorsColorList: React.Dispatch<React.SetStateAction<DoctorColor[]>>;
}
export interface DoctorColor {
  color: string;
  select: boolean;
}

const Context = createContext<ContextType | undefined>(undefined);

export const ContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [weekWorkSchedule, setWeekWorkSchedule] = useState<WorkSchedule[]>([]);
  const { doctors } = useDoctor();

  const [doctorsColorList, setDoctorsColorList] = useState<DoctorColor[]>([
    { color: "#BBE3FF", select: false },
    { color: "#22DEF2", select: false },
    { color: "#41A9FF", select: false },
    { color: "#276FF3", select: false },
    { color: "#D0FBD0", select: false },
    { color: "#2CEFB8", select: false },
    { color: "#A9EF77", select: false },
    { color: "#58CF78", select: false },
    { color: "#FFE7BD", select: false },
    { color: "#FFC39B", select: false },
    { color: "#FFA763", select: false },
    { color: "#E97871", select: false },
    { color: "#FDCEFF", select: false },
    { color: "#E591FF", select: false },
    { color: "#FE6FB4", select: false },
    { color: "#9C95FF", select: false },
    { color: "#95A7BE", select: false },
    { color: "#AC8C78", select: false },
  ]);

  useEffect(() => {
    if (doctors) {
      // 의사 색상 리스트 정의 (선택된 색상 제거를 위함)
      doctors.forEach((doctor: Doctor) => {
        setDoctorsColorList((prevColors) => prevColors.map((colorItem) => (colorItem.color === doctor.color ? { ...colorItem, select: true } : colorItem)));
      });
    }
  }, [doctors]);

  const fetchDoctorWeekSchedule = async (date: Date) => {
    const fetchDoctorScheduleResponse = await localDoctorController.handleFetchDoctorWeekSchedule(date);
    if (fetchDoctorScheduleResponse.success) {
      const workingDoctors = doctors.filter((doctor) => !doctor.exitDate || doctor.exitDate <= new Date());
      const parsedDoctorSchedule: WorkSchedule[] = [];

      workingDoctors.forEach((doctor) => {
        for (let i = 0; i < 7; i++) {
          const targetDate = new Date();
          targetDate.setDate(date.getDate() - date.getDay() + i);

          //doctorUuid가 있는 경우
          const existingSchedule = fetchDoctorScheduleResponse.data.find((schedule: WorkSchedule) => schedule.doctorId === doctor.doctorId && isSameDay(new Date(schedule.date), targetDate));

          //dragId는 드레그를 위한 데이터
          const workSchedule: WorkSchedule = existingSchedule
            ? {
                ...existingSchedule,
                dragId: Number(`${i}${Math.floor(Math.random() * 1000)}`),
              }
            : {
                doctorWorkScheduleId: null,
                doctorId: doctor.doctorId,
                doctorName: doctor.name,
                date: formatDate(targetDate),
                workStartTime: null,
                workEndTime: null,
                workType: "none",
                dragId: Number(`${i}${Math.floor(Math.random() * 1000)}`),
              };
          parsedDoctorSchedule.push(workSchedule);
        }
      });
      setWeekWorkSchedule(parsedDoctorSchedule);
    } else {
      alert(fetchDoctorScheduleResponse.message);
    }
  };

  const updateDoctorWeekSchedule = async (changedDoctorSchedule: WorkSchedule[]) => {
    const updateDoctorWeekScheduleResponse = await localDoctorController.handleUpdateDoctorSchedule(changedDoctorSchedule);
    if (updateDoctorWeekScheduleResponse.success) {
      const updatedSchedules = weekWorkSchedule.map((schedule) => {
        const updatedSchedule = updateDoctorWeekScheduleResponse.data.find((updated: WorkSchedule) => updated.date === schedule.date && updated.doctorId === schedule.doctorId);
        return updatedSchedule ? { ...schedule, ...updatedSchedule } : schedule;
      });
      setWeekWorkSchedule(updatedSchedules);
    } else {
      alert(updateDoctorWeekScheduleResponse.message);
    }
  };

  return <Context.Provider value={{ weekWorkSchedule, fetchDoctorWeekSchedule, updateDoctorWeekSchedule, doctorsColorList, setDoctorsColorList }}>{children}</Context.Provider>;
};

export const useLocalContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useLocalContext must be used within a Provider");
  }
  return context;
};
