import React, { useState } from "react";
import { Modal, Box, Typography, Button, Tabs, Tab, Grid } from "@mui/material";

// 스타일 정의
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

interface StorageSupplyProps {
  onClickClose: () => void;
  open: boolean;
}

const StorageSupply: React.FC<StorageSupplyProps> = ({ onClickClose, open }) => {
  const [tabValue, setTabValue] = useState(0);

  // 탭 변경 핸들러
  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Grid>
      <Modal open={open} onClose={onClickClose} aria-labelledby="modal-title">
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            창고 목록
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={onChangeTab} aria-label="창고 정보 탭">
              <Tab label="1층" />
              <Tab label="2층" />
              <Tab label="3층" />
              <Tab label="공사" />
              <Tab label="물건" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <Box sx={{ p: 3 }}>
              <Typography>1층 창고에 대한 정보</Typography>
            </Box>
          )}
          {tabValue === 1 && (
            <Box sx={{ p: 3 }}>
              <Typography>2층 창고에 대한 정보</Typography>
            </Box>
          )}
          {tabValue === 2 && (
            <Box sx={{ p: 3 }}>
              <Typography>3층 창고에 대한 정보</Typography>
            </Box>
          )}
          {tabValue === 3 && (
            <Box sx={{ p: 3 }}>
              <Typography>공사 관련 정보</Typography>
            </Box>
          )}
          {tabValue === 4 && (
            <Box sx={{ p: 3 }}>
              <Typography>물건 관련 정보</Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button onClick={onClickClose} variant="contained" color="secondary">
              닫기
            </Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default StorageSupply;
