import React, { useEffect } from "react";
import { useDoctor } from "../DoctorsContext";
import { useDevice } from "../DevicesContext";
import { useProduct } from "../ProductContext";
import { useAuth } from "../AuthContext";
import { Doctor } from "../../models/DoctorModel";
import { Device } from "../../models/DevicesModel";
import { useSurgery } from "../SurgeryContext";
import { Counselor } from "../../models/CounselorModel";
import { useCounselor } from "../CounselorContext";
import { Product } from "../../models/ProductModel";

const apiUrl = "/sse";
const clientId = crypto.randomUUID();

export const SseContext = ({ children }: { children: React.ReactNode }) => {
  const { doctors, setDoctors } = useDoctor();
  const { deviceList, setDeviceList } = useDevice();
  const { counselorList, setCounselorList } = useCounselor();
  const { surgeryList, setSurgeryList } = useSurgery();
  const { user } = useAuth();

  const { productList, setPromotionProductList, setProductList, updateProductList } = useProduct();
  let eventSource: EventSource | null = null;
  let retryTimeout: NodeJS.Timeout;
  const token = localStorage.getItem("token");

  const connect = () => {
    console.log(eventSource);
    if (eventSource) {
      console.log("기존 sse 닫기");
      eventSource.close();
    }
    eventSource = new EventSource(`${apiUrl}/global?clientId=${user?.userId + "-" + clientId}`);

    eventSource.onopen = function () {
      console.log("Global SSE connection opened.");
    };
    eventSource.onmessage = function (event) {
      const parsedData = JSON.parse(event.data);
      const flag = parsedData.flag;
      const data = parsedData.data;

      switch (flag) {
        //doctor
        case "doctorColorUpdate":
          setDoctors((prevItems) =>
            prevItems.map((item) => {
              if (item.doctorId === data.doctorId) {
                return {
                  ...item,
                  color: data.color,
                };
              }
              return item;
            })
          );
          break;
        case "registerDoctor":
          const updatedDoctor = data;
          const parsedDoctor = {
            ...updatedDoctor,
            birthDate: updatedDoctor.birthDate ? new Date(updatedDoctor.birthDate) : null,
            joiningDate: new Date(updatedDoctor.joiningDate),
            exitDate: updatedDoctor.exitDate ? new Date(updatedDoctor.exitDate) : null,
          };
          setDoctors((prevDoctors) => [...prevDoctors, parsedDoctor]);
          break;
        //device
        case "updateDevice":
          const updatedDevice: Device = data;
          setDeviceList((prevDevices) => prevDevices.map((device) => (device.deviceId === updatedDevice.deviceId ? updatedDevice : device)));

          break;
        case "registerDevice":
          const registerDevice = data;
          setDeviceList((prevDevices) => [...prevDevices, registerDevice]);
          break;
        //surgery
        case "updateSurgery":
          const updatedSurgery = data;
          setSurgeryList((prevSurgery) => prevSurgery.map((surgery) => (surgery.surgeryId === updatedSurgery.surgeryId ? updatedSurgery : surgery)));
          break;
        case "registerSurgery":
          const registerSurgery = data;
          setSurgeryList((prevSurgery) => [...prevSurgery, registerSurgery]);
          break;
        case "deleteSurgery":
          const deleteSurgery = data;
          setSurgeryList((prevSurgery) => prevSurgery.filter((item) => item.surgeryId !== deleteSurgery.surgeryId));
          break;
        //counselor
        case "updateCounselor":
          const updatedCounselor: Counselor = data;
          // setCounselorList((prevCounselor) => prevCounselor.map((counselor) => (counselor.counselorId === updatedCounselor.counselorId ? { ...counselor, updatedCounselor } : counselor)));
          setCounselorList((prevCounselors) =>
            prevCounselors.map((existingCounselor) =>
              existingCounselor.counselorId === updatedCounselor.counselorId
                ? { ...existingCounselor, ...updatedCounselor } // 기존 데이터를 업데이트된 데이터로 덮어씀
                : existingCounselor
            )
          );
          break;
        case "registerCounselor":
          const registerCounselor = data;
          setCounselorList((prevCounselor) => [...prevCounselor, registerCounselor]);
          break;
        //product
        case "registerProduct":
          const registerProduct: Product = data;
          if (registerProduct.category === "promotion") {
            setPromotionProductList((prevList) => [...prevList, registerProduct]);
          } else {
            setProductList((prevList) => [...prevList, registerProduct]);
          }
          break;
        case "updateProduct":
          const updateProduct = data;
          updateProductList(updateProduct, updateProduct.category === "promotion");

          break;
        case "endProduct":
          const endProduct = data;
          updateProductList(endProduct, endProduct.category === "promotion");

          break;
      }
    };
    eventSource.onerror = function (error) {
      if (eventSource?.readyState === EventSource.CLOSED) {
        console.log("Reconnecting after 1 second...");
        if (eventSource) {
          console.log("eventSource closed");
        }
        retryTimeout = setTimeout(connect, 1000); // 1초 후 재연결 시도
        console.log("Reconnection try end");
      } else if (eventSource?.readyState === EventSource.CONNECTING) {
        console.log("Currently reconnecting...");
        eventSource.close(); // 기존 연결 닫기
        console.log(eventSource);
        setTimeout(connect, 1000); // 1초 후 새로운 연결 시도
      } else {
        console.log({ readyState: eventSource?.readyState });
        console.error("SSE connection error:", error);
      }
    };

    // keepAlive 이벤트 수신 및 처리 - 로드밸런서 연결 유지 역할
    eventSource.addEventListener("keepAlive", function (event) {
      console.log("Received keepAlive message:", event.data);
    });
  };

  useEffect(() => {
    connect();
    return () => {
      if (eventSource) {
        eventSource.close();
      }
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, []);

  return <>{children}</>;
};
