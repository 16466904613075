import React, { useState } from "react";
import { Grid, Typography, Button, Box, Tooltip, Modal } from "@mui/material";
import { CardProp } from "../DailyOperations";
import { useHomeContext } from "../Context";
import WaitingReservationCard from "./WaitingTransitionCard";
import ChartNumberTooltipName from "./ChartNumberTooltipName";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import RegisterVisitModal from "./RegisterVisitCard";

const VisitingCard: React.FC<CardProp> = ({ scheduleData }) => {
  const { returnCustomer, onClickDetailModalOpen, visitCustomer } = useHomeContext();
  const [waitModalOpen, setWaitModalOpen] = useState(false);
  const [visitModalOpen, setVisitModalOpen] = useState(false); // 모달 상태 관리'

  //귀가 현황으로 이동
  const onClickReturnCustomer = async (scheduleId: number) => {
    returnCustomer(scheduleId);
  };

  // 대기현황이동 모딜
  const onClickOpenWaitModal = () => setWaitModalOpen(true); // 모달 열기

  // 룸 변경 모달
  const onClickOpenVisitModal = () => {
    setVisitModalOpen(true);
  }; // 모달 열기
  // 룸 변경 모달 닫기
  const onClickCloseVisitModal = () => setVisitModalOpen(false); // 모달 닫기

  // 방문 고객 변경
  const onClickVisitCustomer = async (scheduleId: number, building?: string, floor?: string, room?: string) => {
    visitCustomer(scheduleId, building, floor, room);
    setVisitModalOpen(false);
  };

  return (
    <Grid container item sx={{ background: scheduleData.schedule.status === "onVisit" ? "#fafafa" : "#FEECEC", borderBottom: "1px solid #b0b0b0", padding: "8px" }}>
      <Grid container item sx={{ width: "100%", height: "54px" }}>
        <Grid container item xs={9.5}>
          <Box width="100%" display="flex" overflow="hidden" position="relative">
            <ChartNumberTooltipName scheduleData={scheduleData} />
            <Typography sx={{ fontSize: "12px", marginLeft: "4px" }}>
              ({scheduleData.customer.gender === "M" ? "남" : "여"}/{scheduleData.customer.age})
            </Typography>
            <Tooltip title={"정보 확인"} followCursor={false} arrow placement="right">
              <Box sx={{ fontSize: "14px", position: "absolute", right: "4px" }}>
                <InfoRoundedIcon sx={{ color: "#bdbdbd", fontSize: "14px", cursor: "pointer" }} onClick={() => onClickDetailModalOpen(scheduleData)} />
              </Box>
            </Tooltip>
          </Box>
          <Typography sx={{ width: "100%", height: "18px", fontSize: "12px" }} onClick={() => scheduleData.schedule.status !== "wait" && onClickOpenVisitModal()}>
            {scheduleData.schedule.building} {scheduleData.schedule.floor} {scheduleData.schedule.room}
          </Typography>
          <Box sx={{ width: "100%", height: "18px", fontSize: "12px", display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: scheduleData.schedule.doctor === null ? "#ff4d4d" : "black", marginRight: "5px", fontSize: "12px" }}>
              {scheduleData.schedule.doctor === null ? "의사 미지정" : scheduleData.schedule.doctor}
            </Typography>
            <Typography sx={{ marginRight: "5px", fontSize: "12px" }}>/</Typography>
            <Typography sx={{ color: scheduleData.schedule.counselor === null ? "#ff4d4d" : "black", fontSize: "12px" }}>
              {scheduleData.schedule.counselor === null ? "실장 미지정" : scheduleData.schedule.counselor}
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={2.5} direction="column" justifyContent="center" alignItems="center">
          <Button
            sx={{ width: "100%", height: "24px", fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textTransform: "none", mb: "6px", minWidth: "unset" }}
            onClick={() => (scheduleData.schedule.status === "wait" ? onClickOpenVisitModal() : onClickOpenWaitModal())}
          >
            {scheduleData.schedule.status !== "wait" ? "대기" : "룸할당"}
          </Button>
          <Button
            sx={{
              width: "100%",
              height: "24px",
              fontSize: "12px",
              textTransform: "none",
              minWidth: "unset",
              backgroundColor: "lightgray",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
              "&:hover": { backgroundColor: "#4f4f4f", color: "white" },
            }}
            onClick={() => {
              if (window.confirm("고객님의 시술이 완료되고 귀가하셨습니까?")) {
                onClickReturnCustomer(scheduleData.schedule.scheduleId);
              }
            }}
          >
            귀가
          </Button>
        </Grid>
      </Grid>
      <Grid container item sx={{ width: "100%", height: "18px" }}>
        <Typography
          sx={{
            width: "100%",
            height: "18px",
            fontSize: "12px",
            color: scheduleData.service.length === 0 ? "#ff4d4d" : "black",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {scheduleData.service.length === 0 ? "시술 플랜 미입력" : scheduleData.service.map((service) => service.name).join(", ")}
        </Typography>
      </Grid>
      <WaitingReservationCard open={waitModalOpen} setWaitModalOpen={setWaitModalOpen} scheduleData={scheduleData} />
      <Modal open={visitModalOpen} onClose={onClickCloseVisitModal} sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ width: "440px", p: "16px", backgroundColor: "white", borderRadius: "8px", height: "auto" }}>
          <RegisterVisitModal
            scheduleId={scheduleData.schedule.scheduleId}
            customerName={scheduleData.customer.name}
            initialBuilding={scheduleData.schedule.building}
            initialFloor={scheduleData.schedule.floor}
            initialRoom={scheduleData.schedule.room}
            onClickVisitCustomer={onClickVisitCustomer}
            onClose={onClickCloseVisitModal}
          />
        </Box>
      </Modal>
    </Grid>
  );
};
export default VisitingCard;
