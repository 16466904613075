import React, { ReactNode } from "react";
import Sidebar, { sidebarWidth } from "./Sidebar";
import { CssBaseline, Box, Grid } from "@mui/material";

const SidebarWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box display={"flex"} sx={{ width: "100%", height: "100vh" }}>
      <CssBaseline />
      <Box marginRight={`${sidebarWidth}px`}>
        <Sidebar />
      </Box>
      <Grid container width={`calc(100% - ${sidebarWidth}px)`} height="100%">
        <main style={{ flexGrow: 1 }}>{children}</main>
      </Grid>
    </Box>
  );
};

export default SidebarWrapper;
