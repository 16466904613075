import { MenuItem, TextField } from "@mui/material";

interface LanguageSelectorProps {
  selectedLanguage: string;
  onChange: (language: string) => void;
}

const LanguageList = [
  { id: 1, name: "한국어" },
  { id: 2, name: "영어" },
  { id: 3, name: "일본어" },
  { id: 4, name: "중국어" },
];

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ selectedLanguage, onChange }) => {
  return (
    <TextField
      variant="standard"
      select
      value={selectedLanguage}
      size="small"
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        style: {
          fontSize: "13px",
        },
      }}
      sx={{ minWidth: "70px", width: "100px", textAlign: "center", padding: "0px 10px" }}
    >
      {LanguageList.map((language) => (
        <MenuItem key={language.id} value={language.name} sx={{ fontSize: "13px" }}>
          {language.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LanguageSelector;
