import { Product } from "../models/ProductModel";
import { endProduct, fetchProductList, registerProduct, updateProduct } from "../service/ProductService";
import { handleControllerResponse } from "../util/Function";
class ProductController {
  async handleFetchProduct() {
    const fetchDeviceListResponse = await fetchProductList();
    return handleControllerResponse(fetchDeviceListResponse.status, fetchDeviceListResponse.data);
  }
  async handleRegisterProduct(productDto: Product) {
    const registerDeviceResponse = await registerProduct(productDto);
    return handleControllerResponse(registerDeviceResponse.status, registerDeviceResponse.data);
  }
  async handleUpdateProduct(productDto: Product) {
    const updateDeviceResponse = await updateProduct(productDto);
    return handleControllerResponse(updateDeviceResponse.status, updateDeviceResponse.data);
  }
  async handleEndProduct(productDto: Product) {
    const updateDeviceResponse = await endProduct(productDto);
    return handleControllerResponse(updateDeviceResponse.status, updateDeviceResponse.data);
  }
}
export const productController = new ProductController();
