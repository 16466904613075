import React from "react";
import { Grid } from "@mui/material";
import Notice from "./Notice";
import WorkAttendance from "./WorkSchedule";
import DailyOperations from "./DailyOperations";
import DoctorSchedule from "./DoctorSchedule";
import { ContextProvider } from "./Context";
import { SseContext } from "./SseContext";

const Home: React.FC = () => {
  return (
    <ContextProvider>
      <SseContext>
        <Grid container width="100%" height="100%" sx={{ padding: "10px" }}>
          <Grid container item xs={2.5} direction="column" width="100%" height="100%">
            <Grid container item xs={6} width="100%" height="50%">
              <Notice />
            </Grid>
            <Grid container item xs={6} width="100%" height="50%">
              <WorkAttendance />
            </Grid>
          </Grid>
          <Grid container item xs={7} sx={{ padding: "0px 8px" }}>
            <DailyOperations />
          </Grid>
          <Grid container item xs={2.5}>
            <DoctorSchedule />
          </Grid>
        </Grid>
      </SseContext>
    </ContextProvider>
  );
};

export default Home;
