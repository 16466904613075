import React, { createContext, useContext, ReactNode, useEffect, useState } from "react";
import { ScheduleData, Service } from "../Home/Model";
import { getCurrentTimeInMinutes } from "./utils";
import { scheduleController } from "../Home/Controller";

interface ScheduleContextType {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  scheduleItems: any[];
  currentTimeInMinutes: number;
  updateService: (newScheduleData: ScheduleData, checkServiceArray: Service[]) => Promise<boolean>;
}

export const ScheduleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date()); //날짜 선택
  const [scheduleItems, setScheduleItems] = useState<ScheduleData[]>([]);
  const [currentTimeInMinutes, setCurrentTimeInMinutes] = useState(getCurrentTimeInMinutes()); // 현재 시간

  //실시간 선 표시 자동 타이머
  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = getCurrentTimeInMinutes();
      setCurrentTimeInMinutes(newTime);
    }, 180000); // 300000 = 3분
    return () => clearInterval(interval);
  }, []);

  const fetchSchedule = async () => {
    const fetchShceduleResopnse = await scheduleController.fetchSchedule(selectedDate);
    if (fetchShceduleResopnse.success) {
      const parsedScheduleResponse: ScheduleData[] = (fetchShceduleResopnse.data as unknown as any[]).map((item: any) => {
        return {
          customer: item.customer,
          schedule: item.schedule,
          service: item.service,
          waiting: item.waiting,
        };
      });
      setScheduleItems(parsedScheduleResponse);
    } else {
      alert(fetchShceduleResopnse.message + "\nfetchSchedule Context");
    }
  };

  const updateService = async (newScheduleData: ScheduleData, checkServiceArray: Service[]) => {
    const savedServiceResponse = await scheduleController.updateService(checkServiceArray);

    if (savedServiceResponse.success) {
      const updatedServices = newScheduleData.service.map((service) => {
        if (service.serviceId === 0) {
          const matchingService = savedServiceResponse.data.find((responseData: Service) => responseData.name === service.name);
          return {
            ...service,
            serviceId: matchingService?.serviceId, // serviceId 치환
          };
        }
        return service; // serviceId가 0이 아니면 그대로 유지
      });

      // newScheduleData에 updatedServices 반영
      const updatedScheduleData = {
        ...newScheduleData,
        service: updatedServices,
      };

      // setScheduleItems에서 업데이트
      setScheduleItems((prevItems) =>
        prevItems.some((item) => item.customer.customerId === newScheduleData.customer.customerId)
          ? prevItems.map((item) => (item.customer.customerId === newScheduleData.customer.customerId ? updatedScheduleData : item))
          : [...prevItems, updatedScheduleData]
      );

      alert("성공적으로 저장되었습니다.");
    } else {
      alert("저장에 실패했습니다. \n 상욱님에게 문의해주세요");
    }

    return savedServiceResponse.success;
  };

  // M 임시로 home > service에 있는 fetchSchedule 사용, 추후 해당 fetch service는 전역으로 설정
  useEffect(() => {
    const fetchSchedule = async () => {
      const fetchScheduleResponse = await scheduleController.fetchSchedule(selectedDate);
      if (fetchScheduleResponse.success) {
        const parsedScheduleResponse: ScheduleData[] = (fetchScheduleResponse.data as unknown as any[]).map((item: any) => {
          return {
            customer: item.customer,
            schedule: item.schedule,
            service: item.service,
            waiting: item.waiting,
          };
        });
        setScheduleItems(parsedScheduleResponse);
      } else {
        alert(fetchScheduleResponse.message + "\nfetchSchedule Context");
      }
    };
    fetchSchedule();
  }, [selectedDate]);

  // M : SSE
  // const {
  //   data: scheduleData,
  //   error,
  //   isLoading,
  // } = useQuery({
  //   queryKey: ["scheduleData", formatDate(selectedDate)], // 쿼리 키
  //   queryFn: () => fetchSchedule(), // 데이터를 가져오는 함수
  //   staleTime: 5000,
  //   refetchInterval: 300000,
  //   refetchOnWindowFocus: false, // 창 포커스 시 재요청 방지
  //   retry: 3, // 쿼리 실패 시 3번까지 자동으로 재시도
  //   retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // 재시도 딜레이 설정 (점진적 증가)
  // });
  // useEffect(() => {
  //   if (scheduleData) {
  //     setScheduleItems(scheduleData);
  //   }
  // }, [scheduleData]);

  return <ScheduleContext.Provider value={{ selectedDate, setSelectedDate, scheduleItems, currentTimeInMinutes, updateService }}>{children}</ScheduleContext.Provider>;
};

const ScheduleContext = createContext<ScheduleContextType | undefined>(undefined);

export const useLocalContext = (): ScheduleContextType => {
  const context = useContext(ScheduleContext);
  if (context === undefined) {
    throw new Error("useLocalContext must be used within a ContextProvider");
  }
  return context;
};
