import { useState, useMemo } from "react";
import { useProduct } from "../../context/ProductContext";
import { Box, Button, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { LanguageType } from "../Customer/Model";
import { useLocalContext } from "./Context";
import CloseIcon from "@mui/icons-material/Close";
import { Product } from "../../models/ProductModel";
import ProductDetailModal from "./productDetailModal";

const fieldMap: Record<LanguageType, { name: keyof Product }> = {
  한국어: { name: "name" },
  영어: { name: "surgeryEnName" },
  중국어: { name: "surgeryCnName" },
  일본어: { name: "surgeryJpName" },
};

const nation = [
  { id: 0, name: "한국어" },
  { id: 1, name: "영어" },
  { id: 2, name: "일본어" },
  { id: 3, name: "중국어" },
];

export default function ProductList() {
  const { productList } = useProduct();
  const { language, setLanguage, setSelectCartList, setSelectProduct } = useLocalContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [keyword, setKeyword] = useState("");

  const filteredProducts = useMemo(() => {
    if (!searchTerm.trim()) return productList;
    return productList.filter((product) => product.name?.toLowerCase().includes(searchTerm?.toLowerCase()));
  }, [productList, searchTerm]);

  const addToCart = (product: Product) => {
    setSelectCartList((prevCart) => [
      ...prevCart,
      {
        ...product,
        checked: true, // 초기 체크 상태
        quantity: product.quantity || 1, // 초기 수량: 기본값 1
        vat: true,
        serviceCheck: false,
      },
    ]);
  };

  const getLocalizedValue = (item: Product, type: "name") => {
    const field = fieldMap[language][type]; // 타입 에러 없음
    return item[field] || "";
  };

  const onSubmitSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.nativeEvent.isComposing) {
      return;
    }
    event.preventDefault();
    const trimmedValue = keyword.trim();
    if (trimmedValue) {
      setSearchTerm(trimmedValue);
      setKeyword("");
    }
  };

  const onChangeDelete = () => {
    setKeyword("");
    setSearchTerm("");
  };

  return (
    <Grid container direction="column" sx={{ height: "100%", p: 1, justifyContent: "flex-end" }}>
      <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h2">상품 목록</Typography>
        <TextField select value={language} size="small" sx={{ width: "90px" }} onChange={(e) => setLanguage(e.target.value as LanguageType)}>
          {nation.map((nation) => (
            <MenuItem key={nation.id} value={nation.name}>
              {nation.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Box sx={{ display: "flex" }} gap={0.5} component={"form"} onSubmit={onSubmitSearch}>
        <TextField
          label="검색"
          variant="outlined"
          size="small"
          fullWidth
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onChangeDelete} size="small">
                  <CloseIcon sx={{ fontSize: 14 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Paper sx={{ borderRadius: "8px", display: "flex", flexDirection: "column", boxShadow: 3, width: "100%", height: "calc(100% - 100px)" }}>
        <Grid sx={{ overflowY: "auto", height: "100%" }}>
          <Grid>
            {filteredProducts.map((product, index) => (
              <Grid key={index} sx={{ borderBottom: "1px solid black", padding: "10px", flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
                <Grid item gap={2} xs={10}>
                  <Typography sx={{ color: "gray", fontSize: "12px" }}>{`${product.categoryLarge} > ${product.categoryMedium}`}</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>{getLocalizedValue(product, "name")}</Typography>
                  <Typography sx={{ color: "#B40404" }}>{(product?.priceWithVat || 0).toLocaleString()}원</Typography>

                  <Typography>{product.description}</Typography>
                </Grid>
                <Grid item sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }} gap={1} xs={2}>
                  <Button onClick={() => addToCart(product)} sx={{ minWidth: "unset", fontSize: "12px" }}>
                    추가
                  </Button>
                  <Button
                    sx={{ minWidth: "unset", fontSize: "12px" }}
                    onClick={() => {
                      setSelectProduct(product);
                      setModalOpen(true);
                    }}
                  >
                    상세
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {filteredProducts.length === 0 && <Typography sx={{ textAlign: "center", mt: 2 }}>검색 결과가 없습니다.</Typography>}
        </Grid>
      </Paper>
      <ProductDetailModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Grid>
  );
}
