import React, { useState } from "react";
import { Grid, Typography, Paper, Button, TextField, MenuItem } from "@mui/material";
import { formatPhoneNumber, formatDate, calculateYearsMonthsDays } from "../../util/Function";
import PersonIcon from "@mui/icons-material/Person";
import { useCounselor } from "../../context/CounselorContext";
import { Counselor } from "../../models/CounselorModel";
import { useLocalContext } from "./Context";

const nation = [
  { id: 1, name: "한국" },
  { id: 2, name: "중국" },
  { id: 3, name: "일본" },
];
const CounselorDetails: React.FC = () => {
  const { updateCounselor } = useCounselor();
  const { setSelectedCounselor, selectedCounselor } = useLocalContext();
  const [isEdit, setIsEdit] = useState(false);
  const [editedCounselor, setEditedCounselor] = useState<Counselor | undefined>(undefined);

  const onClickSave = () => {
    if (!selectedCounselor || !editedCounselor) {
      alert("선택된 상담사가 없습니다."); // 에러 핸들링
      return;
    }

    const updatedCounselor = {
      ...selectedCounselor, // 기존 데이터
      nationality: editedCounselor?.nationality, // 덮어쓸 값
      phoneNumber: editedCounselor?.phoneNumber,
      birthDate: editedCounselor?.birthDate,
      joiningDate: editedCounselor?.joiningDate,
    };

    updateCounselor(updatedCounselor);
    setSelectedCounselor(updatedCounselor);
    setIsEdit(false);
  };

  const onChangeEdit = () => {
    setIsEdit(!isEdit);
    // 편집 모드로 전환할 때 현재 선택된 상담사 정보로 초기화
    if (!isEdit && selectedCounselor) {
      setEditedCounselor(selectedCounselor);
    } else {
      setEditedCounselor(undefined); // 편집 모드 종료시 초기화
    }
  };

  const onChangeEditedCounselor = (name: string, value: unknown) => {
    setEditedCounselor((prevValues) => {
      if (!prevValues) return prevValues;
      return {
        ...prevValues,
        [name]: value,
      } as Counselor;
    });
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          상담사 세부사항
        </Typography>
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, boxShadow: 3, borderRadius: "8px", marginTop: "4px", display: "flex" }}>
        {selectedCounselor === undefined ? (
          <Typography variant="h2" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
            조회하고 싶은 상담사를 목록에서 선택해 주세요
          </Typography>
        ) : (
          <>
            <Grid
              container
              item
              xs={3}
              sx={{
                height: "100%",
                padding: "12px",
                background: "#f0f0fb",
                borderRadius: "8px 0px 0px 8px",
                textAlign: "center",
                fontSize: "14px",
                zIndex: 1,
              }}
              direction="column"
            >
              <Grid item xs={5} sx={{ height: "100%", overflow: "hidden", padding: "8px" }}>
                {selectedCounselor?.imgUrl ? (
                  <img
                    src={"https://aac-amred-spaceos.s3.ap-northeast-2.amazonaws.com/doctors/" + selectedCounselor?.imgUrl}
                    alt={selectedCounselor?.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      maxHeight: "100%",
                    }}
                  />
                ) : (
                  <Grid
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#e0e0e0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <PersonIcon sx={{ height: "100%", width: "100%", color: "#808080" }} />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={1} sx={{ fontWeight: "bold" }}>
                {selectedCounselor?.name}
              </Grid>

              <Grid container item xs={0.7} sx={{ display: "flex" }}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  전화번호
                </Grid>
                <Grid item xs={8}>
                  {isEdit ? (
                    <TextField
                      name="phone"
                      type="phone"
                      value={editedCounselor?.phoneNumber}
                      onChange={(event) => onChangeEditedCounselor("phoneNumber", event.target.value)}
                      size="small"
                      placeholder="전화번호"
                      sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                    />
                  ) : (
                    <>{formatPhoneNumber(selectedCounselor?.phoneNumber)}</>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  생년월일
                </Grid>
                <Grid item xs={8}>
                  {isEdit ? (
                    <TextField
                      name="date"
                      type="date"
                      value={formatDate(editedCounselor?.birthDate)}
                      onChange={(event) => onChangeEditedCounselor("birthDate", event.target.value)}
                      size="small"
                      placeholder="생년월일"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": { height: "25px", fontSize: "12px" },
                        "& input": {
                          padding: "5px 8px", // 내부 패딩 조절
                        },
                      }}
                    />
                  ) : (
                    <> {selectedCounselor?.birthDate ? new Date(selectedCounselor?.birthDate).toLocaleDateString() : "날짜 없음"}</>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  입사날짜
                </Grid>
                <Grid item xs={8}>
                  {isEdit ? (
                    <TextField
                      name="date"
                      type="date"
                      value={formatDate(editedCounselor?.joiningDate)}
                      onChange={(event) => onChangeEditedCounselor("joiningDate", event.target.value)}
                      size="small"
                      placeholder="입사날짜"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": { height: "25px", fontSize: "12px" },
                        "& input": {
                          padding: "5px 8px", // 내부 패딩 조절
                        },
                      }}
                    />
                  ) : (
                    <>{selectedCounselor?.joiningDate ? new Date(selectedCounselor.joiningDate).toLocaleDateString() : "날짜 없음"}</>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  재직기간
                </Grid>
                <Grid item xs={8}>
                  {selectedCounselor?.exitDate ? calculateYearsMonthsDays(selectedCounselor.joiningDate, selectedCounselor.exitDate) : `${calculateYearsMonthsDays(selectedCounselor?.joiningDate)}`}{" "}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  퇴사날짜
                </Grid>
                <Grid item xs={8}>
                  {formatDate(selectedCounselor?.exitDate) === "" ? "재직중" : formatDate(selectedCounselor?.exitDate)}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  국적
                </Grid>
                <Grid item xs={8}>
                  {isEdit ? (
                    <TextField
                      name="nationality"
                      select
                      fullWidth
                      variant="standard"
                      value={editedCounselor?.nationality}
                      onChange={(event) => onChangeEditedCounselor("nationality", event.target.value)}
                      size="small"
                      placeholder="국적"
                      sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                    >
                      {nation.map((nation, i) => (
                        <MenuItem key={i} value={nation.name} sx={{ fontSize: "12px" }}>
                          {nation.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <> {selectedCounselor?.nationality}</>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={1.8}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                {isEdit ? (
                  <>
                    <Button sx={{ minWidth: "unset", fontSize: "12px", mr: 1 }} onClick={() => setIsEdit(false)}>
                      취소
                    </Button>
                    <Button sx={{ minWidth: "unset", fontSize: "12px" }} onClick={onClickSave}>
                      저장
                    </Button>
                  </>
                ) : (
                  <Button sx={{ minWidth: "unset", fontSize: "12px" }} onClick={onChangeEdit}>
                    수정
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container item sx={{ height: "100%" }}>
              <Typography variant="h2" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
                Coming Soon
              </Typography>
            </Grid>
          </>
        )}
      </Paper>
    </Grid>
  );
};
export default CounselorDetails;
