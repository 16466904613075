import React from "react";
import {
  Box,
  TextField,
  Input,
  Button,
  Typography,
  IconButton,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Grid,
  Autocomplete,
  InputBase,
  Checkbox,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Customer, initialService, Schedule, ScheduleData, Service } from "../../Home/Model";
import { parseBuildingsToFloors, parseBuildingsToRooms } from "../../../util/Function";
import { buildings, rooms } from "../../../models/RoomModel";
import { useDoctor } from "../../../context/DoctorsContext";
import { SurgeryTime } from "./SurgeryTime";
import { useCounselor } from "../../../context/CounselorContext";

interface ModalSurgeryDetailsProps {
  newScheduleData: ScheduleData;
  setNewScheduleData: React.Dispatch<React.SetStateAction<ScheduleData>>;
  scheduleItem: ScheduleData;
}

const ModalSurgeryDetails: React.FC<ModalSurgeryDetailsProps> = ({ newScheduleData, setNewScheduleData, scheduleItem }) => {
  const { doctors } = useDoctor();
  const today = new Date().toLocaleDateString().replace(/\./g, "").replace(/\s/g, "-");
  const { counselorList } = useCounselor();

  // DESC newScheduleData - schedule 변경 함수
  const onChangeNewScheduleDetails = (field: keyof Schedule, value: string) => {
    setNewScheduleData((prev) => ({
      ...prev,
      schedule: {
        ...prev.schedule,
        [field]: value,
      },
    }));
  };

  const changeNewScheduleData = (field: keyof ScheduleData, value: string | Customer | Partial<Service>[] | Schedule) => {
    setNewScheduleData({ ...newScheduleData, [field]: value });
  };

  // DESC newScheduleData - service[] 변경
  const onChangeNewSurgeryDetails = (index: number, field: keyof Service, value: string) => {
    const updatedSurgeryList = newScheduleData.service.map((surgery, i) => (i === index ? { ...surgery, [field]: value, order: index } : { ...surgery, order: i }));
    changeNewScheduleData("service", updatedSurgeryList);
  };

  // DESC 시술 스케줄 추가 | service[]
  const onClickAddSurgery = () => {
    const newSurgery = {
      ...initialService,
      doctor: newScheduleData.schedule.doctor || "",
      room: newScheduleData.schedule.room || "",
      scheduleId: scheduleItem.schedule.scheduleId,
      date: today,
      customerId: scheduleItem.customer.customerId,
    };
    const newSurgeries = [...newScheduleData.service, newSurgery];
    changeNewScheduleData("service", newSurgeries);
  };

  // DESC 시술 스케줄 삭제 | service[]
  const onClickRemoveSurgery = (index: number, serviceId: number) => {
    // 만약 serviceId가 0이 아니라면?(서버 저장) -> status를 deleted로 함
    if (serviceId !== 0) onChangeNewSurgeryDetails(index, "status", "deleted");
    else {
      // serviceId가 0이라면? -> 그냥 배열에서 없앰 (서버 미저장)
      const deletedSurgeryList = newScheduleData.service.filter((_, i) => i !== index);
      changeNewScheduleData("service", deletedSurgeryList);
    }
  };

  // DESC 시술 플랜 자동 추가 함수 | service[]
  const onClickAutoMakesSurgeries = () => {
    let newSurgeries = [...newScheduleData.service];
    const splitParts = newScheduleData.schedule.surgeriesPlan.split("-").map((part) => part.trim());
    splitParts.forEach((part) => {
      const newSurgery = {
        name: part,
        doctor: newScheduleData.schedule.doctor,
        startTime: "",
        endTime: "",
        room: newScheduleData.schedule.room,
        serviceTime: "",
        serviceUuid: "",
        scheduleId: scheduleItem.schedule.scheduleId,
        serviceId: 0,
        memo: "",
        status: "beforeReady",
        date: today,
        customerId: scheduleItem.customer.customerId,
      };
      newSurgeries = [...newSurgeries, newSurgery];
    });
    changeNewScheduleData("service", newSurgeries);
  };

  // TODO 시작/종료 버튼 api 연결
  // Q 저장하지 않고서도 시작/종료가 가능한지? -> 불가
  const handleStart = async (surgery: Service) => {
    // const token = localStorage.getItem("token");
    // if (!token) {
    //   console.error("No token found");
    //   return "error";
    // }
    // try {
    //   const startResponse = await axios.post("/schedule/startSurgery", surgery, { headers: { Authorization: `Bearer ${token}` } });
    //   if (startResponse.status === 200) {
    //     if (startResponse.data !== "error") {
    //       return startResponse.data;
    //     }
    //   } else {
    //     console.error("Failed to save startingTime");
    //     return "error";
    //   }
    // } catch (error) {
    //   console.error("Error starting surgery", error);
    //   return "error";
    // }
  };

  const handleEnd = async (surgery: Service) => {
    // const token = localStorage.getItem("token");
    // if (!token) {
    //   console.error("No token found");
    //   return "error";
    // }
    // try {
    //   const startResponse = await axios.post("/schedule/endSurgery", surgery, { headers: { Authorization: `Bearer ${token}` } });
    //   if (startResponse.status === 200) {
    //     return startResponse.data;
    //   } else {
    //     console.error("Failed to save endingTime");
    //     return "error";
    //   }
    // } catch (error) {
    //   console.error("Error starting surgery", error);
    //   return "error";
    // }
  };

  return (
    <Grid container item xs={9.4} direction="column" sx={{ overflow: "hidden", height: "100%", backgroundColor: "white" }}>
      {/* M 상 */}
      <Grid container item xs={5} sx={{ overflow: "hidden", height: "auto" }}>
        {/* M 방문 일정 */}
        <Grid container item xs={6} sx={{ height: "100%" }}>
          <Grid item xs={6} padding={0.5}>
            <TextField
              label="방문 예정 시간"
              type="time"
              value={newScheduleData.schedule.expectedVisitTime}
              onChange={(e) => onChangeNewScheduleDetails("expectedVisitTime", e.target.value)}
              inputProps={{ step: 300 }}
              fullWidth
              InputProps={{ sx: { fontSize: "14px" } }}
              InputLabelProps={{ sx: { fontSize: "14px" }, shrink: true }}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} padding={0.5}>
            <TextField
              label="귀가 예정 시간"
              type="time"
              value={newScheduleData.schedule.expectedReturnTime ?? ""}
              onChange={(e) => onChangeNewScheduleDetails("expectedReturnTime", e.target.value)}
              inputProps={{ step: 300 }}
              fullWidth
              InputProps={{ sx: { fontSize: "14px" } }}
              InputLabelProps={{ sx: { fontSize: "14px" }, shrink: true }}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} paddingX={0.5}>
            <InputLabel id="counselor-label" sx={{ fontSize: "12px", color: "#b0b0b0", height: "16px" }}>
              방문 담당 상담실장
            </InputLabel>
            <Select
              value={newScheduleData.schedule.counselor ?? ""}
              onChange={(e) => onChangeNewScheduleDetails("counselor", e.target.value)}
              labelId="counselor-label"
              variant="standard"
              sx={{ width: "100%", fontSize: "14px", textAlign: "center" }}
              inputProps={{ sx: { textAlign: "center" } }}
            >
              {counselorList.map((counselor) => (
                <MenuItem key={counselor.counselorId} value={counselor.name}>
                  {counselor.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} paddingX={0.5}>
            <InputLabel id="doctor-label" sx={{ fontSize: "12px", color: "#b0b0b0", height: "16px" }}>
              방문 담당 원장
            </InputLabel>
            <Select
              value={newScheduleData.schedule.doctor ?? ""}
              onChange={(e) => onChangeNewScheduleDetails("doctor", e.target.value)}
              labelId="doctor-label"
              variant="standard"
              sx={{ width: "100%", fontSize: "14px", textAlign: "center" }}
              inputProps={{ sx: { textAlign: "center" } }}
            >
              {doctors.map((doctor, i) => (
                <MenuItem key={i} value={doctor.name}>
                  {doctor.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4} padding={0.5}>
            <TextField
              variant="standard"
              select
              label="본관/신관"
              value={newScheduleData.schedule.building}
              onChange={(e) => {
                onChangeNewScheduleDetails("building", e.target.value);
                onChangeNewScheduleDetails("floor", "");
                onChangeNewScheduleDetails("room", "");
              }}
              sx={{ textAlign: "center" }}
              InputProps={{ sx: { fontSize: "14px" } }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
              fullWidth
            >
              {buildings.map((building) => (
                <MenuItem key={building.id} value={building.name}>
                  {building.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} padding={0.5}>
            <TextField
              select
              variant="standard"
              label="층 선택"
              value={newScheduleData.schedule.floor}
              onChange={(e) => onChangeNewScheduleDetails("floor", e.target.value)}
              fullWidth
              InputProps={{ sx: { fontSize: "14px" } }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
              sx={{ textAlign: "center" }}
              disabled={newScheduleData.schedule?.building === ""}
            >
              {parseBuildingsToFloors(buildings, newScheduleData.schedule?.building || "").map((floor) => (
                <MenuItem key={floor.id} value={floor.name}>
                  {floor.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} padding={0.5}>
            <TextField
              variant="standard"
              select
              label="룸 선택"
              value={newScheduleData.schedule.room}
              onChange={(e) => onChangeNewScheduleDetails("room", e.target.value)}
              sx={{ textAlign: "center" }}
              InputProps={{ sx: { fontSize: "14px" } }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
              disabled={newScheduleData.schedule?.floor === ""}
              fullWidth
            >
              {parseBuildingsToRooms(buildings, newScheduleData.schedule?.building || "", newScheduleData.schedule?.floor || "").map((room) => (
                <MenuItem key={room.id} value={room.name}>
                  {room.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} paddingX={0.5}>
            <TextField
              variant="standard"
              label="프로그램 이름"
              value={newScheduleData.schedule?.program ?? ""}
              InputProps={{ sx: { fontSize: "14px" } }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
              onChange={(e) => onChangeNewScheduleDetails("program", e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        {/* M 메모 4개 */}
        <Grid container item xs={6} sx={{ height: "100%" }}>
          {/* M 어레인저 메모/시술 플랜 */}
          <Grid container item xs={6} direction="column" sx={{ height: "100%" }} paddingX={0.5}>
            <Grid item xs={6} sx={{ height: "50%" }}>
              <Typography className="text-title-3" align="center" gutterBottom sx={{ height: "24px", margin: "0px", fontSize: "16px", fontWeight: "bold" }}>
                어레인저 메모
              </Typography>
              <Grid item sx={{ height: "calc(100% - 24px)" }}>
                <Box height="100%">
                  <TextField
                    value={newScheduleData.schedule.arrangerMemo ?? ""}
                    onChange={(e) => onChangeNewScheduleDetails("arrangerMemo", e.target.value)}
                    multiline
                    fullWidth
                    variant="outlined"
                    sx={{ height: "calc(100% - 8px)" }}
                    InputProps={{
                      sx: { fontSize: "12px", padding: "8px", height: "100%", alignItems: "flex-start", overflowY: "auto" },
                      inputComponent: "textarea",
                      inputProps: {
                        style: { height: "100%", overflow: "auto", boxSizing: "border-box" },
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* M 시술 플랜 */}
            <Grid item xs={6} sx={{ position: "relative", height: "50%" }}>
              <Box sx={{ height: "24px", position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography className="text-title-3" align="center" gutterBottom sx={{ height: "24px", margin: "0px", fontSize: "16px", fontWeight: "bold" }}>
                  시술 플랜
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  disabled={!newScheduleData.schedule.surgeriesPlan || newScheduleData.schedule.surgeriesPlan.trim() === ""}
                  sx={{
                    color: "white",
                    padding: "2px 6px",
                    fontSize: "10px",
                    minWidth: "auto",
                    height: "20px",
                    position: "absolute",
                    right: "0",
                    bgcolor: "#655dc6",
                    ":hover": { bgcolor: "#554ea2" },
                  }}
                  onClick={onClickAutoMakesSurgeries}
                >
                  시술 추가
                </Button>
              </Box>
              <Grid item sx={{ height: "calc(100% - 24px)" }}>
                <Box height="100%">
                  <TextField
                    value={newScheduleData.schedule.surgeriesPlan ?? ""}
                    onChange={(e) => onChangeNewScheduleDetails("surgeriesPlan", e.target.value)}
                    multiline
                    fullWidth
                    variant="outlined"
                    placeholder="시술 플랜을 '-'로 구분해서 입력해주세요"
                    sx={{ height: "100%" }}
                    InputProps={{
                      sx: { fontSize: "12px", padding: "8px", height: "100%", alignItems: "flex-start", overflowY: "auto" },
                      inputComponent: "textarea",
                      inputProps: {
                        style: { height: "100%", overflow: "auto", boxSizing: "border-box" },
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* TODO doctorMemo, counselorMemo 두 개가 있는데 어떤 걸로 하는지? -> employeeMemo로 변경 */}
          <Grid container item xs={6} direction="column" paddingX={0.5}>
            <Grid item>
              <Typography className="text-title-3" align="center" sx={{ margin: "0px", fontSize: "16px", fontWeight: "bold" }}>
                시술 메모
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <TextField
                value={newScheduleData.schedule.doctorMemo ?? ""}
                onChange={(e) => onChangeNewScheduleDetails("doctorMemo", e.target.value)}
                multiline
                rows={9}
                fullWidth
                sx={{ height: "100%" }}
                variant="outlined"
                InputProps={{
                  sx: { fontSize: "12px", padding: "8px", height: "100%", alignItems: "flex-start", overflowY: "auto" },
                  inputComponent: "textarea",
                  inputProps: {
                    style: { height: "100%", overflow: "auto", boxSizing: "border-box" },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* M 하 */}
      <Grid container item xs={7} sx={{ flexGrow: 1, overflow: "hidden", display: "flex", flexDirection: "column", paddingTop: 2 }}>
        <Grid item xs={12} sx={{ flexGrow: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
          <TableContainer
            sx={{
              flexGrow: 1,
              height: "200px",
              overflowY: "auto",
              backgroundColor: "white",
              borderRadius: "4px",
              border: "1px solid lightgrey",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Table stickyHeader sx={{ borderCollapse: "collapse" }}>
              {/*M TABLE HEADER*/}
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "5%", textAlign: "center", padding: "0px", fontWeight: "bold" }}>준비</TableCell>
                  <TableCell style={{ width: "25%", textAlign: "center", padding: "0px", fontWeight: "bold" }}>시술</TableCell>
                  <TableCell style={{ width: "10%", textAlign: "center", padding: "0px", fontWeight: "bold" }}>담당의사</TableCell>
                  <TableCell style={{ width: "15%", textAlign: "center", padding: "0px", fontWeight: "bold" }}>룸</TableCell>
                  <TableCell style={{ width: "10%", textAlign: "center", padding: "0px", fontWeight: "bold" }}>시작시간</TableCell>
                  <TableCell style={{ width: "10%", textAlign: "center", padding: "0px", fontWeight: "bold" }}>종료시간</TableCell>
                  <TableCell style={{ width: "10%", textAlign: "center", padding: "0px", fontWeight: "bold" }}>소요시간</TableCell>
                  <TableCell style={{ width: "5%", textAlign: "center", padding: "0px", fontWeight: "bold" }}></TableCell>
                </TableRow>
              </TableHead>
              {/* M TABLE BODY */}
              <TableBody>
                {newScheduleData.service.map((surgery, index) =>
                  surgery.status === "deleted" ? null : (
                    <TableRow key={index} sx={{ backgroundColor: surgery.serviceId === 0 ? "#f9f9f9" : null }}>
                      <TableCell style={{ border: "1px solid #ddd", borderLeft: 0, padding: "0px", textAlign: "center" }}>
                        {/* M 방문 */}
                        <Checkbox
                          sx={{ padding: "0px" }}
                          checked={surgery.status === "getReady"}
                          disabled={surgery.name === "방문"}
                          onChange={(event) => onChangeNewSurgeryDetails(index, "status", event.target.checked ? "getReady" : "beforeReady")}
                        />
                      </TableCell>
                      {/* M 시술이름 */}
                      <TableCell style={{ border: "1px solid #ddd", padding: "0px", textAlign: "center" }}>
                        <InputBase
                          value={surgery.name}
                          onChange={(e) => onChangeNewSurgeryDetails(index, "name", e.target.value)}
                          className="input"
                          fullWidth
                          placeholder="시술단계 이름을 자유롭게 적어주세요"
                          sx={{ fontSize: "14px", "& input": { textAlign: "center" } }}
                        />
                      </TableCell>
                      {/* M 담당의사 */}
                      <TableCell style={{ border: "1px solid #ddd", padding: "0px", textAlign: "center" }}>
                        <Select
                          value={doctors.find((doctor) => doctor.name === surgery.doctor)?.name ?? "미지정"}
                          onChange={(e) => onChangeNewSurgeryDetails(index, "doctor", e.target.value)}
                          variant="standard"
                          sx={{ width: "100%", fontSize: "14px", textAlign: "center" }}
                          input={<Input disableUnderline />}
                          inputProps={{ sx: { textAlign: "center" } }}
                          renderValue={(selected) => (selected === "미지정" ? <span style={{ color: "#655dc6" }}>미지정</span> : selected)}
                        >
                          <MenuItem value="미지정" sx={{ color: "gray" }}>
                            미지정
                          </MenuItem>
                          {doctors.map((doctor, i) => (
                            <MenuItem key={i} value={doctor.name}>
                              {doctor.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      {/* M 룸설정 */}
                      <TableCell style={{ border: "1px solid #ddd", padding: "0px", textAlign: "center" }}>
                        <Autocomplete
                          value={rooms.find((doc) => doc.name === surgery.room) || null}
                          onChange={(event, newValue) => onChangeNewSurgeryDetails(index, "room", newValue ? newValue.name : "")}
                          inputValue={surgery.room}
                          onInputChange={(event, newValue) => onChangeNewSurgeryDetails(index, "room", newValue)}
                          options={rooms}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: "14px", textAlign: "center" },
                                disableUnderline: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                style: { textAlign: "center" },
                              }}
                            />
                          )}
                          fullWidth
                        />
                      </TableCell>
                      {/* TODO 시작/종료 버튼 -> 실시간 연동이 되어야 함, 시술 이름이 들어가면 클릭이 안 됨 */}
                      <TableCell style={{ border: "1px solid #ddd", padding: "0px", textAlign: "center" }}>
                        {surgery.startTime === "" ? (
                          <Button
                            variant="contained"
                            onClick={() => handleStart(surgery)}
                            disabled={!scheduleItem.service.find((service) => service.name === surgery.name)}
                            sx={{
                              padding: "2px 2px",
                              fontSize: "12px",
                              color: "white",
                              backgroundColor: "#655dc6", // 기본 색상
                              "&:hover": {
                                backgroundColor: "#554ea2", // 호버 시 색상
                              },
                            }}
                          >
                            시작
                          </Button>
                        ) : (
                          <TextField
                            disabled
                            type="time"
                            value={surgery.startTime}
                            onChange={(e) => onChangeNewSurgeryDetails(index, "startTime", e.target.value)}
                            fullWidth
                            variant="standard"
                            inputProps={{ step: 1, style: { fontSize: "14px", textAlign: "right" } }}
                            sx={{ fontSize: "12px", "& .MuiInput-underline:before": { borderBottom: "none" }, "& .MuiInput-underline:disabled:before": { borderBottom: "none" } }}
                          />
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "0px", textAlign: "center" }}>
                        {surgery.endTime === "" ? (
                          <Button
                            variant="contained"
                            onClick={() => handleEnd(surgery)}
                            disabled={!scheduleItem.service.find((service) => service.name === surgery.name)}
                            sx={{
                              padding: "2px 2px",
                              fontSize: "12px",
                              color: "white",
                              backgroundColor: "#655dc6", // 기본 색상
                              "&:hover": {
                                backgroundColor: "#554ea2", // 호버 시 색상
                              },
                            }}
                          >
                            종료
                          </Button>
                        ) : (
                          <TextField
                            disabled
                            type="time"
                            value={surgery.endTime}
                            onChange={(e) => onChangeNewSurgeryDetails(index, "endTime", e.target.value)}
                            fullWidth
                            variant="standard"
                            inputProps={{ step: 1, style: { fontSize: "14px", textAlign: "right" } }}
                            sx={{ fontSize: "12px", "& .MuiInput-underline:before": { borderBottom: "none" }, "& .MuiInput-underline:disabled:before": { borderBottom: "none" } }}
                          />
                        )}
                      </TableCell>
                      {/* M 소요시간 */}
                      <TableCell style={{ border: "1px solid #ddd", padding: "0px", textAlign: "center", color: "#655dc6" }}>
                        <Typography sx={{ fontSize: "14px" }}>
                          {surgery.startTime === "" ? "-" : surgery.serviceTime === "" ? <SurgeryTime startTime={surgery.startTime} /> : surgery.serviceTime}
                        </Typography>
                      </TableCell>
                      {/* M 삭제버튼 */}
                      <TableCell style={{ border: "1px solid #ddd", padding: "0px", textAlign: "center", backgroundColor: "#e1e1f5", cursor: "pointer" }}>
                        <IconButton onClick={() => onClickRemoveSurgery(index, surgery.serviceId)} disabled={false}>
                          <CloseRoundedIcon sx={{ fontSize: "16px" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
            <Grid item textAlign="center" width="100%">
              <IconButton onClick={onClickAddSurgery}>
                <AddRoundedIcon />
              </IconButton>
            </Grid>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalSurgeryDetails;
