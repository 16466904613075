import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Tooltip, Box, Modal } from "@mui/material";
import { formatPhoneNumber } from "../../../util/Function";
import { calculateTimeDifference } from "../../../util/Function";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { CardProp } from "../DailyOperations";
import { useHomeContext } from "../Context";
import RegisterVisitModal from "./RegisterVisitCard";
import ChartNumberTooltipName from "./ChartNumberTooltipName";

export const parsingTimeDifference = (timeDifferenceInMs: number) => {
  const absTimeDiff = Math.abs(timeDifferenceInMs);
  const hours = Math.floor(absTimeDiff / (1000 * 60 * 60))
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((absTimeDiff % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, "0");

  if (timeDifferenceInMs < 0) {
    if (hours === "00") {
      return `+${minutes}분`;
    } else {
      return `+${hours}시간 ${minutes}분`;
    }
  } else {
    if (hours === "00") {
      return `-${minutes}분`;
    } else {
      return `-${hours}시간 ${minutes}분`;
    }
  }
};

const ReservationCard: React.FC<CardProp> = ({ scheduleData }) => {
  const { visitCustomer, cancelSchedule, onClickDetailModalOpen } = useHomeContext();
  const [timeDifference, setTimeDifference] = useState<number>(0);

  useEffect(() => {
    setTimeDifference(calculateTimeDifference(scheduleData.schedule.expectedVisitTime));
    const intervalId = setInterval(() => {
      setTimeDifference(calculateTimeDifference(scheduleData.schedule.expectedVisitTime));
    }, 60000);

    return () => clearInterval(intervalId);
  }, [scheduleData.schedule.expectedVisitTime]);

  const onClickVisitCustomer = async (scheduleId: number, building?: string, floor?: string, room?: string) => {
    visitCustomer(scheduleId, building, floor, room);
    setVisitModalOpen(false);
  };
  const onClickCancelSchedule = async (scheduleId: number) => {
    cancelSchedule(scheduleId);
  };

  const [visitModalOpen, setVisitModalOpen] = useState(false); // 모달 상태 관리

  const onClickOpenVisitModal = () => {
    setVisitModalOpen(true);
  }; // 모달 열기
  const onClickCloseVisitModal = () => setVisitModalOpen(false); // 모달 닫기

  return (
    <Grid container item sx={{ width: "100%", height: "70px", background: scheduleData.schedule.status === "beforeVisit" ? "#fafafa" : "#E0E0E0", borderBottom: "1px solid #b0b0b0", padding: "8px" }}>
      <Grid container item xs={9.5}>
        <Box width="100%" display="flex" overflow="hidden" position="relative">
          <ChartNumberTooltipName scheduleData={scheduleData} />
          <Typography sx={{ fontSize: "12px", marginLeft: "4px" }}>
            ({scheduleData.customer.gender === "M" ? "남" : "여"}/{scheduleData.customer.age})
          </Typography>
          <Tooltip title={"정보 확인"} followCursor={false} arrow placement="right">
            <Box sx={{ width: "auto", height: "18px", fontSize: "14px", maxWidth: "10%", position: "absolute", right: "4px" }}>
              <InfoRoundedIcon sx={{ color: "#bdbdbd", fontSize: "14px", cursor: "pointer" }} onClick={() => onClickDetailModalOpen(scheduleData)} />
            </Box>
          </Tooltip>
        </Box>

        <Box width="100%" display="flex" overflow="hidden" position="relative">
          <Typography component="span" sx={{ fontSize: "12px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {scheduleData.schedule.building} {scheduleData.schedule.floor} /
          </Typography>
          <Typography component="span" sx={{ fontSize: "12px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", marginLeft: "4px" }}>
            {formatPhoneNumber(scheduleData.customer.phoneNumber)}
          </Typography>
        </Box>
        <Box display="flex" overflow="hidden" position="relative">
          <Typography sx={{ fontSize: "12px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{scheduleData.schedule.expectedVisitTime.slice(0, 5)} 방문예정</Typography>
          {scheduleData.schedule.status !== "cancel" && timeDifference < 1000 * 60 * 10 && (
            <Typography component="span" sx={{ fontSize: "12px", color: timeDifference > 0 ? "blue" : "#ff4d4d", marginLeft: "4px" }}>
              {parsingTimeDifference(timeDifference)}
            </Typography>
          )}
          {scheduleData.schedule.status === "cancel" && (
            <Typography component="span" sx={{ fontSize: "12px", fontWeight: "600", marginLeft: "4px" }}>
              취소된 고객
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid container item xs={2.5} direction="column">
        <Button
          sx={{
            width: "100%",
            height: "24px",
            fontSize: "12px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "none",
            mb: "6px",
            minWidth: "unset",
            border: scheduleData.schedule.status === "cancel" ? "1px solid #bdbdbd" : "none",
          }}
          onClick={onClickOpenVisitModal}
        >
          방문
        </Button>
        <Modal open={visitModalOpen} onClose={onClickCloseVisitModal} sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ width: "440px", p: "16px", backgroundColor: "white", borderRadius: "8px", height: "auto" }}>
            <RegisterVisitModal
              scheduleId={scheduleData.schedule.scheduleId}
              customerName={scheduleData.customer.name}
              initialBuilding={scheduleData.schedule.building}
              initialFloor={scheduleData.schedule.floor}
              initialRoom={scheduleData.schedule.room}
              onClickVisitCustomer={onClickVisitCustomer}
              onClose={onClickCloseVisitModal}
            />
          </Box>
        </Modal>
        {scheduleData.schedule.status !== "cancel" && (
          <Button
            sx={{
              width: "100%",
              height: "24px",
              fontSize: "12px",
              textTransform: "none",
              minWidth: "unset",
              backgroundColor: "lightgray",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
              "&:hover": { backgroundColor: "#e08a8a", color: "black" },
            }}
            onClick={() => {
              if (window.confirm("고객님의 금일 예약을 취소하시겠습니까?")) {
                onClickCancelSchedule(scheduleData.schedule.scheduleId);
              }
            }}
          >
            취소
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
export default ReservationCard;
