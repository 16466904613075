import { useEffect, useState } from "react";

interface SurgeryTimeProps {
  startTime: string;
}

export const SurgeryTime: React.FC<SurgeryTimeProps> = ({ startTime }) => {
  const [elapsedTime, setElapsedTime] = useState("");

  useEffect(() => {
    // 경과 시간을 계산하는 함수
    const calculateElapsedTime = () => {
      if (!startTime) return "-";

      // 오늘 날짜의 startTime을 생성
      const [hours, minutes, seconds] = startTime.split(":");
      const startDate = new Date();
      startDate.setHours(parseInt(hours), parseInt(minutes), parseInt(seconds), 0);

      // 현재 시간
      const now = new Date();

      // 경과 시간 계산 (밀리초 단위)
      const elapsedMilliseconds = now.getTime() - startDate.getTime();

      // 밀리초를 시간, 분, 초로 변환
      const elapsedHours = Math.floor(elapsedMilliseconds / (1000 * 60 * 60));
      const elapsedMinutes = Math.floor((elapsedMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const elapsedSeconds = Math.floor((elapsedMilliseconds % (1000 * 60)) / 1000);

      // 두 자리수로 변환
      const formattedHours = String(elapsedHours).padStart(2, "0");
      const formattedMinutes = String(elapsedMinutes).padStart(2, "0");
      const formattedSeconds = String(elapsedSeconds).padStart(2, "0");

      // 결과 문자열 생성
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    // 1초마다 경과 시간을 계산하고 상태 업데이트
    const intervalId = setInterval(() => {
      setElapsedTime(calculateElapsedTime());
    }, 1000);

    // 컴포넌트 언마운트 시 인터벌 정리
    return () => clearInterval(intervalId);
  }, [startTime]);

  return <div>{elapsedTime}</div>;
};
