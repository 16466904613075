import { sidebarWidth } from "../../../component/Sidebar";
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocalContext } from "../Context";
import CalendarItem from "../CalendarItem";
import { convertMinutesToTime, floorsArray, getTimeInMinutes, roomsArray, timesArray } from "../utils";

const TableInner: React.FC = ({}) => {
  const { scheduleItems, currentTimeInMinutes } = useLocalContext();

  // timeline sx
  const getTimeLinePositionStyle = () => ({
    gridRow:
      timesArray.findIndex(
        (time) => getTimeInMinutes(time) <= getTimeInMinutes(convertMinutesToTime(currentTimeInMinutes)) && getTimeInMinutes(time) + 15 > getTimeInMinutes(convertMinutesToTime(currentTimeInMinutes))
      ) + 3,
    marginTop: `${(((getTimeInMinutes(convertMinutesToTime(currentTimeInMinutes)) - 570) % 15) / 15) * 100}px`,
    position: "absolute",
    width: "100%",
    zIndex: 1,
    border: "1px solid #655dc6",
  });

  return (
    <Grid container sx={{ display: "grid", position: "sticky", gridTemplateColumns: `repeat(${roomsArray.length + 1}, 80px)`, gridTemplateRows: `30px 24px ,repeat(${timesArray.length}, 100px)` }}>
      <Grid
        container
        item
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        sx={{ backgroundColor: "#f0f0f0", border: "1px solid #ddd", zIndex: 999, position: "sticky", top: "50px", left: sidebarWidth, gridColumn: "1/2", gridRow: "1/3" }}
      />

      {/* DESC 층 */}
      {floorsArray.map((floor) => (
        <Grid
          item
          key={floor.id}
          sx={{
            backgroundColor: "#f0f0f0",
            gridColumn: `span ${floor.rooms.length}`,
            gridRow: 1,
            textAlign: "center",
            border: "1px solid #ddd",
            zIndex: 998,
            position: "sticky",
            top: "50px",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>{floor.floor}</Typography>
        </Grid>
      ))}

      {/* DESC 위치 */}
      {floorsArray.map((floor) =>
        floor.rooms.map((room) => (
          <Grid
            item
            key={room.id}
            justifyContent={"center"}
            sx={{ gridRow: "2/3", backgroundColor: "#f0f0f0", textAlign: "center", border: "1px solid #ddd", zIndex: 998, position: "sticky", top: "82px" }} // header 80 + border 2px
          >
            <Typography sx={{ fontSize: "13px" }}>{room.name}</Typography>
          </Grid>
        ))
      )}

      {/* DESC 시간 */}
      {timesArray.map((time) => (
        <React.Fragment key={time}>
          <Grid
            item
            sx={{
              gridColumn: 1,
              backgroundColor: "#f0f0f0",
              textAlign: "center",
              border: "1px solid #ddd",
              position: "sticky",
              left: sidebarWidth,
              zIndex: 95,
            }}
          >
            {time}
          </Grid>

          {/* DESC 룸 */}
          {roomsArray.map((room) => (
            <Grid key={`${time}-${room}`} item sx={{ border: "1px solid #ddd", minHeight: "100px" }} />
          ))}
        </React.Fragment>
      ))}

      {/* DESC TimeLine */}
      <Box sx={getTimeLinePositionStyle()} />

      {scheduleItems &&
        scheduleItems.map((scheduleItem) => <CalendarItem key={scheduleItem.schedule.scheduleUuid} customerStatus={scheduleItem} scheduleItems={scheduleItems} isDoctorSchedule={true} />)}
    </Grid>
  );
};

export default TableInner;
