import axios from "axios";
import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { Doctor } from "../models/DoctorModel";
import { doctorController } from "../controller/DoctorController";
import { DoctorWorkSchedule } from "../page/Home/Model";

interface DoctorContextType {
  doctors: Doctor[];
  todayWorkingDoctors: DoctorWorkSchedule[];
  setDoctors: React.Dispatch<React.SetStateAction<Doctor[]>>;
  registerDoctor: (newDoctor: Doctor, img?: File) => any;
  updateDoctorColor: (updateDoctorColor: Doctor) => any;
}

export interface DoctorColor {
  color: string;
  select: boolean;
}

export const DoctorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [todayWorkingDoctors, setTodayWorkingDoctors] = useState<DoctorWorkSchedule[]>([]);

  useEffect(() => {
    const fetchDoctor = async () => {
      const fetchDoctorResponse = await doctorController.handleFetchDoctor();
      if (fetchDoctorResponse.success) {
        const transformedData: Doctor[] = fetchDoctorResponse.data.map((doctor: Doctor) => ({
          ...doctor,
          birthDate: doctor.birthDate ? new Date(doctor.birthDate) : null,
          joiningDate: new Date(doctor.joiningDate),
          exitDate: doctor.exitDate ? new Date(doctor.exitDate) : null,
        }));
        setDoctors(transformedData);
      } else {
        alert(fetchDoctorResponse.message + "\nfetchDoctor Context");
      }
    };
    fetchDoctor();
  }, []);

  useEffect(() => {
    const fetchTodayWorkingDoctor = async () => {
      const fetchTodayWorkingDoctorResponse = await doctorController.handleFetchTodayWorkingDoctor(new Date()); //배포시 한국 시간으로 보내지는지 확인 필요
      if (fetchTodayWorkingDoctorResponse.success) {
        setTodayWorkingDoctors(fetchTodayWorkingDoctorResponse.data);
      } else {
        alert(fetchTodayWorkingDoctorResponse.message);
      }
    };
    fetchTodayWorkingDoctor();
  }, []);

  const updateDoctorColor = async (updatedDoctor: Doctor) => {
    const updateDoctorColorResponse = await doctorController.handleUpdateDoctorColor(updatedDoctor);
    if (updateDoctorColorResponse.success) {
      /* sse 적용
      setDoctors((prevDoctors) =>
        prevDoctors.some((doctor) => doctor.doctorUuid === updatedDoctor.doctorUuid)
          ? prevDoctors.map((doctor) => (doctor.doctorUuid === updatedDoctor.doctorUuid ? updatedDoctor : doctor))
          : [...prevDoctors, updatedDoctor]
      );
      */
      return updateDoctorColorResponse;
    } else {
      alert(updateDoctorColorResponse.message);
      return updateDoctorColorResponse;
    }
  };

  const registerDoctor = async (newDoctor: Doctor, img?: File) => {
    const registerDoctorResponse = await doctorController.handleRegisterDoctor(newDoctor, img);
    if (registerDoctorResponse.success) {
      /* sse 적용
      const updatedDoctor = registerDoctorResponse.data;
      const parsedDoctor = {
        ...updatedDoctor,
        birthDate: updatedDoctor.birthDate ? new Date(updatedDoctor.birthDate) : null,
        joiningDate: new Date(updatedDoctor.joiningDate),
        exitDate: updatedDoctor.exitDate ? new Date(updatedDoctor.exitDate) : null,
      };
      setDoctors((prevDoctors) => [...prevDoctors, parsedDoctor]);
      */
      return registerDoctorResponse;
    } else {
      alert(registerDoctorResponse.message);
      return registerDoctorResponse;
    }
  };

  return <DoctorsContext.Provider value={{ doctors, todayWorkingDoctors, setDoctors, registerDoctor, updateDoctorColor }}>{children}</DoctorsContext.Provider>;
};

const DoctorsContext = createContext<DoctorContextType | undefined>(undefined);

export const useDoctor = () => {
  const context = useContext(DoctorsContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a DoctorProvider");
  }
  return context;
};
