import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { Device } from "../models/DevicesModel";
import { devicesController } from "../controller/DevicesController";
import { replaceEmptyWithNull } from "../util/Function";

interface DeviceContextType {
  deviceList: Device[];
  registerDevice: (newDevice: Device, image?: File) => Promise<any>;
  updateDevice: (newDevice: Device) => Promise<any>;
  setDeviceList: React.Dispatch<React.SetStateAction<Device[]>>;
}

const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

export const DeviceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [deviceList, setDeviceList] = useState<Device[]>([]);

  // 서버에서 장비 목록 가져오기
  useEffect(() => {
    const fetchDevice = async () => {
      const fetchDeviceResponse = await devicesController.fetchDeviceList();
      if (fetchDeviceResponse.success) {
        setDeviceList(fetchDeviceResponse.data);
      } else {
        alert(fetchDeviceResponse.message + "\nfetchDevice Context");
      }
    };
    fetchDevice();
  }, []);

  // 폼 데이터 변환 함수
  const createFormData = (data: Device, img?: File) => {
    const formData = new FormData();
    formData.append("deviceDto", new Blob([JSON.stringify(data)], { type: "application/json" }));
    if (img) {
      formData.append("img", img);
    }
    return formData;
  };

  // 장비 추가
  const registerDevice = async (newDevice: Device, img?: File) => {
    const cleanedData = replaceEmptyWithNull(newDevice);
    const formData = createFormData(cleanedData, img);
    const registerDeviceResponse = await devicesController.registerDevice(formData);
    if (registerDeviceResponse.success) {
      // sse
      // setDeviceList((prevDevices) => [...prevDevices, registerDeviceResponse.data]);
      return registerDeviceResponse;
    } else {
      alert(registerDeviceResponse.message + "\nregisterDevice Context");
      return false;
    }
  };

  // 장비 수정
  const updateDevice = async (updateDevice: Device) => {
    const updateDeviceResponse = await devicesController.updateDevice(updateDevice);
    if (updateDeviceResponse.success) {
      // sse
      // setDeviceList((prevDevices) => prevDevices.map((device) => (device.deviceId === updateDeviceResponse.data.deviceId ? updateDeviceResponse.data : device)));
      return updateDeviceResponse;
    } else {
      alert(updateDeviceResponse.message + "\nupdateDevice Context");
      return false;
    }
  };

  return (
    <DeviceContext.Provider
      value={{
        deviceList,
        registerDevice,
        updateDevice,
        setDeviceList,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

// 커스텀 Hook으로 context 사용
export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error("useDevice must be used within a DeviceProvider");
  }
  return context;
};
