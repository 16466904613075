import axios from "axios";
import { Building } from "../models/RoomModel";

// 휴대폰 번호 형식 변경 함수
export function formatPhoneNumber(phoneNumber: string | undefined): string {
  if (phoneNumber === undefined || phoneNumber === null || phoneNumber === "") {
    return "미입력";
  } else {
    const phonePattern = /(\d{3})(\d{4})(\d{4})/;
    const match = phoneNumber.match(phonePattern);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }
}

// 날짜 형식 변경 함수(yyyy-MM-dd) => 데이터 추가필요
export const formatDate = (date: Date | null | undefined | string): string => {
  if (!date) return "";
  else {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
};

// 시간 형식 변경 함수(HH:mm:ss) > notice타입 변경해서 수정
export const formTime = (createdAt: string): string => {
  if (!createdAt) return ""; // 유효하지 않은 값 처리
  // const date = createdAt instanceof Date ? createdAt : new Date(createdAt); // 타입 체크
  const date = new Date(createdAt);
  return date.toLocaleTimeString("ko-KR", {
    hour: "2-digit", // 2자리로 맞춤
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // 24시간 형식 (필요 시 변경)
  });
};

//notice타입 변경해서 수정
export const isWithinTenMinutes = (createdAt: string): boolean => {
  const createdTime = new Date(createdAt).getTime();
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - createdTime;
  return timeDiff <= 600000; // 10분 이내면 true 반환
};

// 예상방문 시간과 현재 시간 계산 함수
export const calculateTimeDifference = (time: string) => {
  const now = new Date(); // 현재 시간
  const [hours, minutes] = time.split(":").map(Number);
  const expectedVisitDate = new Date();
  expectedVisitDate.setHours(hours, minutes, 0);
  const timeDifferenceInMs = expectedVisitDate.getTime() - now.getTime();
  return timeDifferenceInMs;
};

//빌딩에 따른 층 가져오기
export const parseBuildingsToFloors = (buildings: Building[], selectedBuilding: string): { id: string; name: string }[] => {
  const building = buildings.find((building) => building.name === selectedBuilding);
  if (!building) {
    return []; // 혹은 빈 배열 [] 반환 가능
  }
  return building.floors.map((floor) => ({
    id: floor.id,
    name: floor.name,
  }));
};

//층에 따른 룸 가져오기
export const parseBuildingsToRooms = (buildings: Building[], selectedBuilding: string, selectedFloor: string): { id: string; name: string }[] => {
  const floor = buildings.find((building) => building.name === selectedBuilding)?.floors.find((floor) => floor.name === selectedFloor);
  if (!floor) {
    return []; // 혹은 빈 배열 [] 반환 가능
  }
  return floor.rooms.map((room) => ({
    id: room.id,
    name: room.name,
  }));
};

export const handleServiceApiError = (error: unknown) => {
  if (axios.isAxiosError(error) && error.response) {
    return { status: error.response.status, data: null };
  } else {
    return { status: 401, data: null };
  }
};

export const handleControllerResponse = (status: number, data: any = null) => {
  if (status === 200 || status === 204) {
    return { success: true, data };
  } else if (status === 401) {
    return { success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" };
  } else {
    return { success: false, message: "요청사항 처리에 실패했습니다.\n운영개발팀에 문의해주세요" };
  }
};

//재직날짜 or 근무일자 계산 함수
export function calculateYearsMonthsDays(joiningDate: Date | null, referenceDate?: Date | null): string {
  const today = referenceDate || new Date();
  if (joiningDate === undefined || joiningDate === null) {
    return "미입력";
  } else {
    let years = today.getFullYear() - new Date(joiningDate).getFullYear();
    let months = today.getMonth() - new Date(joiningDate).getMonth();
    let days = today.getDate() - new Date(joiningDate).getDate() + 1;
    if (days < 0) {
      months -= 1; // 이전 달로 넘어감
      const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      days += lastDayOfPreviousMonth;
    }
    if (months < 0) {
      years -= 1; // 이전 연도로 넘어감
      months += 12; // 12개월을 추가하여 정상화
    }

    return years === 0 ? (months === 0 ? `${days}일` : `${months}개월 ${days}일`) : `${years}년 ${months}개월 ${days}일`;
  }
}

// 빈 문자열을 null로 변환
export const replaceEmptyWithNull = <T extends Record<string, any>>(obj: T): T => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === "" ? null : value])) as T;
};

// null을 빈 문자열로 변환
export const replaceNullWithEmpty = <T extends Record<string, any>>(obj: T): T => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === null ? "" : value])) as T;
};

// 두 날짜가 같은 날짜인지 확인하는 함수
export const isSameDay = (date1: Date, date2: Date) => {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};
