import React, { useState } from "react";
import { Typography, Box, Button, Tooltip } from "@mui/material";
import { useDoctor } from "../../context/DoctorsContext";
import { Customer, RoomHistory, Schedule, ScheduleData, Service } from "../Home/Model";
import { reservationTooltip, ScheduleStatusButton } from "./CalendarItem/component";
import { getTimeInMinutes, overlapIndex, roomsArray, timesArray } from "./utils";
import SurgeriesModal from "./SurgeriesModal";
import { useLocalContext } from "./Context";

export interface CalendarItemProps {
  customerStatus: ScheduleData;
  scheduleItems: ScheduleData[];
  isDoctorSchedule: Boolean;
}

const CalendarItem: React.FC<CalendarItemProps> = React.memo(({ customerStatus, scheduleItems, isDoctorSchedule }) => {
  const { doctors } = useDoctor();
  const { currentTimeInMinutes } = useLocalContext();
  const [surgeriesModalOpen, setSurgeriesModalOpen] = useState(false); // 시술 모달
  const [scheduleData, setScheduleData] = useState(customerStatus); // TODO ScheduleData 내부 내용 변경되면 확인 (roomHistory 등)
  const startTimeInMinutes = getTimeInMinutes(customerStatus.schedule.expectedVisitTime); // M 고객 방문 예정
  const startTimeIndex = timesArray.findIndex((time) => getTimeInMinutes(time) <= getTimeInMinutes(customerStatus.schedule.expectedVisitTime) && getTimeInMinutes(time) + 15 > startTimeInMinutes) + 3;
  const timeSlotHeight = 100; // 각 시간 슬롯의 높이 (분 단위로 계산)
  const endTimeInMinutes = getTimeInMinutes(customerStatus.schedule.expectedReturnTime); // M 고객 귀가 예정 -> 일단 평균 30분으로 잡기

  // M 스케줄 변경 핸들?
  const handleChangeScheduleData = (field: keyof ScheduleData, value: string | Schedule | Service | Customer) => {
    setScheduleData({ ...scheduleData, [field]: value });
  };

  // TODO 닥터 스케줄 파트
  const getSurgeriesStyle = (surgery: Service) => ({
    gridColumn: roomsArray.indexOf(surgery.room) + 2,
    gridRow: timesArray.findIndex((time) => getTimeInMinutes(time) <= getTimeInMinutes(surgery.startTime || "") && getTimeInMinutes(time) + 15 > getTimeInMinutes(surgery.startTime || "")) + 3,
    marginTop: `${(((getTimeInMinutes(surgery.startTime || "") - 570) % 15) / 15) * timeSlotHeight}px`,
    marginLeft: isDoctorSchedule ? "0px" : "72px",
    height: surgery.endTime !== "" ? `${((getTimeInMinutes(surgery.endTime) - getTimeInMinutes(surgery.startTime || "")) / 15) * timeSlotHeight}px` : "20px",
    minHeight: "20px",
    zIndex: 10,
    position: "absolute",
    width: isDoctorSchedule ? "80px" : "8px",
    textAlign: "center",
    overflow: "hidden",
    background: "",
    display: "flex",
    flexDirection: "column",
  });

  // TODO 닥터 스케줄 파트
  const getSurgeriesStatusStyle = (surgery: Service) => {
    const commonStyle = getSurgeriesStyle(surgery);
    if (surgery.endTime === null || surgery.endTime === "") {
      commonStyle.height = "40px";
    }
    const doctor = doctors.find((doc) => doc.name === surgery.doctor);
    if (doctor) {
      commonStyle.background = doctor.color;
    }
    return commonStyle;
  };

  // STYLE 스케줄 위치 잡기 common
  const getScheduleStyle = () => ({
    gridColumn: roomsArray.indexOf(customerStatus.schedule.room) + 2,
    gridRow: startTimeIndex,
    marginTop: `${(((startTimeInMinutes - 570) % 15) / 15) * timeSlotHeight}px`,
    zIndex: 5,
    position: "absolute",
    width: "auto",
    height: "auto",
    display: "inline-flex", // 내용에 맞춰 요소 크기 조정
    maxWidth: "40px",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap", // 텍스트를 한 줄로 유지
    textOverflow: "ellipsis", // 넘치는 텍스트에 ... 표시
    flexDirection: "column",
  });

  // STYLE 방문 상태에 따른 BOX 스타일 (EXCELUPLOAD, ONVISIT...)
  const getScheduleStatusStyle = () => {
    const commonStyle = getScheduleStyle();
    switch (customerStatus.schedule.status) {
      case "cancel":
        return {
          ...commonStyle,
          backgroundColor: "#ddd", // 조건부 스타일
          border: "1px solid #a0a0a0",
          width: "80px",
          maxWidth: "80px",
          marginTop: `${overlapIndex(scheduleItems, customerStatus) * 15}px`,
        };
      case "beforeVisit":
        return {
          ...commonStyle,
          backgroundColor: "#665dc620", // 조건부 스타일
          border: "1px dashed #655dc6",
          width: "80px",
          maxWidth: "80px",
          marginTop: `${overlapIndex(scheduleItems, customerStatus) * 15}px`, // 겹치는 정도에 따라 위에서 아래로 밀어내기
          zIndex: 2 + overlapIndex(scheduleItems, customerStatus), // 겹침 순서를 조정
        };
      case "onVisit":
        return {
          ...commonStyle,
          backgroundColor: "#f5e1e1", // 조건부 스타일
          border: "1px solid #e08a8a",
          width: "80px",
          maxWidth: "80px",
          marginTop: `${overlapIndex(scheduleItems, customerStatus) * 15}px`, // 겹치는 정도에 따라 위에서 아래로 밀어내기
          zIndex: 2 + overlapIndex(scheduleItems, customerStatus), // 겹침 순서를 조정
        };
      case "afterVisit":
        return {
          ...commonStyle,
          backgroundColor: "#f5e1e1", // 조건부 스타일
          border: "1px solid #e08a8a",
          width: "80px",
          maxWidth: "80px",
          marginTop: `${overlapIndex(scheduleItems, customerStatus) * 15}px`, // 겹치는 정도에 따라 위에서 아래로 밀어내기
          zIndex: 2 + overlapIndex(scheduleItems, customerStatus), // 겹침 순서를 조정
          height: `${((endTimeInMinutes === 0 ? currentTimeInMinutes - startTimeInMinutes : currentTimeInMinutes - startTimeInMinutes) / 15) * timeSlotHeight}px`,
        };
      default:
        return {
          ...commonStyle,
          backgroundColor: "#665dc620", // 조건부 스타일
          border: "1px dashed #655dc6",
        };
    }
  };

  // STYLE 고객 방문시 룸 타임 common
  const getCommonRoomStyle = (roomHistory: RoomHistory) => ({
    gridColumn: roomsArray.indexOf(roomHistory.room) + 2,
    gridRow: timesArray.findIndex((time) => getTimeInMinutes(time) <= getTimeInMinutes(roomHistory.time) && getTimeInMinutes(time) + 15 > getTimeInMinutes(roomHistory.time)) + 3,
    marginTop: `${(((getTimeInMinutes(roomHistory.time) - 570) % 15) / 15) * timeSlotHeight}px`,
    zIndex: 5,
    position: "absolute",
    width: "80px",
    border: "1px solid #655dc6",
    backgroundColor: "#665dc620", // 조건부 스타일
    display: "inline-flex", // 내용에 맞춰 요소 크기 조정
    maxWidth: "80px",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap", // 텍스트를 한 줄로 유지
    textOverflow: "ellipsis", // 넘치는 텍스트에 ... 표시
    flexDirection: "column",
  });

  // STYLE 고객 방문시 룸 타임 스타일
  const getRoomTimeStyle = (roomHistory: RoomHistory, index: number) => {
    const commonStyle = getCommonRoomStyle(roomHistory);
    switch (index) {
      case customerStatus.schedule.roomHistory.length - 1:
        if (customerStatus.schedule.status === "onVisit") {
          return {
            ...commonStyle,
            background: "#d1b3ff",
            minHeight: "100px",
            height: `${((currentTimeInMinutes - getTimeInMinutes(roomHistory.time)) / 15) * timeSlotHeight}px`,
          };
        } else {
          return {
            ...commonStyle,
            height: `${((getTimeInMinutes(customerStatus.schedule.expectedReturnTime) - getTimeInMinutes(roomHistory.time)) / 15) * timeSlotHeight}px`,
          };
        }
      default:
        return {
          ...commonStyle,
          minHeight: "18px",
          height: `${((getTimeInMinutes(customerStatus.schedule.roomHistory[index + 1].time) - getTimeInMinutes(roomHistory.time)) / 15) * timeSlotHeight}px`,
        };
    }
  };

  // STYLE 시술 표시 스타일
  const getSurgeriesTime = (surgery: Service) => ({
    gridColumn: roomsArray.indexOf(surgery.room) + 2,
    gridRow: timesArray.findIndex((time) => getTimeInMinutes(time) <= getTimeInMinutes(surgery.startTime || "") && getTimeInMinutes(time) + 15 > getTimeInMinutes(surgery.startTime || "")) + 3,
    marginTop: `${(((getTimeInMinutes(surgery.startTime || "") - 570) % 15) / 15) * timeSlotHeight}px`,
    zIndex: 10,
    position: "absolute",
    width: "80px",
    textAlign: "center",
    overflow: "hidden",
    display: "flex",
    fontSize: "10px",
    flexDirection: "column",
  });

  // M 고객 정보 모달 on/off
  const onClickOpenSurgeriesModal = () => setSurgeriesModalOpen(true);
  const onClickCloseSurgeriesModal = () => setSurgeriesModalOpen(false);

  return (
    <>
      {/* DESC onVisit(룸 할당 X), beforeVisit(예약 상태), cancel 일 때 고객 작은  표시 / 방문시, 귀가시에는 안보임 */}
      {((customerStatus.schedule.status === "onVisit" && customerStatus.schedule.roomHistory.length === 0) ||
        customerStatus.schedule.status === "beforeVisit" ||
        customerStatus.schedule.status === "cancel") && (
        <Tooltip title={reservationTooltip(customerStatus)} arrow followCursor placement="right" disableHoverListener={surgeriesModalOpen}>
          <Box sx={getScheduleStatusStyle()}>
            {customerStatus.schedule.status === "onVisit" && ScheduleStatusButton(onClickOpenSurgeriesModal, customerStatus.customer.name, customerStatus.schedule.status)}
            {customerStatus.schedule.status === "beforeVisit" && ScheduleStatusButton(onClickOpenSurgeriesModal, customerStatus.customer.name, customerStatus.schedule.status)}
            {customerStatus.schedule.status === "cancel" && ScheduleStatusButton(onClickOpenSurgeriesModal, customerStatus.customer.name, customerStatus.schedule.status)}
          </Box>
        </Tooltip>
      )}

      {/* DESC onVisit, afterVisit 일 때 고객 표시 */}
      {((customerStatus.schedule.status === "onVisit" && customerStatus.schedule.roomHistory.length !== 0) || customerStatus.schedule.status === "afterVisit") && (
        <>
          {/* TODO 룸히스토리로 map 생성 (현재는 룸히스토리 빈 배열임) */}
          {customerStatus.schedule.roomHistory.map((roomHistory, index) => (
            <Box key={index} sx={getRoomTimeStyle(roomHistory, index)}>
              <Tooltip title={reservationTooltip(customerStatus)} arrow followCursor placement="right" disableHoverListener={surgeriesModalOpen}>
                <Button
                  onClick={onClickOpenSurgeriesModal}
                  sx={{
                    padding: "0px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "#655dc6",
                    zIndex: 10,
                    borderRadius: 0,
                    height: "18px",
                    "&:hover": {
                      borderRadius: 0,
                      color: "white",
                    },
                  }}
                >
                  {customerStatus.customer.name}({roomHistory.time.substring(0, 5)})
                </Button>
              </Tooltip>
              <Box sx={{ height: "calc(100% - 18px)" }}>
                {customerStatus.service
                  .filter((service) => service.status !== "deleted")
                  .map((service) => {
                    if (service.name !== "방문") {
                      return (
                        <Typography
                          key={service.serviceUuid} // map 함수 사용 시 key 속성 추가
                          sx={{
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: service.status === "getReady" ? "green" : "black", // 조건에 따른 색상 설정
                            textDecoration: service.endTime !== "" ? "line-through" : "none", // 조건에 따른 취소선 설정
                          }}
                        >
                          {service.name}
                        </Typography>
                      );
                    }
                  })}
                {(customerStatus.schedule.expectedReturnTime === "" || customerStatus.schedule.expectedReturnTime === null) && (
                  <Typography sx={{ fontSize: "9px", fontWeight: "bold", color: "#B22222", zIndex: 2 }}>예정귀가시간 미입력</Typography>
                )}
                <Typography sx={{ fontSize: "10px", marginTop: "auto", alignSelf: "flex-end", width: "100%", wordWrap: "break-word", whiteSpace: "normal" }}>
                  {customerStatus.schedule.arrangerMemo}
                </Typography>
              </Box>
            </Box>
          ))}
        </>
      )}

      {/* TODO onVisit, afterVisit 일 때 시술 시작시간 표시*/}
      {(customerStatus.schedule.status === "onVisit" || customerStatus.schedule.status === "afterVisit") &&
        customerStatus.service // MEMO 서비스 없음
          .filter((service) => service.status !== "deleted")
          .map((service) => {
            if (service.name !== "방문" && service.startTime !== null && service.startTime !== "") {
              return (
                <Typography
                  key={service.serviceUuid} // map 함수 사용 시 key 속성 추가
                  sx={getSurgeriesTime(service)}
                >
                  {service.name}({service.startTime.substring(0, 5)})
                </Typography>
              );
            }
          })}
      <>
        {(customerStatus.schedule.status === "onVisit" || customerStatus.schedule.status === "afterVisit") && (
          <>
            {customerStatus.service.map((service) => {
              if (service.name !== "방문" && service.startTime !== null && service.startTime !== "") {
                return (
                  <Tooltip
                    title={
                      <Box>
                        <Typography sx={{ fontSize: "14px" }}>고객 : {customerStatus.customer.name}</Typography>
                        <Typography sx={{ fontSize: "14px" }}>시술명 : {service.name}</Typography>
                      </Box>
                    }
                    arrow
                    followCursor
                    placement="right"
                    disableHoverListener={surgeriesModalOpen}
                  >
                    <Box sx={getSurgeriesStatusStyle(service)}></Box>
                  </Tooltip>
                );
              }
            })}
          </>
        )}
      </>

      <SurgeriesModal open={surgeriesModalOpen} handleClose={onClickCloseSurgeriesModal} scheduleItem={customerStatus} />
    </>
  );
});

export default CalendarItem;
