import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Button, Tooltip, TextareaAutosize } from "@mui/material";
import { useDoctor } from "../../context/DoctorsContext";
import { WorkScheduleItem } from "./Model";
import { useHomeContext } from "./Context";

const WorkAttendance: React.FC = () => {
  console.log("workSchedule 랜더링")!;
  const { todayWorkingDoctors } = useDoctor();
  const [isEdit, setIsEdit] = useState(false);
  const { workSchedule, updateWorkSchedule } = useHomeContext();
  const [newWorkSchedule, setNewWorkSchedule] = useState<WorkScheduleItem>(workSchedule);

  useEffect(() => {
    setNewWorkSchedule(workSchedule);
  }, [workSchedule]);

  // 임시 상태에 입력된 값을 저장
  const onChangeNewWorkSchedule = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewWorkSchedule((prevWorkSchedule) => ({ ...prevWorkSchedule, text: e.target.value }));
  };

  // 수정 내용을 저장
  const onClickSaveNewWorkSchedule = () => {
    if (newWorkSchedule) updateWorkSchedule(newWorkSchedule);
    setIsEdit(false);
  };

  // 편집 버튼 클릭 시 편집 모드로 진입
  const onClickOpenEditNewWorkSchedule = () => {
    if (isEdit) {
      setNewWorkSchedule(workSchedule);
      setIsEdit(false);
    } else {
      setIsEdit(true); // 편집 모드 활성화
    }
  };

  const workType = ["morning", "afternoon"];

  return (
    <Grid container direction="column" sx={{ height: "100%", padding: "4px 0px 0px 0px" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center", height: "24px" }}>
        <Typography variant="h2" sx={{ textAlign: "left", marginLeft: "4px" }}>
          금일 출근 명부
        </Typography>
      </Grid>
      <Paper
        sx={{ flexGrow: 1, margin: "4px 0px 0px 0px", boxShadow: 3, borderRadius: "8px", overflowY: "auto", padding: "15px", height: "calc(100% - 40px)", display: "flex", flexDirection: "column" }}
      >
        <Grid container item direction={"column"} sx={{ paddingBottom: "15px" }}>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            {`<원장님>`} {todayWorkingDoctors.filter((doctor) => doctor.workType !== "off").length}명
          </Typography>

          {workType.map((type) => (
            <Typography key={type} sx={{ fontSize: "12px" }}>
              {type === "morning" ? "오전출근 : " : "오후출근 : "}
              {todayWorkingDoctors
                .filter((doctor) => doctor.workType === type)
                .map((doctor) => doctor.doctorName)
                .join(", ")}
            </Typography>
          ))}
        </Grid>

        {isEdit ? (
          <Grid container item direction="column" sx={{ flexGrow: 1 }}>
            <TextareaAutosize
              minRows={3}
              maxRows={10}
              placeholder="오늘의 출근 명부를 입력해주세요."
              value={newWorkSchedule.text ? newWorkSchedule.text : ""}
              onChange={onChangeNewWorkSchedule}
              style={{ flexGrow: 1, padding: "12px 10px", fontSize: "12px", borderRadius: "10px", border: "1px solid #ccc", overflowY: "auto", resize: "none", whiteSpace: "pre-line" }}
            />

            <Grid container item gap={1} sx={{ marginTop: "5px", justifyContent: "flex-end", flexShrink: 0 }}>
              <Grid item>
                <Button sx={{ height: "25px" }} color="primary" onClick={onClickSaveNewWorkSchedule}>
                  저장
                </Button>
              </Grid>
              <Grid item>
                <Button sx={{ height: "25px", backgroundColor: "lightgray" }} color="secondary" onClick={onClickOpenEditNewWorkSchedule}>
                  취소
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Tooltip title="클릭하여 수정하기" placement="bottom-start" followCursor>
            <Typography
              sx={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                fontSize: "12px",
                padding: "10px",
                flexGrow: 1,
                cursor: "pointer",
                whiteSpace: "pre-line",
                color: workSchedule.text === null || workSchedule.text === "" ? "#bdbdbd" : "black",
              }}
              onClick={onClickOpenEditNewWorkSchedule}
            >
              {workSchedule.text === null || workSchedule.text === "" ? "출근 명부를 입력해주세요." : workSchedule?.text}
            </Typography>
          </Tooltip>
        )}
      </Paper>
    </Grid>
  );
};

export default WorkAttendance;
