import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

import { Counselor } from "../../models/CounselorModel";

interface CounselorContextType {
  selectedCounselor: Counselor | undefined;
  setSelectedCounselor: React.Dispatch<React.SetStateAction<Counselor | undefined>>;
}

export const CounselorContext = createContext<CounselorContextType | undefined>(undefined);

export const CounselorLocalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCounselor, setSelectedCounselor] = useState<Counselor>();

  return <CounselorContext.Provider value={{ selectedCounselor, setSelectedCounselor }}>{children}</CounselorContext.Provider>;
};

export const useLocalContext = () => {
  const context = useContext(CounselorContext);
  if (!context) {
    throw new Error("useLocalContext must be used within a CounselorLocalProvider");
  }
  return context;
};
