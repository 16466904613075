import React, { useEffect, useState } from "react";
import { Button, Modal, Box, Grid, Typography, TextField, MenuItem, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tab } from "@mui/material";
import { initialService, ScheduleData } from "../Model";

interface WaitingModalProps {
  open: boolean;
  setWaitModalOpen: (open: boolean) => void;
  scheduleData: any;
  setNewScheduleData: (newScheduleData: ScheduleData) => void;
  newScheduleData: ScheduleData;
  changeNewScheduleData: (field: keyof ScheduleData, value: any) => void;
}

const WaitingTransitionModal: React.FC<WaitingModalProps> = ({ open, setWaitModalOpen, scheduleData, newScheduleData, setNewScheduleData, changeNewScheduleData }) => {
  const onClickClose = () => {
    setWaitModalOpen(false);
  };
  const [selectedCategory, setSelectedCategory] = useState();

  return (
    <Modal open={open} onClose={onClickClose} sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box sx={{ width: "800px", padding: 2, backgroundColor: "white", borderRadius: "8px" }}>
        <Grid item xs={12}>
          <Typography id="modal-title" variant="h2" component="h2">
            잔여시술 목록
          </Typography>
        </Grid>
        <Grid container gap={1}>
          <Grid container item>
            <Grid container item sx={{ display: "flex", alignItems: "center", marginRight: 1, height: "60%" }}>
              <Grid container item xs={3} sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
                <Grid sx={{ boxShadow: 3, borderRadius: 2, height: "300px" }}>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={{ pb: 1, bgcolor: "#e1e1f5", p: 1, fontWeight: "bold", textAlign: "center" }}> 상품</Typography>
                    <Grid
                      sx={{
                        overflowY: "auto",
                        maxHeight: "240px",
                      }}
                    >
                      {["울세라", "아토믹?"].map((list, index) => (
                        <Grid
                          key={index}
                          // onClick={() => setSelectedCategory(list)}
                          sx={{
                            cursor: "pointer",
                            // bgcolor: selectedCategory?.id === list.id ? "#e0e0e0" : "white",
                            borderBottom: "1px solid #e0e0e0",
                            p: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: "10px" }}>2024-11-12</Typography>
                          <Typography sx={{}}>{list}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={9} sx={{ padding: 1, display: "flex", flexDirection: "column" }}>
                <Grid sx={{ boxShadow: 3, borderRadius: 2, p: 2, height: "300px" }}>
                  <Grid>
                    {/* 데이터 없어서 !==로 했습니다. */}
                    {selectedCategory !== undefined ? (
                      <Grid container item sx={{ height: "80%" }}>
                        <Typography variant="h2" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
                          카테고리를 선택해주세요.
                        </Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid sx={{ width: "100%", pb: 2 }}>
                          <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid container alignItems="center">
                              <Grid sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>시술 이름</Typography>
                                <Typography sx={{ fontSize: "14px" }}>울세라</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid container alignItems="center">
                              <Grid sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>의사</Typography>
                                <Typography sx={{ fontSize: "14px" }}>의사1</Typography>
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>상담자</Typography>
                                <Typography sx={{ fontSize: "14px" }}>상담자1</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid container alignItems="center">
                              <Grid sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>시술 가격</Typography>
                                <Typography sx={{ fontSize: "14px" }}>250000만원</Typography>
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>구매 날짜</Typography>
                                <Typography sx={{ fontSize: "14px" }}>2020-12-12</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid sx={{ height: "100%" }}>
                      <TableContainer
                        sx={{
                          maxHeight: "180px",
                          "& .MuiTableCell-root": {
                            padding: "1px 3px",
                            textAlign: "center",
                            fontSize: "0.875rem",
                          },
                          "& .MuiTableCell-head": {
                            backgroundColor: "#e1e1f5",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          },
                        }}
                      >
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: "10%" }}></TableCell>
                              <TableCell>이름</TableCell>
                              <TableCell>시술 의사</TableCell>
                              <TableCell>시술 날짜</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell sx={{ p: 0 }}>
                                <Checkbox />
                              </TableCell>
                              <TableCell>울세라1회권</TableCell>
                              <TableCell>의사1</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ p: 0 }}>
                                <Checkbox />
                              </TableCell>
                              <TableCell>울세라2회권</TableCell>
                              <TableCell>의사2</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ p: 0 }}>
                                <Checkbox />
                              </TableCell>
                              <TableCell>울세라3회권</TableCell>
                              <TableCell>의사3</TableCell>
                              <TableCell>2024-11-23</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item sx={{ display: "flex", alignItems: "center", height: "40%" }}>
              <Grid container item sx={{ borderRadius: "8px", display: "flex", flexDirection: "column", p: 1 }}>
                <Grid sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2, fontSize: "14px" }}>
                    선택한 시술 목록
                  </Typography>
                  <Grid sx={{ overflowY: "auto", height: "100px" }}>
                    <Grid container sx={{ borderBottom: "1px solid #e0e0e0", pb: 1, mb: 1, position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>시술명</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>설명</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>날짜</Typography>
                      </Grid>
                    </Grid>

                    {["임시데이터"].map((list, index) => {
                      return (
                        <Grid
                          container
                          key={index}
                          sx={{
                            py: 0.5,
                            "&:hover": { backgroundColor: "#f5f5f5" },
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={1}>
                            <Checkbox size="small" />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography sx={{ fontSize: "12px" }}>{"ㅇㅖ시"}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {"-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container gap={1} sx={{ alignContent: "center", justifyContent: "center", display: "flex", flexDirection: "row" }}>
                <Button variant="contained" sx={{ pt: "4px", pb: "4px", fontSize: "14px", color: "white", bgcolor: "#655dc6", ":hover": { bgcolor: "#554ea2" } }}>
                  시술 등록
                </Button>
                <Button variant="contained" onClick={onClickClose} sx={{ pt: "4px", pb: "4px", fontSize: "14px", color: "white", bgcolor: "grey", ":hover": { bgcolor: "#e08a8a" } }}>
                  취소
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default WaitingTransitionModal;
