import React, { createContext, useContext, useState, ReactNode } from "react";
import { Device } from "../../models/DevicesModel";
import { replaceEmptyWithNull } from "../../util/Function";

interface DeviceContextType {
  selectedDevice: Device | null; // 선택한 장비
  setSelectedDevice: (device: Device | null) => void;
  onChangeSelectDevice: (device: Device) => void;
  searchQuery: string; // 검색
  setSearchQuery: (query: string) => void;
  isEdit: boolean; // 수정
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

export const DeviceLocalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // 선택한 장비 변경 핸들러
  const onChangeSelectDevice = (device: Device) => {
    setSelectedDevice(replaceEmptyWithNull(device));
  };

  return (
    <DeviceContext.Provider
      value={{
        selectedDevice,
        setSelectedDevice,
        onChangeSelectDevice,
        searchQuery,
        setSearchQuery,
        isEdit,
        setIsEdit,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export const useLocalContext = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error("useLocalContext must be used within a DeviceLocalProvider");
  }
  return context;
};
