export interface Product {
  id: number;
  productCode: string;
  productName: string;
  customCode: string;
  mainCategory: string;
  subCategory: string;
  stock: number;
  location: string;
  type: string;
}

export interface RegisterProduct {
  id: number;
  productCode: string;
  customCode: string;
  productName: string;
  storageMethod: string;
  mainCategory: string;
  subCategory: string;
  stock: number;
  team: string;
}

export const dummyResults: Product[] = [
  {
    id: 1,
    productCode: "PC001",
    productName: "상품1",
    customCode: "CC001",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "0",
    location: "창고1",
  },
  {
    id: 2,
    productCode: "PC002",
    productName: "상품2",
    customCode: "CC002",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "1",
    location: "창고2",
  },
  {
    id: 3,
    productCode: "PC003",
    productName: "상품3",
    customCode: "CC003",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "2",
    location: "A2",
  },
  {
    id: 4,
    productCode: "PC003",
    productName: "상품3",
    customCode: "CC003",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "2",
    location: "A1",
  },
  {
    id: 5,
    productCode: "PC003",
    productName: "상품3",
    customCode: "CC003",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "2",
    location: "c1",
  },
  {
    id: 6,
    productCode: "PC003",
    productName: "상품3",
    customCode: "CC003",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "2",
    location: "옥상",
  },
  {
    id: 7,
    productCode: "PC003",
    productName: "상품3",
    customCode: "CC003",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "2",
    location: "창고1",
  },
  {
    id: 8,
    productCode: "PC003",
    productName: "상품3",
    customCode: "CC003",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "2",
    location: "창고1",
  },
  {
    id: 9,
    productCode: "PC003",
    productName: "상품3",
    customCode: "CC003",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    type: "2",
    location: "창고2",
  },
];

export const dummyRegisterData: RegisterProduct[] = [
  {
    id: 1,
    productCode: "PC001",
    productName: "상품1",
    customCode: "CC001",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    storageMethod: "",

    team: "",
  },
  {
    id: 2,
    productCode: "PC002",
    productName: "상품2",
    customCode: "CC002",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    storageMethod: "",
    team: "",
  },
  {
    id: 3,
    productCode: "PC003",
    productName: "상품3",
    customCode: "CC003",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    storageMethod: "",
    team: "",
  },
];

export interface HistoryRecord {
  id: string; // 고유 식별자
  date: string; // 날짜 (ISO 형식: YYYY-MM-DD)
  location: string; // 장소
  person: string; // 담당자
  type: "입고" | "출고"; // 작업 유형
  stock: number; // 입고 갯수
  availableStock: number; // 출고 갯수
}

export const exampleData: HistoryRecord[] = [
  {
    id: "1",
    date: "2024-11-22",
    type: "입고",
    location: "A1",
    person: "김철수",
    stock: 0, // 입고 갯수
    availableStock: 30, // 출고 갯수
  },
  {
    id: "2",
    date: "2024-11-21",
    type: "출고",
    location: "A2",
    person: "이영희",
    stock: 0, // 입고 갯수
    availableStock: 30, // 출고 갯수
  },
  {
    id: "3",
    date: "2024-11-20",
    type: "입고",
    location: "창고2",
    person: "박민수",
    stock: 200, // 입고 갯수
    availableStock: 0, // 출고 갯수
  },
  {
    id: "4",
    date: "2024-11-19",
    type: "출고",
    location: "옥상",
    person: "최지은",
    stock: 0, // 입고 갯수
    availableStock: 50, // 출고 갯수
  },
  {
    id: "5",
    date: "2024-11-18",
    type: "입고",
    location: "창고1",
    person: "홍길동",
    stock: 300, // 입고 갯수
    availableStock: 0, // 출고 갯수
  },
  {
    id: "6",
    date: "2024-11-22",
    type: "입고",
    location: "A1",
    person: "김철수",
    stock: 0, // 입고 갯수
    availableStock: 30, // 출고 갯수
  },
  {
    id: "7",
    date: "2024-11-22",
    type: "입고",
    location: "A1",
    person: "김철수",
    stock: 0, // 입고 갯수
    availableStock: 30, // 출고 갯수
  },
  {
    id: "8",
    date: "2024-11-22",
    type: "입고",
    location: "A1",
    person: "김철수",
    stock: 0, // 입고 갯수
    availableStock: 30, // 출고 갯수
  },
];
