import React, { forwardRef } from "react";
import { Box, Typography, Grid, TextField, TableContainer, Table, TableBody, TableCell, TableRow, TableHead, Button } from "@mui/material";
import { Surgery } from "../../../models/SurgeryModel";
import { rows } from "../Model";

interface SurgeryFormProps {
  formData: Surgery | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  onSubmit: () => void;
  onClose: () => void;
}

const SurgeryForm = forwardRef<HTMLDivElement, SurgeryFormProps>(({ formData, onChange, title, onSubmit, onClose }, ref) => {
  return (
    <Grid
      container
      item
      ref={ref}
      padding={3}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
      }}
    >
      <Grid container item direction="column">
        <Box>
          <Typography variant="h2" paddingY={1}>
            {title}
          </Typography>
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={1}>
            <Typography width="25%" sx={{ textAlign: "left" }}>
              카테고리
            </Typography>
            <TextField sx={{ flex: 1 }} name="category" variant="outlined" size="small" value={formData?.category || ""} onChange={onChange} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={1}>
            <Typography width="25%" sx={{ textAlign: "left" }}>
              호칭
            </Typography>
            <TextField sx={{ flex: 1 }} name="surgeryTitle" variant="outlined" size="small" value={formData?.surgeryTitle || ""} onChange={onChange} />
          </Box>

          <TableContainer
            sx={{
              "& .MuiTableCell-root": {
                padding: "4px 8px",
                height: "32px",
                textAlign: "center",
              },
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f2f2f2" }}>
                  <TableCell align="center">언어</TableCell>
                  <TableCell align="center">시술명 (고객용)</TableCell>
                  <TableCell align="center">시술 효과</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.lang}>
                    <TableCell align="center">{row.lang}</TableCell>
                    <TableCell>
                      <TextField
                        variant="standard"
                        name={row.name}
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            "& input::placeholder": {
                              fontSize: "13px",
                              color: "#585858",
                            },
                          },
                        }}
                        placeholder={`${row.lang} 시술명을 입력해주세요.`}
                        size="small"
                        value={formData?.[row.name] || ""}
                        onChange={onChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={row.effect}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            "& input::placeholder": {
                              fontSize: "13px",
                              color: "#585858",
                            },
                          },
                        }}
                        size="small"
                        placeholder={`${row.lang} 시술효과를 입력해주세요.`}
                        value={formData?.[row.effect] || ""}
                        onChange={onChange}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Grid container item justifyContent="center" gap={1} paddingTop={2}>
          <Button variant="contained" onClick={onSubmit} color="primary">
            {title === "시술 추가" ? "추가" : "수정"}
          </Button>
          <Button variant="contained" onClick={onClose} sx={{ backgroundColor: "lightgray", color: "black" }}>
            닫기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default SurgeryForm;
