import { handleControllerResponse } from "../util/Function";
import { fetchDoctor, fetchTodayWorkingDoctor, registerDoctor, updateDoctorColor } from "../service/DoctorService";
import { Doctor } from "../models/DoctorModel";

class DoctorController {
  async handleFetchDoctor() {
    const handleFetchDoctorResponse = await fetchDoctor();
    return handleControllerResponse(handleFetchDoctorResponse.status, handleFetchDoctorResponse.data);
  }
  async handleFetchTodayWorkingDoctor(date: Date) {
    const handleFetchTodayWorkingDoctorResponse = await fetchTodayWorkingDoctor(date);
    return handleControllerResponse(handleFetchTodayWorkingDoctorResponse.status, handleFetchTodayWorkingDoctorResponse.data);
  }
  async handleRegisterDoctor(newDoctor: Doctor, img?: File) {
    const handleRegisterDoctorResponse = await registerDoctor(newDoctor, img);
    return handleControllerResponse(handleRegisterDoctorResponse.status, handleRegisterDoctorResponse.data);
  }

  async handleUpdateDoctorColor(doctor: Doctor) {
    const handleUpdateDoctorColorResponse = await updateDoctorColor(doctor);
    return handleControllerResponse(handleUpdateDoctorColorResponse.status, handleUpdateDoctorColorResponse.data);
  }
}
export const doctorController = new DoctorController();
