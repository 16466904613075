import { authLogin, validateToken } from "../service/AuthService";
import { handleControllerResponse } from "../util/Function";

class AuthController {
  async handleLogin(loginId: string, password: string) {
    const loginResponse = await authLogin(loginId, password);
    return handleControllerResponse(loginResponse.status, loginResponse.data);
  }

  async validateToken() {
    const token = localStorage.getItem("token");
    if (token === null) {
      return { success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" };
    } else {
      const validateTokenResponse = await validateToken(token);
      return handleControllerResponse(validateTokenResponse.status, validateTokenResponse.data);
    }
  }
}
export const authController = new AuthController();
