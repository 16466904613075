import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Modal, Box, Tooltip } from "@mui/material";
import { CardProp } from "../DailyOperations";
import { useHomeContext } from "../Context";
import { calculateTimeDifference } from "../../../util/Function";
import { parsingTimeDifference } from "./ReservationCard";
import ChartNumberTooltipName from "./ChartNumberTooltipName";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Waiting } from "../Model";

const WaitingCard: React.FC<CardProp> = ({ scheduleData }) => {
  const { onClickDetailModalOpen } = useHomeContext();

  const [timeDifference, setTimeDifference] = useState<number>(0);
  const [waitingData, setWaitingData] = useState<Waiting>(scheduleData.waiting.find((item) => item.endTime === null)!);

  useEffect(() => {
    setTimeDifference(calculateTimeDifference(waitingData.startTime!));
    const intervalId = setInterval(() => {
      setTimeDifference(calculateTimeDifference(waitingData.startTime!));
    }, 60000);

    return () => clearInterval(intervalId);
  }, [scheduleData.schedule.expectedVisitTime]);

  return (
    <Grid container item sx={{ width: "100%", height: "32px", background: "#fafafa", borderBottom: "1px solid #b0b0b0" }}>
      <Grid container item width="25%" justifyContent="center" display="flex" alignItems="center">
        <ChartNumberTooltipName scheduleData={scheduleData} />
        <Typography sx={{ fontSize: "12px", marginLeft: "4px" }}>
          ({scheduleData.customer.gender === "M" ? "남" : "여"}/{scheduleData.customer.age})
        </Typography>
        <Tooltip title={"정보 확인"} followCursor={false} arrow placement="right">
          <Box sx={{ height: "18px", maxWidth: "20px", ml: "1px" }}>
            <InfoRoundedIcon sx={{ color: "#bdbdbd", fontSize: "14px", cursor: "pointer" }} onClick={() => onClickDetailModalOpen(scheduleData)} />
          </Box>
        </Tooltip>
      </Grid>
      <Grid container item width="25%" justifyContent="center" display="flex" alignItems="center">
        {scheduleData.waiting?.map(
          (el) =>
            el.endTime === null && (
              <Typography key={el.waitingId || `waiting-${el.waitingRoom}-${el.startTime}`} sx={{ height: "18px", fontSize: "12px" }}>
                {el.waitingRoom || "미지정"}
              </Typography>
            )
        )}
      </Grid>
      <Grid container item width="25%" justifyContent="center" display="flex" alignItems="center">
        {scheduleData.waiting?.map(
          (el) =>
            el.endTime === null && (
              <Typography key={el.waitingId} sx={{ height: "18px", fontSize: "12px" }}>
                {el.startTime ? el.startTime : "시작시간"}
              </Typography>
            )
        )}
        {timeDifference < 1000 * 60 * 10 && (
          <Typography
            component="span"
            sx={{
              fontSize: "12px",
              color: timeDifference > 0 ? "blue" : "#ff4d4d",
              marginLeft: "4px",
            }}
          >
            {parsingTimeDifference(timeDifference)}
          </Typography>
        )}
      </Grid>

      <Grid container item width="25%" justifyContent="center" display="flex" alignItems="center">
        {scheduleData.waiting?.map((el, i) =>
          el.endTime === null ? (
            <Typography
              sx={{
                height: "18px",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
                lineHeight: "18px",
              }}
            >
              {el.waitingMemo || ""}
            </Typography>
          ) : (
            <Typography sx={{ height: "18px", fontSize: "12px" }} key={el.startTime?.toString()} />
          )
        )}
      </Grid>

      {/* <Grid container item width="15%" direction="column" justifyContent="center" alignItems="center">
        <Button sx={{ width: "52px", height: "20px", fontSize: "11px", textTransform: "none", minWidth: "unset" }} onClick={onClickOpenVisitModal}>
          룸할당
        </Button>
      </Grid> */}

      {/* <Modal open={waitModalOpen} onClose={onClickClose} sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ width: "440px", p: "16px", backgroundColor: "white", borderRadius: "8px", height: "auto" }}>
          <RegisterVisitModal
            scheduleId={scheduleData.schedule.scheduleId}
            customerName={scheduleData.customer.name}
            initialBuilding={scheduleData.schedule.building!}
            initialFloor={scheduleData.schedule.floor!}
            initialRoom={scheduleData.schedule.room!}
            onClickVisitCustomer={onClickVisitCustomer}
            onClickReturnCustomer={onClickReturnCustomer}
            onClose={onClickClose}
            waiting={true}
            onChangeWaitingList={onChangeWaitingList}
          />
        </Box>
      </Modal> */}
    </Grid>
  );
};
export default WaitingCard;
