import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { Counselor } from "../models/CounselorModel";
import { counselorController } from "../controller/CounselorController";

interface CounselorContextType {
  counselorList: Counselor[];
  setCounselorList: React.Dispatch<React.SetStateAction<Counselor[]>>;
  // selectedCounselor: Counselor | undefined;
  // setSelectedCounselor: React.Dispatch<React.SetStateAction<Counselor | undefined>>;
  registerCounselor: (newCounselor: Counselor) => Promise<any>;
  updateCounselor: (updatedCounselor: Counselor) => Promise<any>;
}

export const CounselorContext = createContext<CounselorContextType | undefined>(undefined);

export const CounselorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [counselorList, setCounselorList] = useState<Counselor[]>([]);
  // const [selectedCounselor, setSelectedCounselor] = useState<Counselor>();

  useEffect(() => {
    const fetchCounselor = async () => {
      const fetchDoctorResponse = await counselorController.handleFetchCounselor();
      if (fetchDoctorResponse.success) {
        const transformedData: Counselor[] = fetchDoctorResponse.data.map((doctor: Counselor) => ({
          ...doctor,
          birthDate: doctor.birthDate ? new Date(doctor.birthDate) : null,
          joiningDate: new Date(doctor.joiningDate),
          exitDate: doctor.exitDate ? new Date(doctor.exitDate) : null,
        }));
        setCounselorList(transformedData);
      } else {
        alert(fetchDoctorResponse.message + "\nfetchCounselor Context");
      }
    };
    fetchCounselor();
  }, []);

  const registerCounselor = async (counselor: Counselor) => {
    const response = await counselorController.handleRegisterCounselor(counselor);
    if (response.success) {
      //sse
      // setCounselorList((prevDevices) => [...prevDevices, response.data]);
      return response;
    } else {
      alert(response.message + "\nregisterCounselor Context");
    }
  };

  const updateCounselor = async (counselor: Counselor) => {
    const response = await counselorController.handleUpdateCounselor(counselor);
    if (response.success) {
      // 성공적으로 업데이트되었을 때 상태를 수정
      // setCounselorList((prevCounselors) =>
      //   prevCounselors.map((existingCounselor) =>
      //     existingCounselor.counselorId === counselor.counselorId
      //       ? { ...existingCounselor, ...counselor } // 기존 데이터를 업데이트된 데이터로 덮어씀
      //       : existingCounselor
      //   )
      // );
      return response;
    } else {
      alert(response.message + "\nupdateCounselor Context");
    }
  };
  return <CounselorContext.Provider value={{ counselorList, setCounselorList, registerCounselor, updateCounselor }}>{children}</CounselorContext.Provider>;
};

export const useCounselor = () => {
  const context = useContext(CounselorContext);
  if (!context) {
    throw new Error("useCounselor must be used within a CounselorProvider");
  }
  return context;
};
