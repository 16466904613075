import { Modal } from "@mui/material";
import { useSurgery } from "../../../context/SurgeryContext";
import { useState } from "react";
import { Surgery } from "../../../models/SurgeryModel";
import { initialSurgeryState } from "../Model";
import SurgeryForm from "./SurgeryForm";

interface SurgeryAddModalProps {
  open: boolean;
  onClickAddModalClose: () => void;
}

function SurgeryAddModal({ open, onClickAddModalClose }: SurgeryAddModalProps) {
  const { registerSurgery } = useSurgery();
  const [newSurgery, setNewSurgery] = useState<Surgery>(initialSurgeryState);

  /* 모달 닫기 버튼 클릭시 실행되는 핸들러 */
  const onClickCloseModal = () => {
    setNewSurgery(initialSurgeryState);
    onClickAddModalClose();
  };

  /* 추가버튼 클릭시 실행되는 핸들러 */
  const onClickRegisterSurgery = () => {
    if (!newSurgery.category || !newSurgery.surgeryTitle) {
      alert("내용을 입력해주세요.");
    } else {
      registerSurgery(newSurgery);
      setNewSurgery(initialSurgeryState);
      onClickAddModalClose();
    }
  };

  /* 인풋의 값을 변경하는 핸들러 */
  const onChangeNewSurgery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewSurgery((prev) => ({
      ...prev!,
      [name]: value,
    }));
  };

  return (
    <Modal open={open} onClose={onClickAddModalClose}>
      <SurgeryForm formData={newSurgery} onChange={onChangeNewSurgery} title="시술 추가" onSubmit={onClickRegisterSurgery} onClose={onClickCloseModal} />
    </Modal>
  );
}

export default SurgeryAddModal;
