import React, { useState } from "react";
import Sidebar from "../../component/Sidebar";
import { CssBaseline, Box, Tabs, Tab, Typography, Button, Grid } from "@mui/material";
import AllSupplies from "./AllSupplies";
import RegisterSupplies from "../Supply/RegisterSupply";
import RegisterModal from "./RegisterSupply/RegisterSupplyModal";
import { useLocalContext } from "./Context";
import Modal from "./StorageSupply";
import Search from "./Search";
import SupplyHistoryCard from "./SupplyHistoryCard";

interface TabConfig {
  label: string;
  category?: "ALL" | "SKIN" | "NURSE";
  component?: React.ReactNode;
}

const tabList: TabConfig[] = [
  { label: "전체 재고 목록", category: "ALL" },
  { label: "피부팀 재고 목록", category: "SKIN" },
  { label: "간호팀 재고 목록", category: "NURSE" },
  { label: "상품 추가", component: <RegisterSupplies /> },
];

const SupplyInfo: React.FC = () => {
  const { tab, onChangeTab, openDetailModal } = useLocalContext();
  const [open, setOpen] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  // 모달 열기/닫기 핸들러
  const onClickOpen = () => setOpen(true);
  const onClickClose = () => setOpen(false);

  // 모달 열기/닫기 핸들러
  const onClickOpenRegisterModal = () => setOpenRegisterModal(true);
  const onClickCloseRegisterModal = () => setOpenRegisterModal(false);

  return (
    <Box sx={{ display: "flex", position: "relative", width: "100%", height: "100vh" }}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          left: 0,
          top: 0,
          // height: "100vh",
          zIndex: 100,
          backgroundColor: "white",
        }}
      >
        <Sidebar />
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, bgcolor: "primary.contrastText", display: "flex", flexDirection: "column", height: "100%", widows: "100%" }}>
        {/* 상단 고정 영역 */}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 2,
            bgcolor: "primary.contrastText",
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          {/* 탭 영역 */}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // height: "54px",
            }}
          >
            <Tabs value={tab} aria-label="Supplies tabs" onChange={onChangeTab}>
              {tabList.map((item, index) => (
                <Tab key={index} label={item.label} />
              ))}
            </Tabs>
            <Button variant="contained" color="primary" onClick={onClickOpen}>
              창고 목록
            </Button>
          </Box>

          {/* 검색 영역 */}
          <Box
            sx={{
              bgcolor: "white",
              margin: "10px 0px",
            }}
          >
            <Grid container>
              <Grid item xs={10}>
                <Search />
              </Grid>
              <Grid item xs={2} sx={{ pl: 2 }}>
                <Button sx={{ width: "100%", height: "100%" }} onClick={tabList[tab].category ? onClickOpen : onClickOpenRegisterModal}>
                  {tabList[tab].category ? `입고 등록` : "상품 등록"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
            height: "calc(100vh - 250px)",
          }}
        >
          {tabList.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: tab === index ? "block" : "none",
                height: "100%",
              }}
            >
              {tab === index && (
                <Typography component="div" sx={{ height: "100%" }}>
                  {data.category ? <AllSupplies /> : data.component}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Modal onClickClose={onClickClose} open={open} />
      <RegisterModal open={openRegisterModal} onClickClose={onClickCloseRegisterModal} />
      {openDetailModal && <SupplyHistoryCard />}
    </Box>
  );
};
export default SupplyInfo;
