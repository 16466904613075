import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { Product, initialValue } from "../../models/ProductModel";
import { LanguageType } from "./Model";

interface ProductContextType {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: EditState;
  setIsEdit: React.Dispatch<React.SetStateAction<EditState>>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  language: LanguageType;
  onChangeLanguage: (language: LanguageType) => void;
}

interface EditState {
  type: boolean;
  item: Product;
}

interface ProviderProps {
  value: number;
  children: ReactNode;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const ProductLocalProvider: React.FC<ProviderProps> = ({ value, children }) => {
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<EditState>({ type: false, item: initialValue }); // 수정
  const [language, setLanguage] = useState<LanguageType>("한국어");
  const [searchQuery, setSearchQuery] = useState<string>(""); // 검색
  const [searchValue, setSearchValue] = useState<string>(""); // 검색 입력값

  // 검색 초기화 useEffect
  useEffect(() => {
    if (searchQuery !== "") {
      setSearchQuery("");
      setSearchValue("");
    }
  }, [value]);

  const onChangeLanguage = (language: LanguageType) => {
    setLanguage(language);
  };

  return (
    <ProductContext.Provider value={{ searchValue, setSearchValue, openForm, setOpenForm, isEdit, setIsEdit, searchQuery, setSearchQuery, language, onChangeLanguage }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useLocalContext = (): ProductContextType => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error("useLocalContext must be used within an ProductLocalProvider");
  }
  return context;
};
