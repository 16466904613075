import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid, Typography, TextField, Button, MenuItem, IconButton } from "@mui/material";
import { buildings } from "../../../models/RoomModel";
import { useHomeContext } from "../Context";
import { Schedule, ScheduleData, Service, initialService } from "../Model";
import { parseBuildingsToFloors, parseBuildingsToRooms } from "../../../util/Function";
import { useDoctor } from "../../../context/DoctorsContext";
import CloseIcon from "@mui/icons-material/Close";
import { useCounselor } from "../../../context/CounselorContext";
import CardCustomerDetails from "./CustomerDetailCard";
import RemainingSurgeryCard from "./RemainingSurgeryCard";
import { compareServiceLists } from "../utils";
import DetailCardTable from "./DetailCardTable";

export interface DetailCardProp {
  customer: ScheduleData;
}

const DetailCard: React.FC = () => {
  const { counselorList } = useCounselor();
  const { onClickDetailModalClose, detailModalContent, updateService } = useHomeContext();
  const { doctors } = useDoctor();
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState<ScheduleData["schedule"] | null>(null);
  const [newScheduleData, setNewScheduleData] = useState<ScheduleData>(detailModalContent);

  useEffect(() => {
    setNewScheduleData(detailModalContent);
  }, [detailModalContent]);

  // ESC 키 이벤트 핸들러 추가
  const handleEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClickDetailModalClose(false);
      }
    },
    [onClickDetailModalClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [handleEsc]);

  const [openRemainingSurgery, setOpenRemainingSurgery] = useState(false);
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  const onClickRemainingSurgeryOpen = () => {
    setOpenRemainingSurgery(true);
  };
  //api연결후 수정
  const onClickSave = () => {};

  // 취소 버튼 클릭
  const onClickCancel = () => {
    setEditData(null);
    setIsEdit(false);
  };

  const onChangeInput = useCallback((key: keyof ScheduleData["schedule"], value: string) => {
    setEditData((prev) => {
      if (!prev) return null;
      if (prev[key] === value) return prev; // 변경 없음
      const updates = { ...prev };

      switch (key) {
        case "building":
          return { ...updates, building: value, floor: "", room: "" };
        case "floor":
          return { ...updates, floor: value, room: "" };
        case "room":
          return { ...updates, room: value };
        default:
          return { ...updates, [key]: value };
      }
    });
  }, []);

  const styleInput = {
    InputProps: {
      sx: {
        fontSize: "12px",
      },
      readOnly: !isEdit,
    },
    InputLabelProps: {
      style: {
        fontSize: "12px",
      },
      shrink: true,
    },
  };

  const getNonNullValue = (value: string | undefined) => value ?? "";

  const onClickEdit = () => {
    setIsEdit(true);
    setEditData({ ...(detailModalContent?.schedule as Schedule) }); // 타입 단언 사용
  };

  const changeNewScheduleData = (field: keyof ScheduleData, value: any) => {
    setNewScheduleData((prev) => prev && { ...prev, [field]: value });
  };

  const onClickAddSurgery = () => {
    if (!newScheduleData) return;

    const newSurgery = {
      ...initialService,
      doctor: newScheduleData.schedule?.doctor || "",
      room: newScheduleData.schedule?.room || "",
      scheduleId: detailModalContent.schedule.scheduleId,
      date: formattedDate,
      customerId: detailModalContent.customer.customerId,
    };
    const newSurgeries = [...newScheduleData.service, newSurgery];

    changeNewScheduleData("service", newSurgeries);
  };

  const onChangeNewSurgeryDetails = (index: number, field: keyof Service, value: string) => {
    const updatedSurgeryList = newScheduleData?.service.map((surgery, i) => (i === index ? { ...surgery, [field]: value, order: index } : { ...surgery, order: i }));
    changeNewScheduleData("service", updatedSurgeryList);
  };

  const onClickRemoveSurgery = (index: number, serviceId: number) => {
    if (serviceId !== 0) {
      onChangeNewSurgeryDetails(index, "status", "deleted");
    } else {
      const deletedSurgeryList = newScheduleData?.service.filter((_, i) => i !== index);
      changeNewScheduleData("service", deletedSurgeryList);
    }
  };

  const checkService = compareServiceLists(detailModalContent.service, newScheduleData?.service);

  const timeSlice = (key: string) => {
    const visitTime =
      (!isEdit
        ? detailModalContent?.schedule?.status === "beforeVisit"
          ? detailModalContent?.schedule?.[key === "visit" ? "expectedVisitTime" : "expectedReturnTime"]
          : detailModalContent?.schedule?.[key === "visit" ? "visitTime" : "returnTime"]
        : editData?.status === "beforeVisit"
        ? editData?.[key === "visit" ? "expectedVisitTime" : "expectedReturnTime"]
        : editData?.[key === "visit" ? "visitTime" : "returnTime"]
      )?.slice(0, 5) ?? "";

    return visitTime;
  };

  const onClickAddScheduleList = async () => {
    updateService(checkService.array);
    alert("저장되었습니다.");
  };

  // 고정형
  return (
    <Box sx={{ position: "fixed", bottom: 0, right: 0, padding: 1, width: "840px", height: "600px", bgcolor: "#F1EEF4", border: "1px solid #ccc", borderRadius: "8px", zIndex: 1 }}>
      {/* M 이름 */}
      <Grid container justifyContent={"space-between"} sx={{ mb: 1 }}>
        <Typography variant="h2">
          {detailModalContent?.customer.name}({detailModalContent?.customer.age},{detailModalContent?.customer.gender === "F" ? "여" : "남"}) 고객님 시술 일정 관리
        </Typography>
        <IconButton onClick={() => onClickDetailModalClose(false)} sx={{ padding: 0, color: "#655dc6" }}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid container gap={1} direction={"column"} height={"95%"}>
        {/* M 상단 */}
        <Grid container item flex={1} gap={1}>
          <CardCustomerDetails />

          {/* M 고객 시술 정보 */}
          <Grid container item flex={3} padding={2} sx={{ boxShadow: 3, borderRadius: 2, bgcolor: "white" }}>
            {/* M 이름 / 버튼 */}
            <Grid container item direction={"row"} justifyContent={"space-between"}>
              <Typography sx={{ fontWeight: "bold" }}>{detailModalContent?.schedule.program || "프로그램 이름 미지정"}</Typography>
              {isEdit ? (
                <Box display={"flex"} gap={0.5}>
                  <Button sx={{ maxHeight: "23px", minWidth: "unset", fontSize: "10px", padding: "1px 5px" }} onClick={onClickSave}>
                    저장
                  </Button>
                  <Button sx={{ maxHeight: "23px", minWidth: "unset", fontSize: "10px", padding: "1px 5px", backgroundColor: "lightgray" }} onClick={onClickCancel}>
                    취소
                  </Button>
                </Box>
              ) : (
                <Button sx={{ maxHeight: "23px", minWidth: "unset", fontSize: "10px", padding: "1px 5px" }} onClick={onClickEdit}>
                  수정
                </Button>
              )}
            </Grid>
            {/* M 이름 말고 아래 */}
            <Grid container item gap={1}>
              {/* M 왼쪽 정보 */}
              <Grid container flex={1}>
                <Grid container item>
                  <Grid item flex={1}>
                    <TextField
                      variant="standard"
                      type="time"
                      fullWidth
                      size={"small"}
                      label={"방문시간"}
                      value={timeSlice("visit")}
                      disabled={!isEdit}
                      onChange={(e) => (detailModalContent?.schedule.status === "beforeVisit" ? onChangeInput("expectedVisitTime", e.target.value) : onChangeInput("visitTime", e.target.value))}
                      {...styleInput}
                    />
                  </Grid>
                  <Grid item flex={1}>
                    <TextField
                      variant="standard"
                      type="time"
                      fullWidth
                      size={"small"}
                      label={"귀가시간"}
                      value={timeSlice("")}
                      disabled={!isEdit}
                      onChange={(e) => (detailModalContent?.schedule.status === "beforeVisit" ? onChangeInput("expectedReturnTime", e.target.value) : onChangeInput("returnTime", e.target.value))}
                      {...styleInput}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item flex={1}>
                    <TextField
                      variant="standard"
                      disabled={!isEdit}
                      select
                      label="본관/신관"
                      fullWidth
                      value={detailModalContent?.schedule.building}
                      onChange={(e) => onChangeInput("building", e.target.value)}
                      {...styleInput}
                    >
                      {buildings.map((b) => (
                        <MenuItem key={b.id} value={b.name} sx={{ fontSize: "12px" }}>
                          {b.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item flex={1}>
                    <TextField
                      variant="standard"
                      select
                      label="층 선택"
                      fullWidth
                      {...styleInput}
                      value={isEdit ? editData?.floor ?? "" : detailModalContent?.schedule.floor ?? ""}
                      onChange={(e) => onChangeInput("floor", e.target.value)}
                      disabled={!isEdit}
                    >
                      {parseBuildingsToFloors(buildings, detailModalContent?.schedule.building || "").map((f) => (
                        <MenuItem key={f.id} value={f.name}>
                          {f.name || ""}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item flex={1}>
                    <TextField
                      variant="standard"
                      select
                      fullWidth
                      label="룸 선택"
                      {...styleInput}
                      value={isEdit ? editData?.room ?? "" : detailModalContent?.schedule.room ?? ""}
                      onChange={(e) => onChangeInput("room", e.target.value)}
                      disabled={!isEdit}
                    >
                      {parseBuildingsToRooms(buildings, detailModalContent?.schedule.building || "", detailModalContent?.schedule.floor || "").map((r) => (
                        <MenuItem key={r.id} value={r.name}>
                          {r.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item flex={1}>
                    <TextField
                      variant="standard"
                      select
                      fullWidth
                      value={isEdit ? getNonNullValue(editData?.doctor) : getNonNullValue(detailModalContent?.schedule?.doctor)}
                      onChange={(e) => onChangeInput("doctor", e.target.value)}
                      label="의사 선택"
                      disabled={!isEdit}
                      {...styleInput}
                    >
                      {doctors.map((doctor, index) => (
                        <MenuItem key={index} value={doctor.name} sx={{}}>
                          {doctor.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item flex={1}>
                    <TextField
                      variant="standard"
                      select
                      fullWidth
                      value={isEdit ? getNonNullValue(editData?.counselor) : getNonNullValue(detailModalContent?.schedule?.counselor)}
                      onChange={(e) => onChangeInput("counselor", e.target.value)}
                      label="상담자 선택"
                      disabled={!isEdit}
                      {...styleInput}
                    >
                      {counselorList.map((doctor, index) => (
                        <MenuItem key={index} value={doctor.name}>
                          {doctor.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>

              {/* 오른쪽 Grid */}
              <Grid container direction={"column"} flex={1}>
                <Grid container item xs={6} gap={1} sx={{ paddingBottom: "px" }}>
                  <Grid item flex={1}>
                    <TextField
                      fullWidth
                      disabled={!isEdit}
                      multiline
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "8px",
                          overflowY: "auto",
                        },
                      }}
                      rows={5}
                      value={(isEdit ? editData?.doctorMemo : detailModalContent?.schedule.doctorMemo) ?? ""}
                      {...styleInput}
                      onChange={(e) => onChangeInput("doctorMemo", e.target.value)}
                      label="의사/상담사 메모"
                    />
                  </Grid>
                  <Grid item flex={1}>
                    <TextField
                      fullWidth
                      multiline
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "8px",
                          overflowY: "auto",
                        },
                      }}
                      disabled={!isEdit}
                      rows={5}
                      value={isEdit ? editData?.arrangerMemo ?? "" : detailModalContent?.schedule.arrangerMemo ?? ""}
                      {...styleInput}
                      onChange={(e) => onChangeInput("arrangerMemo", e.target.value)}
                      label="시술 메모"
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={6} alignContent={"end"} gap={1}>
                  <Grid item flex={1}>
                    <TextField
                      fullWidth
                      disabled={true}
                      multiline
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "8px",
                          overflowY: "auto",
                        },
                      }}
                      rows={5}
                      value={detailModalContent?.schedule.vegasEmployeeMemo}
                      {...styleInput}
                      label="vagas 직원 메모"
                    />
                  </Grid>
                  <Grid item flex={1}>
                    <TextField
                      fullWidth
                      disabled={true}
                      multiline
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "8px",
                          overflowY: "auto",
                        },
                      }}
                      value={detailModalContent?.schedule.vegasReservationMemo || ""}
                      rows={5}
                      {...styleInput}
                      label="vagas 예약 메모"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* M 하단 */}
        <DetailCardTable
          newScheduleData={newScheduleData}
          onChangeNewSurgeryDetails={onChangeNewSurgeryDetails}
          onClickAddSurgery={onClickAddSurgery}
          onClickRemainingSurgeryOpen={onClickRemainingSurgeryOpen}
          onClickRemoveSurgery={onClickRemoveSurgery}
        />
        <Grid item container xs={1} gap={1} sx={{ alignContent: "center", justifyContent: "center" }}>
          <Button variant="contained" onClick={onClickAddScheduleList} sx={{ pt: "4px", pb: "4px", fontSize: "14px", color: "white", bgcolor: "#655dc6", ":hover": { bgcolor: "#554ea2" } }}>
            일정 등록
          </Button>
          <Button variant="contained" sx={{ pt: "4px", pb: "4px", fontSize: "14px", color: "white", bgcolor: "grey", ":hover": { bgcolor: "#e08a8a" } }} onClick={() => onClickDetailModalClose(false)}>
            취소
          </Button>
        </Grid>
      </Grid>
      <RemainingSurgeryCard
        open={openRemainingSurgery}
        setWaitModalOpen={setOpenRemainingSurgery}
        scheduleData={newScheduleData}
        setNewScheduleData={setNewScheduleData}
        newScheduleData={newScheduleData}
        changeNewScheduleData={changeNewScheduleData}
      />
    </Box>
  );
};

export default DetailCard;
