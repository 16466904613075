import React, { useState } from "react";
import { Grid, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, TextField, InputAdornment, Pagination } from "@mui/material";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Customer } from "./Model";
import { formatDate, formatPhoneNumber } from "../../util/Function";
import { useLocalContext } from "./Context";

interface CustomerListProps {
  setIsAddCustomer: (newState: boolean) => void;
}

const ColumnList = [
  { name: "이름", width: 25 },
  { name: "성별", width: 10 },
  { name: "생년월일", width: 23 },
  { name: "전화번호", width: 30 },
  { name: "등급", width: 15 },
];

const CustomerList: React.FC<CustomerListProps> = ({ setIsAddCustomer }) => {
  const { customers, setSelectCustomer, selectCustomer, page, setPage, totalCustomers } = useLocalContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState("");
  const rowsPerPage = 25; // 페이지 당 표시할 행의 수
  // 총 페이지 수 계산
  const totalPages = Math.ceil(totalCustomers / rowsPerPage);

  // 검색어 입력 핸들러
  const onKeyDownSearch = () => {
    setValue(searchTerm);
  };

  // 검색어 삭제 핸들러
  const clearSearch = () => {
    setSearchTerm("");
    setValue("");
  };

  // 행 클릭
  const onClickRow = (customer: Customer) => {
    setIsAddCustomer(false);
    setSelectCustomer(customer);
  };

  // 페이지 변경 핸들러
  const onChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Grid container direction="column" sx={{ height: "100%", flexGrow: 1 }}>
      <Grid container item sx={{ flexShrink: 0, alignItems: "center", justifyContent: "space-between" }}>
        <Grid item sx={{ display: "flex", alignItems: "center" }} xs={5}>
          <Typography variant="h2" sx={{ textAlign: "left" }}>
            고객 명단
          </Typography>
          <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={() => setIsAddCustomer(true)}>
            <PersonAddAlt1RoundedIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Grid>
        <Grid item xs={7}>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                height: "30px",
              },
              "& .MuiOutlinedInput-input": {
                padding: 0,
                width: "100%",
                fontSize: "12px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "4px",
              },
            }}
            placeholder="고객 검색"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onKeyDownSearch();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: 14 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => searchTerm && clearSearch()}>
                    <CloseIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Paper sx={{ flexGrow: 1, marginTop: 1, height: "calc(100vh - 200px)", boxShadow: 3, borderRadius: "8px", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
        <TableContainer component={"div"} sx={{ borderRadius: "8px" }}>
          <Table
            stickyHeader
            sx={{
              "& .MuiTableCell-root": {
                padding: "3.5px",
                textAlign: "center",
                fontSize: "11px",
                height: "20px",
              },
            }}
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#e1e1f5" }}>
                {ColumnList.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      width: `${column.width}%`,
                      textAlign: "center",
                      paddingBottom: "0px",
                      fontWeight: "bold",
                      background: "#e1e1f5",
                    }}
                  >
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {customers.map((customer) => (
                <TableRow
                  key={customer.customerUuid}
                  onClick={() => onClickRow(customer)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#f0f0fb",
                    },
                    backgroundColor: selectCustomer?.customerUuid === customer.customerUuid ? "#d0e0ff" : "transparent",
                  }}
                >
                  <TableCell sx={{ borderRight: "1px solid lightgrey" }}>{customer.name}</TableCell>
                  <TableCell sx={{ borderRight: "1px solid lightgrey" }}>{customer.gender === "F" ? "여" : "남"}</TableCell>
                  <TableCell sx={{ borderRight: "1px solid lightgrey" }}>{formatDate(customer.birthDate)}</TableCell>
                  <TableCell sx={{ borderRight: "1px solid lightgrey" }}>{formatPhoneNumber(customer.phoneNumber)}</TableCell>
                  <TableCell>{customer.grade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={totalPages}
          page={page}
          onChange={onChangePage}
          color="primary"
          size="small"
          sx={{
            width: "100%",
            backgroundColor: "#f0f0f0",
            padding: "8px",
            borderRadius: "0 0 8px 8px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Paper>
    </Grid>
  );
};

export default CustomerList;
