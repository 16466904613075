import { CssBaseline, Grid, Typography } from "@mui/material";
import PurchaseInfo from "./ProductList";
import ShoppingCardList from "./ShoppingCartList";
import CustomerInfo from "./CustomerInfo";
import { PurchaseLocalProvider } from "./Context";

const Purchase: React.FC = () => {
  return (
    <PurchaseLocalProvider>
      <Grid>
        <CssBaseline />
        <Grid sx={{ height: "100vh", display: "flex", flexDirection: "column", p: 2 }}>
          <Typography variant="h2">결제 페이지</Typography>
          <Grid sx={{ width: "100%", display: "flex", height: "calc(100% - 25px)" }}>
            <Grid container item xs={4} direction="column" width="100%" height="100%">
              <Grid container item xs={3.2} width="100%" height="20%">
                <CustomerInfo />
              </Grid>
              <Grid container item xs={8.7} width="100%" height="60%">
                <PurchaseInfo />
              </Grid>
            </Grid>
            <Grid container item width="100%" xs={8}>
              <ShoppingCardList />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PurchaseLocalProvider>
  );
};
export default Purchase;
