import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { NoticeItem, ScheduleData, Service, Waiting, WorkScheduleItem, initialWorkScheduleItem, DoctorDailySchedule, Customer, Schedule } from "./Model";
import { doctorDailyScheduleController, noticeController, scheduleController, workScheduleController } from "./Controller";
import { useAuth } from "../../context/AuthContext";
import { useDoctor } from "../../context/DoctorsContext";

export interface ContextType {
  noticeList: NoticeItem[];
  setNoticeList: React.Dispatch<React.SetStateAction<NoticeItem[]>>;
  registerNotice: (newNotice: string) => void;
  deleteNotice: (noticeId: number) => void;
  workSchedule: WorkScheduleItem;
  setWorkSchedule: React.Dispatch<React.SetStateAction<WorkScheduleItem>>;
  updateWorkSchedule: (workSchedule: WorkScheduleItem) => void;
  scheduleDataList: ScheduleData[];
  setScheduleDataList: React.Dispatch<React.SetStateAction<ScheduleData[]>>;
  visitCustomer: (scheduleId: number, building?: string, floor?: string, room?: string) => void;
  cancelSchedule: (scheduleId: number) => void;
  waitCustomer: (waitingData: Waiting) => void;
  returnCustomer: (scheduleId: number) => void;
  // setWaitingList: (waitingList: Waiting[]) => void;
  openDetailModal: boolean;
  onClickDetailModalClose: (openDetailModal: boolean) => void;
  setOpenDetailModal: React.Dispatch<React.SetStateAction<boolean>>;
  detailModalContent: ScheduleData;
  onClickDetailModalOpen: (scheduleData: ScheduleData) => void;
  doctorDailyScheduleList: DoctorDailySchedule[];
  setDoctorDailyScheduleList: React.Dispatch<React.SetStateAction<DoctorDailySchedule[]>>;
  registerDoctorDailySchedule: (doctorId: number, dailyScheduleText: string) => void;
  updateDoctorDailySchedule: (doctorDailyScheduleId: number, doctorScheduleText: string) => void;
  updateService: (service: Service[]) => void;
}

export const ContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [scheduleDataList, setScheduleDataList] = useState<ScheduleData[]>([]);
  const [noticeList, setNoticeList] = useState<NoticeItem[]>([]);
  // const [waitingList, setWaitingList] = useState<Waiting[]>([]);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [detailModalContent, setDetailModalContent] = useState<ScheduleData>({
    customer: {} as Customer,
    schedule: {} as Schedule,
    service: [],
    waiting: [],
  });

  const onClickDetailModalOpen = (scheduleData: ScheduleData) => {
    setOpenDetailModal(true);
    setDetailModalContent(scheduleData);
  };

  const onClickDetailModalClose = () => {
    setOpenDetailModal(false);
    setDetailModalContent({
      customer: {} as Customer,
      schedule: {} as Schedule,
      service: [],
      waiting: [],
    });
  };

  // const onClickDetailModalOpen = (scheduleData: ScheduleData) => {
  //   // console.log("눌렸나요?");
  //   setOpenDetailModal(true);
  //   setDetailModalContent(scheduleData);
  // };

  // // 박스를 닫을 때
  // const onClickDetailModalClose = () => {
  //   setOpenDetailModal(false);
  //   setDetailModalContent({});
  // };

  const { todayWorkingDoctors } = useDoctor();
  const [doctorDailyScheduleList, setDoctorDailyScheduleList] = useState<DoctorDailySchedule[]>([]);

  useEffect(() => {
    const fetchNoticeList = async () => {
      const fetchNoticeListResponse = await noticeController.fetchNoticeList();
      if (fetchNoticeListResponse.success) {
        setNoticeList(fetchNoticeListResponse.data);
      } else {
        alert(fetchNoticeListResponse.message + "\nfetchNoticeList Context");
      }
    };
    fetchNoticeList();
  }, []);

  const updateService = async (checkServiceArray: Service[]) => {
    const savedServiceResponse = await scheduleController.updateService(checkServiceArray);
    if (savedServiceResponse.success) {
      /* sse
      const updatedServices = newScheduleData.service.map((service) => {
        if (service.serviceId === 0) {
          const matchingService = savedServiceResponse.data.find((responseData: Service) => responseData.name === service.name);
          return {
            ...service,
            serviceId: matchingService?.serviceId, // serviceId 치환
          };
        }
        return service; // serviceId가 0이 아니면 그대로 유지
      }); */

      // newScheduleData에 updatedServices 반영
      /* sse
      const updatedScheduleData = {
        ...newScheduleData,
        service: updatedServices,
      }; */

      // setScheduleItems에서 업데이트
      /* sse
      setScheduleDataList((prevItems) =>
        prevItems.some((item) => item.customer.customerId === newScheduleData.customer.customerId)
          ? prevItems.map((item) => (item.customer.customerId === newScheduleData.customer.customerId ? updatedScheduleData : item))
          : [...prevItems, updatedScheduleData]
      ); */

      /* sse
      setDetailModalContent((prevData) => ({
        ...prevData,
        service: updatedServices, // 직접 업데이트된 서비스 배열 사용
      })); */
      return savedServiceResponse;
    } else {
      alert("저장에 실패했습니다. \n 상욱님에게 문의해주세요");
    }
    return savedServiceResponse.success;
  };

  const registerNotice = async (newNotice: string) => {
    const registerNoticeResponse = await noticeController.registerNotice(newNotice, user?.userId);
    if (registerNoticeResponse.success) {
      /* sse
      setNoticeList((prevNotices) => [...prevNotices, registerNoticeResponse.data]); 
      */
      return registerNoticeResponse;
    } else {
      alert(registerNoticeResponse.message + "\nregisterNotice Context");
    }
  };
  const deleteNotice = async (noticeId: number) => {
    const deleteNoticeResponse = await noticeController.deleteNotice(noticeId);
    if (deleteNoticeResponse?.success) {
      /* sse
      setNoticeList((prevNotices) => prevNotices.map((notice) => (notice.noticeId === deleteNoticeResponse.data.noticeId ? { ...notice, isDeleted: deleteNoticeResponse.data.isDeleted } : notice)));
      */
      return deleteNoticeResponse;
    } else {
      alert(deleteNoticeResponse.message + "\ndeleteNotice Context");
    }
  };

  // M 금일 출근 명부
  const [workSchedule, setWorkSchedule] = useState<WorkScheduleItem>(initialWorkScheduleItem);
  useEffect(() => {
    const fetchWorkSchedule = async () => {
      try {
        const fetchWorkScheduleResponse = await workScheduleController.fetchWorkSchedule();
        if (fetchWorkScheduleResponse.success) {
          setWorkSchedule(fetchWorkScheduleResponse.data);
        } else {
          alert(fetchWorkScheduleResponse.message + "\nfetchWorkSchedule Context");
        }
      } catch (error) {
        console.error("Error fetching notices:", error);
      }
    };
    fetchWorkSchedule();
  }, []);

  const updateWorkSchedule = async (workSchedule: WorkScheduleItem) => {
    const updateWorkScheduleResponse = await workScheduleController.updateWorkSchedule(workSchedule);
    if (updateWorkScheduleResponse.success) {
      return updateWorkScheduleResponse;
      /* sse
      setWorkSchedule(updateWorkScheduleResponse.data);
      */
    } else {
      alert(updateWorkScheduleResponse.message + "\nupdatedWorkSchedule Context");
    }
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      const scheduleResponse = await scheduleController.fetchSchedule();
      if (scheduleResponse.success) {
        const parsedScheduleResponse: ScheduleData[] = (scheduleResponse.data as unknown as any[]).map((item: any) => {
          return {
            customer: item.customer,
            schedule: item.schedule,
            service: [...item.service.filter((service: Service) => service.name === "방문"), ...item.service.filter((service: Service) => service.name !== "방문")],
            waiting: item.waiting,
          };
        });
        setScheduleDataList(parsedScheduleResponse);
      } else {
        alert(scheduleResponse.message + "\nfetchSchedule Context");
      }
    };
    fetchSchedule();
  }, []);

  const visitCustomer = async (scheduleId: number, building?: string, floor?: string, room?: string) => {
    const visitCustomerSchedule = await scheduleController.visitCustomer(scheduleId, building, floor, room);
    if (visitCustomerSchedule.success) {
      /* sse
      setScheduleDataList((prevData) =>
        prevData.map((item) => (item.schedule.scheduleId === updatedSchedule.data.scheduleId ? { ...item, schedule: { ...item.schedule, ...updatedSchedule.data } } : item))
      );
      */
      return visitCustomerSchedule;
    } else {
      alert(visitCustomerSchedule.message + "\nvisitCustomer Context");
    }
  };

  const cancelSchedule = async (scheduleId: number) => {
    const cancelCustomerSchedule = await scheduleController.cancelSchedule(scheduleId);
    if (cancelCustomerSchedule.success) {
      /* sse
      setScheduleDataList((prevData) =>
        prevData.map((item) => (item.schedule.scheduleId === updatedSchedule.data.scheduleId ? { ...item, schedule: { ...item.schedule, ...updatedSchedule.data } } : item))
      );
      */
      return cancelCustomerSchedule;
    } else {
      alert(cancelCustomerSchedule.message + "\ncancelSchedule Context");
    }
  };

  const waitCustomer = async (waitingData: Waiting) => {
    const waitCustomerSchedule = await scheduleController.waitCustomer(waitingData);
    if (waitCustomerSchedule.success) {
      /* sse
      setScheduleDataList((prevData) =>
        prevData.map((item) =>
          item.schedule.scheduleId === updatedSchedule.data.scheduleId
            ? {
                ...item,
                schedule: { ...item.schedule, status: "wait" },
                waiting: [...item.waiting, updatedSchedule.data],
              }
            : item
        )
      );
      */
      return waitCustomerSchedule;
    } else {
      alert(waitCustomerSchedule.message + "\nwaitCustomer Context");
    }
  };
  const returnCustomer = async (scheduleId: number) => {
    const returnCustomerSchedule = await scheduleController.returnCustomer(scheduleId);
    if (returnCustomerSchedule.success) {
      // setScheduleDataList((prevData) =>
      //   prevData.map((item) => (item.schedule.scheduleId === updatedSchedule.data.scheduleId ? { ...item, schedule: { ...item.schedule, ...updatedSchedule.data } } : item))
      // );
      return returnCustomerSchedule;
    } else {
      alert(returnCustomerSchedule.message + "\nreturnCustomer Context");
    }
  };

  useEffect(() => {
    const fetchDoctorDailySchedule = async () => {
      const fetchDoctorDailyScheduleResponse = await doctorDailyScheduleController.fetchDoctorDailySchedule(todayWorkingDoctors);
      if (fetchDoctorDailyScheduleResponse.success) {
        setDoctorDailyScheduleList(fetchDoctorDailyScheduleResponse.data);
      } else {
        alert(fetchDoctorDailyScheduleResponse.message + "\nfetchDoctorDailySchedule Context");
      }
    };
    fetchDoctorDailySchedule();
  }, [todayWorkingDoctors]);

  const registerDoctorDailySchedule = async (doctorId: number, dailyScheduleText: string) => {
    const registerDoctorDailyScheduleResponse = await doctorDailyScheduleController.registerDoctorSchedule(doctorId, dailyScheduleText);
    if (registerDoctorDailyScheduleResponse.success) {
      /* sse
      setDoctorDailyScheduleList((prevList: DoctorDailySchedule[]) => {
        return [...prevList, registerDoctorDailyScheduleResponse.data];
      });
      */
      return registerDoctorDailyScheduleResponse;
    } else {
      alert(registerDoctorDailyScheduleResponse.message + "\nregisterDoctorDailySchedule Context");
    }
  };

  const updateDoctorDailySchedule = async (doctorDailyScheduleId: number, doctorScheduleText: string) => {
    const startDoctorDailyScheduleResponse = await doctorDailyScheduleController.updateDoctorSchedule(doctorDailyScheduleId, doctorScheduleText);
    if (startDoctorDailyScheduleResponse.success) {
      /* sse
      setDoctorDailyScheduleList((prevList: DoctorDailySchedule[]) =>
        prevList.map((item) =>
          item.doctorDailyScheduleId === startDoctorDailyScheduleResponse.data.doctorDailyScheduleId ? { ...item, dailyScheduleText: startDoctorDailyScheduleResponse.data.dailyScheduleText } : item
        )
      );
      */
    } else {
      alert(startDoctorDailyScheduleResponse.message + "\nupdateDoctorDailySchedule Context");
    }
  };
  return (
    <HomeContext.Provider
      value={{
        noticeList,
        setNoticeList,
        registerNotice,
        deleteNotice,
        workSchedule,
        setWorkSchedule,
        updateWorkSchedule,
        scheduleDataList,
        setScheduleDataList,
        visitCustomer,
        cancelSchedule,
        waitCustomer,
        returnCustomer,
        // setWaitingList,
        openDetailModal,
        onClickDetailModalOpen,
        onClickDetailModalClose,
        setOpenDetailModal,
        detailModalContent,
        doctorDailyScheduleList,
        setDoctorDailyScheduleList,
        registerDoctorDailySchedule,
        updateDoctorDailySchedule,
        updateService,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

const HomeContext = createContext<ContextType | undefined>(undefined);

export const useHomeContext = (): ContextType => {
  const homeContext = useContext(HomeContext);
  if (homeContext === undefined) {
    throw new Error("useHome must be used within an HomeContextProvider");
  }
  return homeContext;
};
