import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Button, TextField, Select, MenuItem, Grid, Box, Typography } from "@mui/material";
import { useDoctor } from "../../../context/DoctorsContext";
import { useHomeContext } from "../Context";

interface AddScheduleModalProps {
  open: boolean;
  onClose: () => void;
}

const AddScheduleModal: React.FC<AddScheduleModalProps> = ({ open, onClose }) => {
  const { registerDoctorDailySchedule } = useHomeContext();
  const { todayWorkingDoctors, doctors } = useDoctor();
  const [selectedDoctorId, setSelectedDoctorId] = useState<number | "">("");
  const [dailyScheduleText, setDailyScheduleText] = useState<string>("");

  // 모달 추가 함수
  const onClickSave = () => {
    const selectedDoctor = doctors.find((doctor) => doctor.doctorId === selectedDoctorId);
    if (!selectedDoctorId) {
      alert("의사를 선택해주세요");
    }
    if (selectedDoctor?.doctorId) {
      registerDoctorDailySchedule(selectedDoctor.doctorId, dailyScheduleText);
      setSelectedDoctorId("");
      setDailyScheduleText("");
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent sx={{ padding: "20px 20px 0px 20px" }}>
        <Grid container item sx={{ mb: 2, justifyContent: "space-between" }}>
          <Typography variant="h1">의사 일정 추가</Typography>
          <Select
            value={selectedDoctorId}
            onChange={(e) => {
              setSelectedDoctorId(e.target.value ? parseInt(e.target.value as string, 10) : "");
            }}
            sx={{ width: "120px", height: "40px" }}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <span style={{ color: "#aaa" }}>의사 선택</span>;
              }
              const selectedDoctor = todayWorkingDoctors.find((doctor) => doctor.doctorId === selected);
              return selectedDoctor ? selectedDoctor.doctorName : "";
            }}
          >
            {todayWorkingDoctors.map((doctor) => (
              <MenuItem key={doctor.doctorId} value={doctor.doctorId ?? ""}>
                {doctor.doctorName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid container item>
          <TextField fullWidth label="일정 내용" multiline rows={2} value={dailyScheduleText} onChange={(e) => setDailyScheduleText(e.target.value)} sx={{ mb: 1 }} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickSave} variant="contained" color="primary">
          저장
        </Button>
        <Button onClick={onClose}>취소</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddScheduleModal;
