import React, { useState } from "react";
import { Grid, Typography, Paper, Checkbox, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import DoctorScheduleCard from "./DoctorSchedule/DoctorScheduleCard";
import { useHomeContext } from "./Context";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddScheduleModal from "./DoctorSchedule/DoctorScheduleAddCard";
import AddIcon from "@mui/icons-material/Add";
import { useDoctor } from "../../context/DoctorsContext";
import { DoctorWorkSchedule, DoctorDailySchedule } from "./Model";

const DoctorSchedule: React.FC = () => {
  const { doctorDailyScheduleList } = useHomeContext();
  const { todayWorkingDoctors } = useDoctor();
  const [isCompleteState, setIsCompleteState] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const changeCompleteState = () => {
    setIsCompleteState(!isCompleteState);
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center", justifyContent: "space-between" }}>
        <Grid sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h2" sx={{ textAlign: "left", marginLeft: "4px" }}>
            원장님 스케쥴
          </Typography>
          <AddIcon onClick={() => setIsAddModalOpen(true)} sx={{ cursor: "pointer", color: "#655dc6" }} />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              sx={{
                padding: "4px",
                "& .MuiSvgIcon-root": {
                  fontSize: "14px",
                },
                "&.Mui-disabled": {
                  color: "#b0b0b0",
                },
              }}
              checked={isCompleteState}
              onChange={changeCompleteState}
            />
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              완료
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, margin: "4px 0px 0px 4px", boxShadow: 3, borderRadius: "8px", overflowY: "auto" }}>
        {todayWorkingDoctors.map((doctor: DoctorWorkSchedule) => (
          <Accordion
            disableGutters
            sx={{
              margin: "0",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              sx={{
                height: "30px",
                minHeight: "30px !important",
                maxHeight: "30px !important",
                "&.Mui-expanded": {
                  height: "30px",
                  minHeight: "30px !important",
                  maxHeight: "30px !important",
                },
                backgroundColor: "#f0f0fb",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                  alignItems: "center",
                  "&.Mui-expanded": {
                    margin: "0 !important",
                  },
                },
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>{`${doctor.doctorName} 원장님`} </Typography>
              <Typography sx={{ fontSize: "10px", marginLeft: "4px", alignContent: "end", color: "#655dc6", verticalAlign: "sub" }}>
                {doctor.workType === "morning" ? "오전출근" : "오후출근"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px" }}>
              <Grid sx={{ display: "flex", flexDirection: "column", minHeight: "30px" }}>
                {doctorDailyScheduleList
                  ?.filter((doctorDailySchedule) => {
                    const isDoctorMatch = doctorDailySchedule.doctorId === doctor.doctorId;
                    const isStatusMatch = isCompleteState || doctorDailySchedule.status !== "end";
                    return isDoctorMatch && isStatusMatch;
                  })
                  .map((filteredSchedule: DoctorDailySchedule) => (
                    <Grid item xs={12} key={filteredSchedule.doctorDailyScheduleId}>
                      <DoctorScheduleCard doctorDailySchedule={filteredSchedule} />
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
      <AddScheduleModal open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} />
    </Grid>
  );
};
export default DoctorSchedule;
