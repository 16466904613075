import React, { Fragment } from "react";
import { Box, Button, IconButton, MenuItem, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useLocalContext } from "./Context";
import RegisterSurgeryFormModal from "./MainTable/RegisterFormModal";
import { LanguageType } from "./Model";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import ExportToCsv from "../Surgery/Excel";
import { useProduct } from "../../context/ProductContext";

const nation = [
  { id: 0, name: "한국어" },
  { id: 1, name: "영어" },
  { id: 2, name: "일본어" },
  { id: 3, name: "중국어" },
];

const ControlsBar: React.FC = () => {
  const { productList } = useProduct();
  const { setOpenForm, setSearchQuery, searchValue, setSearchValue, language, onChangeLanguage } = useLocalContext();

  // 시술 아이템 생성 폼 오픈 핸들러
  const onClickOpenRegister = () => {
    setOpenForm(true);
  };

  // 검색어 입력 제어 핸들러
  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  // 검색어 전송 핸들러
  const onSubmitSearchValue = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchQuery(searchValue);
  };

  const onChangeExcelFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // scheduleController.uploadScheduleExcel(file); // api 추가 필요
    }
  };

  const onChangeDelete = () => {
    setSearchQuery(""); // 검색어 초기화
    setSearchValue("");
  };

  return (
    <Fragment>
      <Box display={"flex"} gap={1} mr={1}>
        <Box sx={{ display: "flex", alignItems: "center", pt: 0.5 }}>
          <ExportToCsv data={productList} name="상품정보" />
        </Box>
        <Box sx={{ display: "flex", boxSizing: "border-box", alignItems: "center" }}>
          <input accept=".xlsx, .xls" style={{ display: "none" }} id="upload-button-file" type="file" onChange={onChangeExcelFile} />
          <label htmlFor="upload-button-file">
            <IconButton component="span" sx={{ padding: "4px" }}>
              <CloudUploadRoundedIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </label>
        </Box>
        <TextField
          select
          value={language}
          size="small"
          sx={{ width: "90px" }}
          onChange={(e) => {
            onChangeLanguage(e.target.value as LanguageType);
          }}
        >
          {nation.map((nation) => (
            <MenuItem key={nation.id} value={nation.name}>
              {nation.name}
            </MenuItem>
          ))}
        </TextField>
        <Button onClick={onClickOpenRegister}>상품 추가</Button>
        <Box sx={{ display: "flex" }} gap={0.5} component={"form"} onSubmit={onSubmitSearchValue}>
          <TextField
            value={searchValue}
            onChange={onChangeSearchValue}
            variant="outlined"
            placeholder="검색어를 입력해 주세요"
            size="small"
            sx={{ width: "300px" }}
            InputProps={{
              endAdornment: searchValue && (
                <InputAdornment position="end">
                  <IconButton onClick={onChangeDelete} size="small">
                    <CloseIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            type="submit"
            aria-label="search"
            sx={{
              borderRadius: 1,
              backgroundColor: "primary.main",
              color: "white",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            <SearchIcon style={{ fill: "white" }} />
          </IconButton>
        </Box>
      </Box>
      <RegisterSurgeryFormModal />
    </Fragment>
  );
};

export default React.memo(ControlsBar);
