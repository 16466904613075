import { Modal } from "@mui/material";
import { useSurgery } from "../../../context/SurgeryContext";
import SurgeryForm from "./SurgeryForm";

interface SurgeryEditModalProps {
  open: boolean;
  onClickEditModalClose: () => void;
}

function SurgeryEditModal({ open, onClickEditModalClose }: SurgeryEditModalProps) {
  const { selectedSurgery, setSelectedSurgery, updateSurgery } = useSurgery();

  /* 인풋 변경 시 실행되는 핸들러 */
  const onChangeSurgery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedSurgery((prev) => ({
      ...prev!,
      [name]: value,
    }));
  };

  /* 수정버튼 클릭시 실행되는 핸들러 */
  const onClickUpdateSurgery = () => {
    if (window.confirm("내용을 수정하시겠습니까?")) {
      if (selectedSurgery) {
        updateSurgery(selectedSurgery);
        onClickEditModalClose();
      } else {
        onClickEditModalClose();
      }
    }
  };

  return (
    <Modal open={open} onClose={onClickEditModalClose}>
      <SurgeryForm formData={selectedSurgery} onChange={onChangeSurgery} title="시술 정보 수정" onSubmit={onClickUpdateSurgery} onClose={onClickEditModalClose} />
    </Modal>
  );
}

export default SurgeryEditModal;
