import React, { useState } from "react";
import { CounselorLocalProvider } from "./Context";
import CounselorList from "./CounselorList";
import CounselorDetails from "./CounselorDetails";
import { Grid } from "@mui/material";
import RegisterCounselor from "./RegisterCounselor";

const Counselor: React.FC = () => {
  const [isAddCounselor, setIsAddCounselor] = useState<boolean>(false);

  return (
    <CounselorLocalProvider>
      <Grid container direction="row" sx={{ height: "100%", padding: "10px" }}>
        <Grid container item xs={3} sx={{ height: "100%" }}>
          <CounselorList setIsAddCounselor={setIsAddCounselor} />
        </Grid>
        <Grid container item xs={9} sx={{ height: "100%", paddingLeft: "20px" }}>
          {isAddCounselor ? <RegisterCounselor setIsAddCounselor={setIsAddCounselor} /> : <CounselorDetails />}
        </Grid>
      </Grid>
    </CounselorLocalProvider>
  );
};

export default Counselor;
