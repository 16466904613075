import { Box, FormGroup, TextField, IconButton } from "@mui/material";
import React, { useState, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useLocalContext } from "../Context";

const SearchBar: React.FC = () => {
  const { setSearchQuery } = useLocalContext();
  const [inputValue, setInputValue] = useState<string>("");

  // 메모이제이션된 핸들러
  const onChangeSearchValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  // 검색 제출 핸들러
  const onSubmitSearchValue = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setSearchQuery(inputValue);
    },
    [inputValue, setSearchQuery]
  );

  return (
    <Box sx={{ width: "100%", mx: "auto" }}>
      <form onSubmit={onSubmitSearchValue}>
        <FormGroup>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="장비 검색"
              size="small"
              value={inputValue}
              onChange={onChangeSearchValue}
              InputProps={{
                sx: { padding: 0, height: "40px" },
              }}
              sx={{ mr: 0.5 }}
            />
            <IconButton
              type="submit"
              aria-label="search"
              sx={{
                width: 40,
                height: 40,
                borderRadius: 1,
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </FormGroup>
      </form>
    </Box>
  );
};

export default SearchBar;
