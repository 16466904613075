import React, { useState } from "react";
import { AppBar, Grid, Tab, Tabs } from "@mui/material";
import { ProductLocalProvider } from "./Context";
import ControlsBar from "./ControlsBar";
import MainTable from "./MainTable";

const Product: React.FC = () => {
  const [value, setValue] = useState(0);

  // 탭 변경 핸들러
  const onChangeTabsValue = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ProductLocalProvider value={value}>
      <Grid container direction="column" sx={{ height: "100vh" }}>
        <AppBar
          position="sticky"
          elevation={0}
          sx={{
            backgroundColor: "primary.contrastText",
            top: 0,
            left: 0,
            height: "48px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid lightgrey",
          }}
        >
          <Tabs value={value} onChange={onChangeTabsValue}>
            <Tab label="판매중 프로모션" />
            <Tab label="판매중 상품" />
            <Tab label="판매종료 프로모션" />
            <Tab label="판매종료 상품" />
          </Tabs>
          <ControlsBar />
        </AppBar>

        <Grid item sx={{ flex: 1, overflowY: "auto" }}>
          <MainTable value={value} />
        </Grid>
      </Grid>
    </ProductLocalProvider>
  );
};

export default Product;
