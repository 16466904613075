import { Product } from "../../models/ProductModel";

export interface Customer {
  customerId: number | null;
  customerUuid: string;
  vegasId: string;
  name: string;
  gender: string;
  birthDate: Date;
  phoneNumber: string;
  address: string | null;
  counselor: string;
  doctorUuid: string;
  memo: string | null;
  country: string;
  funnel: string | null;
  imgUrl: string | null;
  grade: string;
}

export interface VisitCustomer {
  id: number;
  date: string;
  status: string | null;
  package: string | null;
  count: number | null;
  total: number | null;
  doctor: string;
  room: string;
  description: string | null;
  manager: string;
  visitingTime: string | null;
  returnTime: string | null;
  plannedVisitingTime: string | null;
  plannedReturnTime: string | null;
  funnel: string | null;
}

export interface ProductPurchaseList extends Product {
  vat: boolean;
  serviceCheck: boolean;
  checked: boolean;
  quantity: number;
}
export const initialCustomer: Customer = {
  customerId: null,
  customerUuid: "",
  vegasId: "",
  name: "",
  phoneNumber: "",
  address: "",
  birthDate: new Date(),
  funnel: "",
  imgUrl: "",
  memo: "",
  gender: "",
  grade: "",
  counselor: "",
  doctorUuid: "",
  country: "",
};

export type LanguageType = "한국어" | "영어" | "중국어" | "일본어";

export const mockCustomerData = [
  {
    customerUuid: 0,
    name: "홍길동",
    gender: "남",
    birthDate: new Date("1990-05-15"),
    phoneNumber: "010-1234-5678",
    grade: "VIP",
    address: "서울 강남1 바나나 바나나",
    funnel: "블로그",
    imgUrl: "",
    memo: "이 고객은 특별한 고객입니다. 각별한 주의가 필요합니다",
  },
  {
    customerUuid: 1,
    name: "김철수",
    gender: "여",
    birthDate: new Date("1990-05-15"),
    phoneNumber: "010-9876-5432",
    grade: "일반",
    address: "서울 강남2 아이스크림 아이스크림",
    funnel: "네이버 카페",
    imgUrl: "",
    memo: "이 고객은 얼굴에 필러를 넣은 것 같다",
  },
  {
    customerUuid: 2,
    name: "이영희",
    gender: "여",
    birthDate: new Date("1990-05-15"),
    phoneNumber: "010-2468-1357",
    grade: "VIP",
    address: "서울 강남3 카페인 카페인",
    funnel: "인스타",
    imgUrl: "",
    memo: "쓸말이 더이상 없다 뭘쓰지;",
  },
];

export const consultations = [
  {
    id: 1,
    date: "2024-08-12(목) 오전 10:37",
    status: "재진",
    package: "리프팅 프로파운드 비스포크 프로파운드(수면마취+369 진정관리) ",
    count: 3,
    total: 10,
    description: "",
    doctor: "최두영 의사",
    room: "본관 2층 1번룸",
    manager: "홍홍홍",
    visitingTime: "2024-08-12(목) 오전 10:37",
    returnTime: "2024-08-12(목) 오후 14:37",
    plannedVisitingTime: "2024-08-12(목) 오전 10:37",
    plannedReturnTime: "2024-08-12(목) 오후 14:37",
    funnel: "인스타",
  },
  {
    id: 2,
    date: "2024-08-03(화) 오전 10:35",
    status: "초진",
    package: "리프팅 프로파운드 비스포크 프로파운드(수면마취+369 진정관리) ",
    count: 2,
    total: 10,
    description: "",

    doctor: "최두영 의사",
    room: "본관 2층 2번룸",
    manager: "홍홍홍",
    visitingTime: "2024-08-12(목) 오전 10:37",
    returnTime: "2024-08-12(목) 오후 14:37",
    plannedVisitingTime: "2024-08-12(목) 오전 10:37",
    plannedReturnTime: "2024-08-12(목) 오후 14:37",
    funnel: "블로그",
  },
  {
    id: 3,
    date: "2024-07-28(수) 오후 4:09",
    status: "초진",
    package: "볼륨감소 초음파 보톡스 풀페이스 보톡스 (보툴렉스) 1+1회",
    count: 1,
    total: 10,
    doctor: "홍민표 의사",
    room: "본관 4층 1번룸",
    description: "",
    manager: "홍홍홍",
    visitingTime: "2024-08-12(목) 오전 10:37",
    returnTime: "2024-08-12(목) 오후 14:37",
    plannedVisitingTime: "2024-08-12(목) 오전 10:37",
    plannedReturnTime: "2024-08-12(목) 오후 14:37",
    funnel: "카페",
  },
];
