import React, { useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Button } from "@mui/material";
import Search from "./Search";
import { useLocalContext } from "./Context";
import RegisterModal from "./RegisterSupply/RegisterSupplyModal";

const RegisterSupply: React.FC = () => {
  const { filterRegisterData } = useLocalContext();
  const [open, setOpen] = useState(false);

  // 모달 열기/닫기 핸들러
  const onClickOpen = () => setOpen(true);
  const onClickClose = () => setOpen(false);
  return (
    <>
      <Paper>
        <TableContainer
          sx={{
            maxHeight: "calc(100vh - 48px)",
            overflowY: "auto",
            "& .MuiTableCell-root": {
              padding: "4px 8px",
              height: "32px",
              textAlign: "center",
              fontSize: "0.875rem",
            },
            "& .MuiTableCell-head": {
              backgroundColor: "#e1e1f5",
            },
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>대카테고리</TableCell>
                <TableCell>소카테고리</TableCell>
                <TableCell>상품명</TableCell>
                <TableCell>저장방법</TableCell>
                <TableCell>상품코드</TableCell>
                <TableCell>자체코드</TableCell>
                <TableCell>단위 개수</TableCell>
                <TableCell>관리팀</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterRegisterData.map((item: any) => (
                <TableRow key={item.id}>
                  <TableCell>{item.mainCategory}</TableCell>
                  <TableCell>{item.subCategory}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.productCode}</TableCell>
                  <TableCell>{item.productCode}</TableCell>
                  <TableCell>{item.customCode}</TableCell>
                  <TableCell>{item.stock}</TableCell>
                  <TableCell>{item.location}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <RegisterModal open={open} onClickClose={onClickClose} />
    </>
  );
};

export default RegisterSupply;
