import axios from "axios";
import { handleServiceApiError } from "../util/Function";
import { Doctor } from "../models/DoctorModel";

const customerApiUrl = "/api/v1/doctor";

const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});

export const fetchDoctor = async () => {
  try {
    const response = await apiClient.post(`${customerApiUrl}`, {});
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const fetchTodayWorkingDoctor = async (date: Date) => {
  try {
    const response = await apiClient.post(`${customerApiUrl}/work/today`, { date: date });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const registerDoctor = async (newDoctor: Doctor, img?: File) => {
  const formData = new FormData();
  formData.append("doctorDto", new Blob([JSON.stringify(newDoctor)], { type: "application/json" }));
  if (img) {
    formData.append("img", img);
  }
  try {
    const response = await apiClient.post(`${customerApiUrl}/register`, formData);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const updateDoctorColor = async (doctor: Doctor) => {
  try {
    const response = await apiClient.post(`${customerApiUrl}/color/update`, { doctorId: doctor.doctorId, color: doctor.color });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
