import React, { useState } from "react";
import { Grid } from "@mui/material";
import DoctorsList from "./DoctorsList";
import DoctorWeeklySchedule from "./DoctorWeeklySchedule";
import DoctorDetails from "./DoctorDetails";
import { Doctor } from "../../models/DoctorModel";
import RegisterDoctor from "./RegisterDoctor";
import { ContextProvider } from "./Context";

export interface preDoctor {
  id: string;
  name: string;
  color: string;
}

export interface Counselor {
  id: string;
  name: string;
}
export const counselors: Counselor[] = [
  { id: "오민희", name: "오민희" },
  { id: "정슬기", name: "정슬기" },
  { id: "최미령", name: "최미령" },
  { id: "소효빈", name: "소효빈" },
  { id: "김지원", name: "김지원" },
  { id: "조윤정", name: "조윤정" },
  { id: "이아름", name: "이아름" },
  { id: "박소현", name: "박소현" },
  { id: "진우", name: "진우" },
  { id: "한아름", name: "한아름" },
  { id: "박연실", name: "박연실" },
  { id: "전정원", name: "전정원" },
  { id: "김정연", name: "김정연" },
  { id: "none", name: "미지정" },
];
export const doctorsColor: string[] = [
  "#BBE3FF",
  "#22DEF2",
  "#41A9FF",
  "#276FF3",
  "#D0FBD0",
  "#2CEFB8",
  "#A9EF77",
  "#58CF78",
  "#FFE7BD",
  "#FFC39B",
  "#FFA763",
  "#E97871",
  "#FDCEFF",
  "#E591FF",
  "#FE6FB4",
  "#9C95FF",
  "#95A7BE",
  "#AC8C78",
];

const Doctors: React.FC = () => {
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor>();
  const [isAddDoctor, setIsAddDoctor] = useState<boolean>(false);
  return (
    <ContextProvider>
      <Grid container height="100%" direction="column" padding="10px">
        <Grid container item xs={4} sx={{ height: "100%", overflow: "hidden" }}>
          <Grid container item xs={12} sx={{ height: "100%", overflow: "hidden" }}>
            <DoctorWeeklySchedule />
          </Grid>
        </Grid>
        <Grid container item xs={8} sx={{ paddingTop: "20px" }}>
          <Grid container item xs={3.5} sx={{ height: "100%" }}>
            <DoctorsList onDoctorSelect={setSelectedDoctor} setIsAddDoctor={setIsAddDoctor} />
          </Grid>
          <Grid container item xs={8.5} sx={{ height: "100%", paddingLeft: "20px" }}>
            {isAddDoctor ? <RegisterDoctor isAddDoctor={isAddDoctor} setIsAddDoctor={setIsAddDoctor} /> : <DoctorDetails selectedDoctor={selectedDoctor} />}
          </Grid>
        </Grid>
      </Grid>
    </ContextProvider>
  );
};
export default Doctors;
