import { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, TextField, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { VisitCustomer, consultations } from "../Model";

const ConsultationHistory = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [mainCustomer, setMainCustomer] = useState<VisitCustomer[]>(consultations);
  const [isEditInfo, setIsEditInfo] = useState<VisitCustomer[]>(consultations);

  //방문 탭 내용 수정 핸들러
  const onChangeEditCustomer = (index: number, field: string | number, value: string | Date) => {
    setIsEditInfo((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [field]: value,
      };
      return newState;
    });
  };

  // 저장 버튼 클릭시 저장 함수 실행 핸들러
  const onClickSave = () => {
    setMainCustomer((prevCustomers) => prevCustomers.map((customer, index) => (isEditInfo[index] && customer.id === isEditInfo[index].id ? { ...customer, ...isEditInfo[index] } : customer)));
    setIsEditMode(false);
  };

  // 취소 버튼 클릭시 수정 내용 초기화 및 모달 닫기 핸들러
  const onClickCancel = () => {
    setIsEditInfo(mainCustomer);
    setIsEditMode(false);
  };

  // 수정 버튼 클릭시 수정 모드 활성화 및 배열 복사 핸들러
  const onClickEdit = () => {
    setIsEditInfo([...mainCustomer]);
    setIsEditMode(true);
  };

  // 텍스트 필드 렌더링
  const renderTextField = (index: number, field: string | number, value: string | number) => (
    <TextField
      fullWidth
      value={value}
      size="small"
      onChange={(e) => onChangeEditCustomer(index, field, e.target.value)}
      sx={{
        "& .MuiInputBase-input": { fontSize: "14px", padding: "3px" },
        "& .MuiInputLabel-root": { fontSize: "14px", padding: "0px" },
      }}
      inputProps={{
        style: { fontSize: "14px" },
      }}
    />
  );

  return (
    <Grid container>
      {isEditInfo?.map((item, index) => (
        <Accordion key={index} sx={{ width: "100%", border: "1px solid #e0e0e0", borderRadius: 1, mb: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {item.date} | {item.status}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {/* 시술 목록 섹션 */}
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  시술 목록
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          시술:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        {isEditMode ? renderTextField(index, "package", item.package || "") : <Typography variant="body2">{item.package}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          시술 횟수:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? (
                          <Grid container sx={{ flexDirection: "row" }}>
                            <Grid>{renderTextField(index, "count", item.count || "")} </Grid>/<Grid>{renderTextField(index, "total", item.total || "")}</Grid>
                          </Grid>
                        ) : (
                          <Typography variant="body2">
                            {item.count} / {item.total}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          담당의:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "doctor", item.doctor) : <Typography variant="body2">{item.doctor}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          시술 상세내용:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "description", item.description || "") : <Typography variant="body2">{item.description}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          시술 룸:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "room", item.room) : <Typography variant="body2">{item.room}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* 방문 정보 섹션 */}
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  방문 정보
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          담당 실장:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "manager", item.manager) : <Typography variant="body2">{item.manager}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          유입경로:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "funnel", item.funnel || "") : <Typography variant="body2">{item.funnel}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          방문 시간:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "visitingTime", item.visitingTime || "") : <Typography variant="body2">{item.visitingTime}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          예정 방문 시간:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "plannedVisitingTime", item.plannedVisitingTime || "") : <Typography variant="body2">{item.plannedVisitingTime}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          귀가 시간:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "returnTime", item.returnTime || "") : <Typography variant="body2">{item.returnTime}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          예정 귀가 시간:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {isEditMode ? renderTextField(index, "plannedReturnTime", item.plannedReturnTime || "") : <Typography variant="body2">{item.plannedReturnTime}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* 버튼 섹션 */}
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 2 }}>
                {isEditMode ? (
                  <>
                    <Button variant="outlined" size="small" onClick={onClickCancel}>
                      취소
                    </Button>
                    <Button variant="outlined" size="small" onClick={onClickSave}>
                      저장
                    </Button>
                  </>
                ) : (
                  <Button variant="outlined" size="small" onClick={onClickEdit}>
                    수정
                  </Button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default ConsultationHistory;
