import { Grid, Box, Typography } from "@mui/material";

const CustomerHomeCard = () => {
  return (
    <Grid container sx={{ height: "100%", flexDirection: "column", p: 2 }}>
      {/* 상단 섹션 */}
      <Grid item container spacing={2} sx={{ flexGrow: 1, paddingBottom: 2 }}>
        <Grid item xs={8} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" sx={{ textAlign: "left" }}>
            재무정보
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              padding: 2,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "white",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>* 총 결제 금액 * 고객 등급 * 적립금 * 미수급(차트로 표현예정)</Typography>
          </Box>
        </Grid>

        <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" sx={{ textAlign: "left" }}>
            서비스 내역
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              padding: 2,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "white",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ textAlign: "center" }}> 담당 의사: ""</Typography>
            <Typography sx={{ textAlign: "center" }}> 담당 실장: ""</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* 하단 섹션 */}
      <Grid item container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" sx={{ textAlign: "left" }}>
            서비스 통계
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "white",
              p: 2,
            }}
          >
            차트 넣을 예정
          </Box>
        </Grid>

        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", p: 0 }}>
          <Typography variant="h2" sx={{ textAlign: "left" }}>
            선호 서비스
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "white",
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography>1. 리프팅 프로파운드 비스포크 프로파운드 : 10회 / 2,390,0000 </Typography>
            <Typography>2.볼륨감소 초음파 보톡스 풀페이스 보톡스 (보툴렉스) 1+1회 : 5회 / 1.090,000 </Typography>
            <Typography>3.보톡스보톡스보톡스보톡스보톡스1 : 1회 / 30만원 </Typography>
            차트에 나온 내용 통계 및 순위로 나타내려고 합니다.
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerHomeCard;
