import React, { useState } from "react";
import { TextField, Select, MenuItem, Button, Box, Typography, Grid, Modal, SelectChangeEvent } from "@mui/material";
import { useLocalContext } from "../Context";
import { RegisterProduct } from "../Model";

const gradeOptions = [
  { label: "간호팀", value: "간호팀" },
  { label: "피부팀", value: "피부팀" },
];

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

interface RegisterSupplyModalProps {
  open: boolean;
  onClickClose: () => void;
}

const RegisterSupplyModal: React.FC<RegisterSupplyModalProps> = ({ open, onClickClose }) => {
  const { productData, setProductData } = useLocalContext();
  const [formData, setFormData] = useState<RegisterProduct>({
    id: Date.now(), // ID 초기값
    productCode: "",
    customCode: "",
    productName: "",
    storageMethod: "",
    mainCategory: "",
    subCategory: "",
    stock: 0,
    team: "",
  });

  // 폼 변경 핸들러
  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: name === "stock" ? Number(value) : value, // 숫자 필드 처리
    });
  };

  // 폼 제출 핸들러
  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // 새 데이터 추가
    setProductData([...productData, { ...formData, id: Date.now() }]);

    // 폼 초기화
    setFormData({
      id: Date.now(),
      productCode: "",
      customCode: "",
      productName: "",
      storageMethod: "",
      mainCategory: "",
      subCategory: "",
      stock: 0,
      team: "",
    });

    onClickClose();
  };

  return (
    <Modal open={open} onClose={onClickClose} aria-labelledby="modal-title">
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" sx={{ mb: 2 }}>
          상품 정보 등록
        </Typography>
        <Box component="form" onSubmit={onSubmitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center", gap: 2, marginBottom: 2 }}>
                {gradeOptions.map((option) => (
                  <Button
                    key={option.value}
                    variant="outlined"
                    onClick={() => setFormData({ ...formData, team: option.value })}
                    sx={{
                      fontSize: "14px",
                      backgroundColor: formData.team === option.value ? "#e1e1f5" : "transparent",
                    }}
                  >
                    {option.label}
                  </Button>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Select fullWidth value={formData.mainCategory} displayEmpty name="mainCategory" onChange={onChangeValue} variant="outlined">
                <MenuItem value="" disabled>
                  대카테고리
                </MenuItem>
                <MenuItem value="category1">카테고리 1</MenuItem>
                <MenuItem value="category2">카테고리 2</MenuItem>
                <MenuItem value="category3">카테고리 3</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Select fullWidth value={formData.subCategory} displayEmpty name="subCategory" onChange={onChangeValue} variant="outlined">
                <MenuItem value="" disabled>
                  소카테고리
                </MenuItem>
                <MenuItem value="subCategory1">서브카테고리 1</MenuItem>
                <MenuItem value="subCategory2">서브카테고리 2</MenuItem>
                <MenuItem value="subCategory3">서브카테고리 3</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="제품명" name="productName" value={formData.productName} onChange={onChangeValue} variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <Select fullWidth value={formData.storageMethod} displayEmpty name="storageMethod" onChange={onChangeValue} variant="outlined">
                <MenuItem value="" disabled>
                  저장 방법
                </MenuItem>
                <MenuItem value="roomTemp">실온 보관</MenuItem>
                <MenuItem value="refrigerated">냉장 보관</MenuItem>
                <MenuItem value="frozen">냉동 보관</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="자체코드" name="productCode" value={formData.productCode} onChange={onChangeValue} variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="재고 수량" name="stock" type="number" value={formData.stock} onChange={onChangeValue} variant="outlined" />
            </Grid>
            <Grid item xs={12} gap={2} sx={{ display: "flex" }}>
              <Button type="submit" variant="contained" color="primary" fullWidth size="large">
                등록
              </Button>
              <Button type="submit" variant="contained" color="primary" fullWidth size="large">
                취소
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default RegisterSupplyModal;
