import { Building, buildings } from "../../models/RoomModel";
import { Schedule, ScheduleData, Service } from "../Home/Model";

export const countryFlags: { [key: string]: string } = {
  대한민국: "🇰🇷",
  미국: "🇺🇸",
  일본: "🇯🇵",
  중국: "🇨🇳",
  홍콩: "🇭🇰",
  대만: "🇹🇼",
  싱가포르: "🇸🇬",
};

//방 배열 정리 함수
export const parseBuildingsToFloorsStatic = (buildings: Building[]) => {
  const floors = buildings.flatMap((building) =>
    building.floors.map((floor) => ({
      id: building.id + " " + floor.id,
      floor: building.name + " " + floor.name,
      rooms: floor.rooms.map((room) => ({
        id: room.id,
        name: room.name,
      })),
    }))
  );
  return floors;
};

// MEMO 대기 항목 밀림 계산
export const overlapIndex = (scheduleItems: ScheduleData[], item: ScheduleData) => {
  const overlappingItems = scheduleItems.filter((otherItem) => otherItem.schedule.room === item.schedule.room && otherItem.schedule.expectedVisitTime === item.schedule.expectedVisitTime);
  return overlappingItems.findIndex((overlapItem) => overlapItem.customer.customerUuid === item.customer.customerUuid);
};

// 시간과 분으로 가지고 옴
export const getTimeInMinutes = (time: string): number => {
  if (time === "" || time === null) {
    return 0;
  } else {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }
};

// 분을 "00:00" 형식으로 변환
export const convertMinutesToTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60); // 전체 시간을 계산
  const mins = minutes % 60; // 나머지 분을 계산
  return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`; // 2자리 형식으로 출력
};

// 현재 시간을 분 단위로 계산하는 함수
export const getCurrentTimeInMinutes = (): number => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  return hours * 60 + minutes;
};

export const floorsArray = parseBuildingsToFloorsStatic(buildings); //{id : 본관 4층, floor : , rooms : {id : , name : }}

export const roomsArray: string[] = floorsArray.flatMap((floorsArray) => floorsArray.rooms.map((room) => room.name)); //[4A, 4M, ...]

export const timesArray = Array.from({ length: 60 }, (_, i) => {
  const hours = 9 + Math.floor((i + 2) / 4);
  const minutes = ((i + 2) % 4) * 15;
  return `${hours}:${minutes === 0 ? "00" : minutes}`;
});

// M 저장 시 vaild
export function compareScheduleDetails(originalSchedule: Schedule, updatedSchedule: Schedule): boolean {
  // 각 필드를 비교
  if (
    originalSchedule.building !== updatedSchedule.building ||
    originalSchedule.floor !== updatedSchedule.floor ||
    originalSchedule.room !== updatedSchedule.room ||
    originalSchedule.program !== updatedSchedule.program ||
    originalSchedule.counselor !== updatedSchedule.counselor ||
    originalSchedule.doctor !== updatedSchedule.doctor ||
    originalSchedule.expectedVisitTime !== updatedSchedule.expectedVisitTime ||
    originalSchedule.expectedReturnTime !== updatedSchedule.expectedReturnTime ||
    originalSchedule.status !== updatedSchedule.status ||
    originalSchedule.surgeriesPlan !== updatedSchedule.surgeriesPlan ||
    originalSchedule.arrangerMemo !== updatedSchedule.arrangerMemo ||
    originalSchedule.doctorMemo !== updatedSchedule.doctorMemo ||
    originalSchedule.counselorMemo !== updatedSchedule.counselorMemo ||
    originalSchedule.vegasEmployeeMemo !== updatedSchedule.vegasEmployeeMemo ||
    originalSchedule.vegasReservationMemo !== updatedSchedule.vegasReservationMemo
  ) {
    return true; // 하나라도 다르면 변경된 것으로 간주
  } else {
    return false; // 모두 동일하면 변경되지 않음
  }
}
export const compareServiceLists = (originalList: Service[], updatedList: Service[]): { check: boolean; array: Service[] } => {
  const changedServices: Service[] = [];

  // 두 리스트 중 긴 길이를 기준으로 비교
  const maxLength = Math.max(originalList.length, updatedList.length);

  for (let i = 0; i < maxLength; i++) {
    const originalSurgery = originalList[i];
    const updatedSurgery = updatedList[i];

    // 추가된 항목 (originalList에 없고 updatedList에만 있는 경우)
    if (!originalSurgery && updatedSurgery) {
      changedServices.push(updatedSurgery);
      continue;
    }

    // 삭제된 항목은 변경된 항목으로 간주하지 않음 (필요하면 처리 가능)
    if (originalSurgery && !updatedSurgery) {
      continue;
    }

    // 각 필드를 비교하여 변경 여부 확인
    if (
      originalSurgery?.name !== updatedSurgery?.name ||
      originalSurgery?.doctor !== updatedSurgery?.doctor ||
      originalSurgery?.room !== updatedSurgery?.room ||
      originalSurgery?.status !== updatedSurgery?.status
    ) {
      changedServices.push(updatedSurgery!); // 변경된 항목 추가
    }
  }

  // 결과 반환
  return { check: changedServices.length > 0, array: changedServices };
};
