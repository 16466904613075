import React, { useEffect } from "react";
import { useHomeContext } from "./Context";
import { DoctorDailySchedule, NoticeItem, ScheduleData, Service } from "./Model";
import { useAuth } from "../../context/AuthContext";

const apiUrl = "/sse";
const clientId = crypto.randomUUID();

export const SseContext = ({ children }: { children: React.ReactNode }) => {
  const { setNoticeList, setWorkSchedule, setScheduleDataList, setDoctorDailyScheduleList, scheduleDataList } = useHomeContext();
  const { user } = useAuth();
  let eventSource: EventSource | null = null;
  let retryTimeout: NodeJS.Timeout;

  const connect = () => {
    console.log(eventSource);
    if (eventSource) {
      console.log("기존 sse 닫기");
      eventSource.close();
    }
    eventSource = new EventSource(`${apiUrl}/home?clientId=${user?.userId + "-" + clientId}`);

    eventSource.onopen = function () {
      console.log("Global SSE connection opened.");
    };

    eventSource.onmessage = function (event) {
      const parsedData = JSON.parse(event.data);
      const flag = parsedData.flag;
      const data = parsedData.data;

      switch (flag) {
        case "keepAlive":
          console.log("Received keepAlive message:", data);
          break;
        // notice
        case "registerNotice":
          const registerNotice: NoticeItem = data;
          setNoticeList((prevNotices) => [...prevNotices, registerNotice]);
          break;
        case "deleteNotice":
          const deleteNotice: NoticeItem = data;
          setNoticeList((prevNotices) => prevNotices.map((notice) => (notice.noticeId === deleteNotice.noticeId ? { ...notice, isDeleted: deleteNotice.isDeleted } : notice)));
          break;
        //workSchedule
        case "updateWorkSchedule":
          const updateWorkSchedule = data;
          setWorkSchedule(updateWorkSchedule);
          break;

        //schedule
        case "updateScheduleDataList":
          const updateScheduleDataList = data;
          const updatedScheduleItems = scheduleDataList.map((el) => ({
            ...el,
            service: el.service.map((service: Service) => {
              if (service.serviceId === 0) {
                const matchingService = updateScheduleDataList.find((responseData: Service) => responseData.name === service.name);
                return {
                  ...service,
                  serviceId: matchingService?.serviceId, // serviceId 치환
                };
              }
              return service; // serviceId가 0이 아니면 그대로 유지
            }),
          }));

          setScheduleDataList(updatedScheduleItems);
          alert("성공적으로 저장되었습니다.");

          break;
        case "customerVisit":
          const homeVisitCustomer = data;
          setScheduleDataList((prevData) =>
            prevData.map((item) => (item.schedule.scheduleId === homeVisitCustomer.scheduleId ? { ...item, schedule: { ...item.schedule, ...homeVisitCustomer } } : item))
          );
          break;
        case "customerCancel":
          const homeCancelSchedule = data;
          setScheduleDataList((prevData) =>
            prevData.map((item) => (item.schedule.scheduleId === homeCancelSchedule.scheduleId ? { ...item, schedule: { ...item.schedule, ...homeCancelSchedule } } : item))
          );
          break;
        case "customerReturn":
          const homeReturnCustomer = data;
          setScheduleDataList((prevData) =>
            prevData.map((item) => (item.schedule.scheduleId === homeReturnCustomer.scheduleId ? { ...item, schedule: { ...item.schedule, ...homeReturnCustomer } } : item))
          );
          break;
        case "customerWait":
          const homeWaitCustomer = data;
          setScheduleDataList((prevData) =>
            prevData.map((item) =>
              item.schedule.scheduleId === homeWaitCustomer.scheduleId
                ? {
                    ...item,
                    schedule: { ...item.schedule, status: "wait" },
                    waiting: [...item.waiting, homeWaitCustomer],
                  }
                : item
            )
          );
          break;
        case "customerWaitEnd":
          const waitEndCustomer = data;
          setScheduleDataList((prevData) =>
            prevData.map((item) =>
              item.schedule.scheduleId === waitEndCustomer.scheduleId
                ? {
                    ...item,
                    schedule: { ...item.schedule, status: "wait" },
                    waiting: [...item.waiting, waitEndCustomer],
                  }
                : item
            )
          );
          break;
        //doctorDailyScheduleResponse
        case "registerDoctorDailySchedule":
          const registerDoctorDailyScheduleResponse = data;
          setDoctorDailyScheduleList((prevList: DoctorDailySchedule[]) => {
            return [...prevList, registerDoctorDailyScheduleResponse];
          });
          break;
        case "updateDoctorDailySchedule":
          const updateHomeDoctorDailySchedule = data;
          setDoctorDailyScheduleList((prevList: DoctorDailySchedule[]) =>
            prevList.map((item) =>
              item.doctorDailyScheduleId === updateHomeDoctorDailySchedule.doctorDailyScheduleId ? { ...item, dailyScheduleText: updateHomeDoctorDailySchedule.dailyScheduleText } : item
            )
          );
          break;
        case "startDoctorDailySchedule":
          const startDoctorDailySchedule = data;
          setDoctorDailyScheduleList((prevList: DoctorDailySchedule[]) =>
            prevList.map((item) => (item.doctorDailyScheduleId === startDoctorDailySchedule.doctorDailyScheduleId ? { ...item, dailyScheduleText: startDoctorDailySchedule.dailyScheduleText } : item))
          );
          break;
        case "endDoctorDailySchedule":
          const endDoctorDailySchedule = data;
          setDoctorDailyScheduleList((prevList: DoctorDailySchedule[]) =>
            prevList.map((item) => (item.doctorDailyScheduleId === endDoctorDailySchedule.doctorDailyScheduleId ? { ...item, dailyScheduleText: endDoctorDailySchedule.dailyScheduleText } : item))
          );
          break;
      }
    };
    eventSource.onerror = function (error) {
      if (eventSource?.readyState === EventSource.CLOSED) {
        console.log("Reconnecting after 1 second...");
        if (eventSource) {
          console.log("eventSource closed");
        }
        retryTimeout = setTimeout(connect, 1000); // 1초 후 재연결 시도
        console.log("Reconnection try end");
      } else if (eventSource?.readyState === EventSource.CONNECTING) {
        console.log("Currently reconnecting...");
        eventSource.close(); // 기존 연결 닫기
        console.log(eventSource);
        setTimeout(connect, 1000); // 1초 후 새로운 연결 시도
      } else {
        console.log({ readyState: eventSource?.readyState });
        console.error("SSE connection error:", error);
      }
    };
  };

  useEffect(() => {
    connect();
    return () => {
      if (eventSource) {
        eventSource.close();
      }
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, []);

  return <>{children}</>;
};
