import { Counselor } from "../models/CounselorModel";
import { fetchCounselor, registerCounselor, updateCounselor } from "../service/CounselorService";
import { handleControllerResponse } from "../util/Function";

class CounselorController {
  async handleFetchCounselor() {
    const fetchDeviceListResponse = await fetchCounselor();
    return handleControllerResponse(fetchDeviceListResponse.status, fetchDeviceListResponse.data);
  }
  async handleRegisterCounselor(counselor: Counselor) {
    const registerDeviceResponse = await registerCounselor(counselor);
    return handleControllerResponse(registerDeviceResponse.status, registerDeviceResponse.data);
  }
  async handleUpdateCounselor(counselor: Counselor) {
    const updateDeviceResponse = await updateCounselor(counselor);
    return handleControllerResponse(updateDeviceResponse.status, updateDeviceResponse.data);
  }
}
export const counselorController = new CounselorController();
