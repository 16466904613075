import axios from "axios";
import { handleServiceApiError } from "../../util/Function";
import { Employee } from "./Model";

const employeeApiUrl = "/api/v1/user";

const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});

export const fetchEmployee = async () => {
  try {
    const response = await apiClient.post(`${employeeApiUrl}/employee`, {});
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const resetPassword = async (userId: number) => {
  try {
    const response = await apiClient.post(`${employeeApiUrl}/password/reset`, { userId: userId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const registerEmployee = async (employee: Employee) => {
  try {
    const response = await apiClient.post(`${employeeApiUrl}/register`, employee);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
