import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import Login from "./page/Login/Login";
import Home from "./page/Home/Home";
import Account from "./page/Account/Account";
import Doctors from "./page/Doctors/Doctors";
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Schedule from "./page/Schedule/Schedule";
import Device from "./page/Device/Device";
import Employee from "./page/Employee/Employee";
import Supply from "./page/Supply/Supply";
import Product from "./page/Product/Product";
import Surgery from "./page/Surgery/Surgery";
import SidebarWrapper from "./component/SidebarWrapper";
import Counselor from "./page/Counselor/Counselor";
import Customer from "./page/Customer/Customer";
import Purchase from "./page/Purchase/Purchase";
import { GlobalProvider } from "./context/global/GlobalContext";

const mainColor = "#655dc6"; // 메인 색상
const lightMainColor = "#e1e1f5"; // 연한 메인 색상
const semiLightMainColor = "#e0e0ff"; // 연한 배경색 (면적 차지하는 요소)
const grayColor = "#b0b0b0"; // 기본 회색
const lightHoverColor = "#f0f0fb";
const hoverColor = "#504bb5"; // 호버 시 색상
const lightFocusColor = "#c2c2e6";
const pinkLightFocusColor = "#e8dff1";
const focusColor = "#8470d4"; // 포커스 시 색상
const whiteColor = "#ffffff"; // 흰색 색상
const blackColor = "#000000"; // 검은 색상
const errorColor = "#e08a8a"; // 에러 색상
const lightRedColor = "#f5e1e1";
const transparentColor = "rgba(255, 255, 255, 0)";
// QueryClient 생성
const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: mainColor, // 메인 색상
      contrastText: "#ffffff", // 텍스트 색상
    },
    grey: {
      500: grayColor, // 기본 회색을 palette에 추가
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: blackColor,
          "&:hover": {
            color: whiteColor,
            backgroundColor: mainColor, // 호버 시 배경색
          },
          "&.Mui-focused": {
            backgroundColor: focusColor, // 포커스 시 배경색
          },
          background: semiLightMainColor,
          boxShadow: "none", // 그림자 제거
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff", // 연한 배경색
          "& .MuiInputBase-root": {
            color: "#000000", // 입력 텍스트 색상
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: grayColor, // 기본 아웃라인 색상
            },
            "&:hover fieldset": {
              borderColor: hoverColor, // 호버 시 아웃라인 색상
              borderWidth: "1px", // 두께 유지
            },
            "&.Mui-focused fieldset": {
              borderColor: focusColor, // 포커스 시 아웃라인 색상
              borderWidth: "1px", // 두께 유지
            },
            // TextField가 disabled일 때
            "&.Mui-disabled fieldset": {
              borderColor: grayColor, // 비활성화 상태에서의 기본 아웃라인 색상
            },
            // disabled 상태일 때 호버 효과 방지
            "&.Mui-disabled:hover fieldset": {
              borderColor: grayColor, // 비활성화 상태에서는 호버 시 색상이 바뀌지 않도록 함
            },
          },
          "& .MuiInputLabel-root": {
            color: grayColor, // 기본 라벨 색상
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: focusColor, // 포커스 시 라벨 색상
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: whiteColor, // 연한 배경색
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: grayColor, // 기본 아웃라인 색상
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: hoverColor, // 호버 시 아웃라인 색상
            borderWidth: "1px", // 두께 유지
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor, // 포커스 시 아웃라인 색상
            borderWidth: "1px", // 두께 유지
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: hoverColor, // 호버 시 밑줄 색상
            borderBottomWidth: "1px", // 호버 시 밑줄 두께
          },
          "&:after": {
            borderBottomColor: focusColor, // 포커스 시 밑줄 색상
            borderBottomWidth: "1px", // 호버 시 밑줄 두께
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "1.5rem",
          fontWeight: 700,
          color: mainColor,
        },
        h2: {
          fontSize: "1.3rem",
          fontWeight: 700,
          color: mainColor,
        },
        h3: {
          fontSize: "1rem",
          fontWeight: 700,
          color: "black",
        },
        h4: {
          fontSize: "1rem",
          fontWeight: 700,
          color: mainColor,
        },
      },
    },
  },
});

const App: React.FC = () => {
  const { isAuthenticated, isLoading, validateToken } = useAuth();
  const location = useLocation();
  const closeSidebar = location.pathname === "/purchase" && location.search.includes("customer_id=");

  if (!isAuthenticated) {
    validateToken();
    if (isLoading) {
      return (
        <Typography variant="h1" sx={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          Loading...
        </Typography>
      );
    }
    return (
      <Routes>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    );
  }

  return (
    <GlobalProvider>
      {closeSidebar ? (
        <Routes>
          <Route path="/purchase" element={<Purchase />} />
        </Routes>
      ) : (
        <SidebarWrapper>
          <Routes>
            <Route path="/login" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/doctor" element={<Doctors />} />
            <Route path="/customer" element={<Customer />} />
            <Route path="/account" element={<Account />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/device" element={<Device />} />
            <Route path="/product" element={<Product />} />
            <Route path="/employee" element={<Employee />} />
            <Route path="/surgery" element={<Surgery />} />
            <Route path="/supply" element={<Supply />} />
            <Route path="/counselor" element={<Counselor />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </SidebarWrapper>
      )}
    </GlobalProvider>
  );
};

const WrappedApp: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default WrappedApp;
