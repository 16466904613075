import { useState } from "react";
import { CustomerLocalProvider } from "./Context";
import { Grid } from "@mui/material";
import CustomersList from "./CustomerList";
import RegisterCustomersCard from "./RegisterCustomerCard";
import CustomersDetails from "./CustomerDetails";

export const Customer: React.FC = () => {
  const [isAddCustomer, setIsAddCustomer] = useState(false);

  return (
    <CustomerLocalProvider>
      <Grid container direction="row" sx={{ height: "100%", padding: "10px" }}>
        <Grid container item xs={3} sx={{ height: "100%" }}>
          <CustomersList setIsAddCustomer={setIsAddCustomer} />
        </Grid>
        <Grid container item xs={9} sx={{ height: "100%", paddingLeft: "20px" }}>
          {isAddCustomer ? <RegisterCustomersCard setIsAddCustomer={setIsAddCustomer} /> : <CustomersDetails />}
        </Grid>
      </Grid>
    </CustomerLocalProvider>
  );
};

export default Customer;
