import React from "react";
import { ScheduleProvider } from "./Context";
import TableTopHeader from "./Schedule/TableTopHeader";
import TableInner from "./Schedule/TableInner";

const Schedule: React.FC = () => {
  return (
    <ScheduleProvider>
      <TableTopHeader />
      <TableInner />
    </ScheduleProvider>
  );
};

export default Schedule;
