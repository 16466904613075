import React from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import SearchBar from "./DevicesList/SearchBar";
import DevicesList from "./DeviceList";
import AddIcon from "@mui/icons-material/Add";
import { useLocalContext } from "./Context";

export interface DeviceControlsProps {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeviceControls: React.FC<DeviceControlsProps> = ({ openForm, setOpenForm }) => {
  const { isEdit, setIsEdit } = useLocalContext();
  // 장비 추가 폼 열기
  const onClickRegisterDevice = () => {
    if (isEdit) {
      const userConfirmed = window.confirm("정보 수정 페이지를 벗어나시겠습니까?");
      if (userConfirmed) {
        setIsEdit(false);
      }
    }
    setOpenForm(true);
  };

  return (
    <>
      <Grid item>
        <Box display={"flex"} alignItems="center">
          <Typography variant="h2">장비 정보</Typography>
          <IconButton sx={{ padding: 0, marginLeft: "4px" }} onClick={onClickRegisterDevice}>
            <AddIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ height: "95%", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", paddingTop: "4px" }}>
        <SearchBar />
        <DevicesList openForm={openForm} setOpenForm={setOpenForm} />
      </Grid>
    </>
  );
};

export default DeviceControls;
