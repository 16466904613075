import React, { useState } from "react";
import { Grid, Typography, Paper, Box, IconButton, Checkbox, FormControlLabel, Select, MenuItem, ListItemText, SelectChangeEvent, Modal } from "@mui/material";
import ReservationCard from "./DailyOperationsCard/ReservationCard";
import VisitingCard from "./DailyOperationsCard/VisitingCard";
import ReturnCard from "./DailyOperationsCard/ReturnCard";
import WaitingCard from "./DailyOperationsCard/WaitingCard";
import { ScheduleData } from "./Model";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import { scheduleController } from "./Controller";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import { useHomeContext } from "./Context";
import DetailCard from "./DailyOperationsCard/DetailCard";
import { useAuth } from "../../context/AuthContext";

export interface CardProp {
  scheduleData: ScheduleData;
}

const DailyOperations: React.FC = () => {
  const { scheduleDataList, openDetailModal } = useHomeContext();
  const allFloors = ["4층", "2층", "G층"];
  const { user } = useAuth();

  const [isReservationFilter, setIsReservationFilter] = useState<boolean>(false);
  const [isVisitFilter, setIsVisitFilter] = useState<boolean>(false);
  const [isFinishFilter, setIsFinishFilter] = useState<boolean>(false);
  const [isReservationMainBuilding, setIsReservationMainBuilding] = useState<boolean>(true);
  const [isReservationNewBuilding, setIsReservationNewBuilding] = useState<boolean>(true);
  const [isVisitMainBuilding, setIsVisitMainBuilding] = useState<boolean>(true);
  const [isVisitNewBuilding, setIsVisitNewBuilding] = useState<boolean>(true);
  const [isFinishMainBuilding, setIsFinishMainBuilding] = useState<boolean>(true);
  const [isFinishNewBuilding, setIsFinishNewBuilding] = useState<boolean>(true);
  const [selectedReservationFloors, setSelectedReservationFloors] = useState<string[]>(allFloors);
  const [selectedVisitFloors, setSelectedVisitFloors] = useState<string[]>(allFloors);
  const [selectedFinishFloors, setSelectedFinishFloors] = useState<string[]>(allFloors);

  const reservationLength = scheduleDataList.filter(
    (scheduleData) => scheduleData.schedule.status === "beforeVisit" || scheduleData.schedule.status === "cancel" || scheduleData.schedule.status === "excelUpload"
  ).length;
  const cancelLength = scheduleDataList.filter((scheduleData) => scheduleData.schedule.status === "cancel").length;
  const visitLength = scheduleDataList.filter((scheduleData) => scheduleData.schedule.status === "onVisit").length;
  const waitLength = scheduleDataList.filter((scheduleData) => scheduleData.schedule.status === "wait").length;

  const onClickOpenFilter = (filter: boolean, setFilter: React.Dispatch<React.SetStateAction<boolean>>) => setFilter(!filter);
  const onChangeIsMainBuilding = (event: React.ChangeEvent<HTMLInputElement>, setMainBuilding: React.Dispatch<React.SetStateAction<boolean>>) => setMainBuilding(event.target.checked);
  const onChangeIsNewBuilding = (event: React.ChangeEvent<HTMLInputElement>, setNewBuilding: React.Dispatch<React.SetStateAction<boolean>>) => setNewBuilding(event.target.checked);

  const onChangeFloors = (event: SelectChangeEvent<string[]>, selectedFloors: string[], setSelectedFloors: React.Dispatch<React.SetStateAction<string[]>>) => {
    const value = event.target.value as string[];
    if (value.includes("All")) {
      if (selectedFloors.length === allFloors.length) {
        setSelectedFloors([]);
      } else {
        setSelectedFloors([...allFloors]);
      }
    } else {
      setSelectedFloors(value);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      scheduleController.uploadScheduleExcel(file);
    }
  };

  return (
    <Grid container direction="column" sx={{ height: "100%", width: "100%" }}>
      {/* M 고객 현황  헤더 */}
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center", display: "flex" }}>
        <Typography variant="h2" sx={{ textAlign: "left", marginLeft: "4px" }}>
          고객 현황
        </Typography>
        {(user?.authRole === "developer" || user?.authRole === "admin") && (
          <>
            <Box sx={{ display: "flex", flexWrap: "wrap", boxSizing: "border-box" }}>
              <input accept=".xlsx, .xls" style={{ display: "none" }} id="upload-button-file" type="file" onChange={handleFileChange} />
              <label htmlFor="upload-button-file">
                <IconButton component="span" sx={{ padding: "4px", marginLeft: "4px" }}>
                  <CloudUploadRoundedIcon sx={{ fontSize: 15 }} />
                </IconButton>
              </label>
            </Box>
          </>
        )}
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, margin: "0px 0px 0px 0px", boxShadow: 3, borderRadius: "8px" }}>
        {/* M 상단 현황 3종세트 */}
        <Grid container item sx={{ width: "100%", height: "75%" }}>
          {/* DESC 예약 현황 */}
          <Grid container item xs={4} direction="column" sx={{ borderRight: "1px solid #b0b0b0", height: "100%", width: "100%" }}>
            <Grid
              container
              item
              sx={{ width: "100%", height: "42px", background: "#f0f0fb", padding: "4px", borderRadius: "8px 0px 0px 0px", borderBottom: "1px solid #b0b0b0", position: "relative" }}
            >
              <Typography sx={{ width: "100%", fontSize: "14px", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}>예약현황</Typography>
              <IconButton component="span" onClick={() => onClickOpenFilter(isReservationFilter, setIsReservationFilter)} sx={{ padding: "4px", position: "absolute", right: 0, bottom: 0 }}>
                <FilterAltRoundedIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <Typography
                sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}
              >{`(전체: ${reservationLength} / 취소: ${cancelLength})`}</Typography>
            </Grid>
            {isReservationFilter && (
              <Grid container item sx={{ width: "100%", height: "30px", padding: "0px 4px", borderBottom: "1px solid #b0b0b0" }}>
                <Grid container item sx={{ width: "auto", height: "30px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={isReservationMainBuilding} onChange={(e) => onChangeIsMainBuilding(e, setIsReservationMainBuilding)} sx={{ padding: "2px", transform: "scale(0.8)" }} />
                    }
                    label="본관"
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" }, margin: "0px" }}
                  />
                </Grid>
                <Grid container item sx={{ width: "40%", height: "30px", display: "flex", alignItems: "center", marginLeft: "2px", maxWidth: "40%", marginRight: "4px" }}>
                  <Select
                    multiple
                    value={selectedReservationFloors}
                    onChange={(event) => onChangeFloors(event, selectedReservationFloors, setSelectedReservationFloors)}
                    sx={{ height: "22px", fontSize: "12px", width: "100%" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>층선택</em>;
                      } else if (selected.length === 3) {
                        return "All";
                      } else if (selected) {
                        return selected.join(", ");
                      } else {
                        return "층선택";
                      }
                    }}
                  >
                    <MenuItem value="All" sx={{ height: "22px" }}>
                      <Checkbox checked={selectedReservationFloors.length === allFloors.length} sx={{ padding: "2px", transform: "scale(0.7)" }} />
                      <ListItemText primary="All" primaryTypographyProps={{ sx: { fontSize: "12px" } }} />
                    </MenuItem>
                    {allFloors.map((floor) => (
                      <MenuItem key={floor} value={floor} sx={{ height: "22px" }}>
                        <Checkbox checked={selectedReservationFloors.indexOf(floor) > -1} sx={{ padding: "2px", transform: "scale(0.7)" }} />
                        <ListItemText primary={`${floor}`} primaryTypographyProps={{ sx: { fontSize: "12px" } }} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid container item sx={{ width: "auto", height: "30px" }}>
                  <FormControlLabel
                    control={<Checkbox checked={isReservationNewBuilding} onChange={(e) => onChangeIsNewBuilding(e, setIsReservationNewBuilding)} sx={{ padding: "2px", transform: "scale(0.8)" }} />}
                    label="신관"
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" }, margin: "0px" }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container item direction="column" sx={{ height: isReservationFilter ? "calc(100% - 72px)" : "calc(100% - 42px)", overflowY: "auto", width: "100%" }}>
              <Grid container item sx={{ height: "auto", width: "100%" }}>
                {scheduleDataList
                  .filter(
                    (scheduleData) =>
                      (scheduleData.schedule.status === "beforeVisit" || scheduleData.schedule.status === "cancel") &&
                      ((isReservationMainBuilding && scheduleData.schedule.building === "본관" && selectedReservationFloors.some((floor) => scheduleData.schedule.floor.includes(floor))) ||
                        (isReservationNewBuilding && scheduleData.schedule.building === "신관"))
                  )
                  .sort((a, b) => {
                    const timeA = a.schedule.expectedVisitTime.split(":").map(Number);
                    const timeB = b.schedule.expectedVisitTime.split(":").map(Number);
                    const totalSecondsA = timeA[0] * 3600 + timeA[1];
                    const totalSecondsB = timeB[0] * 3600 + timeB[1];
                    if (a.schedule.status === "beforeVisit" && b.schedule.status === "cancel") return -1;
                    if (totalSecondsA !== totalSecondsB) return totalSecondsA - totalSecondsB; // 빠른 시간 순으로 정렬
                    if (a.schedule.status === "cancel" && b.schedule.status === "beforeVisit") return 1;
                    return 0;
                  })
                  .map((beforeVisitScheduleData) => (
                    <ReservationCard key={beforeVisitScheduleData.schedule.scheduleUuid} scheduleData={beforeVisitScheduleData} />
                  ))}
              </Grid>
            </Grid>
          </Grid>
          {/* DESC 방문 현황 */}
          <Grid container item xs={4} direction="column" sx={{ borderRight: "1px solid #b0b0b0", height: "100%", width: "100%" }}>
            <Grid container item sx={{ width: "100%", height: "42px", background: "#f0f0fb", padding: "4px", borderBottom: "1px solid #b0b0b0", position: "relative" }}>
              <Typography sx={{ width: "100%", fontSize: "14px", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}>방문현황</Typography>
              <IconButton component="span" onClick={() => onClickOpenFilter(isVisitFilter, setIsVisitFilter)} sx={{ padding: "4px", position: "absolute", right: 0, bottom: 0 }}>
                <FilterAltRoundedIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <Typography sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>{`(전체: ${visitLength}, 대기: ${waitLength})`}</Typography>
            </Grid>
            {isVisitFilter && (
              <Grid container item sx={{ width: "100%", height: "30px", padding: "0px 4px", borderBottom: "1px solid #b0b0b0" }}>
                <Grid container item sx={{ width: "auto", height: "30px" }}>
                  <FormControlLabel
                    control={<Checkbox checked={isVisitMainBuilding} onChange={(e) => onChangeIsMainBuilding(e, setIsVisitMainBuilding)} sx={{ padding: "2px", transform: "scale(0.8)" }} />}
                    label="본관"
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" }, margin: "0px" }}
                  />
                </Grid>
                <Grid container item sx={{ width: "40%", height: "30px", display: "flex", alignItems: "center", marginLeft: "2px", maxWidth: "40%", marginRight: "4px" }}>
                  <Select
                    multiple
                    value={selectedVisitFloors}
                    onChange={(event) => onChangeFloors(event, selectedVisitFloors, setSelectedVisitFloors)}
                    sx={{ height: "22px", fontSize: "12px", width: "100%" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>층선택</em>;
                      } else if (selected.length === 3) {
                        return "All";
                      } else if (selected) {
                        return selected.join(", ");
                      } else {
                        return "층선택";
                      }
                    }}
                  >
                    <MenuItem value="All" sx={{ height: "22px" }}>
                      <Checkbox checked={selectedVisitFloors.length === allFloors.length} sx={{ padding: "2px", transform: "scale(0.7)" }} />
                      <ListItemText primary="All" primaryTypographyProps={{ sx: { fontSize: "12px" } }} />
                    </MenuItem>
                    {allFloors.map((floor) => (
                      <MenuItem key={floor} value={floor} sx={{ height: "22px" }}>
                        <Checkbox checked={selectedVisitFloors.indexOf(floor) > -1} sx={{ padding: "2px", transform: "scale(0.7)" }} />
                        <ListItemText primary={`${floor}`} primaryTypographyProps={{ sx: { fontSize: "12px" } }} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid container item sx={{ width: "auto", height: "30px" }}>
                  <FormControlLabel
                    control={<Checkbox checked={isVisitNewBuilding} onChange={(e) => onChangeIsNewBuilding(e, setIsVisitNewBuilding)} sx={{ padding: "2px", transform: "scale(0.8)" }} />}
                    label="신관"
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" }, margin: "0px" }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container item direction="column" sx={{ height: isVisitFilter ? "calc(100% - 72px)" : "calc(100% - 42px)", overflowY: "auto", width: "100%" }}>
              <Grid container item sx={{ height: "auto", width: "100%" }}>
                {scheduleDataList
                  .filter(
                    (scheduleData) =>
                      (scheduleData.schedule.status === "onVisit" || scheduleData.schedule.status === "wait") &&
                      ((isVisitMainBuilding && scheduleData.schedule.building === "본관" && selectedVisitFloors.some((floor) => scheduleData.schedule.floor.includes(floor))) ||
                        (isVisitNewBuilding && scheduleData.schedule.building === "신관"))
                  )
                  .map((onVisitScheduleData) => (
                    <VisitingCard key={onVisitScheduleData.schedule.scheduleUuid} scheduleData={onVisitScheduleData} />
                  ))}
              </Grid>
            </Grid>
          </Grid>
          {/* DESC 귀가 현황 */}
          <Grid container item xs={4} direction="column" sx={{ height: "100%", width: "100%" }}>
            <Grid
              container
              item
              sx={{ width: "100%", height: "42px", background: "#f0f0fb", padding: "4px", borderRadius: "0px 8px 0px 0px", borderBottom: "1px solid #b0b0b0", position: "relative" }}
            >
              <Typography sx={{ width: "100%", fontSize: "14px", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}>귀가현황</Typography>
              <IconButton component="span" onClick={() => onClickOpenFilter(isFinishFilter, setIsFinishFilter)} sx={{ padding: "4px", position: "absolute", right: 0, bottom: 0 }}>
                <FilterAltRoundedIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <Typography sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>{`(수납: ${visitLength} / 미수납:) `}</Typography>
            </Grid>
            {isFinishFilter && (
              <Grid container item sx={{ width: "100%", height: "30px", padding: "0px 4px", borderBottom: "1px solid #b0b0b0" }}>
                <Grid container item sx={{ width: "auto", height: "30px" }}>
                  <FormControlLabel
                    control={<Checkbox checked={isFinishMainBuilding} onChange={(e) => onChangeIsMainBuilding(e, setIsFinishMainBuilding)} sx={{ padding: "2px", transform: "scale(0.8)" }} />}
                    label="본관"
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" }, margin: "0px" }}
                  />
                </Grid>
                <Grid container item sx={{ width: "40%", height: "30px", display: "flex", alignItems: "center", marginLeft: "2px", maxWidth: "40%", marginRight: "4px" }}>
                  <Select
                    multiple
                    value={selectedFinishFloors}
                    onChange={(event) => onChangeFloors(event, selectedFinishFloors, setSelectedFinishFloors)}
                    sx={{ height: "22px", fontSize: "12px", width: "100%" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>층선택</em>;
                      } else if (selected.length === 3) {
                        return "All";
                      } else if (selected) {
                        return selected.join(", ");
                      } else {
                        return "층선택";
                      }
                    }}
                  >
                    <MenuItem value="All" sx={{ height: "22px" }}>
                      <Checkbox checked={selectedFinishFloors.length === allFloors.length} sx={{ padding: "2px", transform: "scale(0.7)" }} />
                      <ListItemText primary="All" primaryTypographyProps={{ sx: { fontSize: "12px" } }} />
                    </MenuItem>
                    {allFloors.map((floor) => (
                      <MenuItem key={floor} value={floor} sx={{ height: "22px" }}>
                        <Checkbox checked={selectedFinishFloors.indexOf(floor) > -1} sx={{ padding: "2px", transform: "scale(0.7)" }} />
                        <ListItemText primary={`${floor}`} primaryTypographyProps={{ sx: { fontSize: "12px" } }} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid container item sx={{ width: "auto", height: "30px" }}>
                  <FormControlLabel
                    control={<Checkbox checked={isFinishNewBuilding} onChange={(e) => onChangeIsNewBuilding(e, setIsFinishNewBuilding)} sx={{ padding: "2px", transform: "scale(0.8)" }} />}
                    label="신관"
                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" }, margin: "0px" }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container item direction="column" sx={{ height: isFinishFilter ? "calc(100% - 72px)" : "calc(100% - 42px)", overflowY: "auto", width: "100%" }}>
              <Grid container item sx={{ height: "auto", width: "100%" }}>
                {scheduleDataList
                  .filter(
                    (scheduleData) =>
                      scheduleData.schedule.status === "return" &&
                      ((isFinishMainBuilding && scheduleData.schedule.building === "본관" && selectedFinishFloors.some((floor) => scheduleData.schedule.floor.includes(floor))) ||
                        (isFinishNewBuilding && scheduleData.schedule.building === "신관"))
                  )
                  .map((returnScheduleData) => (
                    <ReturnCard key={returnScheduleData.schedule.scheduleUuid} scheduleData={returnScheduleData} />
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* M 하단 대기 고객 */}
        <Grid container item sx={{ width: "100%", height: "25%", borderTop: "1px solid #b0b0b0" }} direction="column">
          <Grid container item sx={{ width: "100%", height: "30px", background: "#f0f0fb", borderBottom: "1px solid #b0b0b0" }}>
            {["고객", "대기 공간", "대기 시간", "대기 사유"].map((name) => (
              <Typography
                key={name}
                sx={{
                  width: name === "대기 사유" ? "25%" : name === "종료" ? "20%" : "25%",
                  fontSize: "14px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {name}
              </Typography>
            ))}
          </Grid>
          <Grid container item direction="column" sx={{ height: "calc(100% - 30px)", overflow: "auto", width: "100%" }}>
            <Grid container item sx={{ height: "auto", width: "100%" }}>
              {scheduleDataList
                .filter((scheduleData) => scheduleData.schedule.status === "wait")
                .map((waitingSchedule, i) => (
                  <WaitingCard key={i} scheduleData={waitingSchedule} />
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* M 스케줄 데이터 넣어 줌 */}
      {openDetailModal && <DetailCard />}
    </Grid>
  );
};
export default DailyOperations;
