import React, { useState } from "react";
import { Grid, Typography, Paper, Tabs, Tab, Stack, Box, TextField, Button, TextFieldProps } from "@mui/material";
import CustomersHomeCard from "./CustomerDetails/CustomerHomeCard";
import PurchaseHistoryCard from "./CustomerDetails/PurchaseHistoryCard";
import VisitingHistoryCard from "./CustomerDetails/VisitHistoryCard";
import { Customer } from "./Model";
import { formatDate } from "../../util/Function";
import { useLocalContext } from "./Context";

interface CustomTextFieldProps extends Omit<TextFieldProps, "onChange"> {
  field: keyof Customer;
  value: string | number | undefined;
  onChange: (field: keyof Customer, value: string) => void;
  multiline?: boolean;
  rows?: number;
}

const gradeOptions = [
  { label: "VIP", value: "VIP" },
  { label: "일반", value: "일반" },
];

const genderOptions = [
  { label: "남", value: "M" },
  { label: "여", value: "F" },
];

const CustomerDetails: React.FC = () => {
  const { selectCustomer, setCustomers } = useLocalContext();
  const [tabValue, setTabValue] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditInfo, setIsEditInfo] = useState(selectCustomer);

  // 탭 변경 핸들러
  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // 텍스트 필드 변경 핸들러
  const onChangeEditCustomer = (field: keyof Customer, value: string | Date) => {
    if (isEditInfo) {
      setIsEditInfo((prev) => ({
        ...prev!,
        [field]: value,
      }));
    }
  };

  // 수정 저장 핸들러
  const onClickSave = () => {
    if (isEditInfo && selectCustomer) {
      setCustomers((prevCustomers) => prevCustomers.map((customer) => (customer.customerUuid === selectCustomer.customerUuid ? isEditInfo : customer)));
      setIsEditMode(false);
    }
  };

  // 수정 취소 핸들러
  const onClickCancel = () => {
    setIsEditInfo(selectCustomer);
    setIsEditMode(false);
  };

  /// 수정 모드 변경 핸들러
  const onClickEdit = () => {
    setIsEditInfo(selectCustomer);
    setIsEditMode(true);
  };

  const onClickNewTab = () => {
    window.open(`${window.location.origin}/purchase?customer_id=${selectCustomer?.customerId}`, "_blank", "noopener,noreferrer");
  };
  // 텍스트 필드 컴포넌트 커스텀
  const CustomTextField: React.FC<CustomTextFieldProps> = ({ field, value, onChange, multiline = false, rows = 1, ...props }) => {
    return (
      <TextField
        fullWidth
        value={value}
        size="small"
        multiline={multiline}
        rows={rows}
        onChange={(e) => onChange(field, e.target.value)}
        sx={{
          "& .MuiInputBase-input": { fontSize: "14px", padding: "3px" },
        }}
        InputProps={{
          sx: {
            fontSize: "14px",
            padding: "0px", // multiline일 때 다른 패딩 설정
          },
        }}
        {...props}
      />
    );
  };

  // selectCustomer없을 시 보여주는 문구
  if (selectCustomer === undefined) {
    return (
      <Typography variant="h2" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
        조회하고 싶은 고객을 목록에서 선택해 주세요
      </Typography>
    );
  }

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      {/* 위 4 (높이의 1/3) */}
      <Grid container item xs={4} direction="column" sx={{ width: "100%", paddingBottom: 1 }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          고객 세부사항
        </Typography>
        <Paper sx={{ borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", flex: 1, position: "relative", boxShadow: 3 }}>
          {/* 버튼 */}
          <Grid container item sx={{ display: "flex", justifyContent: "flex-end", width: "100%", cursor: "pointer" }}>
            {isEditMode ? (
              <Box>
                <Button onClick={onClickSave} sx={{ minWidth: "40px", maxHeight: "25px", position: "absolute", top: 10, right: 60 }}>
                  저장
                </Button>
                <Button onClick={onClickCancel} sx={{ minWidth: "40px", maxHeight: "25px", position: "absolute", top: 10, right: 10, backgroundColor: "#e0e0e0" }}>
                  취소
                </Button>
              </Box>
            ) : (
              <Button onClick={onClickEdit} sx={{ minWidth: "40px", maxHeight: "25px", position: "absolute", top: 10, right: 10 }}>
                수정
              </Button>
            )}
          </Grid>
          {/* 세부사항 BOX */}
          <Grid container item padding={3} sx={{ height: "100%" }} gap={2}>
            {/* 왼쪽 : 고객 사진 */}
            <Grid container item xs={2} justifyContent={"center"} alignContent={"center"} sx={{ backgroundColor: "#e0e0e0" }}>
              <Grid item>
                <Typography sx={{ color: "grey", fontSize: 12 }}>이미지가 없습니다</Typography>
              </Grid>
            </Grid>
            {/* 오른쪽 : 고객 정보 */}
            <Grid container item xs={9.5} sx={{ height: "100%" }}>
              <Grid sx={{ width: "100%", display: "flex", alignItems: "center", paddingBottom: "7px" }}>
                <Typography>{selectCustomer?.name}</Typography>
              </Grid>
              <Stack direction="row" spacing={2} width="100%" sx={{ height: "calc(100% - 30px)" }}>
                <Stack
                  spacing={0}
                  flex="1"
                  sx={{
                    height: "100%",
                    "& .MuiGrid-container": {
                      flex: 1,
                      minHeight: 0,
                    },
                  }}
                >
                  <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={2}>
                      <Typography variant="body2" color="textSecondary">
                        등급
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {isEditMode ? (
                        <Grid item xs={6} pr={1} gap={1} display="flex">
                          {gradeOptions.map((option) => (
                            <Button
                              key={option.value} // Use a unique key for each button
                              onClick={() => onChangeEditCustomer("grade", option.value)}
                              sx={{
                                width: "50%",
                                height: "20px",
                                fontSize: "14px",
                                backgroundColor: isEditInfo?.grade === option.value ? "#e1e1f5" : "#b0b0b0",
                              }}
                            >
                              {option.label}
                            </Button>
                          ))}
                        </Grid>
                      ) : (
                        <Typography
                          sx={{
                            backgroundColor: `${selectCustomer?.grade === "VIP" ? "#FBD118" : "#e0e0e0"}`,
                            fontSize: "10px",
                            padding: "3px 7px 2px 7px",
                            width: "15%",
                            textAlign: "center",
                            borderRadius: "10px",
                          }}
                        >
                          {selectCustomer?.grade ?? "미지정"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={2}>
                      <Typography variant="body2" color="textSecondary">
                        생년월일
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {isEditMode ? (
                        <TextField
                          value={isEditInfo?.birthDate ? formatDate(new Date(isEditInfo.birthDate)) : ""}
                          onChange={(e) => onChangeEditCustomer("birthDate", e.target.value)}
                          fullWidth
                          size="small"
                          type="date"
                          sx={{
                            "& .MuiInputBase-input": { fontSize: "14px", padding: "3px" },
                            "& .MuiInputLabel-root": { fontSize: "14px", padding: "0px" },
                          }}
                        />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          {formatDate(selectCustomer?.birthDate)}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={2}>
                      <Typography variant="body2" color="textSecondary">
                        연락처
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {isEditMode ? (
                        <CustomTextField field="phoneNumber" value={isEditInfo?.phoneNumber} onChange={onChangeEditCustomer} />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          {selectCustomer?.phoneNumber}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={2}>
                      <Typography variant="body2" color="textSecondary">
                        인지경로
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {isEditMode ? (
                        <CustomTextField field="funnel" value={isEditInfo?.funnel || ""} onChange={onChangeEditCustomer} />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          {selectCustomer?.funnel}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Stack>

                <Stack spacing={0} flex="1">
                  <Grid item container alignItems="flex-start" flexDirection={"row"} wrap="nowrap" sx={{ width: "100%", display: "flex", flex: "1" }}>
                    <Grid item sx={{ width: "auto", minWidth: "16.5%" }}>
                      <Typography variant="body2" color="textSecondary">
                        성별
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {isEditMode ? (
                        <Grid item xs={6} pr={1} gap={1} display="flex">
                          {genderOptions.map((option) => (
                            <Button
                              key={option.value} // Use a unique key for each button
                              onClick={() => onChangeEditCustomer("gender", option.value)}
                              sx={{
                                width: "50%",
                                height: "20px",
                                fontSize: "14px",
                                backgroundColor: isEditInfo?.gender === option.value ? "#e1e1f5" : "#b0b0b0",
                              }}
                            >
                              {option.label}
                            </Button>
                          ))}
                        </Grid>
                      ) : (
                        <Grid item sx={{ flex: 1 }}>
                          <Typography variant="body2" color="textSecondary" display="flex" justifyContent={"flex-start"}>
                            {selectCustomer?.gender === "F" ? "여성" : "남성"}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container alignItems="flex-start" flexDirection={"row"} wrap="nowrap" sx={{ width: "100%", display: "flex", flex: "1" }}>
                    <Grid item xs={2}>
                      <Typography variant="body2" color="textSecondary">
                        주소
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {isEditMode ? (
                        <CustomTextField field="address" value={isEditInfo?.address || ""} onChange={onChangeEditCustomer} />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          {selectCustomer?.address}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="flex-start" flexDirection={"row"} sx={{ width: "100%", display: "flex", flex: "2" }}>
                    <Grid item xs={2}>
                      <Typography variant="body2" color="textSecondary">
                        특이사항
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {isEditMode ? (
                        <CustomTextField field="memo" value={isEditInfo?.memo || ""} onChange={onChangeEditCustomer} multiline rows={3} />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          {selectCustomer?.memo}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* 아래 8 (높이의 2/3) */}
      <Grid container item xs={8}>
        <Paper sx={{ borderRadius: "8px", display: "flex", flexDirection: "column", boxShadow: 3, width: "100%" }}>
          <Grid item sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={onChangeTab}>
              <Tab label="홈" />
              <Tab label="구매 내역" />
              <Tab label="방문 내역" />
            </Tabs>
          </Grid>

          <Grid
            item
            sx={{
              flexGrow: 1,
              overflow: "auto",
              height: 0,
            }}
          >
            {tabValue === 0 && (
              <Box sx={{ height: "100%", overflow: "auto" }}>
                <CustomersHomeCard />
              </Box>
            )}
            {tabValue === 1 && (
              <Box sx={{ height: "100%", overflow: "auto", p: 2 }}>
                <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                    구매/시술내역
                  </Typography>
                  <Button onClick={onClickNewTab}>추가 구매</Button>
                </Grid>
                <PurchaseHistoryCard />
              </Box>
            )}
            {tabValue === 2 && (
              <Box sx={{ height: "100%", overflow: "auto", p: 2 }}>
                <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                  방문 내역
                </Typography>
                <VisitingHistoryCard />
              </Box>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CustomerDetails;
