import React, { useState } from "react";
import { Grid } from "@mui/material";
import DeviceDetails from "./DeviceDetails";
import RegisterDevice from "./RegisterDevice";
import { DeviceLocalProvider } from "./Context";
import DeviceControls from "./DeviceControls";

const Devices: React.FC = () => {
  const [openForm, setOpenForm] = useState<boolean>(false);

  return (
    <DeviceLocalProvider>
      {/* TODO: 탭이 들어가야 한다면 이곳에 */}
      <Grid container>
        <Grid container direction={"column"} padding={"10px"} wrap="nowrap" item xs={4} height={"100vh"}>
          <DeviceControls openForm={openForm} setOpenForm={setOpenForm} />
        </Grid>
        <Grid item xs={8} height={"100vh"}>
          <Grid container direction={"column"} height={"100vh"} padding={"10px"}>
            <Grid item xs={12}>
              {openForm ? <RegisterDevice setOpenForm={setOpenForm} /> : <DeviceDetails />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DeviceLocalProvider>
  );
};

export default Devices;
