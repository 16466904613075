import React, { useState, useRef } from "react";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { ko } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, format } from "date-fns";
import { sidebarWidth } from "../../../component/Sidebar";
import { useDoctor } from "../../../context/DoctorsContext";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocalContext } from "../Context";
import "dayjs/locale/ko";

const TableTopHeader: React.FC = ({}) => {
  const { selectedDate, setSelectedDate } = useLocalContext();
  const { doctors } = useDoctor();

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const popperAnchorRef = useRef(null);

  const handlePrevDay = () => {
    setSelectedDate((prevDate) => addDays(prevDate, -1));
  };

  const handleNextDay = () => {
    setSelectedDate((prevDate) => addDays(prevDate, 1));
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    toggleDatePicker();
  };

  const toggleDatePicker = () => {
    setDatePickerOpen(!isDatePickerOpen);
  };

  const dayMap: { [key: string]: string } = {
    Mon: "월",
    Tue: "화",
    Wed: "수",
    Thu: "목",
    Fri: "금",
    Sat: "토",
    Sun: "일",
  };

  return (
    <Grid
      container
      item
      sx={{ width: `calc(100vw - ${sidebarWidth}px)`, height: "50px", position: "sticky", top: 0, left: `${sidebarWidth}px`, zIndex: 1000, padding: "0px 16px", backgroundColor: "white" }}
    >
      <Grid container item sx={{ display: "flex", alignItems: "center", position: "relative" }}>
        <Grid container item gap={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", position: "absolute" }}>
          <IconButton onClick={handlePrevDay} sx={{ padding: 0 }}>
            <ArrowBackIosNewIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
            <Typography align="center" onClick={toggleDatePicker} sx={{ fontSize: 22, cursor: "pointer" }} ref={popperAnchorRef}>
              {selectedDate ? format(selectedDate, "yyyy년 MM월 dd일") : ""} {selectedDate ? `(${dayMap[format(selectedDate, "EEE")]})` : ""}
            </Typography>
            {isDatePickerOpen && (
              <DatePicker
                open={isDatePickerOpen}
                onClose={toggleDatePicker}
                onChange={(date: Date | null) => {
                  if (date !== null) {
                    handleDateChange(date);
                  }
                }}
                value={selectedDate}
                slots={{ textField: () => <></> }}
                slotProps={{
                  popper: {
                    anchorEl: popperAnchorRef.current,
                    placement: "bottom-start",
                  },
                  day: {
                    sx: {
                      "&:hover": { backgroundColor: "#e1e1f5 !important" },
                      "&.Mui-selected": {
                        backgroundColor: "#655dc6 !important",
                        color: "white",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#554ea2 !important",
                      },
                    },
                  },
                }}
              />
            )}
          </LocalizationProvider>
          <IconButton onClick={handleNextDay} sx={{ padding: 0 }}>
            <ArrowForwardIosIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "35%",
            gap: "5px",
            overflow: "auto",
            maxHeight: "35px",
            justifyContent: "flex-end",
            position: "absolute",
            right: 0,
          }}
        >
          {doctors
            .filter((doctor) => doctor.exitDate === null)
            .map((doctor) => (
              <Box key={doctor.doctorUuid} sx={{ display: "flex", alignItems: "center", textAlign: "center", gap: "1px" }}>
                <Box sx={{ width: "10px", height: "10px", border: "0.5px solid grey", backgroundColor: doctor.color }} />
                <Typography sx={{ fontSize: "10px" }}>{doctor.name}</Typography>
              </Box>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableTopHeader;
