import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { Customer, LanguageType, ProductPurchaseList } from "./Model";
import { customerController } from "./Controller";
import { Product } from "../../models/ProductModel";

export interface CustomerContextType {
  customers: Customer[];
  setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  selectCustomer: Customer | undefined;
  setSelectCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  totalCustomers: number;
  isAddCustomer: boolean;
  setIsAddCustomer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export const CustomerLocalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectCustomer, setSelectCustomer] = useState<Customer>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const size: number = 25; // 한 번에 몇 개까지 불러올 것인지? Rows값 (고정 25)

  // 페이지네이션 실행
  useEffect(() => {
    fetchCustomer(page, size);
  }, [page]);

  // 선택된 고객 업데이트
  useEffect(() => {
    fetchCustomer(page, size);
  }, [page]);

  // 선택된 고객 업데이트
  useEffect(() => {
    if (customers && selectCustomer) {
      const updatedCustomer = customers.find((customer) => customer.customerUuid === selectCustomer.customerUuid);
      if (updatedCustomer) {
        setSelectCustomer(updatedCustomer);
      }
    }
  }, [selectCustomer]);

  // 페이지가 변경될 때마다 고객을 불러온다
  const fetchCustomer = async (page: number, size: number) => {
    const fetchCustomerResponse = await customerController.handleFetchCustomer(page - 1, size);
    if (fetchCustomerResponse.success) {
      // 고객 리스트, 전체 페이지, 전체 고객 수
      setCustomers(fetchCustomerResponse.data.customers);
      setTotalPages(fetchCustomerResponse.data.totalPages);
      setTotalCustomers(fetchCustomerResponse.data.totalItems);
    } else {
      alert(fetchCustomerResponse.message + "\nfetchCustomer Context");
    }
  };

  return (
    <CustomerContext.Provider
      value={{
        customers,
        setCustomers,
        selectCustomer,
        setSelectCustomer,
        isEdit,
        setIsEdit,
        page,
        setPage,
        totalPages,
        totalCustomers,
        isAddCustomer,
        setIsAddCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useLocalContext = () => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error("useLocalContext must be used within a CustomerLocalProvider");
  }
  return context;
};
