import React, { useState, useEffect } from "react";
import { Button, Modal, Box, Grid, Typography, TextField, styled, MenuItem } from "@mui/material";
import { ScheduleData, Service } from "../page/Home/Model";
import domtoimage from "dom-to-image";
import LanguageSelector from "./LanguageSelect";

interface ReportModalProps {
  open: boolean;
  setWaitModalOpen: (open: boolean) => void;
  scheduleData: ScheduleData;
}

const LanguageList = [
  { id: 1, name: "한국어" },
  { id: 2, name: "영어" },
  { id: 3, name: "일본어" },
  { id: 4, name: "중국어" },
];

// 스타일 컴포넌트 정의
const LabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
});
const Label = styled(Typography)(({ size }: { size?: "large" | "small" }) => ({
  color: "#655dc6",
  fontSize: size === "large" ? "14px" : "12px",
  lineHeight: 1,
  fontWeight: "bold",
}));

const Value = styled(Typography)({
  borderBottom: "1px solid #655dc6",
  fontWeight: "bold",
  textAlign: "center",
  width: "120px",
  fontSize: "14px",
});

const ReportModal: React.FC<ReportModalProps> = ({ open, setWaitModalOpen, scheduleData }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSelectedButton, setIsSelectedButton] = useState<string>("한국어");
  const [reportData, setReportData] = useState({
    name: scheduleData.customer.name,
    date: scheduleData.schedule.date,
    doctor: scheduleData.schedule.doctor,
    program: scheduleData.schedule.program || "",
  });

  const fields = [
    { labelSmall: "이름", labelLarge: "NAME", field: "name", value: reportData.name },
    { labelSmall: "날짜", labelLarge: "DATE", field: "date", value: new Date(reportData.date) },
    { labelSmall: "담당원장", labelLarge: "DR.", field: "doctor", value: reportData.doctor },
  ];

  const [editReportData, setEditReportData] = useState({ ...reportData });
  const [services, setServices] = useState<Service[]>([]);
  const [editServices, setEditServices] = useState<Service[]>([]);
  // 모달 창 닫기
  const onClickClose = () => {
    setWaitModalOpen(false);
    setIsEdit(false);
  };

  useEffect(() => {
    if (isEdit) {
      setEditReportData({ ...reportData });
      setEditServices([...services]);
    }
  }, [isEdit]);

  // 저장 버튼 클릭시 저장함수
  const onClickSave = () => {
    setReportData(editReportData);
    setServices(editServices);
    setIsEdit(false);
  };

  // 취소 버튼 클릭시 취소함수
  const onClickCancel = () => {
    // 임시 데이터를 원래 데이터로 되돌림
    setEditReportData({ ...reportData });
    setEditServices([...services]);
    setIsEdit(false);
  };

  // 인풋 스케줄 수정함수
  const onChangeMemo = (index: number, value: string) => {
    const updatedServices = [...editServices];
    updatedServices[index] = {
      ...updatedServices[index],
      memo: value,
    };
    setEditServices(updatedServices);
  };

  //인풋 고객 수정 함수
  const onChangeReportData = (field: keyof typeof reportData, value: string) => {
    setEditReportData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onClickLanguage = (id: string) => {
    setIsSelectedButton(id);
  };

  // 캡쳐 버튼 클릭시 캡쳐 함수
  const onClickCapture = async () => {
    const captureArea = document.getElementById("capture-area"); // 캡쳐할 영역을 선택
    if (captureArea) {
      try {
        const targetWidth = 705; // 고정된 가로 길이

        // 동적으로 비율 계산
        const aspectRatio = captureArea.scrollHeight / captureArea.scrollWidth;

        // 세로 길이 계산 (개별 DOM 요소의 비율 적용)
        const targetHeight = Math.ceil(targetWidth * aspectRatio);
        // 숨겨진 캡처 영역 생성
        const hiddenContainer = document.createElement("div");
        hiddenContainer.style.width = `${740}px`;
        hiddenContainer.style.height = `${targetHeight}px`;

        const clonedArea = captureArea.cloneNode(true) as HTMLElement;

        clonedArea.style.overflow = "visible";
        clonedArea.style.boxSizing = "border-box"; // 테두리와 패딩을 포함
        clonedArea.style.margin = "0 auto"; // 수평 중앙 정렬
        clonedArea.style.paddingLeft = "0px"; // 테두리 추가
        clonedArea.style.paddingRight = "0px"; // 테두리 추가

        hiddenContainer.appendChild(clonedArea);
        document.body.appendChild(hiddenContainer);

        const dataUrl = await domtoimage.toPng(clonedArea, {
          style: {
            transform: "scale(0.9,0.78)",
            transformOrigin: "top center",
            backgroundColor: "white",
          },
          bgcolor: "white",
          width: 705,
          height: targetHeight * 0.78,
          cacheBust: true, // 캐시 무시
        });

        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `Report_${reportData.name}-${reportData.date}.png`;
        link.click();
      } catch (error) {
        console.error("Error capturing image:", error);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClickClose} sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box sx={{ width: "100%", maxWidth: "720px", backgroundColor: "white", borderRadius: "8px", height: "auto" }}>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "3%",
            width: "100%",
            height: "50px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            justifyContent: "space-between",
            borderBottom: "2px solid #ddd",
          }}
        >
          <Grid>
            <LanguageSelector selectedLanguage={isSelectedButton} onChange={onClickLanguage} />
          </Grid>
          <Grid>
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>금일 레포트</Typography>
          </Grid>
          <Grid sx={{ width: "120px", display: "flex", justifyContent: "flex-end" }}>
            {isEdit ? (
              <>
                <Button onClick={onClickSave} sx={{ marginRight: "6px", minWidth: "unset", fontSize: "11px" }}>
                  완료
                </Button>
                <Button onClick={onClickCancel} sx={{ minWidth: "unset", fontSize: "11px" }}>
                  취소
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => setIsEdit(!isEdit)} sx={{ marginRight: "6px", minWidth: "unset", fontSize: "11px" }}>
                  수정
                </Button>
                <Button sx={{ minWidth: "unset", fontSize: "11px" }} onClick={onClickCapture}>
                  이미지 저장
                </Button>
              </>
            )}
          </Grid>
        </Grid>

        <Grid container sx={{ p: "4%", overflow: "auto", maxHeight: "600px" }} id="capture-area" gap={2.5}>
          <Grid item container sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Grid>
              <Typography id="modal-title" variant="h1" component="h2">
                <img src="/asset/amred.png" alt="Logo" style={{ width: "150px", height: "auto" }} crossOrigin="anonymous" />
              </Typography>
            </Grid>
          </Grid>

          <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            {fields.map(({ labelSmall, labelLarge, field, value }) => (
              <Box key={field} sx={{ display: "flex", alignItems: "flex-end", gap: 1, flexBasis: "33%", maxWidth: "33%" }}>
                <LabelContainer>
                  <Label size="small">{labelSmall}</Label>
                  <Label size="large">{labelLarge}</Label>
                </LabelContainer>
                {isEdit ? (
                  <TextField
                    variant="standard"
                    sx={{
                      width: "62%",
                      "& .MuiInputBase-input": {
                        padding: "1px", // 입력 필드의 패딩 제거
                        fontSize: "14px",
                        textAlign: "center",
                      },
                    }}
                    value={editReportData[field as keyof typeof reportData] || ""}
                    onChange={(e) => onChangeReportData(field as keyof typeof reportData, e.target.value)}
                  />
                ) : (
                  <Value sx={{ width: labelSmall === "담당원장" ? "53%" : "64%" }}>
                    {typeof value !== "string"
                      ? (() => {
                          const date = new Date(value);
                          const year = String(date.getFullYear()).slice(-2);
                          const month = String(date.getMonth() + 1).padStart(2, "0");
                          const day = String(date.getDate()).padStart(2, "0");
                          return `${year}.${month}.${day}`;
                        })()
                      : value}
                  </Value>
                )}
                {labelSmall === "담당원장" && <Label size="large">원장</Label>}
              </Box>
            ))}
          </Grid>
          <Grid item sx={{ border: "3px solid #655dc6", width: "100%", maxWidth: "705px" }}>
            <Grid sx={{ display: "flex", alignItems: "center", padding: "10px 0px" }}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontWeight: "bold",
                  paddingLeft: "15px",
                }}
              >
                진행 시술
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontWeight: "bold",
                  paddingTop: "4px",
                  fontSize: "14px",
                  lineHeight: 1,
                  transform: "scaleY(0.8)",
                  paddingLeft: "10px",
                }}
              >
                TODAY'S PROCEDURE
              </Typography>
            </Grid>
            <Grid sx={{ py: 3, px: 5, borderTop: "3px solid #655dc6" }}>
              <Grid>
                {isEdit ? (
                  <Grid sx={{ display: "flex", alignItems: "center" }}>
                    ▶
                    <TextField
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "1px", // 입력 필드의 패딩 제거
                          fontSize: "14px",
                          minWidth: "unset",
                        },
                      }}
                      variant="standard"
                      value={reportData.program || "없음"}
                    />
                  </Grid>
                ) : (
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      minHeight: "30px",
                    }}
                  >
                    ▶ {reportData.program ? reportData.program : "시술명 업데이트 테스트"}
                  </Typography>
                )}
              </Grid>

              <Grid container item sx={{ display: "flex", flexDirection: "column", gap: "10px", color: "#b0b0b0" }}>
                {editServices.map((service, index) => (
                  <Box key={service.serviceId}>
                    {isEdit ? (
                      <Grid sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        -
                        <TextField
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "1px", // 입력 필드의 패딩 제거
                              fontSize: "14px",
                            },
                          }}
                          fullWidth
                          variant="standard"
                          value={service.memo}
                          onChange={(e) => onChangeMemo(index, e.target.value)}
                          size="small"
                        />
                      </Grid>
                    ) : (
                      <Typography sx={{ fontSize: "14px", minHeight: "30px" }}>- {services[index].memo || "메모 없음"}</Typography>
                    )}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" alignContent="center" sx={{ pt: 1 }}>
            <Grid item>
              <Typography sx={{ border: "3px solid #6B5FF7", fontWeight: "bold", fontSize: "13px", padding: "0px 10px", color: "#655dc6" }}>ONLY PRIVATE</Typography>
            </Grid>
            <Grid item sx={{ fontWeight: "bold", color: "#655dc6" }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>REWIND,---------</Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>--------- REWRITE.</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ReportModal;
