import { Employee } from "./Model";
import { handleControllerResponse } from "../../util/Function";
import { fetchEmployee, resetPassword, registerEmployee } from "./Service";

class EmployeeController {
  async handleFetchEmployee() {
    const fetchEmployeeResponse = await fetchEmployee();
    return handleControllerResponse(fetchEmployeeResponse.status, fetchEmployeeResponse.data);
  }
  async handleResetPassword(userId: number) {
    const resetPasswordResponse = await resetPassword(userId);
    return handleControllerResponse(resetPasswordResponse.status, resetPasswordResponse.data);
  }

  async handleRegisterEmployee(employee: Employee) {
    const registerEmployeeResponse = await registerEmployee(employee);
    return handleControllerResponse(registerEmployeeResponse.status, registerEmployeeResponse.data);
  }
}

export const employeeController = new EmployeeController();
