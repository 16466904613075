import { Service } from "./Model";

export const compareServiceLists = (originalList: Service[], updatedList: Service[]): { check: boolean; array: Service[] } => {
  const changedServices: Service[] = [];

  // 두 리스트 중 긴 길이를 기준으로 비교
  const maxLength = Math.max(originalList.length, updatedList.length);

  for (let i = 0; i < maxLength; i++) {
    const originalSurgery = originalList[i];
    const updatedSurgery = updatedList[i];

    // 추가된 항목 (originalList에 없고 updatedList에만 있는 경우)
    if (!originalSurgery && updatedSurgery) {
      changedServices.push(updatedSurgery);
      continue;
    }

    // 삭제된 항목은 변경된 항목으로 간주하지 않음 (필요하면 처리 가능)
    if (originalSurgery && !updatedSurgery) {
      continue;
    }

    // 각 필드를 비교하여 변경 여부 확인
    if (
      originalSurgery?.name !== updatedSurgery?.name ||
      originalSurgery?.doctor !== updatedSurgery?.doctor ||
      originalSurgery?.room !== updatedSurgery?.room ||
      originalSurgery?.status !== updatedSurgery?.status
    ) {
      changedServices.push(updatedSurgery!); // 변경된 항목 추가
    }
  }

  // 결과 반환
  return { check: changedServices.length > 0, array: changedServices };
};
