import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { Product } from "../models/ProductModel";
import { productController } from "../controller/ProductController";
import { replaceEmptyWithNull } from "../util/Function";

interface ProductContextType {
  productList: Product[];
  promotionProductList: Product[];
  setPromotionProductList: React.Dispatch<React.SetStateAction<Product[]>>;
  setProductList: React.Dispatch<React.SetStateAction<Product[]>>;
  updateProductList: (updatedProduct: Product, isPromotion: boolean) => void;
  addProduct: (newProduct: Product) => void;
  updateProduct: (newProduct: Product) => void;
  endProduct: (newProduct: Product) => void;
}

interface ProductProviderProps {
  children: ReactNode;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
  const [productList, setProductList] = useState<Product[]>([]);
  const [promotionProductList, setPromotionProductList] = useState<Product[]>([]);

  //상품 정보 가져오기
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await productController.handleFetchProduct();
        // 데이터 분류
        const result = response.data.reduce(
          (
            acc: {
              promotionArray: Product[];
              productArray: Product[];
            },
            element: Product
          ) => {
            const { category, endDate } = element;
            if (category === "promotion") {
              acc.promotionArray.push(element);
            } else {
              acc.productArray.push(element);
            }

            return acc;
          },
          { promotionArray: [], productArray: [] }
        );

        // 최종적으로 상태 업데이트
        setPromotionProductList(result.promotionArray);
        setProductList(result.productArray);
      } catch (error) {
        console.error("Failed to fetch product data:", error);
      }
    };

    fetchProduct();
  }, []);

  // 상품 추가
  const addProduct = async (newProduct: Product) => {
    const cleanedData = replaceEmptyWithNull(newProduct);
    const response = await productController.handleRegisterProduct(cleanedData);
    if (response.success) {
      return response;
    } else {
      alert("상품을 등록하지 못했습니다. 다시 시도해 주세요.");
    }
    /* sse 수정 및 기존 코드 success 구조로 수정 
    try {
      const newDataList: Product = response.data;
      // 새로 추가된 상품을 적절한 리스트에 반영
      if (newDataList.category === "promotion") {
        // newDataList.endDate !== null && new Date(newDataList.endDate) < today
        //   ? setClosePromotionProductList((prevList) => [...prevList, newDataList])
        //   : setPromotionProductList((prevList) => [...prevList, newDataList]);
        setPromotionProductList((prevList) => [...prevList, newDataList]);
      } else {
        // newDataList.endDate !== null && new Date(newDataList.endDate) < today
        //   ? setCloseProductList((prevList) => [...prevList, newDataList])
        //   : setProductList((prevList) => [...prevList, newDataList]);
        setProductList((prevList) => [...prevList, newDataList]);
      }
      alert("상품이 정상적으로 추가되었습니다.");
    } catch (error) {
      alert("상품을 등록하지 못했습니다. 다시 시도해 주세요.");
      console.error("Failed to add Product:", error);
    } */
  };

  // 상품 수정
  const updateProduct = async (newProduct: Product) => {
    const cleanedData = replaceEmptyWithNull(newProduct);
    const response = await productController.handleUpdateProduct(cleanedData);
    /* sse 수정 및 기존 코드 success 구조로 수정 
    const editDataList: Product = response.data;
    try {
      updateProductList(editDataList, editDataList.category === "promotion");
      alert("상품이 정상적으로 수정되었습니다.");
    } catch (error) {
      alert("상품을 수정하지 못했습니다. 다시 시도해 주세요.");
      console.error("Failed to add Product:", error);
    }
      */
    if (response.success) {
      return response;
    } else {
      alert("상품을 수정하지 못했습니다. 다시 시도해 주세요.");
    }
    return false;
  };

  // 상품 종료
  const endProduct = async (product: Product) => {
    const cleanedData = replaceEmptyWithNull(product);
    const endProductResponse = await productController.handleEndProduct(cleanedData);
    // const editDataList: Product = endProductResponse.data;

    if (endProductResponse.success) {
      return endProductResponse;
    } else {
      alert("종료 시간을 설정하지 못했습니다. 다시 시도해 주세요.");
    }
    /* sse 수정 및 기존 코드 success 구조로 수정 
    try {
      updateProductList(editDataList, editDataList.category === "promotion");
    } catch (error) {
      alert("종료 시간을 설정하지 못했습니다. 다시 시도해 주세요.");
      console.error(error);
    }
      */
  };

  // 상품 상태 업데이트 로직 => 이것도 sse에 넣어야할까요?
  const updateProductList = (updatedProduct: Product, isPromotion: boolean) => {
    const updateList = (list: Product[], product: Product) => list.map((item) => (item.productUuid === product.productUuid ? product : item));

    if (isPromotion) {
      setPromotionProductList((prevList) => updateList(prevList, updatedProduct));
    } else {
      setProductList((prevList) => updateList(prevList, updatedProduct));
    }
  };

  return (
    <ProductContext.Provider
      value={{
        productList,
        promotionProductList,
        // closePromotionProductList,
        // setClosePromotionProductList,
        setPromotionProductList,
        // setCloseProductList,
        setProductList,
        // closeProductList,
        addProduct,
        updateProduct,
        endProduct,
        updateProductList,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = (): ProductContextType => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error("useProduct must be used within an ProductProvider");
  }
  return context;
};
