import axios from "axios";
import { handleServiceApiError } from "../util/Function";
import { Counselor } from "../models/CounselorModel";

const counselorApiUrl = "/api/v1/counselor";
const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});

export const fetchCounselor = async () => {
  try {
    const response = await apiClient.post(`${counselorApiUrl}`);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const registerCounselor = async (counselor: Counselor) => {
  try {
    const response = await apiClient.post(`${counselorApiUrl}/register`, counselor);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const updateCounselor = async (counselor: Counselor) => {
  try {
    const response = await apiClient.post(`${counselorApiUrl}/update`, counselor);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
