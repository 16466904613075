import React, { useState } from "react";
import { Grid, TextField, Checkbox } from "@mui/material";
import { DoctorDailySchedule } from "../Model";
import { useHomeContext } from "../Context";

interface DoctorScheduleCardProps {
  doctorDailySchedule: DoctorDailySchedule; // 단일 객체로 변경
}

export default function DoctorScheduleCard({ doctorDailySchedule }: DoctorScheduleCardProps) {
  const { updateDoctorDailySchedule } = useHomeContext();
  const [editedContent, setEditedContent] = useState(doctorDailySchedule.dailyScheduleText);
  const [tempContent, setTempContent] = useState(editedContent || "");

  // 저장 버튼 클릭 시 실행되는 함수
  const onClickSave = () => {
    setEditedContent(tempContent);
    if (editedContent === tempContent) {
      return;
    } else {
      updateDoctorDailySchedule(doctorDailySchedule.doctorDailyScheduleId, tempContent); // 업데이트 함수 호출
    }
  };

  return (
    <Grid sx={{ borderBottom: "1px solid #ddd" }}>
      <Grid sx={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "column", padding: "0px 5px" }}>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
          <Checkbox
            size="small"
            checked={doctorDailySchedule.status === "end"}
            onChange={(e) => {
              const newStatus = doctorDailySchedule.status === "end" ? "start" : "end";
              // updateCheckDoctorDailySchedule(doctorDailySchedule.doctorDailyScheduleId, newStatus);
            }}
            sx={{
              padding: "4px 8px 0px 0px", // 상단 패딩 조정
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />

          <TextField
            fullWidth
            size="small"
            value={tempContent} // 임시 상태를 표시
            onChange={(e) => setTempContent(e.target.value)}
            onBlur={onClickSave}
            multiline
            variant="standard"
            // value={editedContent || ""}
            sx={{
              mt: 1,
              padding: "0px",
              "& .MuiInputBase-input": {
                fontSize: 13,
                padding: "0px",
              },
              "& .MuiInputBase-root": {
                // 포커스 되지 않았을 때
                "&.Mui-focused": {
                  border: "none", // 포커스 시에도 테두리 없음
                  backgroundColor: "transparent",
                },
                // 기본 필드라인 제거
                "&:before": {
                  borderBottom: "none",
                },
                "&:after": {
                  borderBottom: "none",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
