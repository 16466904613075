import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { Surgery, SurgeryType } from "../models/SurgeryModel";
import { surgeryController } from "../controller/SurgeryController";

const SurgeryContext = createContext<SurgeryType | undefined>(undefined);

export const SurgeryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [surgeryList, setSurgeryList] = useState<Surgery[]>([]);
  const [selectedSurgery, setSelectedSurgery] = useState<Surgery | null>(null);

  useEffect(() => {
    const fetchSurgery = async () => {
      const fetchSurgeryResponse = await surgeryController.handleFetchSurgery();
      if (fetchSurgeryResponse.success) {
        setSurgeryList(fetchSurgeryResponse.data);
      } else {
        alert(fetchSurgeryResponse.message + "\nfetchDevice Context");
      }
    };
    fetchSurgery();
  }, []);
  const registerSurgery = async (surgery: Surgery) => {
    const registerSurgeryResponse = await surgeryController.handleRegisterSurgery(surgery);
    if (registerSurgeryResponse.success) {
      //sse
      // setSurgeryList((prevList) => [...prevList, registerSurgeryResponse.data]);
    } else {
      alert(registerSurgeryResponse.message + "\nfetchDevice Context");
    }
  };
  const updateSurgery = async (surgery: Surgery) => {
    const registerSurgeryResponse = await surgeryController.handleUpdateSurgery(surgery);
    if (registerSurgeryResponse.success) {
      //sse
      // setSurgeryList((prevList) => prevList.map((item) => (item.surgeryId === registerSurgeryResponse.data.surgeryId ? registerSurgeryResponse.data : item)));
      return registerSurgeryResponse;
    } else {
      alert(registerSurgeryResponse.message + "\nfetchDevice Context");
    }
  };
  const deleteSurgery = async (surgeryId: number) => {
    const registerSurgeryResponse = await surgeryController.handleDeleteSurgery(surgeryId);
    if (registerSurgeryResponse.success) {
      //sse
      // setSurgeryList((prevList) => prevList.filter((item) => item.surgeryId !== registerSurgeryResponse.data.surgeryId));
      return registerSurgeryResponse;
    } else {
      alert(registerSurgeryResponse.message + "\nfetchDevice Context");
    }
  };

  return <SurgeryContext.Provider value={{ surgeryList, setSurgeryList, selectedSurgery, setSelectedSurgery, registerSurgery, updateSurgery, deleteSurgery }}>{children}</SurgeryContext.Provider>;
};

export const useSurgery = (): SurgeryType => {
  const surgeryContext = useContext(SurgeryContext);
  if (surgeryContext === undefined) {
    throw new Error("useSurgery must be used within an SurgeryProvider");
  }
  return surgeryContext;
};
