import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ProductPurchaseList } from "../Customer/Model";
import { LanguageType } from "../Customer/Model";
import { Product } from "../../models/ProductModel";
import { Customer } from "../Home/Model";
import { customerController } from "../Customer/Controller";

export interface PurchaseContextType {
  customerId: string | null;
  selectCartList: ProductPurchaseList[];
  setSelectCartList: React.Dispatch<React.SetStateAction<ProductPurchaseList[]>>;
  language: LanguageType;
  setLanguage: React.Dispatch<React.SetStateAction<LanguageType>>;
  selectProduct: Product | undefined;
  setSelectProduct: React.Dispatch<React.SetStateAction<Product | undefined>>;
  customer: Customer | undefined;
}

export const PurchaseContext = createContext<PurchaseContextType | undefined>(undefined);

export const PurchaseLocalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id"); // 쿼리 파라미터 값 가져오기
  const [selectCartList, setSelectCartList] = useState<ProductPurchaseList[]>([]);
  const [language, setLanguage] = useState<LanguageType>("한국어");
  const [selectProduct, setSelectProduct] = useState<Product>();
  const [customer, setCustomer] = useState<Customer>();
  useEffect(() => {
    const fetchCustomerByCustomerId = async () => {
      const fetchCustomerByCustomerIdResponse = await customerController.handleFetchCustomerByCustomerId(customerId || "");
      if (fetchCustomerByCustomerIdResponse.success) {
        setCustomer(fetchCustomerByCustomerIdResponse.data);
      } else {
        alert(fetchCustomerByCustomerIdResponse.message + "\nfetchNoticeList Context");
      }
    };
    fetchCustomerByCustomerId();
  }, []);

  return <PurchaseContext.Provider value={{ customerId, selectCartList, setSelectCartList, language, setLanguage, selectProduct, setSelectProduct, customer }}>{children}</PurchaseContext.Provider>;
};

export const useLocalContext = () => {
  const context = useContext(PurchaseContext);
  if (!context) {
    throw new Error("useLocalContext must be used within a PurchaseLocalProvider");
  }
  return context;
};
