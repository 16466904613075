import axios from "axios";
import { handleServiceApiError } from "../util/Function";
import { Product } from "../models/ProductModel";

const productApiUrl = "/api/v1/product";

const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});

export const fetchProductList = async () => {
  try {
    const response = await apiClient.post(`${productApiUrl}`);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const registerProduct = async (productDto: Product) => {
  try {
    const response = await apiClient.post(`${productApiUrl}/register`, productDto);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const updateProduct = async (productDto: Product) => {
  try {
    const response = await apiClient.post(`${productApiUrl}/update`, productDto);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const endProduct = async (productDto: Product) => {
  try {
    const response = await apiClient.post(`${productApiUrl}/end`, productDto);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
