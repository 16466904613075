import React, { useState } from "react";
import { Box, TextField, Button, Grid, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Typography } from "@mui/material";
import { useLocalContext } from "./Context";
import { exampleData, HistoryRecord } from "./Model";

const locationList = [
  { id: 1, location: "A1", storage: 10 },
  { id: 2, location: "A2", storage: 10 },
  { id: 3, location: "c1", storage: 10 },
  { id: 4, location: "창고1", storage: 10 },
  { id: 5, location: "창고2", storage: 10 },
  { id: 6, location: "옥상", storage: 10 },
];
const SupplyHistoryCard: React.FC = () => {
  const { detailModalData, onClickDetailModalClose, storageHistory, setStorageHistory } = useLocalContext();
  const [formData, setFormData] = useState<HistoryRecord>({
    id: "",
    date: "",
    location: "",
    person: "",
    stock: 0, // 입고 갯수
    availableStock: 0, // 출고 갯수
    type: "출고",
  });

  // 입력값 변경 핸들러
  const onChangeInput = (field: keyof HistoryRecord, value: string | number) => {
    setFormData({ ...formData, [field]: value });
  };

  // 데이터 저장 핸들러
  const onClickSave = () => {
    if (!formData.date || !formData.location || !formData.person || formData.stock <= 0) {
      alert("모든 필드를 올바르게 입력해주세요.");
      return;
    }

    const newRecord: HistoryRecord = {
      ...formData,
      availableStock: storageHistory[0].availableStock ? storageHistory[0].availableStock - formData.stock : storageHistory[0].stock,
      id: new Date().getTime().toString(), // 고유 ID 생성
    };
    setStorageHistory([newRecord, ...storageHistory]);
    setFormData({
      id: "",
      date: "",
      location: "",
      person: "",
      stock: 0, // 입고 갯수
      availableStock: 0, // 출고 갯수
      type: "입고",
    });
  };

  return (
    <Grid
      sx={{
        position: "fixed",
        right: 0,
        width: "600px",
        height: "100%",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        zIndex: 1200,
        padding: 2,
        transition: "transform 0.3s ease",
        overflowY: "auto",
        backgroundColor: "white",
      }}
    >
      <Grid container direction="column" sx={{ height: "100%" }}>
        <Grid sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Typography onClick={() => onClickDetailModalClose(false)}>x</Typography>
        </Grid>

        <Box sx={{ height: "26%", p: 1 }}>
          <Typography variant="h2">현재 상황</Typography>
          <Paper sx={{ width: "100%", height: "90%", display: "flex", flexDirection: "row" }}>
            <Grid sx={{ width: "40%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <Typography sx={{ fontSize: "14px" }}>상품명:{detailModalData?.productName} </Typography>
              <Typography sx={{ fontSize: "14px" }}>대카테고리 : {detailModalData?.mainCategory}</Typography>
              <Typography sx={{ fontSize: "14px" }}>소카테고리 : {detailModalData?.subCategory}</Typography>
              <Typography sx={{ fontSize: "14px" }}>상품코드 {detailModalData?.productCode}</Typography>
              <Typography sx={{ fontSize: "14px" }}>자체코드 {detailModalData?.customCode}</Typography>
            </Grid>
            <TableContainer sx={{ height: "100%", overflow: "auto" }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {["장소", "갯수"].map((header) => (
                      <TableCell key={header} align="center" sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exampleData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        저장된 기록이 없습니다.
                      </TableCell>
                    </TableRow>
                  ) : (
                    locationList.map((record) => (
                      <TableRow key={record.id} sx={{ backgroundColor: record.location === detailModalData?.location ? "red" : "white" }}>
                        <TableCell align="center">{record.location}</TableCell>
                        <TableCell align="center">{record.storage}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <Box sx={{ height: "42%", p: 1 }}>
          <Typography variant="h2">입/출고 히스토리</Typography>
          <Paper sx={{ width: "100%", flex: 1, height: "90%" }}>
            <TableContainer sx={{ height: "100%", overflow: "auto" }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {["날짜", "유형", "장소", "담당자", "개수", "잔여갯수"].map((header) => (
                      <TableCell key={header} align="center" sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storageHistory.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        저장된 기록이 없습니다.
                      </TableCell>
                    </TableRow>
                  ) : (
                    storageHistory.map((record, index) => (
                      <TableRow key={record.id}>
                        <TableCell align="center">{record.date}</TableCell>
                        <TableCell align="center" sx={{ color: record.type === "입고" ? "blue" : "red" }}>
                          {record.type}
                        </TableCell>
                        <TableCell align="center">{record.location}</TableCell>
                        <TableCell align="center">{record.person}</TableCell>
                        <TableCell align="center">{record.type === "입고" ? `+${record.stock}` : `-${record.stock}`}</TableCell>
                        <TableCell align="center">{record?.availableStock}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <Box sx={{ height: "25%", p: 1 }}>
          <Typography variant="h2">출고</Typography>
          <Paper sx={{ width: "100%", flex: 2, p: 2, overflow: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="날짜"
                  type="date"
                  variant="standard"
                  inputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  fullWidth
                  value={formData.date}
                  onChange={(e) => onChangeInput("date", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="장소"
                  inputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  fullWidth
                  variant="standard"
                  value={formData.location}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onChangeInput("location", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="담당자"
                  inputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  fullWidth
                  variant="standard"
                  value={formData.person}
                  onChange={(e) => onChangeInput("person", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="갯수"
                  inputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  type="number"
                  variant="standard"
                  fullWidth
                  value={formData.stock}
                  onChange={(e) => onChangeInput("stock", Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" color="primary" onClick={onClickSave} sx={{ fontSize: "13px" }}>
                  출고하기
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SupplyHistoryCard;
