import { Grid, Paper, Typography } from "@mui/material";
import { useLocalContext } from "./Context";
import { formatPhoneNumber } from "../../util/Function";

const CustomerInfo = () => {
  const { customer } = useLocalContext();
  return (
    <Grid sx={{ width: "100%", height: "100%", p: 1 }}>
      <Typography variant="h2" gutterBottom sx={{ height: "20px" }}>
        고객 정보
      </Typography>
      <Paper
        sx={{
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          boxShadow: 3,
          width: "100%",
          height: "calc(100% - 20px)",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
            gap: 1, // 요소 간 간격 설정
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ width: "20%", fontWeight: "bold" }}>이름</Typography>
            <Typography>{customer?.name}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ width: "20%", fontWeight: "bold" }}>생년월일</Typography>
            <Typography>{customer?.birthDate}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ width: "20%", fontWeight: "bold" }}>연락처</Typography>
            <Typography>{formatPhoneNumber(customer?.phoneNumber)}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default CustomerInfo;
