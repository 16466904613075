import React, { useState } from "react";
import { Grid, Paper, Typography, Button, Box, IconButton, Select, MenuItem } from "@mui/material";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { formatDate } from "../../util/Function";
import { Customer } from "./Model";
import InputField from "./CustomerDetails/TextFiled";
import { initialCustomer } from "./Model";

interface RegisterCustomerProp {
  setIsAddCustomer: (newState: boolean) => void;
}

const genderList = ["남", "여"];
const gradeOption = ["VIP", "일반"];

const RegisterCustomerCard: React.FC<RegisterCustomerProp> = ({ setIsAddCustomer }) => {
  const [newCustomer, setNewCustomer] = useState(initialCustomer);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [image, setImage] = useState<File>(); // 업로드된 파일을 저장
  const [previewUrl, setPreviewUrl] = useState<string>(); // 미리보기 URL

  // 새로운 고객 정보 입력 핸들러
  const onChangeNewCustomer = (field: keyof Customer, value: string | Date) => {
    setNewCustomer({ ...newCustomer, [field]: value });
  };

  // 이미지 업로드 핸들러
  const onChangeImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // 첫 번째 선택한 파일
    if (file) {
      setImage(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
  };

  // 이미지 삭제 핸들러
  const onClickDeleteImage = () => {
    setImage(undefined);
    setPreviewUrl(undefined);
  };

  // 신규 고객 등록 핸들러
  const onClickRegisterNewCustomer = async () => {
    setIsRegistering(true);
  };

  /// 신규 고객 등록 취소 핸들러(취소 버튼 클릭 시)
  const onClickCancelRegisterNewCustomer = () => {
    setIsAddCustomer(false);
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          신규 고객 등록
        </Typography>
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, boxShadow: 3, borderRadius: "8px", marginTop: "4px", display: "flex" }}>
        <Grid item xs={3} sx={{ background: "#f0f0fb", borderRadius: "8px 0px 0px 8px" }} />
        <Grid container item xs={6} sx={{ height: "100%", padding: "12px", textAlign: "center", fontSize: "14px", position: "relative" }} direction="column">
          <Grid container item xs={3} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                width: "70%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                position: "relative",
              }}
            >
              {previewUrl ? (
                <Box
                  sx={{
                    width: "40%",
                    height: "100px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box sx={{ aspectRatio: "1", height: "100px", borderRadius: "50%" }}>
                    <img src={previewUrl} alt="고객님 사진" style={{ aspectRatio: "1", height: "100%", borderRadius: "50%", objectFit: "cover" }} />
                  </Box>
                  <input id="image-edit" type="file" accept="image/*" onChange={onChangeImageUpload} style={{ display: "none" }} />
                  <label htmlFor="image-edit">
                    <IconButton component="span" sx={{ padding: "4px", position: "absolute", bottom: 0, right: 24 }}>
                      <EditRoundedIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </label>
                  <IconButton sx={{ padding: "4px", position: "absolute", bottom: 0, right: 0 }} onClick={onClickDeleteImage}>
                    <CloseRoundedIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    aspectRatio: "1",
                    height: "100px",
                    borderRadius: "50%",
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input id="image-upload" type="file" accept="image/*" onChange={onChangeImageUpload} style={{ display: "none" }} />
                  <label htmlFor="image-upload">
                    <IconButton component="span" sx={{ padding: "10px" }}>
                      <UploadRoundedIcon sx={{ color: "#ccc", fontSize: "30px" }} />
                    </IconButton>
                  </label>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center" }}>
            <InputField label="이름" value={newCustomer.name} type="text" width="70%" onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeNewCustomer("name", e.target.value)} />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <Grid container sx={{ width: "70%" }}>
              <Grid item xs={6} pr={1}>
                <Select
                  value={newCustomer.gender || ""}
                  onChange={(e) => onChangeNewCustomer("gender", e.target.value)}
                  displayEmpty
                  renderValue={(selected) => (selected ? <em>{selected as string}</em> : <em>성별</em>)}
                  sx={{
                    width: "100%",
                    height: "40px",
                    "& .MuiSelect-select": {
                      padding: "8px 14px",
                      fontSize: "14px",
                    },
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                >
                  {genderList.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6} pl={1}>
                <Select
                  value={newCustomer.grade || ""}
                  onChange={(e) => onChangeNewCustomer("grade", e.target.value)}
                  displayEmpty
                  renderValue={(selected) => (selected ? <em>{selected as string}</em> : <em>등급</em>)}
                  sx={{
                    width: "100%",
                    height: "40px",
                    "& .MuiSelect-select": {
                      padding: "8px 14px",
                      fontSize: "14px",
                    },
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                >
                  {gradeOption.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <InputField
              label="전화번호"
              value={newCustomer.phoneNumber}
              type="text"
              width="70%"
              onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeNewCustomer("phoneNumber", e.target.value)}
            />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <InputField
              label="생년월일"
              value={formatDate(newCustomer.birthDate)}
              type="date"
              width="70%"
              onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeNewCustomer("birthDate", new Date(e.target.value))}
            />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <InputField label="주소" value={newCustomer.address} type="text" width="70%" onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeNewCustomer("address", e.target.value)} />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <InputField label="인지경로" value={newCustomer.funnel} type="text" width="70%" onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeNewCustomer("funnel", e.target.value)} />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <InputField label="메모" value={newCustomer.memo} type="text" width="70%" onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeNewCustomer("memo", e.target.value)} />
          </Grid>
          <Grid container item xs={2} sx={{ height: "100%", overflow: "hidden", justifyContent: "center", paddingTop: "24px" }}>
            <Button sx={{ marginRight: "8px", height: "30px", padding: "0px" }} onClick={onClickRegisterNewCustomer}>
              저장
            </Button>
            <Button sx={{ marginLeft: "8px", height: "30px", padding: "0px" }} onClick={onClickCancelRegisterNewCustomer}>
              취소
            </Button>
          </Grid>
          {isRegistering && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                color: "#655dc6",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
            >
              등록 중...
            </Box>
          )}
        </Grid>
        <Grid item xs={3} sx={{ background: "#f0f0fb", borderRadius: "0px 8px 8px 0px" }} />
      </Paper>
    </Grid>
  );
};
export default RegisterCustomerCard;
