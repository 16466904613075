export interface Employee {
  userId: number | null;
  userUuid: string;
  loginId: string;
  password: string | null;
  name: string;
  phoneNumber: string;
  team: string;
  role: string;
  authRole: string;
  birthDate?: Date | null;
  joiningDate: Date;
  isExited?: boolean | null;
  isActive?: boolean | null;
  exitDate?: Date | null;
  imgUrl?: string | null;
}

export const initialEmployee: Employee = {
  userId: null,
  userUuid: "",
  loginId: "",
  password: null,
  name: "",
  phoneNumber: "",
  team: "",
  role: "",
  authRole: "",
  birthDate: null,
  joiningDate: new Date(),
  isExited: null,
  isActive: null,
  exitDate: null,
  imgUrl: null,
};
