import { updateUser } from "./Service";
import { handleControllerResponse } from "../../util/Function";
import { UserInputType } from "./Model";

class UserController {
  async handleUpdateUser(userInput: UserInputType) {
    const fetchWorkScheduleResponse = await updateUser(userInput);
    return handleControllerResponse(fetchWorkScheduleResponse.status, fetchWorkScheduleResponse.data);
  }
}
export const userController = new UserController();
