import React from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { formatPhoneNumber, formatDate, calculateYearsMonthsDays } from "../../util/Function";
import PersonIcon from "@mui/icons-material/Person";
import { useLocalContext } from "./Context";

const EmployeeDetails: React.FC = () => {
  const { selectedEmployee, resetPassword } = useLocalContext();

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          직원 세부사항
        </Typography>
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, boxShadow: 3, borderRadius: "8px", marginTop: "4px", display: "flex" }}>
        {selectedEmployee === undefined ? (
          <Typography variant="h2" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
            조회하고 싶은 직원을 목록에서 선택해 주세요
          </Typography>
        ) : (
          <>
            <Grid
              container
              item
              xs={3}
              sx={{
                height: "100%",
                padding: "12px",
                background: "#f0f0fb",
                borderRadius: "8px 0px 0px 8px",
                textAlign: "center",
                fontSize: "14px",
                zIndex: 1,
              }}
              direction="column"
            >
              <Grid item xs={5} sx={{ height: "100%", overflow: "hidden", padding: "8px" }}>
                {selectedEmployee?.imgUrl ? (
                  <img
                    src={"https://aac-amred-spaceos.s3.ap-northeast-2.amazonaws.com/doctors/" + selectedEmployee?.imgUrl}
                    alt={selectedEmployee?.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      maxHeight: "100%",
                    }}
                  />
                ) : (
                  <Grid
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#e0e0e0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <PersonIcon sx={{ height: "100%", width: "100%", color: "#808080" }} />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={1} sx={{ fontWeight: "bold" }}>
                {selectedEmployee?.name}
              </Grid>

              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  전화번호
                </Grid>
                <Grid item xs={8}>
                  {formatPhoneNumber(selectedEmployee?.phoneNumber)}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  생년월일
                </Grid>
                <Grid item xs={8}>
                  {selectedEmployee?.birthDate ? new Date(selectedEmployee.birthDate).toLocaleDateString() : "날짜 없음"}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  입사날짜
                </Grid>
                <Grid item xs={8}>
                  {selectedEmployee?.joiningDate ? new Date(selectedEmployee.joiningDate).toLocaleDateString() : "날짜 없음"}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  재직기간
                </Grid>
                <Grid item xs={8}>
                  {selectedEmployee?.exitDate ? calculateYearsMonthsDays(selectedEmployee.joiningDate, selectedEmployee.exitDate) : `${calculateYearsMonthsDays(selectedEmployee?.joiningDate)}`}{" "}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  퇴사날짜
                </Grid>
                <Grid item xs={8}>
                  {formatDate(selectedEmployee?.exitDate) === "" ? "재직중" : formatDate(selectedEmployee?.exitDate)}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  팀
                </Grid>
                <Grid item xs={8}>
                  {selectedEmployee.team}
                </Grid>
              </Grid>
              <Grid container item xs={0.7}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  직급
                </Grid>
                <Grid item xs={8}>
                  {selectedEmployee.role}
                </Grid>
              </Grid>
              <Grid container item xs={0.5} sx={{ justifyContent: "flex-end", display: "flex" }}>
                <Button
                  sx={{ color: "white", backgroundColor: "#655dc6", fontSize: "10px" }}
                  onClick={() => {
                    if (window.confirm("비밀번호를 초기화 하시겠습니까?")) {
                      resetPassword();
                    }
                  }}
                >
                  비밀번호 초기화
                </Button>
              </Grid>
            </Grid>

            <Grid container item sx={{ height: "100%" }}>
              <Typography variant="h2" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
                Coming Soon
              </Typography>
            </Grid>
          </>
        )}
      </Paper>
    </Grid>
  );
};
export default EmployeeDetails;
