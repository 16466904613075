import React from "react";
import { Grid } from "@mui/material";
import { SupplyLocalProvider } from "./Context";
import SupplyInfo from "./SupplyInfo";

const Supply: React.FC = () => {
  return (
    <SupplyLocalProvider>
      <Grid container width="100%" height="100%">
        <SupplyInfo />
      </Grid>
    </SupplyLocalProvider>
  );
};

export default Supply;
