import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography, TextField, Button, Box, Avatar } from "@mui/material";
import { formTime } from "../../util/Function";
import DeleteIcon from "@mui/icons-material/Delete";
import { isWithinTenMinutes } from "../../util/Function";
import { useHomeContext } from "./Context";

const Notice: React.FC = () => {
  console.log("Notice 랜더링 !");
  const [newNotice, setNewNotice] = useState<string>("");
  const { noticeList, registerNotice, deleteNotice, setNoticeList } = useHomeContext();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setNoticeList([...noticeList]);
    }, 300000); // 5분 렌더링
    return () => clearInterval(interval);
  }, [noticeList]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [noticeList]);

  const onClickRegisterNotice = () => {
    registerNotice(newNotice);
    setNewNotice("");
  };
  const onClickDeleteNotice = (noticeId: number) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteNotice(noticeId);
    }
  };

  const onChangeNewNoticeValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setNewNotice(evt.target.value);
  };

  return (
    <Grid container direction="column" sx={{ width: "100%", height: "100%", padding: "0px 0px 4px 0px", flexWrap: "nowrap" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center", width: "100%", height: "28px" }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          공지사항
        </Typography>
      </Grid>
      <Grid container item direction="column" sx={{ boxShadow: 3, borderRadius: "8px", width: "100%", height: "calc(100% - 28px)", flexGrow: 1, overflow: "hidden" }}>
        <Grid item sx={{ overflowY: "auto", height: "86%" }}>
          {noticeList
            .filter((notice) => !notice.isDeleted)
            .map((notice) => (
              <Box key={notice.noticeId} sx={{ width: "100%", padding: "6px 6px 12px 6px", borderBottom: "1px solid #b0b0b0" }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ textAlign: "left", fontSize: "13px", marginBottom: "3px" }}>{notice.name}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: "9px", textAlign: "end", marginLeft: "3px", display: "inline-flex", alignItems: "center" }}>
                      {formTime(notice.createdAt)}
                    </Typography>
                    {isWithinTenMinutes(notice.createdAt) && <Avatar sx={{ bgcolor: "#f5e1e1", width: 10, height: 10, color: "red", fontSize: "7px", marginLeft: "5px" }}>N</Avatar>}
                  </Box>
                  <Grid sx={{ textAlign: "right" }}>
                    <DeleteIcon
                      sx={{ fontSize: "16px", color: "#b0b0b0", alignItems: "center", cursor: "pointer", "&:hover": { color: "#655dc6" } }}
                      onClick={() => onClickDeleteNotice(notice.noticeId)}
                    />
                  </Grid>
                </Grid>
                <Typography sx={{ fontSize: "12px" }}>{notice.noticeText}</Typography>
              </Box>
            ))}
          <Box ref={scrollRef}></Box>
        </Grid>
        <Grid item sx={{ height: "14%", display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center", flex: 1, borderTop: "1px solid lightgray" }}>
          <TextField
            size="small"
            placeholder="새 공지사항 입력"
            fullWidth
            variant="standard"
            InputProps={{
              multiline: true,
              rows: 2,
              disableUnderline: true,
              inputComponent: "input",
            }}
            value={newNotice}
            onChange={onChangeNewNoticeValue}
            sx={{
              flex: 7,
              width: "100%",
              height: "100%",
              "& .MuiInputBase-inputMultiline:focus::placeholder": {
                opacity: 0,
                transition: "none",
              },
              "& .MuiInputBase-inputMultiline::placeholder": {
                lineHeight: "35px",
              },
              "& .MuiInputBase-root": {
                fontSize: "12px",
                height: "100%",
                padding: "5px",
                alignItems: "center", // 세로 정렬
              },
              "& .MuiInputBase-inputMultiline": {
                height: "100%",
                padding: "1px",
                alignItems: "center", // 세로 정렬
              },
            }}
          />

          <Button onClick={onClickRegisterNotice} sx={{ width: "100%", height: "100%", fontSize: "12px", textTransform: "none", minWidth: "unset", flex: 1, borderRadius: 0, color: "#655dc6" }}>
            추가
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notice;
