import React, { useEffect, useState } from "react";
import { Button, Modal, Box, Grid, Typography, TextField, MenuItem } from "@mui/material";
import { useHomeContext } from "../Context";
import { buildings } from "../../../models/RoomModel";
import { parseBuildingsToFloors, parseBuildingsToRooms } from "../../../util/Function";
import { ScheduleData, Waiting } from "../Model";
interface WaitingModalProps {
  open: boolean;
  setWaitModalOpen: (open: boolean) => void;
  scheduleData: ScheduleData;
}

interface WaitingFormData {
  building: string;
  floor: string;
  room: string;
  waitingMemo: string;
}

const WaitingTransitionModal: React.FC<WaitingModalProps> = ({ open, setWaitModalOpen, scheduleData }) => {
  const { waitCustomer } = useHomeContext();
  const onClickClose = () => setWaitModalOpen(false);
  const [waiting, setWaiting] = useState<Waiting>({
    scheduleId: scheduleData.schedule.scheduleId,
    customerId: scheduleData.customer.customerId,
    date: scheduleData.schedule.date,
    waitingBuilding: scheduleData.schedule.building,
    waitingFloor: scheduleData.schedule.floor,
    waitingRoom: scheduleData.schedule.room,
  });
  useEffect(() => {
    setWaiting((prev) => ({ ...prev, waitingBuilding: scheduleData.schedule.building, waitingFloor: scheduleData.schedule.floor, waitingRoom: scheduleData.schedule.room }));
  }, [open]);

  // 대기 고객 등록
  const onClickWaitCustomer = () => {
    if (!waiting.waitingMemo || !waiting.waitingRoom) {
      alert("룸 선택 및 대기 사유를 모두 입력하세요");
      return;
    }
    waitCustomer(waiting);
    onClickClose();
  };

  // 룸 미지정 대기 고객 등록
  const onClickNoRoomWaitCustomer = () => {
    if (!waiting.waitingMemo) {
      alert("대기 사유를 입력하세요");
      return;
    }
    waitCustomer(waiting);
    onClickClose();
  };

  const onChangeLocation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setWaiting((prev) => ({
      ...prev!,
      [name]: value,
    }));
    if (name === "waitingBuilding") {
      setWaiting((prev) => ({ ...prev, waitingFloor: "", waitingRoom: "" }));
    } else if (name === "waitingFloor") {
      setWaiting((prev) => ({ ...prev, waitingRoom: "" }));
    }
  };

  return (
    <Modal open={open} onClose={onClickClose} sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box sx={{ width: "440px", padding: 2, backgroundColor: "white", borderRadius: "8px" }}>
        <Grid container gap={1}>
          <Grid item xs={12}>
            <Typography id="modal-title" variant="h2" component="h2">
              고객 대기
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container sx={{ display: "flex", flexDirection: "column" }}>
              <Grid item>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <Grid item xs={4} sx={{ paddingRight: "4px" }}>
                    <TextField variant="standard" select label="본관/신관" value={waiting.waitingBuilding} name="waitingBuilding" onChange={onChangeLocation} fullWidth>
                      {buildings.map((b) => (
                        <MenuItem key={b.id} value={b.name}>
                          {b.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4} sx={{ padding: "0px 4px" }}>
                    <TextField variant="standard" select label="층 선택" value={waiting.waitingFloor} name="waitingFloor" onChange={onChangeLocation} fullWidth disabled={!waiting.waitingBuilding}>
                      {parseBuildingsToFloors(buildings, waiting.waitingBuilding || "").map((f) => (
                        <MenuItem key={f.id} value={f.name}>
                          {f.name || ""}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4} sx={{ paddingLeft: "4px" }}>
                    <TextField variant="standard" select name="waitingRoom" label="룸 선택" value={waiting.waitingRoom} onChange={onChangeLocation} fullWidth disabled={!waiting.waitingFloor}>
                      {parseBuildingsToRooms(buildings, waiting.waitingBuilding || "", waiting.waitingFloor || "").map((r) => (
                        <MenuItem key={r.id} value={r.name}>
                          {r.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  placeholder="대기 사유를 입력해주세요."
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "8px", // 입력 칸 내부의 전체 패딩
                    },
                  }}
                  value={waiting.waitingMemo}
                  onChange={(e) => setWaiting({ ...waiting, waitingMemo: e.target.value })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
            <Button variant="contained" onClick={onClickWaitCustomer} sx={{ marginRight: "8px" }}>
              대기 시작
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightgrey",
                "&:hover": { backgroundColor: "#e08a8a" },
              }}
              onClick={onClickClose}
            >
              닫기
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default WaitingTransitionModal;
