import React, { useEffect, useState, useRef } from "react";
import { Grid, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Checkbox, Box } from "@mui/material";
import { useDoctor } from "../../context/DoctorsContext";
import { Doctor } from "../../models/DoctorModel";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import { formatDate } from "../../util/Function";

interface DoctorsListProps {
  onDoctorSelect: (doctor: Doctor) => void;
  setIsAddDoctor: (newState: boolean) => void;
}

const DoctorsList: React.FC<DoctorsListProps> = ({ onDoctorSelect, setIsAddDoctor }) => {
  const { doctors } = useDoctor();
  const [isShowExitedDoctor, setIsShowExitedDoctor] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | undefined>(undefined);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (doctors && selectedDoctor) {
      const updatedDoctor = doctors.find((doctor) => doctor.doctorUuid === selectedDoctor.doctorUuid);
      if (updatedDoctor) {
        setSelectedDoctor(updatedDoctor);
        onDoctorSelect(updatedDoctor);
      }
    }
  }, [doctors]);

  useEffect(() => {
    const updatedDoctor = doctors[doctors.length - 1];
    if (isFirstRender.current) {
      isFirstRender.current = false; // 첫 렌더링 후 false로 설정
      return;
    }
    if (updatedDoctor) {
      setSelectedDoctor(updatedDoctor);
      onDoctorSelect(updatedDoctor);
    }
  }, [doctors.length]);

  const onClickTableRow = (doctor: Doctor) => {
    setSelectedDoctor(doctor);
    setIsAddDoctor(false);
    onDoctorSelect(doctor);
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left", marginLeft: "4px" }}>
          원장님 목록
        </Typography>
        <Grid sx={{ position: "absolute", right: 0, display: "flex", marginRight: "4px" }}>
          <Checkbox size="small" sx={{ padding: "0px", marginRight: "2px" }} checked={isShowExitedDoctor} onChange={(event) => setIsShowExitedDoctor(event.target.checked)} />
          <Typography sx={{ fontSize: "14px" }}>퇴사자 보기</Typography>
        </Grid>
        <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={() => setIsAddDoctor(true)}>
          <PersonAddAlt1RoundedIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </Grid>
      {/* 내용부분: 나머지 공간을 차지하고 스크롤 가능 */}
      <Paper sx={{ flexGrow: 1, height: 0, margin: "4px 0px 0px 4px", boxShadow: 3, borderRadius: "8px" }}>
        <TableContainer sx={{ maxHeight: "100%", borderRadius: "8px 8px 0px 0px" }}>
          <Table stickyHeader sx={{ "& .MuiTableCell-root": { padding: "4px", textAlign: "center", verticalAlign: "middle" } }}>
            <TableHead sx={{ top: 0, position: "sticky" }}>
              <TableRow sx={{ backgroundColor: "#e1e1f5" }}>
                <TableCell sx={{ width: "15%", textAlign: "center", paddingBottom: "0px", fontWeight: "bold", background: "#e1e1f5" }}>이름</TableCell>
                <TableCell sx={{ width: "5%", textAlign: "center", paddingBottom: "0px", fontWeight: "bold", background: "#e1e1f5" }}>색상</TableCell>
                <TableCell sx={{ width: "20%", textAlign: "center", paddingBottom: "0px", fontWeight: "bold", background: "#e1e1f5" }}>퇴사날짜</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: "auto" }}>
              {doctors.map(
                (doctor) =>
                  (isShowExitedDoctor || !doctor.exitDate || doctor.exitDate > new Date()) && (
                    <TableRow
                      key={doctor.doctorUuid}
                      onClick={() => onClickTableRow(doctor)}
                      sx={{ cursor: "pointer", backgroundColor: selectedDoctor?.doctorUuid === doctor.doctorUuid ? "#e8dff1" : "#ffffff", "&:hover": { backgroundColor: "#f0f0fb" } }}
                    >
                      <TableCell>{doctor.name}</TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
                          <Box sx={{ width: "16px", height: "16px", backgroundColor: doctor.color, border: "1px solid #b0b0b0", borderRadius: "4px", textAlign: "center" }}></Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ color: doctor.exitDate === null ? "#655dc6" : "rgba(0,0,0,0.87)" }}>
                        {!doctor.exitDate ? "재직중" : doctor.exitDate > new Date() ? `${formatDate(doctor.exitDate)} 예정` : formatDate(doctor.exitDate)}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};
export default DoctorsList;
