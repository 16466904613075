import { Device } from "../models/DevicesModel";
import { handleControllerResponse } from "../util/Function";
import { fetchDeviceList, registerDevice, updateDevice } from "../service/DevicesService";

class DevicesController {
  async fetchDeviceList() {
    const fetchDeviceListResponse = await fetchDeviceList();
    return handleControllerResponse(fetchDeviceListResponse.status, fetchDeviceListResponse.data);
  }
  async registerDevice(deviceDto: FormData) {
    const registerDeviceResponse = await registerDevice(deviceDto);
    return handleControllerResponse(registerDeviceResponse.status, registerDeviceResponse.data);
  }
  async updateDevice(deviceDto: Device) {
    const updateDeviceResponse = await updateDevice(deviceDto);
    return handleControllerResponse(updateDeviceResponse.status, updateDeviceResponse.data);
  }
}
export const devicesController = new DevicesController();
