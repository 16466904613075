import axios from "axios";
import { DoctorWorkSchedule, Service, Waiting, WorkScheduleItem } from "./Model";
import { formatDate } from "../../util/Function";
import { handleServiceApiError } from "../../util/Function";
import { Doctor } from "../../models/DoctorModel";
const noticeApiUrl = "/api/v1/home/notice";
const workScheduleApiUrl = "/api/v1/home/workSchedule";
const scheduleApiUrl = "/api/v1/schedule";
const doctorScheduleApiUrl = "/api/v1/doctor/doctorDailySchedule";
const serviceApiUrl = "/api/v1/service";
const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});

export const fetchNoticeList = async () => {
  try {
    const response = await apiClient.post(`${noticeApiUrl}`, {});
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const registerNotice = async (newNotice: string, userId: number | undefined) => {
  try {
    const response = await apiClient.post(`${noticeApiUrl}/register`, { noticeText: newNotice, createdById: userId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const deleteNotice = async (noticeId: number) => {
  try {
    const response = await apiClient.post(`${noticeApiUrl}/delete`, { noticeId: noticeId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const fetchWorkSchedule = async () => {
  try {
    const response = await apiClient.post(`${workScheduleApiUrl}`, {});
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const updateWorkSchedule = async (workSchedule: WorkScheduleItem) => {
  try {
    const response = await apiClient.post(`${workScheduleApiUrl}/update`, workSchedule);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const fetchSchedule = async (date: Date) => {
  try {
    const response = await apiClient.post(`${scheduleApiUrl}`, { date: formatDate(date) });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const uploadScheduleExcel = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await apiClient.post(`${scheduleApiUrl}/uploadExcel`, formData, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const visitCustomer = async (scheduleId: number, building?: string, floor?: string, room?: string) => {
  const requestData: { scheduleId: number; building?: string; floor?: string; room?: string } = {
    scheduleId,
  };
  if (building && floor && room) {
    requestData.building = building;
    requestData.floor = floor;
    requestData.room = room;
  }
  try {
    const response = await apiClient.post(`${scheduleApiUrl}/visit`, requestData);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const cancelSchedule = async (scheduleId: number) => {
  try {
    const response = await apiClient.post(`${scheduleApiUrl}/cancel`, { scheduleId: scheduleId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const waitCustomer = async (waiting: Waiting) => {
  try {
    const response = await apiClient.post(`${scheduleApiUrl}/wait/start`, waiting);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const returnCustomer = async (scheduleId: number) => {
  try {
    const response = await apiClient.post(`${scheduleApiUrl}/return`, { scheduleId: scheduleId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const updateService = async (serviceList: Service[]) => {
  try {
    const response = await apiClient.post(`${serviceApiUrl}/update`, serviceList);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const fetchDoctorDailySchedule = async (workingDoctorList: DoctorWorkSchedule[]) => {
  try {
    const response = await apiClient.post(`${doctorScheduleApiUrl}`, workingDoctorList);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const registerDoctorDailySchedule = async (doctorId: number, dailyScheduleText: string) => {
  try {
    const response = await apiClient.post(`${doctorScheduleApiUrl}/register`, { doctorId: doctorId, dailyScheduleText: dailyScheduleText });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const updateDoctorDailySchedule = async (doctorDailyScheduleId: number, dailyScheduleText: string) => {
  try {
    const response = await apiClient.post(`${doctorScheduleApiUrl}/update`, { doctorDailyScheduleId: doctorDailyScheduleId, dailyScheduleText: dailyScheduleText });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const startDoctorDailySchedule = async (doctorDailyScheduleId: number) => {
  try {
    const response = await apiClient.post(`${doctorScheduleApiUrl}/start`, { doctorDailyScheduleId: doctorDailyScheduleId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const endDoctorDailySchedule = async (doctorDailyScheduleId: number) => {
  try {
    const response = await apiClient.post(`${doctorScheduleApiUrl}/end`, { doctorDailyScheduleId: doctorDailyScheduleId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
