import { Box, Grid, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Customer, ScheduleData } from "../../Home/Model";
import { useDoctor } from "../../../context/DoctorsContext";
import { countryFlags } from "../utils";
import { formatPhoneNumber } from "../../../util/Function";
import { useCounselor } from "../../../context/CounselorContext";

interface ModalCustomerDetailsProps {
  newScheduleData: ScheduleData;
  setNewScheduleData: React.Dispatch<React.SetStateAction<ScheduleData>>;
  scheduleItem: ScheduleData;
}

const ModalCustomerDetails: React.FC<ModalCustomerDetailsProps> = ({ newScheduleData, setNewScheduleData, scheduleItem }) => {
  const { doctors } = useDoctor();
  const { counselorList } = useCounselor();
  const [isCustomerEditing, setIsCustomerEditing] = useState<boolean>(false);
  const [updatedNewCustomer, setUpdateNewCustomer] = useState<Customer>(newScheduleData.customer);

  const onChangeNewCustomer = (e: React.ChangeEvent<HTMLInputElement | { name: string; value: unknown }> | SelectChangeEvent<string | null>) => {
    const { name, value } = e.target;
    setUpdateNewCustomer((prevValues) => ({
      ...prevValues,
      [name]: value === "" ? "" : value,
    }));
  };

  // TODO home context, controller에 할 것
  // TODO 완료 후 newScheduleData에 customer 파트 업데이트 (그래야 회원 정보 닫을 때 반영이 됨)
  const onClickUpdateCustomer = async () => {
    try {
      // const updateResponse = await fetchController.handleUpdateCustomer(updatedNewCustomer);
    } catch (error: any) {
      alert("고객 정보 업데이트 실패\n상욱님에게 문의 바랍니다.");
    }
  };

  // TODO 여기에서 newScheduleData로 변경하기 때문에 꼭! 위에서 업데이트해 줘야 함
  const onClickCustomerEditing = () => {
    setUpdateNewCustomer(newScheduleData.customer);
    setIsCustomerEditing(!isCustomerEditing);
  };

  return (
    <Grid container item xs={2.5} direction="column" sx={{ height: "100%", overflow: "hidden", borderRadius: "4px" }}>
      {/* M 고객 정보 */}
      <Grid container item xs={6} sx={{ backgroundColor: "#f5f5f5", borderBottom: "1px solid lightgrey" }} direction="column" paddingX={1}>
        {/* M 나이, 성별, 국적 */}
        <Grid container item xs={1.6} gap={1} sx={{ alignItems: "center", justifyContent: "center", position: "relative" }}>
          <Typography align="center" sx={{ color: "#655dc6", fontWeight: "bold", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            {countryFlags[newScheduleData.customer.country] || "국적 미정"} ({newScheduleData.customer.age}/{newScheduleData.customer.gender === "M" ? "남성" : "여성"})
          </Typography>
          {isCustomerEditing ? (
            <Grid item position={"absolute"} sx={{ right: 0 }}>
              <IconButton sx={{ padding: 1 }} onClick={onClickUpdateCustomer}>
                <SaveAltRoundedIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <IconButton sx={{ padding: 1 }} onClick={onClickCustomerEditing}>
                <CloseRoundedIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Grid>
          ) : (
            <Grid item position={"absolute"} sx={{ right: 0 }}>
              <IconButton sx={{ padding: 1 }} onClick={onClickCustomerEditing}>
                <EditRoundedIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
        {/* M 생년월일 */}
        <Grid container item xs={1.6} sx={{ alignItems: "center", justifyContent: "center", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          <Grid item xs={4}>
            <Typography align="center" sx={{ fontSize: "14px", fontWeight: "bold" }}>
              생년월일
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              value={newScheduleData.customer.birthDate}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: { fontSize: "14px" },
                inputProps: { style: { textAlign: "center" } },
              }}
              disabled={true}
            />
          </Grid>
        </Grid>
        {/* M 전화번호 */}
        <Grid container item xs={1.6} sx={{ alignItems: "center", justifyContent: "center", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          <Grid item xs={4}>
            <Typography align="center" sx={{ fontSize: "14px", fontWeight: "bold" }}>
              전화번호
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              value={formatPhoneNumber(newScheduleData.customer.phoneNumber)}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: { fontSize: "14px" },
                inputProps: { style: { textAlign: "center" } },
              }}
              disabled={true}
            />
          </Grid>
        </Grid>
        {/* M 전담 원장 */}
        <Grid container item xs={1.6} sx={{ alignItems: "center", justifyContent: "center", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          <Grid item xs={4}>
            <Typography align="center" sx={{ fontSize: "14px", fontWeight: "bold" }}>
              전담 원장
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              name={"doctor"}
              value={updatedNewCustomer.doctor ?? ""}
              onChange={onChangeNewCustomer}
              variant="standard"
              sx={{ width: "100%", fontSize: "14px", textAlign: "center" }}
              disabled={!isCustomerEditing}
              inputProps={{ sx: { textAlign: "center" } }}
            >
              {doctors.map((doctor, i) => (
                <MenuItem key={i} value={doctor.name} sx={{ fontSize: "12px" }}>
                  {doctor.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        {/* M 전담 실장 */}
        <Grid container item xs={1.6} sx={{ alignItems: "center", justifyContent: "center", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          <Grid item xs={4}>
            <Typography align="center" sx={{ fontSize: "14px", fontWeight: "bold" }}>
              전담 실장
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              name={"counselor"}
              value={updatedNewCustomer.counselor ?? ""}
              onChange={onChangeNewCustomer}
              variant="standard"
              sx={{ width: "100%", fontSize: "14px", textAlign: "center" }}
              disabled={!isCustomerEditing}
              inputProps={{ sx: { textAlign: "center" } }}
            >
              {counselorList.map((counselor) => (
                <MenuItem key={counselor.counselorId} value={counselor.name} sx={{ fontSize: "12px" }}>
                  {counselor.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        {/* M 메모 */}
        <Grid container item xs={3.9} alignItems={"center"} sx={{ overflow: "hidden" }}>
          <TextField
            name={"memo"}
            value={updatedNewCustomer.memo ?? ""}
            onChange={onChangeNewCustomer}
            multiline
            fullWidth
            variant="standard"
            sx={{ height: "90%" }}
            placeholder="고객 메모를 입력해주세요"
            disabled={!isCustomerEditing}
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: "12px", padding: "8px", height: "100%", alignItems: "flex-start", overflowY: "auto" },
              inputComponent: "textarea",
              inputProps: {
                style: { height: "100%", overflow: "auto", boxSizing: "border-box" },
              },
            }}
          />
        </Grid>
      </Grid>

      {/* M 메모 */}
      <Grid container item xs={6} direction="column" sx={{ backgroundColor: "#f5f5f5", overflow: "hidden" }}>
        <Grid item xs={6} sx={{ width: "100%", backgroundColor: "#f5f5f5", paddingX: 1, overflow: "hidden" }}>
          <Typography sx={{ color: "#655dc6", fontSize: "14px", fontWeight: "bold", textAlign: "center", height: "20px" }}>베가스 직원 메모</Typography>
          <Box sx={{ height: "calc(100% - 20px)", width: "100%", overflowY: "auto" }}>
            <TextField
              value={newScheduleData.schedule.vegasEmployeeMemo}
              multiline
              fullWidth
              variant="standard"
              sx={{ height: "100%", width: "100%" }}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                sx: { fontSize: "12px", padding: "8px", height: "100%", alignItems: "flex-start", overflowY: "auto" },
                inputComponent: "textarea",
                inputProps: {
                  style: { height: "100%", overflow: "auto", boxSizing: "border-box", color: "gray" },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ padding: 1, paddingTop: 0, width: "100%", paddintop: "20px", backgroundColor: "#f5f5f5", overflow: "hidden" }}>
          <Typography sx={{ width: "100%", color: "#655dc6", fontSize: "14px", fontWeight: "bold", textAlign: "center", height: "20px" }}>베가스 예약 메모</Typography>
          <Box sx={{ height: "calc(100% - 20px)", width: "100%", overflowY: "auto" }}>
            <TextField
              value={newScheduleData.schedule.vegasReservationMemo}
              multiline
              fullWidth
              variant="standard"
              sx={{ height: "100%", width: "100%" }}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                sx: { fontSize: "12px", padding: "8px", height: "100%", alignItems: "flex-start", overflowY: "auto" },
                inputComponent: "textarea",
                inputProps: {
                  style: { height: "100%", overflow: "auto", boxSizing: "border-box", color: "gray" },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalCustomerDetails;
