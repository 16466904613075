import { Box, Grid, Typography } from "@mui/material";
import { useHomeContext } from "../Context";

const CardCustomerDetails: React.FC = () => {
  const { detailModalContent } = useHomeContext();
  return (
    <Grid container item flex={1} padding={2} direction={"column"} justifyContent={"space-between"} sx={{ boxShadow: 3, borderRadius: 2, bgcolor: "white" }}>
      <Grid container item gap={1} direction={"column"}>
        <Grid container item gap={1} direction={"row"}>
          <Typography sx={{ fontSize: "12px" }}>현재 상태 :</Typography>
          <Grid item sx={{ width: "40px", textAlign: "center" }}>
            <Typography
              sx={{
                backgroundColor: detailModalContent?.schedule.status === "beforeVisit" ? "#b8b8ff" : detailModalContent?.schedule.status === "onVisit" ? "#655dc6" : "gray",
                fontSize: "12px",
                color: "white",
                borderRadius: "10px",
              }}
            >
              {detailModalContent?.schedule.status === "beforeVisit"
                ? " 예약"
                : detailModalContent?.schedule.status === "onVisit"
                ? " 방문"
                : detailModalContent?.schedule.status === "wait"
                ? "대기중"
                : " 귀가"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: "12px" }}>생년월일 : {detailModalContent?.customer.birthDate}</Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: "12px" }}>담당 상담사 : {detailModalContent?.customer.counselor || "미지정"}</Typography>
        </Grid>
      </Grid>

      <Grid item padding={1} sx={{ border: "1px solid #ccc", borderRadius: "5px", height: "70%", overflow: "auto" }}>
        <Typography sx={{ fontSize: "12px", color: detailModalContent?.customer.memo ?? "gray" }}>{detailModalContent?.customer.memo ?? "메모가 없습니다"}</Typography>
      </Grid>
    </Grid>
  );
};

export default CardCustomerDetails;
