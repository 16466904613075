import React, { useState } from "react";
import { Grid, Typography, Button, Box, TextField, MenuItem } from "@mui/material";
import { buildings } from "../../../models/RoomModel";
import { parseBuildingsToFloors, parseBuildingsToRooms } from "../../../util/Function";
import { RegisterVisitModalProps } from "../Model";

const RegisterVisitModal: React.FC<RegisterVisitModalProps> = ({
  scheduleId,
  customerName,

  onClickVisitCustomer,
  onClose,
  initialBuilding,
  initialFloor,
  initialRoom,
  waiting,
  onClickReturnCustomer,
  onChangeWaitingList,
}) => {
  const [building, setBuilding] = useState(initialBuilding);
  const [floor, setFloor] = useState(initialFloor);
  const [room, setRoom] = useState(initialRoom);
  // 건물 선택 변경 시 층과 방 초기화
  const onChangeBuildingChange = (e: any) => {
    const selectedBuilding = e.target.value;
    setBuilding(selectedBuilding);
    setFloor("");
    setRoom("");
  };

  // 층 선택 변경 시 방 초기화
  const onChangeFloorChange = (e: any) => {
    const selectedFloor = e.target.value;
    setFloor(selectedFloor);
    setRoom("");
  };
  return (
    <>
      <Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
        {customerName} 고객님의 룸을 할당하시겠습니까?
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField variant="standard" select label="본관/신관" value={building} onChange={onChangeBuildingChange} fullWidth>
            {buildings.map((b) => (
              <MenuItem key={b.id} value={b.name}>
                {b.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField variant="standard" select label="층 선택" value={floor} onChange={onChangeFloorChange} fullWidth disabled={!building}>
            {parseBuildingsToFloors(buildings, building || "").map((f) => (
              <MenuItem key={f.id} value={f.name}>
                {f.name || ""}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField variant="standard" select label="룸 선택" value={room} onChange={(e) => setRoom(e.target.value)} fullWidth disabled={!floor}>
            {parseBuildingsToRooms(buildings, building || "", floor || "").map((r) => (
              <MenuItem key={r.id} value={r.name}>
                {r.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        {/* 상황에 따라 실행되는 함수 변경 */}
        <Button
          onClick={() => {
            if (waiting) {
              if (window.confirm("고객님이 귀가하셨습니까?")) {
                onClickReturnCustomer?.(scheduleId);
              }
            } else {
              onClickVisitCustomer(scheduleId);
            }
          }}
        >
          {waiting ? "귀가" : "미지정"}
        </Button>
        {/* 상황에 따라 실행되는 함수 변경 */}
        <Box>
          <Button
            variant="contained"
            onClick={() => {
              if (waiting) {
                onChangeWaitingList?.(scheduleId, building, floor, room); // 적절한 인자 전달
              } else {
                onClickVisitCustomer(scheduleId, building, floor, room);
              }
            }}
            disabled={room.includes("미지정")}
          >
            확인
          </Button>
          <Button onClick={onClose} sx={{ marginLeft: "5px" }}>
            취소
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default RegisterVisitModal;
