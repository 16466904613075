import React, { createContext, useContext, useState, ReactNode } from "react";
import { LanguageType } from "./Model";

interface SurgeryContextType {
  language: LanguageType;
  onChangeLanguage: (language: LanguageType) => void;

  searchTerms: string;
  setSearchTerms: React.Dispatch<React.SetStateAction<string>>;
}
export const SurgeryContext = createContext<SurgeryContextType | undefined>(undefined);

export const SurgeryLocalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<LanguageType>("한국어");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const onChangeLanguage = (language: LanguageType) => {
    setLanguage(language);
  };
  return <SurgeryContext.Provider value={{ language, onChangeLanguage, searchTerms, setSearchTerms }}>{children}</SurgeryContext.Provider>;
};

export const useLocalContext = (): SurgeryContextType => {
  const context = useContext(SurgeryContext);
  if (context === undefined) {
    throw new Error("useLocalContext must be used within an ProductLocalProvider");
  }
  return context;
};
