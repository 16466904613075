import axios from "axios";
import { handleServiceApiError } from "../util/Function";
import { Surgery } from "../models/SurgeryModel";

const surgeryApiUrl = "/api/v1/surgery";

const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});
export const fetchSurgery = async () => {
  try {
    const response = await apiClient.post(`${surgeryApiUrl}`, {});
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const registerSurgery = async (surgery: Surgery) => {
  try {
    const response = await apiClient.post(`${surgeryApiUrl}/register`, surgery);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const updateSurgery = async (surgery: Surgery) => {
  try {
    const response = await apiClient.post(`${surgeryApiUrl}/update`, surgery);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const deleteSurgery = async (surgeryId: number) => {
  try {
    const response = await apiClient.post(`${surgeryApiUrl}/delete`, { surgeryId: surgeryId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const uploadSurgeryExcel = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await apiClient.post(`${surgeryApiUrl}/register/excel`, formData, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
