import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  TextField,
  Modal,
  Typography,
  Card,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Checkbox,
  IconButton,
  Chip,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  InputAdornment,
} from "@mui/material";
import { useProduct } from "../../../context/ProductContext";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useLocalContext } from "../Context";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Product, initialValue, ProductOption } from "../../../models/ProductModel";
import { replaceNullWithEmpty } from "../../../util/Function";
import { useSurgery } from "../../../context/SurgeryContext";
import { Surgery } from "../../../models/SurgeryModel";

interface SurgeryItem {
  surgeryId: number;
  quantity: number;
  surgeryName: string;
  optionId: number;
}

const modalSx = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 920,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "row", // 수평 정렬
  p: 4,
};

const RegisterFormModal: React.FC<{}> = () => {
  const { openForm, setOpenForm, isEdit, setIsEdit } = useLocalContext();
  const { surgeryList } = useSurgery();
  const { addProduct, updateProduct } = useProduct();
  const [optionNum, setOptionNum] = useState<number>(1);
  const [selectedSurgeryItem, setSelectedSurgeryItem] = useState<SurgeryItem[]>([]);
  const [formValues, setFormValues] = useState<Product>(initialValue);
  const [currentOptionId, setCurrentOptionId] = useState<number | null>(null);
  //검색 키워드
  const [keyword, setKeyword] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  // 검색어 입력 핸들러
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  /// 검색어 삭제 핸들러
  const onChangeDelete = () => {
    setSearchQuery(""); // 검색어 초기화
    setKeyword("");
  };

  //엔터 칠 때 필터링되도록 하는 핸들러
  const onKeyDownSearch = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // 엔터를 누르면 필터링된 결과를 업데이트
      setSearchQuery(event.target.value?.trim());
    }
  };

  // 옵션 목록 리스트 추가 핸들러
  const onClickAddProductOption = () => {
    const newId = formValues.options.length > 0 ? Math.max(...formValues.options.map((option) => option.groupId)) + 1 : 0;
    setFormValues((prevValues) => ({
      ...prevValues,
      options: [
        ...prevValues.options,
        {
          groupId: newId,
          selectNum: 1,
          values: [],
        },
      ],
    }));
  };

  // 선택한 리스트 수량 수정 핸들러
  const onChangeListQuantity = (surgeryId: number, isIncrease: boolean) => {
    setSelectedSurgeryItem((prev) => {
      return prev.map((item) => {
        if (item.surgeryId === surgeryId && item.optionId === currentOptionId) {
          return {
            ...item,
            quantity: isIncrease ? item.quantity + 1 : Math.max(1, item.quantity - 1),
          };
        }
        return item;
      });
    });
  };

  // 체크박스 체크 핸들러
  const onChangeCheckItem = (item: Surgery) => {
    setSelectedSurgeryItem((prev) => {
      // 현재 옵션에서 해당 아이템이 이미 존재하는지 확인
      const existingItemIndex = prev.findIndex((i) => i.surgeryId === item.surgeryId && i.optionId === currentOptionId);
      // 이미 존재하면 해당 아이템 제거 (체크 해제)
      if (existingItemIndex !== -1) {
        return prev.filter((_, index) => index !== existingItemIndex);
      }
      if (item.surgeryId != null && currentOptionId != null) {
        return [
          ...prev,
          {
            surgeryId: item.surgeryId,
            quantity: 1,
            surgeryName: item.surgeryNameKr,
            optionId: currentOptionId,
          },
        ];
      }
      return prev;
    });
  };

  // 선택한 옵션(검색 박스 옵션) 삭제 핸들러
  const onClickRemoveItem = (surgeryId: number) => {
    setSelectedSurgeryItem((prev) => prev.filter((item) => !(item.surgeryId === surgeryId && item.optionId === currentOptionId)));
  };

  const getSurgeryNameById = (id: number): string | undefined => {
    const surgery = surgeryList.find((surgery) => surgery.surgeryId === id);
    return surgery ? surgery.surgeryNameKr : undefined;
  };

  //옵션 목록 리스트 삭제 핸들러
  const removeProductOption = (id: number) => {
    setSelectedSurgeryItem([]);
    setFormValues((prevValues) => ({
      ...prevValues,
      options: prevValues.options.filter((option) => option.groupId !== id),
    }));
  };
  // 선택 옵션 수 입력하는 핸들러
  const onChangeSelectNum = (value: number) => {
    setOptionNum(value);
  };
  const saveOptionItems = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      options: prevValues.options.map((option) =>
        option.groupId === currentOptionId
          ? {
              ...option,
              selectNum: optionNum,
              values: selectedSurgeryItem.filter((item) => item.optionId === currentOptionId).flatMap((item) => Array(item.quantity).fill(item.surgeryId)),
            }
          : option
      ),
    }));
  };
  // 검색후 필터링한 검색 리스트
  const filteredSurgery = useMemo(() => {
    return surgeryList.filter((surgery) => surgery.surgeryNameKr.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [surgeryList, searchQuery]);
  // 추가 리스트 메모리제이션
  const currentOptionItems = useMemo(() => {
    return selectedSurgeryItem.filter((item) => item.optionId === currentOptionId);
  }, [selectedSurgeryItem, currentOptionId]);

  const onClickEditOption = (item: ProductOption) => {
    setCurrentOptionId(item?.groupId);

    // values 배열의 요소들을 reduce를 사용하여 중복 처리
    const selectedItems = item.values.reduce<SurgeryItem[]>((acc, id) => {
      const surgeryInfo = surgeryList.find((surgery) => surgery.surgeryId === id);

      // 이미 같은 이름의 수술이 있는지 확인
      const existingItemIndex = acc.findIndex((item) => item.surgeryId === id);

      if (existingItemIndex !== -1) {
        // 이미 존재하는 경우 quantity 증가
        acc[existingItemIndex].quantity += 1;
      } else {
        acc.push({
          surgeryId: surgeryInfo?.surgeryId!,
          surgeryName: surgeryInfo?.surgeryNameKr!,
          quantity: 1,
          optionId: item.groupId,
        });
      }

      return acc;
    }, []);
    setSelectedSurgeryItem(selectedItems);
  };

  // 기존 코드
  // useEffect(() => {
  //   if (isEdit.type) {
  //     setFormValues(replaceNullWithEmpty(isEdit.item));
  //   }
  // }, [isEdit]);

  // 수정 시 기존 데이터 가져오기(option추가 안되서)
  useEffect(() => {
    if (isEdit.type) {
      const editedItem = replaceNullWithEmpty(isEdit.item);
      // options 속성이 없거나 undefined인 경우 빈 배열 추가
      if (!editedItem.options) {
        editedItem.options = [];
      }
      setFormValues(editedItem);
    }
  }, [isEdit]);

  // 전송 핸들러
  const onSubmitRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    formValues.startDate = new Date(formValues.startDate!);
    if (formValues.endDate) {
      formValues.endDate = new Date(formValues.endDate);
    }
    formValues.priceWithoutVat = formValues.priceWithoutVat!.toString();
    formValues.priceWithVat = formValues.priceWithVat!.toString();

    isEdit.type === true ? updateProduct(formValues) : addProduct(formValues);

    setOpenForm(false);
    setFormValues(initialValue);
  };

  // 폼 닫기 핸들러
  const onClickCloseForm = useCallback(() => {
    if (formValues !== initialValue && window.confirm("종료하시겠습니까?") === false) return;
    setOptionNum(1);
    setOpenForm(false);
    setFormValues(initialValue);
    setCurrentOptionId(null);
    setSelectedSurgeryItem([]);
    setIsEdit({ type: false, item: initialValue });
  }, [formValues, setOpenForm, setIsEdit]);

  // 폼 입력값 제어 핸들러
  const onChangeFormValue = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value: inputValue } = event.target;
    let value = inputValue;

    if (name === "priceWithoutVat" || name === "priceWithVat") {
      value = value.replace(/[^0-9]/g, "");
    }

    setFormValues((prevValues) => {
      let updatedValues = { ...prevValues };
      if (name === "priceWithoutVat") {
        updatedValues.priceWithoutVat = value;
        updatedValues.priceWithVat = Math.round(Number(value) * 1.1);
      } else if (name === "priceWithVat") {
        updatedValues.priceWithVat = value;
        updatedValues.priceWithoutVat = Math.round(Number(value) / 1.1);
      } else {
        updatedValues[name] = value;
      }
      return updatedValues;
    });
  }, []);

  return (
    <Modal open={openForm} onClose={onClickCloseForm} aria-labelledby="register-modal" aria-describedby="form-modal">
      <Card sx={modalSx}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
          <Typography id="modal-title" variant="h2" component="h2" sx={{ textAlign: "center" }}>
            상품 추가
          </Typography>
          <Grid container spacing={2}>
            {/* Product Addition Table */}
            <Grid item xs={12} md={7}>
              <Box component="form" onSubmit={onSubmitRegister} display="flex" flexDirection="column" gap={2.6}>
                <FormControl>
                  <RadioGroup row aria-labelledby="category" name="category" value={formValues.category} onChange={onChangeFormValue}>
                    <FormControlLabel value="product" control={<Radio />} label="상품" />
                    <FormControlLabel value="promotion" control={<Radio />} label="프로모션" />
                  </RadioGroup>
                </FormControl>
                <Box display="flex" flexDirection="row" gap={2}>
                  <TextField name="categoryLarge" size="small" required fullWidth label="대카테고리" onChange={onChangeFormValue} value={formValues.categoryLarge} />
                  <TextField name="categoryMedium" size="small" required fullWidth label="중카테고리" onChange={onChangeFormValue} value={formValues.categoryMedium} />
                </Box>
                <TextField name="name" size="small" required fullWidth label="상품명" onChange={onChangeFormValue} value={formValues.name} />
                <Box display="flex" flexDirection="row" gap={2}>
                  <TextField
                    name="priceWithoutVat"
                    size="small"
                    onChange={onChangeFormValue}
                    value={formValues.priceWithoutVat ? new Intl.NumberFormat().format(Number(formValues.priceWithoutVat)) : ""}
                    required
                    fullWidth
                    label="금액(VAT 미포함)"
                  />
                  <TextField
                    name="priceWithVat"
                    size="small"
                    required
                    onChange={onChangeFormValue}
                    value={formValues.priceWithVat ? new Intl.NumberFormat().format(Number(formValues.priceWithVat)) : ""}
                    fullWidth
                    label="금액(VAT 포함)"
                  />
                </Box>
                <Box display="flex" flexDirection="row" gap={2} mt="4px">
                  <TextField
                    type="date"
                    name="startDate"
                    inputProps={{ max: "9999-12-31" }}
                    size="small"
                    onChange={onChangeFormValue}
                    required
                    fullWidth
                    value={formValues.startDate ?? ""}
                    label="판매 시작날짜"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    type="date"
                    name="endDate"
                    value={formValues.endDate ?? ""}
                    inputProps={{ max: "9999-12-31" }}
                    size="small"
                    onChange={onChangeFormValue}
                    fullWidth
                    label="판매 종료날짜"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <TextField name="description" rows={2} multiline size="small" fullWidth label="세부사항" onChange={onChangeFormValue} value={formValues.description} />
                <TableContainer sx={{ border: "1px solid #BDBDBD", overflowY: "auto", height: "15.5vh", borderRadius: "4px" }}>
                  <Table size="small" stickyHeader>
                    <TableHead sx={{ backgroundColor: "#e1e1f5", overflow: "auto" }}>
                      <TableRow>
                        <TableCell sx={{ width: "15%" }} align="center">
                          목차
                        </TableCell>
                        <TableCell align="center" sx={{ width: "40%" }}>
                          선택 옵션
                        </TableCell>
                        <TableCell align="center">선택 개수</TableCell>
                        <TableCell sx={{ width: "20%" }} align="center">
                          <Button
                            onClick={onClickAddProductOption}
                            sx={{
                              p: 0,
                              fontSize: "12px",
                            }}
                          >
                            옵션 추가
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formValues.options.map((item) => (
                        <TableRow key={item.groupId} sx={{ bgcolor: item.groupId === currentOptionId ? "#e1e1f5" : "white" }}>
                          <TableCell align="center">{item.groupId + 1}</TableCell>
                          <TableCell align="center">
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, justifyContent: "center" }}>
                              {item.values.map((value, index) => (
                                <Chip
                                  key={index}
                                  label={getSurgeryNameById(value)}
                                  size="small"
                                  sx={{
                                    fontSize: "12px",
                                    height: "20px",
                                    backgroundColor: "#f5f5f5",
                                  }}
                                />
                              ))}
                            </Box>
                          </TableCell>
                          <TableCell align="center">{item.selectNum}</TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <EditRoundedIcon
                                onClick={() => onClickEditOption(item)}
                                sx={{
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                              />
                              <ClearIcon fontSize="small" onClick={() => removeProductOption(item?.groupId)} sx={{ cursor: "pointer" }} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>

            {/* Search Box */}
            <Grid item xs={12} md={5}>
              <Grid item container sx={{ bgcolor: "#F2F2F2", height: "100%", p: 2, borderRadius: "8px", display: "flex", flexDirection: "column" }} gap={1.3}>
                <Grid item>
                  <TextField
                    fullWidth
                    size="small"
                    label="검색"
                    disabled={currentOptionId === null ? true : false}
                    value={keyword}
                    onChange={onChangeInput}
                    onKeyDown={onKeyDownSearch}
                    InputProps={{
                      endAdornment: keyword && (
                        <InputAdornment position="end">
                          <IconButton onClick={onChangeDelete} size="small">
                            <ClearIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    overflowY: "auto",
                    bgcolor: "white",
                    height: "60%",
                    p: 1.5,
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {currentOptionId === null
                    ? null
                    : filteredSurgery.map((surgery) => (
                        <Box key={surgery.surgeryId} display="flex" alignItems="center">
                          <Checkbox
                            size="small"
                            checked={selectedSurgeryItem.some((item) => item.surgeryId === surgery.surgeryId && item.optionId === currentOptionId)}
                            onChange={() => onChangeCheckItem(surgery)}
                          />
                          <Typography sx={{ ml: 1 }}>{surgery.surgeryNameKr}</Typography>
                        </Box>
                      ))}
                </Grid>

                <Grid
                  item
                  sx={{
                    height: "27%",
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    p: 1.5,
                    bgcolor: "white",
                    borderRadius: "8px",
                    overflowY: "auto",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", pb: 2 }}>
                    <Grid sx={{ fontSize: "13px", display: "flex", alignItems: "center" }}>
                      선택 가능한 옵션 수:
                      <TextField
                        size="small"
                        type="number"
                        value={optionNum}
                        onChange={(e) => {
                          const value = parseInt(e.target.value) || 1;
                          onChangeSelectNum(value);
                        }}
                        inputProps={{
                          min: 1,
                          max: 99,
                        }}
                        sx={{
                          width: "50px",
                          ml: 1,
                          "& input": {
                            padding: "1px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Button
                      sx={{
                        minWidth: "unset",
                        fontSize: "12px",
                        padding: "2px 12px",
                      }}
                      onClick={saveOptionItems}
                    >
                      저장
                    </Button>
                  </Grid>
                  <Grid sx={{ overflow: "auto", justifyContent: "flex-start", width: "100%" }}>
                    {currentOptionItems.map((item, index) => (
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",

                          pb: 1,
                          borderBottom: "1px solid #E0E0E0",
                        }}
                        key={index}
                      >
                        <Grid sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "space-between", width: "100%", p: 0.2 }}>
                          <Typography sx={{ fontSize: "13px" }}>{item.surgeryName}</Typography>
                          <Grid sx={{ display: "flex", alignItems: "center", gap: 1, border: "1px solid #E0E0E0", borderRadius: "5px", p: 0.2 }}>
                            <RemoveIcon sx={{ fontSize: "13px" }} onClick={() => onChangeListQuantity(item.surgeryId, false)} />
                            <Typography sx={{ fontSize: "13px" }}>{item.quantity || 1}</Typography>
                            <AddIcon sx={{ fontSize: "13px" }} onClick={() => onChangeListQuantity(item.surgeryId, true)} />
                          </Grid>
                        </Grid>
                        <ClearIcon sx={{ fontSize: "13px", ml: 1 }} onClick={() => onClickRemoveItem(item.surgeryId)} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Save and Close Buttons */}
          <Box display="flex" justifyContent="center" gap={2} mt={2}>
            <Button type="submit" onClick={onSubmitRegister}>
              저장
            </Button>
            <Button onClick={onClickCloseForm} sx={{ backgroundColor: "lightgray" }}>
              닫기
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default React.memo(RegisterFormModal);
