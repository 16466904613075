import React from "react";
import { Grid } from "@mui/material";
import { AccountLocalProvider } from "./Context";
import UserInfo from "./UserInfo";

const Account: React.FC = () => {
  return (
    <AccountLocalProvider>
      <Grid container width="100%" height="100%" sx={{ padding: "10px" }}>
        <UserInfo />
      </Grid>
    </AccountLocalProvider>
  );
};

export default Account;
