import React, { useState } from "react";
import { Grid, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Checkbox } from "@mui/material";
import { formatDate, formatPhoneNumber } from "../../util/Function";
import { Counselor } from "../../models/CounselorModel";
import { useCounselor } from "../../context/CounselorContext";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import { useLocalContext } from "./Context";

interface CounselorListProps {
  setIsAddCounselor: (newState: boolean) => void;
}

const ColumnList = [
  { name: "이름", width: 10 },
  { name: "국적", width: 10 },
  { name: "전화번호", width: 18 },
  { name: "퇴사날짜", width: 10 },
];

const CounselorList: React.FC<CounselorListProps> = ({ setIsAddCounselor }) => {
  const { counselorList } = useCounselor();
  const { setSelectedCounselor, selectedCounselor } = useLocalContext();
  const [isShowExitedCounselor, setIsShowExitedCounselor] = useState(false);

  // 직원 목록의 행 클릭 핸들러
  const onClickRow = (counselor: Counselor) => {
    setIsAddCounselor(false);
    setSelectedCounselor(counselor);
  };

  return (
    <Grid container direction="column" sx={{ height: "100%", flexGrow: 1 }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left", marginLeft: "4px" }}>
          상담사 목록
        </Typography>
        <Grid sx={{ position: "absolute", right: 0, display: "flex", marginRight: "4px" }}>
          <Checkbox size="small" sx={{ padding: "0px", marginRight: "2px" }} checked={isShowExitedCounselor} onChange={(event) => setIsShowExitedCounselor(event.target.checked)} />
          <Typography sx={{ fontSize: "14px" }}>퇴사자 보기</Typography>
        </Grid>
        <Grid sx={{ position: "absolute", right: 100, display: "flex", marginRight: "4px" }}></Grid>
        <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={() => setIsAddCounselor(true)}>
          <PersonAddAlt1RoundedIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </Grid>

      <Paper sx={{ flexGrow: 1, height: 0, margin: "4px 0px 0px 4px", boxShadow: 3, borderRadius: "8px" }}>
        <TableContainer sx={{ maxHeight: "100%", borderRadius: "8px 8px 0px 0px" }}>
          <Table
            stickyHeader
            sx={{
              "& .MuiTableCell-root": {
                padding: "4px",
                textAlign: "center",
                verticalAlign: "middle",
                fontSize: "11px",
              },
            }}
          >
            <TableHead sx={{ top: 0, position: "sticky" }}>
              <TableRow sx={{ backgroundColor: "#e1e1f5" }}>
                {ColumnList.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      width: `${column.width}%`,
                      textAlign: "center",
                      paddingBottom: "0px",
                      fontWeight: "bold",
                      background: "#e1e1f5",
                    }}
                  >
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {counselorList
                .filter((counselor) => isShowExitedCounselor || counselor.exitDate === null)
                .map((counselors) => {
                  const isActive = counselors.exitDate === null;
                  return (
                    <TableRow
                      key={counselors.counselorId}
                      onClick={() => onClickRow(counselors)}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: selectedCounselor?.counselorId === counselors.counselorId ? "#e8dff1" : "#ffffff",
                        "&:hover": {
                          backgroundColor: "#f0f0fb",
                        },
                      }}
                    >
                      <TableCell>{counselors.name}</TableCell>
                      <TableCell>{counselors?.nationality}</TableCell>
                      <TableCell>{formatPhoneNumber(counselors.phoneNumber)}</TableCell>
                      <TableCell sx={{ color: isActive ? "#655dc6" : "rgba(0,0,0,0.87)" }}>{isActive ? "재직중" : formatDate(counselors.exitDate)}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default CounselorList;
