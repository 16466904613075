import { Box, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useLocalContext } from "./Context";

interface ProductDetailModalProps {
  open: boolean;
  onClose: () => void;
}

function ProductDetailModal({ open, onClose }: ProductDetailModalProps) {
  const { selectProduct } = useLocalContext();

  return (
    <Modal open={open} onClose={onClose} sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box sx={{ width: "100%", maxWidth: "500px", backgroundColor: "white", borderRadius: "8px", height: "auto", p: 2 }}>
        <Box component="form" display="flex" flexDirection="column" gap={2.6}>
          <Typography variant="h2">상세 내용</Typography>
          <FormControl>
            <RadioGroup row aria-labelledby="category" name="category" value={selectProduct?.category}>
              <FormControlLabel value="product" control={<Radio />} label="상품" />
              <FormControlLabel value="promotion" control={<Radio />} label="프로모션" />
            </RadioGroup>
          </FormControl>
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography>대 카테고리 : {selectProduct?.categoryLarge}</Typography>
            <Typography>중 카테고리 : {selectProduct?.categoryMedium}</Typography>
          </Box>
          <Typography>상품 명: {selectProduct?.name}</Typography>
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography>금액(VAT 포함) : {selectProduct?.priceWithVat}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={2} mt="4px">
            <Typography>판매 시작날짜 :{selectProduct?.endDate ? selectProduct.endDate.toLocaleDateString() : "No Date"}</Typography>
            <Typography>판매 종료날짜 :{selectProduct?.endDate ? selectProduct.endDate.toLocaleDateString() : "No Date"}</Typography>
          </Box>
          <TableContainer sx={{ border: "1px solid #BDBDBD", overflowY: "auto", height: "15.5vh", borderRadius: "4px" }}>
            <Table size="small" stickyHeader>
              <TableHead sx={{ backgroundColor: "#e1e1f5", overflow: "auto" }}>
                <TableRow>
                  <TableCell sx={{ width: "15%" }} align="center">
                    목차
                  </TableCell>
                  <TableCell align="center" sx={{ width: "40%" }}>
                    선택 옵션
                  </TableCell>
                  <TableCell align="center">선택 개수</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{/* 여기에 백엔드연결 이후 추가 */}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Modal>
  );
}

export default ProductDetailModal;
