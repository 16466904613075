import React, { useCallback } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Button, Box } from "@mui/material";
import Search from "./Search";
import { useLocalContext } from "./Context";

const AllSupplies: React.FC = () => {
  const { filterProductData, onClickDetailModalOpen } = useLocalContext();

  return (
    <>
      <Grid
        item
        sx={{
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Paper sx={{ height: "100%" }}>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 48px)", // 화면에 따라 유연한 높이 설정
              overflowY: "auto", // 스크롤 활성화
              "& .MuiTableCell-root": {
                // 모든 테이블 셀에 적용
                padding: "4px 8px", // 패딩 축소
                height: "32px", // 셀 높이 축소
                textAlign: "center", // 텍스트 가운데 정렬
                fontSize: "0.875rem", // 글자 크기 설정 (선택사항)
              },
              "& .MuiTableCell-head": {
                backgroundColor: "#e1e1f5",
              },
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>대카테고리</TableCell>
                  <TableCell>소카테고리</TableCell>
                  <TableCell>상품명</TableCell>
                  <TableCell>상품코드</TableCell>
                  <TableCell>자체코드</TableCell>
                  <TableCell>잔여재고</TableCell>
                  <TableCell>위치</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterProductData.map((item: any) => (
                  <TableRow
                    key={item.id}
                    onClick={() => onClickDetailModalOpen(item)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04) !important", // 호버 시 배경색
                      },
                    }}
                  >
                    <TableCell>{item.mainCategory}</TableCell>
                    <TableCell>{item.subCategory}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.productCode}</TableCell>
                    <TableCell>{item.customCode}</TableCell>
                    <TableCell>{item.stock}</TableCell>
                    <TableCell>{item.location}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
};

export default AllSupplies;
