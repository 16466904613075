import React from "react";
import { NavLink } from "react-router-dom";
import { Drawer, List, ListItem, ListItemIcon, Tooltip, Typography, Grid, Button } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useLocation } from "react-router-dom";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Box from "@mui/material/Box";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import InventoryIcon from "@mui/icons-material/Inventory";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { useAuth } from "../context/AuthContext";

export const sidebarWidth = 110;

const drawerSx = {
  flexShrink: 0,
  paddingTop: "20px",
  paddingBottom: "50px",
  [`& .MuiDrawer-paper`]: {
    backgroundColor: "#655dc6",
    color: "#fff",
    width: sidebarWidth,
    height: "100vh",
    boxSizing: "border-box",
  },
};
const gridItemSx = {
  display: "flex",
  alignItems: "center",
  p: 0.5,
  ml: 1.4,
  borderLeft: "1.5px solid #b0b0b0",
  textDecoration: "none",
  "&.active": {
    "& .MuiTypography-root": {
      color: "white",
      fontWeight: "900",
    },
  },
};
const listItemSx = {
  display: "flex",
  justifyContent: "flex-start",
  color: "#E6E6E6",
  textDecoration: "none",
  fontSize: "12px",
  padding: "0px 4px 2px 14px",
};

// 현재 경로에 따라 아이콘 색상을 설정하는 함수
const getIconColor = (path: string, currentPath: string) => {
  return path === currentPath ? "#fff" : "#BDBDBD";
};

const Sidebar: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { user, logout } = useAuth();

  return (
    <Drawer //Material-UI의 Drawer 컴포넌트 사용
      variant="permanent" //항상 화면에 고정되도록 설정
      anchor="left" //항상 왼쪽에 고정되도록 설정
      sx={drawerSx}
    >
      <List sx={{ paddingTop: "10px" }}>
        <Grid sx={{ color: "white", display: "flex", alignItems: "center", p: 0.5 }} gap={1.3}>
          <HomeRoundedIcon sx={{ fontSize: "16px" }} />
          <Typography sx={{ fontSize: "14px" }}>홈화면</Typography>
        </Grid>
        <Grid>
          <Grid component={NavLink} to="/home" sx={gridItemSx}>
            <Typography sx={listItemSx}>대시보드</Typography>
          </Grid>
          <Grid component={NavLink} to="/schedule" sx={gridItemSx}>
            <Typography sx={listItemSx}>스케줄 차트</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ color: "white", display: "flex", alignItems: "center", p: 0.5 }} gap={1.3}>
          <LocalHospitalRoundedIcon sx={{ fontSize: "16px" }} />
          <Typography sx={{ fontSize: "14px" }}>직원 관리</Typography>
        </Grid>
        <Grid>
          <Grid component={NavLink} to="/doctor" sx={gridItemSx}>
            <Typography sx={listItemSx}>의사</Typography>
          </Grid>
          <Grid component={NavLink} to="/counselor" sx={gridItemSx}>
            <Typography sx={listItemSx}>상담사</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ color: "white", display: "flex", alignItems: "center", p: 0.5 }} gap={1.3}>
          <ScreenSearchDesktopIcon sx={{ fontSize: "16px" }} />
          <Typography sx={{ fontSize: "14px" }}>시술 물품</Typography>
        </Grid>
        <Grid>
          <Grid component={NavLink} to="/device" sx={gridItemSx}>
            <Typography sx={listItemSx}>장비</Typography>
          </Grid>
          <Grid component={NavLink} to="/supply" sx={gridItemSx}>
            <Typography sx={listItemSx}>소모품</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ color: "white", display: "flex", alignItems: "center", p: 0.5 }} gap={1.3}>
          <ChromeReaderModeIcon sx={{ fontSize: "16px" }} />
          <Typography sx={{ fontSize: "14px" }}>판매목록</Typography>
        </Grid>
        <Grid>
          <Grid component={NavLink} to="/product" sx={gridItemSx}>
            <Typography sx={listItemSx}>상품</Typography>
          </Grid>
          <Grid component={NavLink} to="/surgery" sx={gridItemSx}>
            <Typography sx={listItemSx}>시술</Typography>
          </Grid>
        </Grid>

        <Grid sx={{ color: "white", display: "flex", alignItems: "center", p: 0.5 }} gap={1.3}>
          <GroupsRoundedIcon sx={{ fontSize: "16px" }} />
          <Typography sx={{ fontSize: "14px" }}>고객 관리</Typography>
        </Grid>
        <Grid>
          <Grid component={NavLink} to="/customer" sx={gridItemSx}>
            <Typography sx={listItemSx}>고객목록</Typography>
          </Grid>
        </Grid>
        {(user?.authRole === "developer" || user?.authRole === "admin") && (
          <>
            <Grid sx={{ color: "white", display: "flex", alignItems: "center", p: 0.5 }} gap={1.3}>
              <BadgeRoundedIcon sx={{ fontSize: "16px" }} />
              <Typography sx={{ fontSize: "14px" }}>관리자</Typography>
            </Grid>
            <Grid>
              <Grid component={NavLink} to="/employee" sx={gridItemSx}>
                <Typography sx={listItemSx}>계정 관리</Typography>
              </Grid>
            </Grid>
          </>
        )}
      </List>
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          p: 1,
        }}
      >
        <Tooltip title="내 정보" placement="top">
          <ListItem component={Link} to="/account" style={{ color: getIconColor("/account", currentPath) }} sx={{ p: 0 }}>
            <AccountCircleIcon sx={{ fontSize: 30 }} style={{ color: getIconColor("/account", currentPath) }} />
          </ListItem>
        </Tooltip>
        <Button onClick={logout} sx={{ fontSize: "13px", width: "100%", textAlign: "center", textTransform: "none", backgroundColor: "#655dc6", color: "#fff" }}>
          로그아웃
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
