import React, { useState } from "react";
import { Box, Button, TextField, Modal, Typography, Card } from "@mui/material";
import { useProduct } from "../../../context/ProductContext";
import { Product } from "../../../models/ProductModel";

const modalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface EndDateFormModalProps {
  openForm: boolean;
  closeForm: React.Dispatch<React.SetStateAction<boolean>>;
  item: Product;
}

const EndDateFormModal: React.FC<EndDateFormModalProps> = ({ openForm, closeForm, item }) => {
  const [endDateValues, setEndDateValues] = useState<string>("");
  const { endProduct } = useProduct();

  // 전송 핸들러
  const onSubmitEndDate = async (event: React.FormEvent) => {
    if (window.confirm("종료 날짜를 저장하시겠습니까?") === false) return;

    event.preventDefault();

    let newProduct = { ...item, endDate: new Date(endDateValues) };
    endProduct(newProduct);
    closeForm(false); // 폼 닫기
    setEndDateValues("");
  };

  // 폼 닫기 핸들러
  const onClickCloseForm = () => {
    // 폼이 채워져 있을 때
    if (endDateValues !== "") {
      if (window.confirm("종료하시겠습니까?") === false) return;
    }
    closeForm(false); // 폼 닫기
    setEndDateValues(""); // 폼 초기화
  };

  // 폼 입력값 제어 핸들러
  const onChangeFormValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEndDateValues(event.target.value);
  };

  return (
    <Modal open={openForm} onClose={onClickCloseForm} aria-labelledby="register-modal" aria-describedby="form-modal">
      <Card sx={modalSx}>
        <Typography id="modal-title" variant="h2" component="h2">
          종료 날짜 설정
        </Typography>
        <Box component="form" onSubmit={onSubmitEndDate} padding={1}>
          <Box padding={3}>
            <TextField
              type="date"
              name="endDate"
              inputProps={{ max: "9999-12-31" }}
              size="small"
              onChange={onChangeFormValue}
              value={endDateValues}
              fullWidth
              required
              label="판매 종료날짜"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          <Box display={"flex"} justifyContent={"center"} gap={1}>
            <Button type="submit">제출</Button>
            <Button onClick={onClickCloseForm} sx={{ backgroundColor: "lightgray" }}>
              닫기
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default React.memo(EndDateFormModal);
