import { handleControllerResponse } from "../../util/Function";
import { fetchCustomer, fetchCustomerByCustomerId } from "./Service";

class CustomerController {
  async handleFetchCustomer(page: number, size: number) {
    const handleFetchCustomerResponse = await fetchCustomer(page, size);
    return handleControllerResponse(handleFetchCustomerResponse.status, handleFetchCustomerResponse.data);
  }
  async handleFetchCustomerByCustomerId(customerId: string) {
    const handleFetchCustomerByCustomerIdResponse = await fetchCustomerByCustomerId(customerId);
    return handleControllerResponse(handleFetchCustomerByCustomerIdResponse.status, handleFetchCustomerByCustomerIdResponse.data);
  }
}

export const customerController = new CustomerController();
