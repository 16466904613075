import { useMemo, useState } from "react";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useProduct } from "../../context/ProductContext";
import { useLocalContext } from "./Context";
import { Product } from "../../models/ProductModel";
import EndDateFormModal from "./MainTable/EndDateFormModal";
import { LanguageType } from "./Model";

const TabPanel: React.FC<{ children?: React.ReactNode; index: number; value: number }> = ({ children, index, value }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const MainTable: React.FC<{ value: number }> = ({ value }) => {
  const { setOpenForm, setIsEdit, searchQuery, language } = useLocalContext();
  const { productList, promotionProductList } = useProduct();
  const [endDateOpenForm, setEndDateOpenForm] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Product | null>(null); // id 상태 추가

  const headers = [
    { label: "대카테고리", name: "categoryLarge", width: "10%" },
    { label: "중카테고리", name: "categoryMedium", width: "15%" },
    { label: "한국 상품명", name: "name", width: "10%" },
    { label: "상품명", name: "otherName", width: "10%" },
    { label: "금액(VAT 미포함)", name: "priceWithoutVat", width: "10%" },
    { label: "금액(VAT 포함)", name: "priceWithVat", width: "10%" },
    { label: "세부사항", name: "description", width: "15%" },
    { label: "수정", name: "edit", width: "5%" },
    { label: "시작날짜", name: "startDate", width: "8%" },
    { label: "종료날짜", name: "endDate", width: "8%" },
  ];
  const endHeaders = [
    { label: "대카테고리", name: "categoryLarge", width: "10%" },
    { label: "중카테고리", name: "categoryMedium", width: "15%" },
    { label: "한국 상품명", name: "name", width: "10%" },
    { label: "상품명", name: "otherName", width: "10%" },
    { label: "금액(VAT 미포함)", name: "priceWithoutVat", width: "10%" },
    { label: "금액(VAT 포함)", name: "priceWithVat", width: "10%" },
    { label: "세부사항", name: "description", width: "15%" },
    { label: "시작날짜", name: "startDate", width: "10%" },
    { label: "종료날짜", name: "endDate", width: "10%" },
  ];

  const fieldMap: Record<LanguageType, { name: keyof Product }> = {
    한국어: { name: "koreanName" }, //속성추가가 필요
    영어: { name: "englishName" },
    중국어: { name: "chineseName" },
    일본어: { name: "japaneseName" },
  };

  // 수정 버튼 클릭 시 수정 폼 여는 핸들러
  const onClickOpenEditForm = (item: Product) => {
    setIsEdit({ type: true, item: item });
    setOpenForm(true);
  };

  // 종료 버튼 클릭 시 종료날자 설정 모달 열기 핸들러
  const onClickCloseEditForm = (item: Product) => {
    setEndDateOpenForm(true);
    setSelectedItem(item);
  };

  // 필터링된 리스트
  const filteredProducts = useMemo(() => {
    let list;
    const today = new Date();
    if (value === 0) {
      list = promotionProductList.filter((item) => item.endDate === null || new Date(item.endDate) > today);
    } else if (value === 1) {
      list = productList.filter((item) => item.endDate === null || new Date(item.endDate) > today);
    } else if (value === 2) {
      list = promotionProductList.filter((item) => item.endDate !== null && new Date(item?.endDate) < today);
    } else {
      list = productList.filter((item) => item.endDate !== null && new Date(item?.endDate) < today);
    }
    return list.filter((item) => {
      const combinedFields = Object.values(item).join(" ").toLowerCase();
      return combinedFields.includes(searchQuery.toLowerCase());
    });
  }, [value, searchQuery, promotionProductList, productList]);

  const getLocalizedValue = (item: Product, type: "name") => {
    const field = fieldMap[language][type]; // 타입 에러 없음
    return item[field] || "";
  };

  return (
    <>
      <TabPanel value={value} index={value}>
        <TableContainer
          sx={{
            maxHeight: "calc(100vh - 48px)",
          }}
        >
          <Table
            stickyHeader
            sx={{
              "& .MuiTableCell-root": {
                borderRight: "1px solid lightgray",
              },
            }}
          >
            <TableHead>
              <TableRow>
                {(value === 0 || value === 1 ? headers : endHeaders).map((header) => (
                  <TableCell key={header.label} sx={{ textAlign: "center", width: header.width, backgroundColor: "#e1e1f5", fontWeight: "bold" }}>
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts.map((item, index) => (
                <TableRow hover key={index}>
                  {(value === 0 || value === 1 ? headers : endHeaders).map((header) => (
                    <TableCell width={header.width} sx={{ textAlign: "center", width: header.width }} key={header.label}>
                      {header.label === "수정" ? (
                        <Box display={"flex"} gap={1} justifyContent={"center"}>
                          <Button size="small" onClick={() => onClickOpenEditForm(item)} sx={{ minWidth: "40px", maxHeight: "20px" }}>
                            수정
                          </Button>
                        </Box>
                      ) : header.name === "priceWithVat" || header.name === "priceWithoutVat" ? (
                        (item[header.name] || 0).toLocaleString() // 10,000 형식으로 표시
                      ) : header.name === "endDate" && item[header.name] === null ? (
                        <Button size="small" onClick={() => onClickCloseEditForm(item)} sx={{ minWidth: "40px", maxHeight: "20px", backgroundColor: "lightgray" }}>
                          종료
                        </Button>
                      ) : header.name === "otherName" ? (
                        <>{getLocalizedValue(item, "name")}</>
                      ) : (
                        item[header.name]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <EndDateFormModal openForm={endDateOpenForm} closeForm={setEndDateOpenForm} item={selectedItem!} />
    </>
  );
};

export default MainTable;
