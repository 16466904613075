import { handleControllerResponse } from "../util/Function";
import { Surgery } from "../models/SurgeryModel";
import { deleteSurgery, fetchSurgery, registerSurgery, updateSurgery, uploadSurgeryExcel } from "../service/SurgeryService";
class SurgeryController {
  async handleFetchSurgery() {
    const handleFetchSurgeryResponse = await fetchSurgery();
    return handleControllerResponse(handleFetchSurgeryResponse.status, handleFetchSurgeryResponse.data);
  }
  async handleRegisterSurgery(surgery: Surgery) {
    const handleRegisterSurgeryResponse = await registerSurgery(surgery);
    return handleControllerResponse(handleRegisterSurgeryResponse.status, handleRegisterSurgeryResponse.data);
  }
  async handleUpdateSurgery(surgery: Surgery) {
    const handleUpdateSurgeryResponse = await updateSurgery(surgery);
    return handleControllerResponse(handleUpdateSurgeryResponse.status, handleUpdateSurgeryResponse.data);
  }
  async handleDeleteSurgery(surgeryId: number) {
    const handleDeleteSurgeryResponse = await deleteSurgery(surgeryId);
    return handleControllerResponse(handleDeleteSurgeryResponse.status, handleDeleteSurgeryResponse.data);
  }
  async handleUploadSurgeryExcel(file: File) {
    const uploadSurgeryExcelResponsse = await uploadSurgeryExcel(file);
    return handleControllerResponse(uploadSurgeryExcelResponsse.status, uploadSurgeryExcelResponsse.data);
  }
}
export const surgeryController = new SurgeryController();
