import React, { useState } from "react";
import { FaUser, FaLock } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { authController } from "../../controller/AuthController";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const { login, setUser } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const loginResponse = await authController.handleLogin(loginId, password);
      if (loginResponse?.success) {
        const token = loginResponse.data.token;
        const userData = { ...loginResponse.data };
        delete userData.password;
        delete userData.token;
        setUser(userData);
        login(token);
        navigate("/home");
      } else {
        alert(loginResponse?.message);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 16px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "0px",
      }}
    >
      <Typography
        sx={{
          width: "100%",
          fontSize: "3rem",
          textAlign: "center",
          marginBottom: "30px",
        }}
        variant="h1"
      >
        Amred Clinic
      </Typography>
      <form
        onSubmit={handleLogin}
        style={{
          alignItems: "center",
          gap: "20px",
          width: "100%",
          maxWidth: "400px",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
              boxSizing: "border-box",
              marginBottom: "10px",
            }}
          >
            <FaUser style={{ marginRight: "10px", color: "#888" }} />
            <input
              style={{
                flex: 1,
                border: "none",
                outline: "none",
                fontSize: "16px",
                width: "300px",
              }}
              type="text"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
              placeholder="아이디를 입력하세요"
              required
            />
          </Box>
          <Box
            sx={{
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
              boxSizing: "border-box",
              marginBottom: "10px",
            }}
          >
            <FaLock style={{ marginRight: "10px", color: "#888" }} />
            <input
              style={{
                flex: 1,
                border: "none",
                outline: "none",
                fontSize: "16px",
                width: "300px",
              }}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호를 입력하세요"
              required
            />
          </Box>
        </Box>
        <button
          type="submit"
          className="login-button"
          style={{
            width: "100%",
            backgroundColor: "#655dc6",
            padding: "10px",
            border: "none",
            color: "white",
            fontSize: "18px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Login
        </button>
      </form>
    </Box>
  );
};

export default Login;
