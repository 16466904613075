import React from "react";
import { TextField } from "@mui/material";
interface InputFieldProps {
  label: string;
  value: string | Date | null;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  width?: string | number;
}
const InputField: React.FC<InputFieldProps> = ({ label, value, onChangeInput, type, width = "100%" }) => {
  return (
    <TextField
      variant="standard"
      label={label}
      type={type}
      size="small"
      sx={{ width }}
      inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
      InputLabelProps={{ shrink: false }}
      value={value}
      onChange={onChangeInput}
    />
  );
};
export default InputField;
