import { useState, useMemo, useCallback } from "react";
import { Grid, Paper, Typography, Button, TableHead, TableRow, TableCell, TableContainer, Table, TableBody, Checkbox, Box, Popover, Chip, TextField, MenuItem, IconButton } from "@mui/material";
import { useLocalContext } from "./Context";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const discountRateArray = ["none", "5", "10", "20", "30"];
export default function ShoppingCardList() {
  const { selectCartList, setSelectCartList } = useLocalContext();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [discountRate, setDiscountRate] = useState<string>("");
  const [discountValue, setDiscountValue] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number | null>(null);
  const [popover, setPopOver] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(popover);

  // totalAmount 계산
  const totalAmount = useMemo(() => {
    return selectCartList.reduce((sum, item) => {
      if (!item.checked) return sum;
      const price = item.serviceCheck ? 0 : item.vat ? Number(item.priceWithVat) : Number(item.priceWithoutVat);
      return price ? sum + price * item.quantity : sum;
    }, 0);
  }, [selectCartList]);

  // 최종 가격 계산
  const finalPriceAmount = useMemo(() => {
    if (finalPrice !== null) {
      return finalPrice; // 사용자가 입력한 값이 우선
    }
    // 할인율이 "none"일 경우 할인율 적용 없이 discountValue만 빼기
    if (discountRate === "none") {
      return Math.ceil(totalAmount - discountValue);
    }
    // 할인율 적용하여 계산된 값 반환
    return Math.ceil(totalAmount - totalAmount * (+discountRate / 100)) - discountValue;
  }, [finalPrice, totalAmount, discountRate, discountValue]);

  // 전체 체크 상태
  const allChecked = selectCartList.length > 0 && selectCartList.every((product) => product.checked);

  // 전체 체크 상태 변경 처리
  const onChangeCheckAll = useCallback(
    (checked: boolean) => {
      const updatedList = selectCartList.map((product) => ({ ...product, checked }));
      setSelectCartList(updatedList);
    },
    [selectCartList]
  );

  // 개별 체크 기능
  const onChangeRawCheck = (index: number, checked: boolean) => {
    setSelectCartList(selectCartList.map((product, i) => (i === index ? { ...product, checked } : product)));
  };

  // 수량 변경 기능
  const onChangeQuantity = (index: number, quantity: number) => {
    setSelectCartList(selectCartList.map((product, i) => (i === index ? { ...product, quantity: quantity > 0 ? quantity : 1 } : product)));
  };

  // 개별 삭제 기능
  const onChangeProductList = (index: number) => {
    setSelectCartList((prevList) => prevList.filter((_, i) => i !== index));
  };

  //할인 금액 수정 핸들러
  const onChangeDiscountValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    const numericValue = inputValue === "" ? 0 : Number(inputValue);
    setDiscountValue(numericValue);
  };

  // 최종 금액 수동 입력 처리
  const onChangeFinalPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    const numericValue = inputValue === "" ? 0 : Number(inputValue);
    setFinalPrice(numericValue);
  };
  // 장바구니 비우기
  const clearCart = () => setSelectCartList([]);

  // 부과세 삭제 핸들러
  const onClickVatRemove = (indexId: number) => {
    setSelectCartList((prevList) =>
      prevList.map((product, index) => {
        if (index === indexId) {
          return { ...product, vat: !product.vat };
        }
        return product;
      })
    );
  };

  //서비스 체크 핸들러
  const onClickServiceCheck = (indexId: number) => {
    setSelectCartList(selectCartList.map((product, index) => (index === indexId ? { ...product, serviceCheck: !product.serviceCheck } : product)));
  };

  //팝오버 여는 핸들러
  const onClickPopoverOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedRowIndex(index);
    setPopOver(event.currentTarget);
  };

  //팝오버 닫는 핸들러
  const onClickPopoverClose = () => {
    setSelectedRowIndex(null); // 인덱스 초기화
    setPopOver(null);
  };

  const [showDetails, setShowDetails] = useState(false); // 세부 항목 표시 여부

  const onClickPurchaseDetails = () => {
    setShowDetails((prev) => !prev);
  };

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        p: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" gutterBottom>
        장바구니
      </Typography>
      <Paper
        sx={{
          padding: 2,
          borderRadius: 2,
          boxShadow: 3,
          width: "100%",
          height: "calc(100% - 38px)",
        }}
      >
        <Grid container direction="column" height={"100%"} sx={{ width: "100%" }}>
          {/* 장바구니 리스트 */}
          <TableContainer
            sx={{
              flex: 1,
              "& .MuiTableCell-head": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width={"2%"}>
                    <Checkbox checked={allChecked} onChange={(e) => onChangeCheckAll(e.target.checked)} />
                  </TableCell>
                  <TableCell align="center" width={"20%"}>
                    상품명
                  </TableCell>
                  <TableCell align="center" width={"15%"}>
                    수량
                  </TableCell>
                  <TableCell align="center" width={"26%"}>
                    상품 금액
                  </TableCell>
                  <TableCell align="center" width={"13%"}>
                    최종 금액
                  </TableCell>
                  <TableCell align="center" width={"10%"}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectCartList.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Checkbox checked={product.checked} onChange={(e) => onChangeRawCheck(index, e.target.checked)} />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      {product.name}
                      {product.serviceCheck && <Chip label="sv" color="primary" size="small" />}
                    </TableCell>
                    <TableCell sx={{ height: "100%", width: "14%" }}>
                      <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button variant="outlined" size="small" onClick={() => onChangeQuantity(index, product.quantity - 1)} disabled={product.quantity <= 1} style={{ minWidth: "20px" }}>
                          -
                        </Button>
                        <Typography sx={{ px: 1 }}>{product.quantity}</Typography>
                        <Button variant="outlined" size="small" onClick={() => onChangeQuantity(index, product.quantity + 1)} style={{ minWidth: "20px" }}>
                          +
                        </Button>
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <Grid
                        onClick={(e) => onClickPopoverOpen(e, index)}
                        sx={{
                          display: "flex",
                          border: "1px solid transparent", // 기본 상태에서 투명한 border 추가
                          borderRadius: "3px",
                          justifyContent: "center",
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            "&:hover": {
                              border: "1px solid #D0A9F5",
                              "&:hover": {
                                backgroundColor: "rgba(243, 243, 248, 0.5)", // 호버 시 불투명
                              },
                              borderRadius: "3px",
                              lineHeight: "14px",
                              cursor: "pointer",
                            },
                          }}
                        >
                          {product.priceWithVat?.toLocaleString()}
                          {product.serviceCheck ? (
                            <Grid sx={{ display: "flex", color: "blue" }}> (-{product.priceWithVat?.toLocaleString()})</Grid>
                          ) : !product.vat ? (
                            <Grid sx={{ display: "flex", color: "blue" }}>(- {(Number(product.priceWithVat) - Number(product.priceWithoutVat))?.toLocaleString()})</Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                      {selectedRowIndex === index && (
                        <Popover
                          open={isOpen}
                          anchorEl={popover}
                          onClose={onClickPopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Grid
                            sx={{
                              padding: "8px",
                              borderRadius: "4px",
                              boxShadow: 3,
                            }}
                          >
                            <Box sx={{ padding: 1, backgroundColor: "white", borderRadius: "8px" }}>
                              <Grid sx={{ display: "flex", alignItems: "center" }}>
                                <Checkbox size="small" checked={selectCartList.some((product, id) => id === index && !product.vat)} onClick={() => onClickVatRemove(index)} />
                                <Typography>부과세 삭제</Typography>
                              </Grid>
                              <Grid sx={{ display: "flex", alignItems: "center" }}>
                                <Checkbox size="small" sx={{}} onClick={() => onClickServiceCheck(index)} checked={selectCartList.some((product, id) => id === index && product.serviceCheck)} />
                                <Typography>서비스 체크</Typography>
                              </Grid>
                            </Box>
                          </Grid>
                        </Popover>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {product.serviceCheck
                        ? 0
                        : product.vat === true
                        ? product.priceWithVat && (+product?.priceWithVat * product.quantity).toLocaleString()
                        : product.priceWithoutVat && (+product?.priceWithoutVat * product.quantity).toLocaleString()}
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: "unset" }}>
                      <IconButton onClick={() => onChangeProductList(index)}>
                        <CloseIcon sx={{ cursor: "pointer", fontSize: "20px" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* 총액 */}
          <Grid
            item
            sx={{
              bgcolor: "#f0f0f0",
              p: 2,
              borderRadius: 2,
              boxShadow: 3,
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Grid sx={{ display: "flex", alignItems: "center", pb: 2 }}>
              <Typography variant="h1">결제 금액</Typography>
              <CreditCardIcon sx={{ color: "#655dc6", ml: 1, fontSize: "20px", cursor: "pointer" }} onClick={onClickPurchaseDetails} />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column" }} gap={3}>
              {showDetails ? (
                <Grid sx={{ display: "flex", flexDirection: "column" }} gap={3}>
                  <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography>상품 금액</Typography>
                    {totalAmount.toLocaleString()}원
                  </Grid>
                  <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography>할인 율</Typography>
                    <Grid sx={{ display: "flex", alignItems: "center" }} gap={2}>
                      <Typography sx={{ color: "blue" }}>( - {Math.ceil(totalAmount * (discountRate === "none" ? 0 : +discountRate / 100))}원)</Typography>
                      <TextField
                        variant="standard"
                        select
                        value={discountRate}
                        size="small"
                        sx={{
                          width: "110px",
                          "& .MuiInputBase-root": {
                            display: "flex",
                            justifyContent: "center", // Horizontally center the text
                            alignItems: "center", // Vertically center the text
                            textAlign: "center", // Ensure text is centered
                          },
                        }}
                        onChange={(e) => setDiscountRate(e.target.value)}
                      >
                        {discountRateArray.map((rate) => (
                          <MenuItem key={rate} value={rate}>
                            {rate === "none" ? "none" : `${rate}%`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography>할인 금액</Typography>
                    <Grid sx={{ display: "flex", alignItems: "center" }} gap={2}>
                      <TextField
                        value={discountValue.toLocaleString()}
                        size="small"
                        onChange={onChangeDiscountValue}
                        variant="standard"
                        sx={{
                          width: "140px",
                          "& .MuiInput-underline:before": {
                            borderBottom: "none",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-input": {
                            textAlign: "center",
                          },
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography>총 결제 금액</Typography>

                <TextField
                  variant="standard"
                  value={finalPriceAmount.toLocaleString()}
                  onChange={onChangeFinalPrice}
                  sx={{
                    width: "140px",
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none", // 포커스 상태에서도 보더라인 제거
                    },
                    "& .MuiInputBase-input": {
                      textAlign: "center", // 텍스트 가운데 정렬
                    },
                  }}
                  placeholder="0"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sx={{ justifyContent: "space-between", pt: 2 }}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={clearCart}>
                전체 삭제
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" sx={{ mr: 2 }}>
                선택 결제
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  window.close();
                }}
              >
                결제하기
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
