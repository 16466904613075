import React from "react";
import SurgeryList from "./SurgeryList";
import { SurgeryLocalProvider } from "./Context";

const Surgery: React.FC = () => {
  return (
    <SurgeryLocalProvider>
      <SurgeryList />
    </SurgeryLocalProvider>
  );
};

export default Surgery;
