import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditForm from "./EditForm";
import { useDevice } from "../../../context/DevicesContext";
import { Device, initialDevice } from "../../../models/DevicesModel";
import { useLocalContext } from "../Context";

const titleTableSx = {
  padding: "4px",
  width: "30%",
  fontWeight: "bolder",
  textAlign: "center",
  backgroundColor: "#F9F9FD",
  fontSize: 13,
};

const InfoView: React.FC = () => {
  const { updateDevice } = useDevice();
  const { isEdit, setIsEdit, selectedDevice, onChangeSelectDevice } = useLocalContext();
  const [formValues, setFormValues] = useState<Device>(initialDevice);

  //selectedDevice가 변경될 때마다 폼 데이터를 업데이트
  useEffect(() => {
    setFormValues(selectedDevice!);
  }, [selectedDevice]);

  const titleData = selectedDevice
    ? [
        { label: "호칭", value: selectedDevice.title },
        { label: "장비명", value: selectedDevice.deviceName },
      ]
    : [];
  const fixedData = selectedDevice
    ? [
        { label: "코드번호", value: selectedDevice.codeNumber },
        { label: "제조사", value: selectedDevice.deviceCompany },
        { label: "의료기기 분류", value: selectedDevice.deviceCategory },
        { label: "S/N", value: selectedDevice.serialNumber },
      ]
    : [];
  const variableData = {
    location: selectedDevice!.location ?? "",
    area: selectedDevice!.area ?? "",
    userType: selectedDevice!.userType ?? "",
    managingTeam: selectedDevice!.managingTeam ?? "",
    deviceStatus: selectedDevice!.deviceStatus ?? "",
    memo: selectedDevice!.memo ?? "",
  };

  // 폼 오픈 핸들러
  const onClickOpenForm = () => {
    setIsEdit(true);
  };

  // 폼 제출 핸들러
  const onClickSubmitForm = async () => {
    if (formValues) {
      const success = await updateDevice(formValues);
      if (success) {
        onChangeSelectDevice(formValues);
      }
      setIsEdit(false);
    }
  };

  // 폼 닫기 핸들러
  const onClickCloseForm = () => {
    setFormValues(selectedDevice!);
    setIsEdit(false);
  };
  return (
    <Box display={"flex"} flexDirection={"row"} height={"100%"}>
      <Grid item flex={1} className="basicInfo" sx={{ display: "flex", flexDirection: "column" }} padding={1}>
        <Grid
          container
          flex={5}
          justifyContent="center"
          alignItems="center"
          sx={{ border: "1px solid lightgray", position: "relative", overflow: "hidden" }} // 부모에 relative 추가
        >
          {selectedDevice?.imgUrl ? (
            <img
              src={`https://aac-amred-spaceos.s3.ap-northeast-2.amazonaws.com/devices/${selectedDevice.imgUrl}`}
              alt="장비 이미지"
              style={{
                position: "absolute", // 이미지에 absolute 추가
                objectFit: "contain",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: "100%",
              }}
            />
          ) : (
            <Typography textAlign="center" color="lightgray">
              이미지 준비중
            </Typography>
          )}
        </Grid>

        <Grid container flex={1} direction={"column"}>
          <TableContainer>
            <Table sx={{ border: "1px solid lightgray" }}>
              <TableBody>
                {titleData.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell sx={titleTableSx}>{item.label}</TableCell>
                      <TableCell sx={{ padding: "4px", paddingLeft: "10px", fontSize: 13 }}>{item.value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item flex={3} className="formInfo" padding={1} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
        <Grid container sx={{ height: "100%" }}>
          <EditForm isEdit={isEdit} setIsEdit={setIsEdit} fixedData={fixedData} variableData={variableData} formValues={formValues} setFormValues={setFormValues} />
        </Grid>

        {isEdit ? (
          <Grid container direction={"row"} justifyContent={"center"} gap={1} mt={1}>
            <Button size="small" onClick={onClickSubmitForm} sx={{ minWidth: "40px", maxHeight: "25px" }}>
              제출
            </Button>
            <Button size="small" onClick={onClickCloseForm} sx={{ minWidth: "40px", maxHeight: "25px", backgroundColor: "lightgray" }}>
              취소
            </Button>
          </Grid>
        ) : (
          <Grid container direction={"row"} justifyContent={"flex-end"} gap={1} mt={1}>
            <Button size="small" onClick={onClickOpenForm} sx={{ minWidth: "40px", maxHeight: "25px" }}>
              수정
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default InfoView;
