import React, { useState } from "react";
import { Grid } from "@mui/material";
import EmployeeList from "./EmployeeList";
import EmployeeDetails from "./EmployeeDetails";
import RegisterEmployee from "./RegisterEmployee";
import { EmployeeProvider } from "./Context";

const EmployeePage: React.FC = () => {
  const [isAddEmployee, setIsAddEmployee] = useState<boolean>(false);

  return (
    <EmployeeProvider>
      <Grid container direction="row" sx={{ height: "100%", padding: "10px" }}>
        <Grid container item xs={3} sx={{ height: "100%" }}>
          <EmployeeList setIsAddEmployee={setIsAddEmployee} />
        </Grid>
        <Grid container item xs={9} sx={{ height: "100%", paddingLeft: "20px" }}>
          {isAddEmployee ? <RegisterEmployee setIsAddEmployee={setIsAddEmployee} /> : <EmployeeDetails />}
        </Grid>
      </Grid>
    </EmployeeProvider>
  );
};

export default EmployeePage;
