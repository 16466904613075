import axios from "axios";
import { handleServiceApiError } from "../../util/Function";

const customerApiUrl = "/api/v1/customer";

const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});

export const fetchCustomer = async (page: number, size: number) => {
  try {
    const response = await apiClient.post(`${customerApiUrl}?page=${page}&size=${size}`, {});
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
export const fetchCustomerByCustomerId = async (customerId: string) => {
  try {
    const response = await apiClient.post(`${customerApiUrl}/search/customerId`, { customerId: customerId });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
