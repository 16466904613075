import React, { useEffect, createContext, useContext, useState, ReactNode, useCallback } from "react";
import { Product, dummyResults, dummyRegisterData, RegisterProduct, exampleData, HistoryRecord } from "./Model";

const SupplyContext = createContext<SuppliesContextType | undefined>(undefined);

export interface SuppliesContextType {
  tab: number;
  productData: RegisterProduct[];
  setProductData: React.Dispatch<React.SetStateAction<RegisterProduct[]>>;
  filterProductData: Product[];
  filterRegisterData: RegisterProduct[];
  searchTerm: string;
  setSearchTerm: (value: any) => void;
  clearSearch: () => void;
  onChangeTab: (event: React.SyntheticEvent, newValue: number) => void;
  searchCategory: "productCode" | "customCode" | "productName";
  onChangeCategory: (newCategory: "productCode" | "customCode" | "productName") => void;
  KeyDownSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClickDetailModalClose: (value: boolean) => void;
  onClickDetailModalOpen: (value: Product) => void;
  openDetailModal: boolean;
  detailModalData: Product | null;
  storageHistory: HistoryRecord[];
  setStorageHistory: React.Dispatch<React.SetStateAction<HistoryRecord[]>>;
}
interface SuppliesProviderProps {
  children: ReactNode;
}

export const SupplyLocalProvider: React.FC<SuppliesProviderProps> = ({ children }) => {
  const [tab, setTab] = useState(0);
  const [SuppliesData, setSuppliesData] = useState<Product[]>(dummyResults);
  const [filterProductData, setFilterProductData] = useState<Product[]>([]);
  const [filterRegisterData, setFilterRegisterData] = useState<RegisterProduct[]>([]);
  const [productData, setProductData] = useState<RegisterProduct[]>(dummyRegisterData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchCategory, setSearchCategory] = useState<"productCode" | "customCode" | "productName">("productName");
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [detailModalData, setDetailModalData] = useState<Product | null>(null);
  const [storageHistory, setStorageHistory] = useState<HistoryRecord[]>([]);

  const onClickDetailModalOpen = useCallback(
    (scheduleData: Product) => {
      const storageHistory = [...exampleData];
      setStorageHistory(storageHistory.filter((item) => item.location === scheduleData.location));
      setOpenDetailModal(true);
      setDetailModalData(scheduleData);
    },
    [openDetailModal, detailModalData?.customCode, exampleData]
  );

  // 박스를 닫을 때
  const onClickDetailModalClose = () => {
    setOpenDetailModal(false);
    setDetailModalData(null);
  };

  /// 검색어로 데이터 필터링
  const filterBySearchTerm = <T extends { [key: string]: any }>(data: T[], category: "productCode" | "customCode" | "productName") => {
    if (!searchTerm) return data;

    return data.filter((item) => {
      const value = item[category] as string;
      if (typeof value === "string") {
        const searchRegex = new RegExp(searchTerm.replace(/[0-9]/g, "\\d*$&"), "i");
        return searchRegex.test(value);
      }
      return false;
    });
  };

  //탭과 검색 조건에 따른 데이터 필터링 핸들러
  const onChangeSearchTab = () => {
    if (tab === 3) {
      // RegisterProduct 데이터 필터링
      let filteredData = [...productData];
      filteredData = filterBySearchTerm(filteredData, searchCategory);
      setFilterRegisterData(filteredData);
    } else {
      // Product 데이터 필터링
      let filteredData = [...SuppliesData];
      switch (tab) {
        case 1:
          filteredData = filteredData.filter((el) => el.type === "1");
          break;
        case 2:
          filteredData = filteredData.filter((el) => el.type === "2");
          break;
      }
      filteredData = filterBySearchTerm(filteredData, searchCategory);
      setFilterProductData(filteredData);
    }
  };

  // 검색어 입력 시 enter후 실행되는 핸들러
  const KeyDownSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onChangeSearchTab(); // 엔터를 눌렀을 때만 검색
    }
  };

  // 검색어 초기화 핸들러
  const clearSearch = () => {
    setSearchTerm("");
    if (tab === 3) {
      setFilterRegisterData([]);
    } else {
      setFilterProductData([]);
    }
  };

  // 검색 카테고리 변경 핸들러
  const onChangeCategory = (newCategory: "productCode" | "customCode" | "productName") => {
    setSearchCategory(newCategory);
  };

  /// 탭 변경 핸들러
  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setSearchTerm("");
  };

  // `tab`, `searchTerm`, `searchCategory`가 변경될 때마다 검색 수행
  useEffect(() => {
    onChangeSearchTab();
  }, [tab, SuppliesData, productData]);

  return (
    <SupplyContext.Provider
      value={{
        tab,
        productData,
        setProductData,
        onChangeTab,
        filterProductData,
        filterRegisterData,
        searchTerm,
        searchCategory,
        clearSearch,
        onChangeCategory,
        setSearchTerm,
        KeyDownSearch,
        onClickDetailModalOpen,
        onClickDetailModalClose,
        detailModalData,
        openDetailModal,
        storageHistory,
        setStorageHistory,
      }}
    >
      {children}
    </SupplyContext.Provider>
  );
};

export const useLocalContext = () => {
  const context = useContext(SupplyContext);
  if (context === undefined) {
    throw new Error("useLocalContext must be used within a SupplyLocalProvider");
  }
  return context;
};
