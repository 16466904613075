import { ko } from "date-fns/locale";

export interface ProductOption {
  groupId: number;
  selectNum: number;
  values: number[];
}

export interface Product {
  productId: string;
  productUuid: string;
  categoryLarge: string;
  categoryMedium: string;
  name: string;
  koreanName: string;
  englishName?: string;
  chineseName?: string;
  japaneseName?: string;
  priceWithoutVat: number | string | null;
  priceWithVat: number | string | null;
  description: string;
  startDate: Date | null;
  endDate: Date | null;
  category: string;
  options: ProductOption[];
  [key: string]: any; // 추가 속성을 위한 인덱스 시그니처
}
export const initialValue = {
  productId: "",
  productUuid: "",
  categoryLarge: "",
  categoryMedium: "",
  name: "",
  koreanName: "",
  englishName: "",
  chineseName: "",
  japaneseName: "",
  priceWithoutVat: "",
  priceWithVat: "",
  description: "",
  startDate: null,
  endDate: null,
  category: "product",
  options: [],
};
