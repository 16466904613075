import React, { useState, useMemo } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, Grid, IconButton } from "@mui/material";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import SurgeryEditModal from "./SurgeryList/SurgeryEditModal";
import { useSurgery } from "../../context/SurgeryContext";
import { Surgery } from "../../models/SurgeryModel";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useLocalContext } from "./Context";
import { LanguageType } from "./Model";
import HeaderControls from "./HeaderControls";
import { surgeryController } from "../../controller/SurgeryController";
import ExportToCsv from "./Excel";
import { useAuth } from "../../context/AuthContext";

const headers = [
  { label: "카테고리", name: "category", width: "15%" },
  { label: "호칭", name: "surgeryTitle", width: "15%" },
  { label: "한국어 시술명(고객용)", name: "surgeryNameKr", width: "15%" },
  { label: "시술명(고객용)", name: "surgeryNameKr", width: "15%" },
  { label: "시술효과", name: "surgeryEffectKr", width: "30%" },
  { label: "", name: "edit", width: "10%" },
];

// 필드 매핑
const fieldMap: Record<LanguageType, { name: keyof Surgery; effect: keyof Surgery }> = {
  한국어: { name: "surgeryNameKr", effect: "surgeryEffectKr" },
  영어: { name: "surgeryNameEn", effect: "surgeryEffectEn" },
  중국어: { name: "surgeryNameCn", effect: "surgeryEffectCn" },
  일본어: { name: "surgeryNameJp", effect: "surgeryEffectJp" },
};

const SurgeryList: React.FC = () => {
  const { surgeryList, setSelectedSurgery, deleteSurgery } = useSurgery();
  const { language, searchTerms } = useLocalContext();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isShowExitedSurgery, setIsShowExitedSurgery] = useState(false);
  const { user } = useAuth();

  const filteredSurgery = useMemo(() => {
    if (!searchTerms.trim()) return surgeryList;
    return surgeryList.filter((surgery) => surgery.surgeryNameKr.toLowerCase().includes(searchTerms.toLowerCase()));
  }, [surgeryList, searchTerms]);

  // 하이라이트 처리를 위한 텍스트 분할 핸들러
  const onClickEditModalOpen = (surgery: Surgery) => {
    setOpenEditModal(true);
    setSelectedSurgery(surgery);
  };
  //모달 닫는 핸들러
  const onClickEditModalClose = () => setOpenEditModal(false);
  //삭제 핸들러
  const onClickDeleteSurgery = (surgeryId: number) => {
    if (window.confirm("시술 정보를 삭제하시겠습니까?")) {
      deleteSurgery(surgeryId);
    }
  };

  // 필드 값 가져오기 함수
  const getLocalizedValue = (surgery: Surgery, type: "name" | "effect") => {
    const field = fieldMap[language][type]; // 타입 에러 없음
    return surgery[field] || "";
  };

  const onChangeExcelFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      surgeryController.handleUploadSurgeryExcel(file);
    }
  };

  return (
    <Grid container>
      <Grid
        container
        item
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "48px",
          borderBottom: "1px solid lightgrey",
        }}
      >
        <Grid item padding={1} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h2" sx={{ pr: 1 }}>
            시술 정보
          </Typography>
          <ExportToCsv data={surgeryList} name="시술정보" />
          {(user?.authRole === "developer" || user?.authRole === "admin") && (
            <>
              <Box sx={{ display: "flex", flexWrap: "wrap", boxSizing: "border-box" }}>
                <input accept=".xlsx, .xls" style={{ display: "none" }} id="upload-button-file" type="file" onChange={onChangeExcelFile} />
                <label htmlFor="upload-button-file">
                  <IconButton component="span" sx={{ padding: "5px" }}>
                    <CloudUploadRoundedIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </label>
              </Box>
            </>
          )}
        </Grid>
        <HeaderControls isShowExitedSurgery={isShowExitedSurgery} setIsShowExitedSurgery={setIsShowExitedSurgery} />
      </Grid>
      <Grid container item overflow={"auto"}>
        <TableContainer
          sx={{
            maxHeight: "calc(100vh - 48px)",
          }}
        >
          <Table
            stickyHeader
            sx={{
              "& .MuiTableCell-root": {
                borderRight: "1px solid lightgray",
              },
            }}
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#e1e1f5" }}>
                {headers.map((header) => (
                  <TableCell key={header.label} sx={{ textAlign: "center", width: header.width, backgroundColor: "#e1e1f5", fontWeight: "bold", lineHeight: 0 }}>
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSurgery
                .filter((surgery) => {
                  if (isShowExitedSurgery) {
                    return surgery.isDeleted === true;
                  }
                  return true;
                })
                .map((surgery) => (
                  <TableRow hover key={surgery.surgeryId}>
                    <TableCell align="center" sx={{ padding: 1 }}>
                      {surgery.category}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: 1 }}>
                      {surgery.surgeryTitle}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: 1 }}>
                      {surgery.surgeryNameKr}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: 1 }}>
                      {getLocalizedValue(surgery, "name")}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: 1 }}>
                      {getLocalizedValue(surgery, "effect")}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: 1 }}>
                      <IconButton onClick={() => onClickEditModalOpen(surgery)}>
                        <EditIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                      <IconButton onClick={() => onClickDeleteSurgery(surgery?.surgeryId || 0)}>
                        <CloseIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <SurgeryEditModal open={openEditModal} onClickEditModalClose={onClickEditModalClose} />
    </Grid>
  );
};

export default SurgeryList;
