import React, { useState, useEffect } from "react";
import { Card, Box, CardContent, Typography, Button, Grid, useTheme, TextField } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useLocalContext } from "./Context";

const UserInfo: React.FC = () => {
  const { logout, user } = useAuth();
  const theme = useTheme();

  const [isEditing, setIsEditing] = useState(false);
  const { userInput, cleanUserInput, changeUserInput, updateUserInput } = useLocalContext();
  const [rePassword, setRePassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isSamePassword, setIsSamePassword] = useState<boolean>(true);

  // 비밀번호 일치 여부 확인
  useEffect(() => {
    if (userInput.password !== rePassword) {
      setError("비밀번호가 일치하지 않습니다");
      setIsSamePassword(false);
    } else {
      setError("");
      setIsSamePassword(true);
    }
  }, [userInput.password, rePassword]);

  // 정보 수정 취소 버튼 핸들러
  const onClickCancelEdit = () => {
    cleanUserInput();
    setRePassword("");
    setError("");
    setIsEditing(false);
  };

  // 정보 수정 완료 버튼 핸들러
  const onClickSaveUserInput = async () => {
    updateUserInput();
    setRePassword("");
    setIsEditing(false);
  };

  // 비밀번호 재확인 입력 핸들러
  const onChangeRePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRePassword(value);
  };

  return (
    <Grid container width="100%" height="100%" sx={{ padding: "10px" }}>
      <Grid container justifyContent="center" alignItems="center">
        <Card sx={{ width: 640, height: 450 }}>
          <CardContent>
            {/* FIXME: Header  */}
            <Grid container gap={2} direction={"column"} justifyContent={"center"}>
              <Grid item sx={{ width: "100%" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} height="60px">
                  <Box>
                    <Typography variant="h2">{user?.name}</Typography>
                    <Typography variant="h6">{user?.team}</Typography>
                  </Box>
                  <Box display="flex" flexDirection={"column"} alignItems="center">
                    {isEditing ? null : (
                      <Button onClick={() => setIsEditing(true)} sx={{ height: "24px", fontSize: "12px", textTransform: "none", minWidth: "70px", mb: "9px" }}>
                        정보 수정
                      </Button>
                    )}
                    <Button
                      onClick={logout}
                      sx={{
                        height: "24px",
                        fontSize: "12px",
                        textTransform: "none",
                        minWidth: "70px",
                        backgroundColor: "white",
                        color: "grey",
                        "&:hover": { backgroundColor: "white", color: "red" },
                      }}
                    >
                      로그아웃
                    </Button>
                  </Box>
                </Box>
              </Grid>

              {/* FIXME: account */}
              <Grid item borderRadius="4px" sx={{ width: "100%", border: "1px solid #e0e0ff" }}>
                <Box display="flex" flexDirection={"column"} alignItems="start">
                  <Box
                    padding={1}
                    display="flex"
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                    sx={{ borderBottom: "1px solid #e0e0ff", boxSizing: "border-box" }}
                  >
                    <Typography variant="h3">Account</Typography>
                    <Typography fontSize={12} color={"lightgray"}>
                      * 비밀번호 미입력 시 변경되지 않습니다
                    </Typography>
                  </Box>
                  <Box padding={2} display={"flex"} flexDirection={"column"} gap={1}>
                    <Box display="flex" flexDirection={"row"} alignItems="center" gap={1} height={"30px"}>
                      <Typography variant="body2" color={`${theme.palette.primary.main}`} width={"80px"}>
                        아이디
                      </Typography>
                      <Typography variant="body2">{user?.loginId}</Typography>
                    </Box>
                    <Box display="flex" flexDirection={"row"} alignItems="center" gap={1} height={"30px"}>
                      <Typography variant="body2" color={`${theme.palette.primary.main}`} width="80px">
                        비밀번호
                      </Typography>
                      {isEditing ? (
                        <>
                          <TextField
                            name="password"
                            type="password"
                            value={userInput.password}
                            onChange={changeUserInput}
                            size="small"
                            placeholder="새로운 비밀번호"
                            sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                            error={!!error}
                          />
                          <TextField
                            name="rePassword"
                            type="password"
                            value={rePassword}
                            onChange={onChangeRePassword}
                            size="small"
                            placeholder="새로운 비밀번호 재입력"
                            sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                            error={!!error}
                          />
                          {error && (
                            <Typography variant="body2" color="error" sx={{ marginLeft: 1, fontSize: "12px" }}>
                              {error}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography variant="body2">⦁⦁⦁⦁⦁⦁⦁</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* FIXME: Contact  */}
              <Grid item borderRadius="4px" sx={{ width: "100%", border: "1px solid #e0e0ff" }}>
                <Box display="flex" flexDirection={"column"} alignItems="start">
                  <Box padding={1} width={"100%"} sx={{ borderBottom: "1px solid #e0e0ff", boxSizing: "border-box" }}>
                    <Typography variant="h3">Contact</Typography>
                  </Box>
                  <Box padding={2} display={"flex"} flexDirection={"column"} gap={1}>
                    <Box display="flex" flexDirection={"row"} alignItems="center" gap={1} height={"30px"}>
                      <Typography variant="body2" color={`${theme.palette.primary.main}`} width={"80px"}>
                        전화번호
                      </Typography>
                      {isEditing ? (
                        <TextField
                          name="phoneNumber"
                          value={userInput.phoneNumber}
                          placeholder="전화번호 입력"
                          onChange={changeUserInput}
                          size="small"
                          sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                        />
                      ) : (
                        <Typography variant="body2">{user?.phoneNumber === "" ? "-" : user?.phoneNumber}</Typography>
                      )}{" "}
                    </Box>
                    <Box display="flex" flexDirection={"row"} alignItems="center" gap={1} height={"30px"}>
                      <Typography variant="body2" color={`${theme.palette.primary.main}`} width="80px">
                        이메일
                      </Typography>
                      {isEditing ? (
                        <TextField
                          name="mail"
                          value={userInput.mail}
                          onChange={changeUserInput}
                          placeholder="메일 입력"
                          size="small"
                          sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                        />
                      ) : (
                        <Typography variant="body2">{user?.mail === "" ? "-" : user?.mail}</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Box>
                {isEditing ? (
                  <Grid container alignItems="center">
                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: "10px" }}>
                      <Button
                        onClick={onClickSaveUserInput}
                        disabled={!isSamePassword}
                        sx={{ height: "24px", fontSize: "12px", textTransform: "none", minWidth: "70px", mr: "7px", ":disabled": { background: "lightgray" } }}
                      >
                        수정 완료
                      </Button>
                      <Button
                        onClick={onClickCancelEdit}
                        sx={{
                          height: "24px",
                          fontSize: "12px",
                          textTransform: "none",
                          minWidth: "70px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        취소
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserInfo;
