export interface UserInputType {
  userId: number | null;
  mail?: string;
  phoneNumber?: string;
  password?: string | null;
}

export const initialUserInput = {
  userId: null,
  mail: "",
  phoneNumber: "",
  password: "",
};
