import React, { useState } from "react";
import { Grid, Typography, Button, Tooltip, Box, Popover } from "@mui/material";
import { CardProp } from "../DailyOperations";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import PaymentReportCard from "../../../component/PaymentReportCard";
import { useHomeContext } from "../Context";
import ChartNumberTooltipName from "./ChartNumberTooltipName";
import ReportModal from "../../../component/ReportCard";

const ReturnCard: React.FC<CardProp> = ({ scheduleData }) => {
  const { onClickDetailModalOpen } = useHomeContext();
  const [isTodayReportOpen, setIsTodayReportOpen] = useState(false);
  const [isPaymentReportOpen, setIsPaymentReportOpen] = useState(false);

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  const onClickOpenReport = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchor(event.currentTarget); // 팝오버 위치 설정
  };

  const onClosePopover = () => {
    setPopoverAnchor(null); // 팝오버 닫기
  };

  const isPopoverOpen = Boolean(popoverAnchor);

  return (
    <Grid container item sx={{ width: "100%", height: "auto", background: "#fafafa", borderBottom: "1px solid #b0b0b0", padding: "8px" }}>
      <Grid container item xs={9.5}>
        <Box width="100%" display="flex" overflow="hidden" position="relative">
          <ChartNumberTooltipName scheduleData={scheduleData} />
          <Typography sx={{ fontSize: "12px", marginLeft: "4px" }}>
            ({scheduleData.customer.gender === "M" ? "남" : "여"}/{scheduleData.customer.age})
          </Typography>
          <Tooltip title={"정보 확인"} followCursor={false} arrow placement="right">
            <Box sx={{ width: "auto", height: "18px", fontSize: "14px", maxWidth: "10%", position: "absolute", right: "4px" }}>
              <InfoRoundedIcon sx={{ color: "#bdbdbd", fontSize: "14px", cursor: "pointer" }} onClick={() => onClickDetailModalOpen(scheduleData)} />
            </Box>
          </Tooltip>
        </Box>
        <Box width="100%" display="flex" overflow="hidden" position="relative">
          <Typography component="span" sx={{ fontSize: "12px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            수납금액 :
          </Typography>
        </Box>
        <Box width="100%" display="flex" overflow="hidden" position="relative">
          <Typography component="span" sx={{ fontSize: "12px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            미수납금액 :
          </Typography>
        </Box>
        <Typography sx={{ width: "100%", height: "18px", fontSize: "12px" }}>레포트</Typography>
      </Grid>
      <Grid container item xs={2.5} direction="column" justifyContent="flex-start" alignItems="center">
        <Button
          sx={{ width: "100%", height: "24px", fontSize: "12px", textTransform: "none", mb: "6px", minWidth: "unset", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          onClick={onClickOpenReport}
        >
          레포트
        </Button>
      </Grid>
      {/* 팝오버 */}
      <Popover
        open={isPopoverOpen}
        anchorEl={popoverAnchor}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 1, display: "flex", flexDirection: "column" }} gap={1}>
          <Button onClick={() => setIsTodayReportOpen(true)} sx={{ fontSize: "12px", minWidth: "unset" }}>
            금일 레포트
          </Button>
          <Button onClick={() => setIsPaymentReportOpen(true)} sx={{ fontSize: "12px", minWidth: "unset" }}>
            결제 레포트
          </Button>
        </Box>
      </Popover>
      <ReportModal open={isTodayReportOpen} setWaitModalOpen={setIsTodayReportOpen} scheduleData={scheduleData} />
      <PaymentReportCard open={isPaymentReportOpen} setWaitModalOpen={setIsPaymentReportOpen} scheduleData={scheduleData} />
    </Grid>
  );
};

export default ReturnCard;
