export interface Counselor {
  counselorId: number | null;
  name: string;
  nationality?: string;
  phoneNumber: string;
  birthDate?: Date | null;
  exitDate?: Date | null;
  joiningDate: Date;
  imgUrl?: string | null;
}

export const initialCounselor: Counselor = {
  counselorId: null,
  name: "",
  nationality: "",
  phoneNumber: "",
  birthDate: null,
  joiningDate: new Date(),
  exitDate: null,
  imgUrl: null,
};
