import { Box, Button, Typography } from "@mui/material";
import { ScheduleData } from "../../Home/Model";
import { formatPhoneNumber } from "../../../util/Function";

export const reservationTooltip = (item: ScheduleData) => (
  <Box>
    <Typography sx={{ fontSize: "14px" }}>전화번호 : {formatPhoneNumber(item.customer.phoneNumber)}</Typography>
    <Typography sx={{ fontSize: "14px" }}>프로그램 : {item.schedule.program === null ? "미입력" : item.schedule.program}</Typography>
    <Typography sx={{ fontSize: "14px" }}>상담실장 : {item.schedule.counselor === null ? " 미지정" : item.schedule.counselor}</Typography>
  </Box>
);

export const ScheduleStatusButton = (handle: () => void, name: string, status: string) => (
  <Button
    onClick={handle}
    sx={{
      backgroundColor: "transparent",
      minHeight: "auto",
      height: "14px",
      lineHeight: 1,
      textAlign: "center",
      display: "inline-flex",
      borderRadius: 0,
      fontSize: "10px",
      "&:hover": {
        backgroundColor: status === "beforeVisit" ? "#8470d4" : status === "onVisit" ? "#e08a8a" : "gray",
        borderRadius: 0,
      },
    }}
  >
    {name}
  </Button>
);
