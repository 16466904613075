import { DoctorWorkSchedule, Service, Waiting, WorkScheduleItem, initialWorkScheduleItem } from "./Model";
import {
  cancelSchedule,
  deleteNotice,
  fetchNoticeList,
  fetchSchedule,
  fetchWorkSchedule,
  registerNotice,
  returnCustomer,
  updateWorkSchedule,
  uploadScheduleExcel,
  updateService,
  visitCustomer,
  waitCustomer,
  fetchDoctorDailySchedule,
  registerDoctorDailySchedule,
  updateDoctorDailySchedule,
  startDoctorDailySchedule,
  endDoctorDailySchedule,
} from "./Service";
import { handleControllerResponse } from "../../util/Function";

class NoticeController {
  async fetchNoticeList() {
    const fetchNoticeResponse = await fetchNoticeList();
    return handleControllerResponse(fetchNoticeResponse.status, fetchNoticeResponse.data);
  }
  async registerNotice(newNotice: string, userId: number | undefined) {
    const registerNoticeResponse = await registerNotice(newNotice, userId);
    return handleControllerResponse(registerNoticeResponse.status, registerNoticeResponse.data);
  }
  async deleteNotice(noticeId: number) {
    const deleteNoticeResponse = await deleteNotice(noticeId);
    return handleControllerResponse(deleteNoticeResponse.status, deleteNoticeResponse.data);
  }
}
export const noticeController = new NoticeController();

class WorkScheduleController {
  async fetchWorkSchedule() {
    const fetchWorkScheduleResponse = await fetchWorkSchedule();
    return handleControllerResponse(fetchWorkScheduleResponse.status, fetchWorkScheduleResponse.data);
  }
  async updateWorkSchedule(workSchedule: WorkScheduleItem) {
    const updateWorkScheduleResponse = await updateWorkSchedule(workSchedule);
    return handleControllerResponse(updateWorkScheduleResponse.status, updateWorkScheduleResponse.data);
  }
}
export const workScheduleController = new WorkScheduleController();

export class ScheduleController {
  async fetchSchedule(date?: Date) {
    if (date) {
      const fetchScheduleResponse = await fetchSchedule(date);
      return handleControllerResponse(fetchScheduleResponse.status, fetchScheduleResponse.data);
    } else {
      const fetchScheduleResponse = await fetchSchedule(new Date());
      return handleControllerResponse(fetchScheduleResponse.status, fetchScheduleResponse.data);
    }
  }
  async uploadScheduleExcel(file: File) {
    const uploadScheduleExcelResponse = await uploadScheduleExcel(file);
    return handleControllerResponse(uploadScheduleExcelResponse.status, uploadScheduleExcelResponse.data);
  }

  async visitCustomer(scheduleId: number, building?: string, floor?: string, room?: string) {
    const visitCustomerResponse = await visitCustomer(scheduleId, building, floor, room);
    return handleControllerResponse(visitCustomerResponse.status, visitCustomerResponse.data);
  }
  async cancelSchedule(scheduleId: number) {
    const cancelScheduleResponse = await cancelSchedule(scheduleId);
    return handleControllerResponse(cancelScheduleResponse.status, cancelScheduleResponse.data);
  }
  async waitCustomer(waiting: Waiting) {
    const waitCustomerResponse = await waitCustomer(waiting);
    return handleControllerResponse(waitCustomerResponse.status, waitCustomerResponse.data);
  }
  async returnCustomer(scheduleId: number) {
    const returnCustomerResponse = await returnCustomer(scheduleId);
    return handleControllerResponse(returnCustomerResponse.status, returnCustomerResponse.data);
  }
  async updateService(service: Service[]) {
    const updateServiceResponse = await updateService(service);
    return handleControllerResponse(updateServiceResponse.status, updateServiceResponse.data);
  }
}

export const scheduleController = new ScheduleController();

class DoctorDailyScheduleController {
  async fetchDoctorDailySchedule(workingDoctorList: DoctorWorkSchedule[]) {
    const fetchDoctorDailyScheduleResponse = await fetchDoctorDailySchedule(workingDoctorList);
    return handleControllerResponse(fetchDoctorDailyScheduleResponse.status, fetchDoctorDailyScheduleResponse.data);
  }
  async registerDoctorSchedule(doctorId: number, doctorScheduleText: string) {
    const registerNoticeResponse = await registerDoctorDailySchedule(doctorId, doctorScheduleText);
    return handleControllerResponse(registerNoticeResponse.status, registerNoticeResponse.data);
  }
  async updateDoctorSchedule(doctorScheduleId: number, doctorScheduleText: string) {
    const deleteNoticeResponse = await updateDoctorDailySchedule(doctorScheduleId, doctorScheduleText);
    return handleControllerResponse(deleteNoticeResponse.status, deleteNoticeResponse.data);
  }
  async startDoctorSchedule(doctorScheduleId: number) {
    const deleteNoticeResponse = await startDoctorDailySchedule(doctorScheduleId);
    return handleControllerResponse(deleteNoticeResponse.status, deleteNoticeResponse.data);
  }
  async endDoctorSchedule(doctorScheduleId: number) {
    const deleteNoticeResponse = await endDoctorDailySchedule(doctorScheduleId);
    return handleControllerResponse(deleteNoticeResponse.status, deleteNoticeResponse.data);
  }
}
export const doctorDailyScheduleController = new DoctorDailyScheduleController();
