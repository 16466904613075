import React, { useState } from "react";
import { Grid, Paper, Typography, Button, TextField, Box, IconButton, Popover } from "@mui/material";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Doctor, initialDoctor } from "../../models/DoctorModel";
import { useDoctor } from "../../context/DoctorsContext";
import { doctorsColor } from "./Doctors";
import CheckIcon from "@mui/icons-material/Check";
import { useLocalContext } from "./Context";
import CloseIcon from "@mui/icons-material/Close";

interface RegisterDoctorProp {
  isAddDoctor: boolean;
  setIsAddDoctor: (newState: boolean) => void;
}
const formatDateToString = (date: Date | undefined | null) => {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
};

const RegisterDoctor: React.FC<RegisterDoctorProp> = ({ isAddDoctor, setIsAddDoctor }) => {
  const { registerDoctor } = useDoctor();
  const { doctorsColorList } = useLocalContext();
  const [newDoctor, setNewDoctor] = useState<Doctor>(initialDoctor);
  const [image, setImage] = useState<File>(); // 업로드된 파일을 저장
  const [previewUrl, setPreviewUrl] = useState<string>(); // 미리보기 URL
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);

  const onChangeNewDoctor = (field: keyof Doctor, value: string | Date) => {
    setNewDoctor({ ...newDoctor, [field]: value });
  };

  const onChangeImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // 첫 번째 선택한 파일
    if (file) {
      setImage(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
  };

  const onClickDeleteImage = () => {
    setImage(undefined);
    setPreviewUrl(undefined);
  };

  // 새로운 doctor 생성
  const onClickRegisterNewDoctor = async () => {
    setIsRegistering(true);
    const registerResponse = await registerDoctor(newDoctor, image);
    if (registerResponse.success === true) {
      setIsRegistering(false);
      setIsAddDoctor(false);
    }
  };

  const onClickCancelRegisterNewDoctor = () => {
    setIsAddDoctor(false);
  };

  const onClickColorChange = (newColor: string) => {
    if (newDoctor.color === newColor) {
      onClickPopoverClose();
    } else {
      onChangeNewDoctor("color", newColor);
      onClickPopoverClose();
    }
  };

  const onClickColorBox = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickPopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          신규 원장님 등록
        </Typography>
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, boxShadow: 3, borderRadius: "8px", marginTop: "4px", display: "flex" }}>
        <Grid item xs={3} sx={{ background: "#f0f0fb", borderRadius: "8px 0px 0px 8px" }} />
        <Grid container item xs={6} sx={{ height: "100%", padding: "12px", textAlign: "center", fontSize: "14px", position: "relative" }} direction="column">
          <Grid container item xs={4} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ width: "70%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex", position: "relative" }}>
              {previewUrl ? (
                <Box sx={{ width: "40%", height: "100px", justifyContent: "center", alignItems: "center", display: "flex", position: "relative" }}>
                  <Box sx={{ aspectRatio: "1", height: "100px", borderRadius: "50%" }}>
                    <img src={previewUrl} alt="원장님 사진" style={{ aspectRatio: "1", height: "100%", borderRadius: "50%", objectFit: "cover" }} />
                  </Box>
                  <input id="image-edit" type="file" accept="image/*" onChange={onChangeImageUpload} style={{ display: "none" }} />
                  <label htmlFor="image-edit">
                    <IconButton component="span" sx={{ padding: "4px", position: "absolute", bottom: 0, right: 24 }}>
                      <EditRoundedIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </label>
                  <IconButton sx={{ padding: "4px", position: "absolute", bottom: 0, right: 0 }} onClick={onClickDeleteImage}>
                    <CloseRoundedIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </Box>
              ) : (
                <Box sx={{ aspectRatio: "1", height: "100px", borderRadius: "50%", border: "2px dashed #ccc", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <input id="image-upload" type="file" accept="image/*" onChange={onChangeImageUpload} style={{ display: "none" }} />
                  <label htmlFor="image-upload">
                    <IconButton component="span" sx={{ padding: "10px" }}>
                      <UploadRoundedIcon sx={{ color: "#ccc", fontSize: "30px" }} />
                    </IconButton>
                  </label>
                </Box>
              )}
              <Box sx={{ position: "absolute", bottom: 0, left: 0 }}>
                {/* 색상 박스 */}
                <Box
                  sx={{ width: "20px", height: "20px", backgroundColor: newDoctor.color, border: "1px solid #b0b0b0", borderRadius: "4px", textAlign: "center", cursor: "pointer" }}
                  onClick={onClickColorBox}
                ></Box>
                {/* 팝업 */}
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={onClickPopoverClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  <Box sx={{ display: "flex", flexWrap: "wrap", padding: 1, maxWidth: "145px" }}>
                    {doctorsColor.map((color) => {
                      const isColorSelected = doctorsColorList.find((item) => item.color === color)?.select;

                      return (
                        <IconButton
                          key={color}
                          disabled={isColorSelected}
                          onClick={() => {
                            onClickColorChange(color);
                          }}
                          sx={{
                            backgroundColor: color,
                            width: "24px",
                            height: "24px",
                            margin: "4px",
                            border: "1px solid #b0b0b0",
                            borderRadius: "4px",
                            "&:hover": { backgroundColor: color },
                            "&.Mui-disabled": {
                              backgroundColor: "#d3d3d3",
                            },
                          }}
                        >
                          {isColorSelected ? (
                            <CloseIcon
                              sx={{
                                color: "#a9a9a9", // X 표시의 색상
                                fontSize: "17px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          ) : (
                            newDoctor.color === color && <CheckIcon sx={{ color: "#000", fontSize: "16px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                          )}
                        </IconButton>
                      );
                    })}
                  </Box>
                </Popover>
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center" }}>
            <TextField
              variant="standard"
              label="이름"
              size="small"
              sx={{ width: "70%", margin: "0px" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={newDoctor.name}
              onChange={(e) => onChangeNewDoctor("name", e.target.value)}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <TextField
              variant="standard"
              label="전화번호"
              size="small"
              sx={{ width: "70%" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={newDoctor.phoneNumber}
              onChange={(e) => onChangeNewDoctor("phoneNumber", e.target.value)}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <TextField
              variant="standard"
              type="date"
              label="생년월일"
              size="small"
              sx={{ width: "70%" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={formatDateToString(newDoctor.birthDate)}
              onChange={(e) => onChangeNewDoctor("birthDate", new Date(e.target.value))}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <TextField
              variant="standard"
              type="Date"
              label="입사날짜"
              size="small"
              sx={{ width: "70%" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={formatDateToString(newDoctor.joiningDate)}
              onChange={(e) => onChangeNewDoctor("joiningDate", new Date(e.target.value))}
            ></TextField>
          </Grid>
          <Grid container item xs={2} sx={{ height: "100%", overflow: "hidden", justifyContent: "center", paddingTop: "24px" }}>
            <Button sx={{ marginRight: "8px", height: "30px", padding: "0px" }} onClick={onClickRegisterNewDoctor}>
              저장
            </Button>
            <Button sx={{ marginLeft: "8px", height: "30px", padding: "0px" }} onClick={onClickCancelRegisterNewDoctor}>
              취소
            </Button>
          </Grid>
          {isRegistering && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                color: "#655dc6",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
            >
              등록 중...
            </Box>
          )}
        </Grid>
        <Grid item xs={3} sx={{ background: "#f0f0fb", borderRadius: "0px 8px 8px 0px" }} />
      </Paper>
    </Grid>
  );
};
export default RegisterDoctor;
