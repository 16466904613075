import axios from "axios";
import { handleServiceApiError } from "../../util/Function";
import { UserInputType } from "./Model";

const userApiUrl = "/api/v1/user";

const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});

export const updateUser = async (userInput: UserInputType) => {
  if (userInput.password === "") {
    userInput.password = null;
  }
  try {
    const response = await apiClient.post(`${userApiUrl}/update`, userInput);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
