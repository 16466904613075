import React, { useEffect, useState } from "react";
import { Grid, Paper, Chip, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { WorkSchedule } from "./Model";
import { useDoctor } from "../../context/DoctorsContext";
import { isSameDay } from "../../util/Function";
import { useLocalContext } from "./Context";

const DoctorWeeklySchedule: React.FC = () => {
  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  const workTypes = ["morning", "afternoon", "off", "none"];
  const { doctors } = useDoctor();
  const { weekWorkSchedule, fetchDoctorWeekSchedule, updateDoctorWeekSchedule } = useLocalContext();
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [newWorkSchedules, setNewWorkSchedules] = useState<WorkSchedule[]>([]);
  const [changedWorkSchedules, setChangedWorkSchedules] = useState<WorkSchedule[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [draggingWorkDragId, setDraggingWorkDragId] = useState<number | null>(null);
  const [draggingFrom, setDraggingFrom] = useState<{
    date: Date;
    workType: string;
    doctorName?: string;
    doctorId: number;
  } | null>(null);

  useEffect(() => {
    if (!isEdit) {
      fetchDoctorWeekSchedule(scheduleDate);
    }
  }, [scheduleDate, doctors]);

  useEffect(() => {
    setNewWorkSchedules(weekWorkSchedule);
  }, [weekWorkSchedule]);

  const nextWeekDay = () => {
    const newDate = new Date(scheduleDate);
    newDate.setDate(scheduleDate.getDate() + 7);
    setScheduleDate(newDate);
  };

  const prevWeekDay = () => {
    const newDate = new Date(scheduleDate);
    newDate.setDate(scheduleDate.getDate() - 7);
    setScheduleDate(newDate);
  };

  const isChangedSchedule = (schedule: WorkSchedule) => {
    return changedWorkSchedules.some((changedSchedule) => changedSchedule.dragId === schedule.dragId);
  };
  useEffect(() => {
    fetchDoctorWeekSchedule(scheduleDate);
  }, [scheduleDate, doctors]);
  useEffect(() => {
    setNewWorkSchedules(weekWorkSchedule);
  }, [weekWorkSchedule]);

  // 현재 수정사항을 저장하고 편집 모드 종료
  const onClickUpdateDoctorWeekSchedule = async (_: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const sanitizedSchedules = changedWorkSchedules.map(({ dragId, ...rest }) => rest);
      console.log(sanitizedSchedules);
      // 업데이트가 완료될 때까지 기다림
      updateDoctorWeekSchedule(sanitizedSchedules);

      setIsEdit(false);
      setChangedWorkSchedules([]);
    } catch (error) {
      console.error("Failed to update schedule:", error);
      // 에러 처리 로직 추가
    }
  };

  // 수정사항을 취소하고 원본 데이터로 복원
  const onChangesCancel = (_: React.MouseEvent<HTMLButtonElement>) => {
    setNewWorkSchedules([...weekWorkSchedule]);
    setIsEdit(false);
    //resetChipBackgrounds();
    setChangedWorkSchedules([]);
  };

  // 드래그 앤 드롭 이벤트 핸들러
  const onDragStart = (event: React.DragEvent, dragId: number, date: Date, doctorId: number, workType: string, doctorName: string) => {
    if (!isEdit) return;

    event.dataTransfer.effectAllowed = "move";
    setDraggingWorkDragId(dragId); // 여기에서 doctorWorkScheduleId를 사용합니다.

    setDraggingFrom({
      date: new Date(date),
      workType,
      doctorName,
      doctorId,
    });
    //(event.target as HTMLElement).style.backgroundColor = "gray";

    // 가상 드래그 이미지 생성
    const dragImage = document.createElement("div");
    dragImage.style.width = "58px";
    dragImage.style.height = "18px";
    dragImage.style.padding = "4px";
    dragImage.style.backgroundColor = "#e1e1f5";
    dragImage.style.borderRadius = "20px";
    dragImage.style.fontSize = "9px";
    dragImage.style.lineHeight = "24px";
    dragImage.style.color = "black";
    dragImage.style.position = "absolute";
    dragImage.style.display = "flex";
    dragImage.style.alignItems = "center";
    dragImage.style.justifyContent = "center";
    dragImage.style.textAlign = "center";
    dragImage.innerText = doctorName;
    document.body.appendChild(dragImage);
    event.dataTransfer.setDragImage(dragImage, 30, 0);

    // 드래그 종료 후 가상 이미지 제거
    setTimeout(() => {
      document.body.removeChild(dragImage);
    }, 0);
  };

  // 드래그 종료 시 처리
  const onDropTableCell = (event: React.DragEvent, targetDate: Date, targetWorkType: string) => {
    event.preventDefault();
    if (!isEdit) return;
    if (draggingFrom?.workType === targetWorkType) {
      return;
    }
    if (!setDraggingWorkDragId || !draggingFrom || !isEdit) return;
    if (draggingFrom.date.getDay() !== targetDate.getDay()) {
      return; // 날짜가 다를 경우 함수를 종료
    }

    // 드래그 중인 항목을 찾아서 제거하고 새로운 상태로 맨 뒤에 추가
    const draggingSchedule = newWorkSchedules.find((schedule) => schedule.dragId === draggingWorkDragId);
    const remainingSchedules = newWorkSchedules.filter((schedule) => schedule.dragId !== draggingWorkDragId);
    if (!draggingSchedule) return;

    const updatedSchedules = [
      ...remainingSchedules,
      {
        ...draggingSchedule,
        workType: targetWorkType as "morning" | "afternoon" | "off" | "none",

        date: targetDate,
      },
    ];

    setChangedWorkSchedules((prevSchedules) => {
      const foundWorkSchedule = weekWorkSchedule.find((schedule) => schedule.dragId === draggingSchedule.dragId);
      // 수정시 기존 workType으로 오면 changedWorkSchedule에서 삭제
      if (foundWorkSchedule && foundWorkSchedule.workType === targetWorkType) {
        return prevSchedules.filter((schedule) => schedule.dragId !== draggingSchedule.dragId);
      }
      const scheduleIndex = prevSchedules.findIndex((schedule) => schedule.dragId === draggingSchedule.dragId);
      // 기존 changedWorkSchedule에 있으면 workType 업데이트
      if (scheduleIndex !== -1) {
        return prevSchedules.map((schedule, index) =>
          index === scheduleIndex
            ? {
                ...schedule,
                workType: targetWorkType as "morning" | "afternoon" | "off" | "none",
                date: targetDate,
              }
            : schedule
        );
      }
      // 새로운 변경사항이면 새로운 내용 추가
      return [
        ...prevSchedules,
        {
          ...draggingSchedule,
          workType: targetWorkType as "morning" | "afternoon" | "off" | "none",
          date: targetDate,
        },
      ];
    });
    setDraggingWorkDragId(null);
    setDraggingFrom(null);
  };

  const onDragOver = (event: React.DragEvent) => {
    if (isEdit) {
      event.preventDefault();
    }
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Grid item sx={{ position: "absolute", left: 0, marginLeft: "4px", display: "flex", alignItems: "center" }}>
          <Typography variant="h2" sx={{ textAlign: "left", marginRight: "2px" }}>
            원장님 주간 스케줄
          </Typography>
          {isEdit ? (
            <>
              <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={(event) => onClickUpdateDoctorWeekSchedule(event)}>
                <FileDownloadOutlinedIcon sx={{ fontSize: "18px" }} />
              </IconButton>
              <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={(event) => onChangesCancel(event)}>
                <CloseIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </>
          ) : (
            <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={() => setIsEdit(true)}>
              <EditIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          )}
        </Grid>
        <Grid item sx={{ margin: "0 auto", display: "flex", alignItems: "center" }}>
          <IconButton onClick={prevWeekDay} sx={{ padding: "4px" }}>
            <ArrowBackIcon sx={{ fontSize: "16px", padding: "0px" }} />
          </IconButton>
          <Typography sx={{ textAlign: "center", fontSize: "18px" }}>
            {scheduleDate.getFullYear()}년 {scheduleDate.getMonth() + 1}월 {scheduleDate.getDate()}일
          </Typography>
          <IconButton onClick={nextWeekDay} sx={{ padding: "4px" }}>
            <ArrowForwardIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Grid>
      </Grid>

      <Paper sx={{ flexGrow: 1, height: 0, margin: "4px", boxShadow: 3, borderRadius: "8px" }}>
        <TableContainer sx={{ maxHeight: "100%", borderRadius: "8px 8px 0px 0px" }}>
          <Table
            stickyHeader
            sx={{
              "& .MuiTableCell-root": {
                padding: "4px",
                textAlign: "center",
                verticalAlign: "middle",
              },
            }}
          >
            <TableHead sx={{ top: 0, position: "sticky", background: "white" }}>
              <TableRow>
                <TableCell sx={{ width: "7%", fontWeight: "bold", borderRight: "1px solid #e0e0e0" }}>출근 상태</TableCell>
                {daysOfWeek.map((day, idx) => (
                  <TableCell
                    key={idx}
                    sx={{
                      width: "12.5%",
                      textAlign: "center",
                      fontWeight: "bold",
                      background: scheduleDate.getDay() === idx ? "#e1e1f5" : "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    {day}요일
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {workTypes.map((workType) => (
                <TableRow key={workType}>
                  <TableCell sx={{ verticalAlign: "middle", width: "7%", borderRight: "1px solid #e0e0e0" }}>
                    {workType === "morning" ? (
                      <>
                        오전출근 <br />
                        {/*(9:30~{morningClosingTime[scheduleDate.getDay()]})*/}
                      </>
                    ) : workType === "afternoon" ? (
                      <>
                        오후출근 <br />
                        {/*(12:00~{afternoonClosingTime[scheduleDate.getDay()]})*/}
                      </>
                    ) : workType === "off" ? (
                      "휴무"
                    ) : (
                      "미지정"
                    )}
                  </TableCell>
                  {new Array(7).fill(null).map((_, dayIdx) => {
                    const targetDate = new Date(scheduleDate);
                    targetDate.setDate(scheduleDate.getDate() - scheduleDate.getDay() + dayIdx);
                    const currentSchedules = isEdit ? newWorkSchedules : weekWorkSchedule;
                    const matchingSchedules = currentSchedules.filter((schedule) => schedule.workType === workType && isSameDay(new Date(schedule.date), targetDate));
                    return (
                      <TableCell
                        key={dayIdx}
                        onDragOver={onDragOver}
                        onDrop={(event) => onDropTableCell(event, targetDate, workType)}
                        sx={{
                          verticalAlign: "middle",
                          width: "12.5%",
                          padding: "0px",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                          {matchingSchedules.length > 0
                            ? matchingSchedules.map((schedule: WorkSchedule, idx: number) => (
                                <Chip
                                  key={`${schedule.doctorWorkScheduleId}-${schedule.doctorName}-${idx}`}
                                  label={schedule.doctorName}
                                  className="chip"
                                  draggable={isEdit && !isChangedSchedule(schedule)}
                                  onDragStart={(event) => onDragStart(event, schedule.dragId ?? 0, schedule.date, schedule.doctorId, schedule.workType, schedule.doctorName)}
                                  sx={{
                                    cursor: isEdit && !isChangedSchedule(schedule) ? "move" : "default",
                                    fontSize: "9px",
                                    backgroundColor: isChangedSchedule(schedule) ? "#bdbdbd" : "#e1e1f5",
                                    lineHeight: "20px",
                                    height: "18px",
                                    fontWeight: "700",
                                  }}
                                />
                              ))
                            : null}
                          {changedWorkSchedules.length > 0
                            ? changedWorkSchedules
                                .filter((schedule) => schedule.workType === workType && isSameDay(new Date(schedule.date), targetDate))
                                .map((schedule: WorkSchedule, idx: number) => {
                                  return (
                                    <Chip
                                      key={`changed-${schedule.doctorWorkScheduleId}-${schedule.doctorName}-${idx}`}
                                      label={schedule.doctorName}
                                      className="chip"
                                      draggable={isEdit}
                                      onDragStart={(event) => onDragStart(event, schedule.dragId ?? 0, schedule.date, schedule.doctorId, schedule.workType, schedule.doctorName)}
                                      sx={{
                                        cursor: isEdit ? "move" : "default",
                                        fontSize: "9px",
                                        backgroundColor: "#655dc6",
                                        color: "white",
                                        lineHeight: "20px",
                                        height: "18px",
                                        fontWeight: "700",
                                      }}
                                    />
                                  );
                                })
                            : null}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default DoctorWeeklySchedule;
