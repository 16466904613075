import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { useAuth } from "../../context/AuthContext";
import { initialUserInput } from "./Model";
import { UserInputType } from "./Model";
import { userController } from "./Controller";

export interface ContextType {
  userInput: UserInputType;
  cleanUserInput: () => void;
  changeUserInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updateUserInput: () => void;
}

export const AccountLocalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user, setUser } = useAuth();
  const [userInput, setUserInput] = useState<UserInputType>(initialUserInput);

  // 유저 정보 초기화
  useEffect(() => {
    cleanUserInput();
  }, [user]);

  //입력 초기화 핸들러
  const cleanUserInput = () => {
    setUserInput({ userId: user?.userId ?? 0, mail: user?.mail, phoneNumber: user?.phoneNumber, password: "" });
  };

  /// 입력 값 변경 핸들러
  const changeUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedInput: UserInputType = { ...userInput };
    if (name === "phoneNumber") {
      updatedInput["phoneNumber"] = value.replace(/\D/g, "") as string; // 타입 단언으로 string 설정
    } else if (name === "userId") {
      updatedInput["userId"] = value ? parseInt(value, 10) : null;
    } else {
      (updatedInput as any)[name] = value; // 타입 단언 사용
    }
    setUserInput(updatedInput);
  };

  /// 유저 정보 수정 핸들러
  const updateUserInput = async () => {
    const updateUserInputResponse = await userController.handleUpdateUser(userInput);
    if (updateUserInputResponse.success) {
      setUser(updateUserInputResponse.data);
    } else {
      alert(updateUserInputResponse.message + "\nupdateUserInfo Context");
    }
  };

  return <AccountContext.Provider value={{ userInput, cleanUserInput, changeUserInput, updateUserInput }}>{children}</AccountContext.Provider>;
};

const AccountContext = createContext<ContextType | undefined>(undefined);

export const useLocalContext = (): ContextType => {
  const Context = useContext(AccountContext);
  if (Context === undefined) {
    throw new Error("useLocalContext must be used within an AccountLocalProvider");
  }
  return Context;
};
