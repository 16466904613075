import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const PurchaseHistoryCard: React.FC = () => {
  const VisitData = [
    {
      id: 1,
      paymentType: "리프팅 프로파운드 비스포크 프로파운드(수면마취+369 진정관리)",
      discountAmount: "100000",
      paymentAmount: "200000",
      paymentManager: "엠레드 2층",
      paymentMethod: "계좌",
    },
    {
      id: 2,
      paymentType: "볼륨감소 초음파 보톡스 풀페이스 보톡스 (보툴렉스) 1+1회",
      discountAmount: "100000",
      paymentAmount: "200000",
      paymentManager: "엠레드 2층",
      paymentMethod: "카드",
    },
  ];
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>결제목록</TableCell>
            <TableCell>할인 내역</TableCell>
            <TableCell>결제 금액</TableCell>
            <TableCell>결제 담당자</TableCell>
            <TableCell>결제 수단</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {VisitData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.paymentType}</TableCell>
              <TableCell>{row.discountAmount}</TableCell>
              <TableCell>{row.paymentAmount}</TableCell>
              <TableCell>{row.paymentManager}</TableCell>
              <TableCell>{row.paymentMethod}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchaseHistoryCard;
