export interface ScheduleData {
  customer: Customer;
  schedule: Schedule;
  service: Service[];
  waiting: Waiting[];
}
export interface Customer {
  customerId: number;
  customerUuid: string;
  vegasId: string;
  name: string;
  gender: string;
  birthDate: string;
  phoneNumber: string;
  address: string;
  counselor: string;
  doctor: string; // uuid로 수정
  memo: string;
  age: string;
  country: string;
}
export interface Schedule {
  scheduleId: number;
  customerUuid: string;
  scheduleUuid: string;
  date: Date;
  building: string;
  floor: string;
  room: string;
  program: string;
  counselor: string;
  doctor: string;
  expectedVisitTime: string;
  expectedReturnTime: string;
  visitTime: string;
  //값없어서 추가
  returnTime?: string;
  status: string;
  surgeriesPlan: string;
  vegasReservationMemo: string;
  vegasEmployeeMemo: string;
  arrangerMemo: string;
  counselorMemo: string;
  doctorMemo: string;
  roomHistory: RoomHistory[];
}
export interface RoomHistory {
  room: string;
  time: string;
}
export interface Service {
  serviceId: number;
  serviceUuid: string;
  scheduleId: number;
  name: string;
  doctor: string;
  startTime: string;
  endTime: string;
  serviceTime: string;
  room: string;
  memo: string;
  status: string;
  date: string;
  customerId: number;
}

export interface Waiting {
  waitingId?: number;
  customerId?: number;
  scheduleId?: number;
  date?: Date;
  startTime?: string | null;
  endTime?: string | null;
  waitingTime?: string | null;
  waitingBuilding?: string;
  waitingFloor?: string;
  waitingRoom?: string;
  waitingMemo?: string;
}

export interface NoticeItem {
  noticeId: number;
  noticeUuid: string;
  date: Date;
  noticeText: string;
  name?: string;
  createdByUuid?: string;
  isDeleted: boolean;
  createdAt: string;
}

export interface WorkScheduleItem {
  workScheduleId: number | null;
  date: Date;
  text: string | null;
}
export const initialWorkScheduleItem = {
  workScheduleId: null,
  date: new Date(),
  text: null,
};

export interface ModalContent {
  scheduleData: ScheduleData;
}

export interface RegisterVisitModalProps {
  scheduleId: number;
  customerName: string;
  initialBuilding: string;
  initialFloor: string;
  initialRoom: string;
  onClickVisitCustomer: (scheduleId: number, building?: string, floor?: string, room?: string) => void;
  onClickReturnCustomer?: (scheduleId: number) => void;
  onClose: () => void;
  waiting?: boolean;
  onChangeWaitingList?: (scheduleId: number, building?: string, floor?: string, room?: string) => void;
}

export const initialService: Service = {
  serviceId: 0,
  serviceUuid: "",
  scheduleId: 0,
  name: "",
  doctor: "",
  startTime: "",
  endTime: "",
  serviceTime: "",
  room: "",
  memo: "",
  status: "beforeReady",
  date: "",
  customerId: 0,
};

export interface ContextType {
  noticeList: NoticeItem[];
  setNoticeList: (notice: NoticeItem[]) => void;
  registerNotice: (newNotice: string) => void;
  deleteNotice: (noticeId: number) => void;
  workSchedule: WorkScheduleItem;
  updateWorkSchedule: (workSchedule: WorkScheduleItem) => void;
  scheduleDataList: ScheduleData[];
  visitCustomer: (scheduleId: number, building?: string, floor?: string, room?: string) => void;
  cancelSchedule: (scheduleId: number) => void;
  waitCustomer: (scheduleId: number) => void;
  returnCustomer: (scheduleId: number) => void;
  doctorDailyScheduleList: DoctorDailySchedule[];
  registerDoctorDailySchedule: (doctorId: number, dailyScheduleText: string) => void;
  startDoctorDailySchedule: (doctorDailyScheduleId: number) => void;
  endDoctorDailySchedule: (doctorDailyScheduleId: number) => void;
  updateDoctorDailySchedule: (doctorDailyScheduleId: number, doctorScheduleText: string) => void;
}

export interface DoctorWorkSchedule {
  date: string;
  doctorId: number | null;
  doctorName: string;
  doctorUuid: string;
  doctorWorkScheduleId: number;
  week: number;
  workEndTime: string | null;
  workStartTime: string | null;
  workType: string;
}

export interface DoctorDailySchedule {
  doctorDailyScheduleId: number;
  doctorId: number;
  date: Date;
  startTime?: Date | null;
  endTime?: Date | null;
  dailyScheduleText: string;
  status: string;
}
