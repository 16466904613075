import React, { useState } from "react";
import { Grid, Typography, Paper, Box, Popover, IconButton, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDoctor } from "../../context/DoctorsContext";
import { Doctor } from "../../models/DoctorModel";
import { doctorsColor } from "./Doctors";
import { formatPhoneNumber, formatDate, calculateYearsMonthsDays } from "../../util/Function";
import { useLocalContext } from "./Context";

interface DoctorDetailsProp {
  selectedDoctor: Doctor | undefined;
}

const DoctorDetails: React.FC<DoctorDetailsProp> = ({ selectedDoctor }) => {
  const { updateDoctorColor } = useDoctor();
  const { doctorsColorList, setDoctorsColorList } = useLocalContext();
  const [color, setColor] = useState(selectedDoctor?.color); // 기본 색상 설정
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const [colorStep, setColorStep] = useState("selectColor");
  const onClickColorChange = (newColor: string) => {
    if (selectedDoctor?.color === newColor) {
      onClosePopover();
    } else {
      setColor(newColor);
      setColorStep("registerColor");
    }
  };

  const onClickBox = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopover = () => {
    setAnchorEl(null);
  };

  const onClickUpdateDoctorColor = async () => {
    if (!selectedDoctor?.doctorId) {
      throw new Error("ID is required");
    }
    const response = await updateDoctorColor({ ...selectedDoctor, color: color ? color : selectedDoctor.color });
    if (response.success) {
      setDoctorsColorList((prevColors) => {
        return prevColors.map((colorItem) => {
          if (colorItem.color === selectedDoctor.color) {
            return { ...colorItem, select: false };
          } else if (colorItem.color === color) {
            return { ...colorItem, select: true };
          }
          return colorItem;
        });
      });
    }

    onClosePopover(); // 팝오버 닫기
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          원장님 세부사항
        </Typography>
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, boxShadow: 3, borderRadius: "8px", marginTop: "4px", display: "flex" }}>
        {selectedDoctor === undefined ? (
          <Typography variant="h2" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
            조회하고 싶은 원장님을 목록에서 선택해 주세요
          </Typography>
        ) : (
          <>
            <Grid container item xs={3} sx={{ height: "100%", padding: "12px", background: "#f0f0fb", borderRadius: "8px 0px 0px 8px", textAlign: "center", fontSize: "14px" }} direction="column">
              <Grid item xs={5} sx={{ height: "100%", overflow: "hidden", padding: "8px" }}>
                <img
                  src={"https://aac-amred-spaceos.s3.ap-northeast-2.amazonaws.com/doctors/" + selectedDoctor.imgUrl}
                  alt={selectedDoctor.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // 이미지가 Grid 영역에 맞게 조정되도록 설정
                    maxHeight: "100%", // 이미지의 최대 높이를 Grid의 높이에 맞게 제한
                  }}
                />
              </Grid>
              <Grid item xs={1} sx={{ fontWeight: "bold" }}>
                {selectedDoctor?.name}
              </Grid>
              <Grid container item xs={1}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  전화번호
                </Grid>
                <Grid item xs={8}>
                  {formatPhoneNumber(selectedDoctor?.phoneNumber)}
                </Grid>
              </Grid>
              <Grid container item xs={1}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  생년월일
                </Grid>
                <Grid item xs={8}>
                  {selectedDoctor?.birthDate ? formatDate(new Date(selectedDoctor.birthDate)) : "날짜 없음"}
                </Grid>
              </Grid>
              <Grid container item xs={1}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  입사날짜
                </Grid>
                <Grid item xs={8}>
                  {formatDate(selectedDoctor?.joiningDate)}
                </Grid>
              </Grid>
              <Grid container item xs={1}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  재직기간
                </Grid>
                <Grid item xs={8}>
                  {formatDate(selectedDoctor?.exitDate) === ""
                    ? `${calculateYearsMonthsDays(selectedDoctor?.joiningDate)}째`
                    : calculateYearsMonthsDays(selectedDoctor?.joiningDate, selectedDoctor?.exitDate)}
                </Grid>
              </Grid>
              <Grid container item xs={1}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  퇴사날짜
                </Grid>
                <Grid item xs={8}>
                  {formatDate(selectedDoctor?.exitDate) === "" ? "재직중" : formatDate(selectedDoctor?.exitDate)}
                </Grid>
              </Grid>
              <Grid container item xs={1}>
                <Grid item xs={4} sx={{ fontWeight: "bold" }}>
                  색상
                </Grid>
                <Grid item xs={8}>
                  <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
                    {/* 색상 박스 */}
                    <Box
                      sx={{ width: "20px", height: "20px", backgroundColor: selectedDoctor?.color, border: "1px solid #b0b0b0", borderRadius: "4px", textAlign: "center", cursor: "pointer" }}
                      onClick={onClickBox}
                    ></Box>
                    {/* 팝업 */}
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      disableAutoFocus
                      onClose={onClosePopover}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                      TransitionProps={{
                        onExited: () => setColorStep("selectColor"),
                      }}
                    >
                      {colorStep === "selectColor" ? (
                        <Box sx={{ display: "flex", flexWrap: "wrap", padding: 1, maxWidth: "145px" }}>
                          {doctorsColor.map((color) => {
                            const isColorSelected = doctorsColorList.find((item) => item.color === color)?.select;
                            return (
                              <IconButton
                                key={color}
                                disabled={isColorSelected && selectedDoctor?.color !== color}
                                onClick={() => {
                                  onClickColorChange(color);
                                }}
                                sx={{
                                  backgroundColor: color,
                                  width: "24px",
                                  height: "24px",
                                  margin: "4px",
                                  border: "1px solid #b0b0b0",
                                  borderRadius: "4px",
                                  "&:hover": { backgroundColor: color },
                                  "&.Mui-disabled": {
                                    backgroundColor: "#d3d3d3",
                                  },
                                }}
                              >
                                {isColorSelected && selectedDoctor?.color !== color ? (
                                  <CloseIcon
                                    sx={{
                                      color: "#a9a9a9", // X 표시의 색상
                                      fontSize: "17px",
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                    }}
                                  />
                                ) : (
                                  selectedDoctor?.color === color && (
                                    <CheckIcon
                                      sx={{
                                        color: "#000",
                                        fontSize: "16px",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                    />
                                  )
                                )}
                              </IconButton>
                            );
                          })}
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex", flexWrap: "wrap", padding: 1, maxWidth: "300px" }}>
                          <Typography variant="h4" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
                            {selectedDoctor.name} 원장님 색상을 변경하시겠습니까?
                          </Typography>
                          <Box sx={{ display: "flex", width: "100%", justifyContent: "center", margin: "8px 0px" }}>
                            기존색상
                            <Box
                              sx={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: selectedDoctor?.color,
                                border: "1px solid #b0b0b0",
                                borderRadius: "4px",
                                textAlign: "center",
                                cursor: "pointer",
                                margin: "0px 12px 0px 4px",
                              }}
                              onClick={onClickBox}
                            ></Box>
                            변경색상
                            <Box
                              sx={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: color,
                                border: "1px solid #b0b0b0",
                                borderRadius: "4px",
                                textAlign: "center",
                                cursor: "pointer",
                                marginLeft: "4px",
                              }}
                              onClick={onClickBox}
                            ></Box>
                          </Box>
                          <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <Button sx={{ marginRight: "6px" }} onClick={onClickUpdateDoctorColor}>
                              변경
                            </Button>
                            <Button sx={{ marginLeft: "6px" }} onClick={onClosePopover}>
                              취소
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Popover>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={9} sx={{ height: "100%" }}>
              <Typography variant="h2" sx={{ justifyContent: "center", display: "flex", alignItems: "center", width: "100%" }}>
                Coming Soon
              </Typography>
            </Grid>
          </>
        )}
      </Paper>
    </Grid>
  );
};
export default DoctorDetails;
