export interface Device {
  area: string;
  asContact: string | null;
  codeNumber: string | null;
  deviceCategory: string | null;
  deviceCompany: string | null;
  deviceId: number | null;
  deviceName: string | null;
  deviceStatus: string | null;
  deviceUuid: string | null;
  imgUrl: string | null;
  location: string | null;
  managingTeam: string | null;
  memo: string | null;
  serialNumber: string | null;
  title: string | null;
  userType: string | null;
}

export const initialDevice: Device = {
  area: "",
  asContact: "",
  codeNumber: "",
  deviceCategory: "",
  deviceCompany: "",
  deviceId: null,
  deviceName: "",
  deviceStatus: "",
  deviceUuid: null,
  imgUrl: null,
  location: "",
  managingTeam: "",
  memo: "",
  serialNumber: "",
  title: "",
  userType: "",
};
