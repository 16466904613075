import React, { Fragment, useMemo } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import InfoView from "./DeviceDetails/InfoView";
import { useLocalContext } from "./Context";

const DeviceDetails: React.FC = () => {
  const { selectedDevice } = useLocalContext();

  const content = useMemo(() => {
    if (selectedDevice) {
      return (
        <Fragment>
          <InfoView />
          {/* 구분선 */}
          <Divider sx={{ my: 1 }} />
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography color="grey">Coming soon</Typography>
          </Box>
        </Fragment>
      );
    } else {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography variant="h2">조회하고 싶은 장비를 목록에서 선택해 주세요</Typography>
        </Box>
      );
    }
  }, [selectedDevice]);

  return (
    <Box display="flex" height="100%">
      <Grid container sx={{ flex: 1 }} direction="column">
        <Grid item xs={12}>
          <Paper sx={{ height: "100%", boxShadow: 3, p: 2, display: "flex", flexDirection: "column" }}>{content}</Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceDetails;
