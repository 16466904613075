import { WorkSchedule } from "./Model";
import { fetchDoctorWeekSchedule, updateDoctorSchedule } from "./Service";
import { handleControllerResponse } from "../../util/Function";

class DoctorController {
  async handleFetchDoctorWeekSchedule(date: Date) {
    const handleFetchDoctorResponse = await fetchDoctorWeekSchedule(date);
    return handleControllerResponse(handleFetchDoctorResponse.status, handleFetchDoctorResponse.data);
  }

  async handleUpdateDoctorSchedule(data: WorkSchedule[]) {
    const handleUpdateDoctorScheduleResponse = await updateDoctorSchedule(data);
    return handleControllerResponse(handleUpdateDoctorScheduleResponse.status, handleUpdateDoctorScheduleResponse.data);
  }
}
export const localDoctorController = new DoctorController();
