import React, { useState } from "react";
import { Grid, Paper, Typography, Button, Box, IconButton, Select, MenuItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, FormControl } from "@mui/material";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Employee, initialEmployee } from "./Model";
import { employeeController } from "./Controller";
import { formatDate } from "../../util/Function";
import { useLocalContext } from "./Context";
import InputField from "./RegisterEmployee/TextInput";
interface RegisterEmployeeProp {
  setIsAddEmployee: (newState: boolean) => void; // 상태 변경 함수
}

const teamList = ["운영", "의사", "피부", "간호", "코디", "CX"]; //팀 리스트

const RegisterEmployee: React.FC<RegisterEmployeeProp> = ({ setIsAddEmployee }) => {
  const { employees, setEmployees, setSelectedEmployee } = useLocalContext();
  const [newEmployee, setNewEmployee] = useState<Employee>(initialEmployee);
  const [errorState, setErrorState] = useState<{
    [key: string]: { error: boolean; message: string };
  }>({
    name: { error: false, message: "" },
    loginId: { error: false, message: "" },
    phoneNumber: { error: false, message: "" },
  });
  const [isIncomplete, setIsIncomplete] = useState<boolean>(false); // 경고창 표시 여부
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [image, setImage] = useState<File>(); // 업로드된 파일을 저장
  const [previewUrl, setPreviewUrl] = useState<string>(); // 미리보기 URL

  // 새로운 직원 등록 폼 입력값 변경 핸들러
  const onChangeEmployee = (field: keyof Employee, value: string | Date) => {
    setNewEmployee({ ...newEmployee, [field]: value });
  };

  // 이미지 업로드 핸들러
  const onChangeImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // 첫 번째 선택한 파일
    if (file) {
      setImage(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
  };

  // 이미지 삭제 핸들러
  const onClickDeleteImage = () => {
    setImage(undefined);
    setPreviewUrl(undefined);
  };

  // 등록 버튼  핸들러
  const onClickRegisterNewEmployee = async () => {
    // 필수 입력값 체크
    const isAllFieldsFilled = newEmployee.name && newEmployee.loginId && newEmployee.password && newEmployee.team && newEmployee.role && newEmployee.phoneNumber && newEmployee.joiningDate;
    const hasErrors = Object.values(errorState).some((field) => field.error);
    if (!isAllFieldsFilled || hasErrors) {
      setIsIncomplete(true); // 필수값 누락 시 경고창 표시
      return;
    }

    // 등록 API 호출
    const registerResponse = await employeeController.handleRegisterEmployee(newEmployee);

    if (registerResponse?.success) {
      setEmployees((prevNotices) => [...prevNotices, registerResponse?.data]);
      setIsAddEmployee(false);
    } else {
      alert(registerResponse?.message);
      setIsRegistering(false);
      setIsAddEmployee(false);
    }
  };

  const onClickCancelRegisterNewEmployee = () => {
    setIsAddEmployee(false);
  };

  // 필드 유효성 검사
  const validateField = (field: "loginId" | "phoneNumber", value: string) => {
    let error = false;
    let message = "";

    // 중복 체크

    if (field === "loginId" && employees.some((employee) => employee.loginId === value)) {
      error = true;
      message = "중복된 아이디가 있습니다.";
    }
    if (field === "phoneNumber" && employees.some((employee) => employee.phoneNumber === value)) {
      error = true;
      message = "중복된 전화번호가 있습니다.";
    }

    // 에러 상태 업데이트
    setErrorState((prev) => ({
      ...prev,
      [field]: { error, message },
    }));
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          신규 직원 등록
        </Typography>
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, boxShadow: 3, borderRadius: "8px", display: "flex" }}>
        <Grid item xs={3} sx={{ background: "#f0f0fb", borderRadius: "8px 0px 0px 8px" }} />
        <Grid container item xs={6} sx={{ height: "100%", padding: "12px", textAlign: "center", fontSize: "14px", position: "relative" }} direction="column">
          <Grid container item xs={3} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                width: "70%",
                height: "70%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                position: "relative",
              }}
            >
              {previewUrl ? (
                <Box
                  sx={{
                    width: "40%",
                    height: "100px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box sx={{ aspectRatio: "1", height: "100px", borderRadius: "50%" }}>
                    <img src={previewUrl} alt="직원 사진" style={{ aspectRatio: "1", height: "100%", borderRadius: "50%", objectFit: "cover" }} />
                  </Box>
                  <input id="image-edit" type="file" accept="image/*" onChange={onChangeImageUpload} style={{ display: "none" }} />
                  <label htmlFor="image-edit">
                    <IconButton component="span" sx={{ padding: "4px", position: "absolute", bottom: 0, right: 24 }}>
                      <EditRoundedIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </label>
                  <IconButton sx={{ padding: "4px", position: "absolute", bottom: 0, right: 0 }} onClick={onClickDeleteImage}>
                    <CloseRoundedIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    aspectRatio: "1",
                    height: "100px",
                    borderRadius: "50%",
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input id="image-upload" type="file" accept="image/*" onChange={onChangeImageUpload} style={{ display: "none" }} />
                  <label htmlFor="image-upload">
                    <IconButton component="span" sx={{ padding: "10px" }}>
                      <UploadRoundedIcon sx={{ color: "#ccc", fontSize: "30px" }} />
                    </IconButton>
                  </label>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center" }}>
            <InputField
              label="이름"
              value={newEmployee.name}
              type="text"
              width="70%"
              onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeEmployee("name", e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center" }}>
            <InputField
              label="아이디"
              value={newEmployee.loginId}
              type="text"
              width="70%"
              onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                onChangeEmployee("loginId", value);
                validateField("loginId", value); // 중복 여부 확인
              }}
              error={errorState.loginId.error}
              helperText={errorState.loginId.message}
            />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center" }}>
            <InputField
              label="비밀번호"
              value={newEmployee?.password}
              type="text"
              width="70%"
              onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEmployee("password", e.target.value)}
            />
          </Grid>
          <Grid container item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <FormControl sx={{ width: "35%", display: "flex", flexDirection: "row", alignItems: "center", marginRight: "30px" }}>
              <InputLabel shrink>팀</InputLabel>
              <Select
                value={newEmployee.team}
                label="팀"
                displayEmpty
                renderValue={(selected) => (selected === "" ? "팀 선택" : selected)}
                onChange={(e) => onChangeEmployee("team", e.target.value)}
                sx={{
                  height: "40px",
                  flexGrow: 1,
                  "& .MuiSelect-select": {
                    padding: "0px",
                    minHeight: "0px",
                    lineHeight: "normal",
                  },
                  "& .MuiInputBase-input": {
                    color: "black",
                  },
                }}
              >
                {teamList.map((value, index) => (
                  <MenuItem key={index} value={value} sx={{ margin: "0px" }}>
                    <ListItemText primary={value} primaryTypographyProps={{ style: { padding: "0px", margin: "0px" } }} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputField label="직급" value={newEmployee.role} type="text" width="30%" onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEmployee("role", e.target.value)} />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <InputField
              label="전화번호"
              value={newEmployee.phoneNumber}
              type="tel"
              width="70%"
              onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                onChangeEmployee("phoneNumber", value);
                validateField("phoneNumber", value); // 중복 여부 확인
              }}
              error={errorState.phoneNumber.error}
              helperText={errorState.phoneNumber.message}
            />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <InputField
              label="생년월일"
              value={formatDate(newEmployee.birthDate)}
              type="date"
              width="70%"
              onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEmployee("birthDate", new Date(e.target.value))}
            />
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <InputField
              label="입사날짜"
              value={formatDate(newEmployee.joiningDate)}
              type="date"
              width="70%"
              onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEmployee("joiningDate", new Date(e.target.value))}
            />
          </Grid>
          <Grid container item xs={2} sx={{ height: "100%", overflow: "hidden", justifyContent: "center", paddingTop: "24px" }}>
            <Button
              sx={{ marginRight: "8px", height: "30px", padding: "0px" }}
              onClick={() => {
                onClickRegisterNewEmployee();
                setSelectedEmployee(newEmployee);
              }}
            >
              저장
            </Button>

            <Button
              sx={{ marginLeft: "8px", height: "30px", padding: "0px" }}
              onClick={() => {
                onClickCancelRegisterNewEmployee();
                setSelectedEmployee(undefined);
              }}
            >
              취소
            </Button>
          </Grid>
          {isRegistering && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                color: "#655dc6",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
            >
              등록 중...
            </Box>
          )}
        </Grid>
        <Grid item xs={3} sx={{ background: "#f0f0fb", borderRadius: "0px 8px 8px 0px" }} />
      </Paper>
      <Dialog open={isIncomplete} onClose={() => setIsIncomplete(false)}>
        <DialogTitle>입력 누락</DialogTitle>
        <DialogContent>모든 필수 입력 필드를 작성해 주세요.</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsIncomplete(false)}>확인</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default RegisterEmployee;
