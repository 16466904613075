import { Surgery } from "../../models/SurgeryModel";

export type LanguageType = "한국어" | "영어" | "중국어" | "일본어";

export interface SurgeryField {
  lang: string;
  name: keyof Surgery;
  effect: keyof Surgery;
}

export const rows: SurgeryField[] = [
  { lang: "한국어", name: "surgeryNameKr", effect: "surgeryEffectKr" },
  { lang: "영어", name: "surgeryNameEn", effect: "surgeryEffectEn" },
  { lang: "중국어", name: "surgeryNameCn", effect: "surgeryEffectCn" },
  { lang: "일본어", name: "surgeryNameJp", effect: "surgeryEffectJp" },
];

export const initialSurgeryState: Surgery = {
  category: "",
  surgeryTitle: "",
  surgeryNameKr: "",
  surgeryNameJp: "",
  surgeryNameEn: "",
  surgeryNameCn: "",
  surgeryEffectKr: "",
  surgeryEffectEn: "",
  surgeryEffectCn: "",
  surgeryEffectJp: "",
  isDeleted: false,
};
