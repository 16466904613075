import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography, Grid } from "@mui/material";
import { ScheduleData } from "../Home/Model";
import ModalCustomerDetails from "./SurgeryModal/ModalCustomerDetails";
import ModalSurgeryDetails from "./SurgeryModal/ModalSurgeryDetails";
import { compareServiceLists } from "./utils";
import { useLocalContext } from "./Context";

interface ModalFormProps {
  scheduleItem: ScheduleData;
  open: boolean;
  handleClose: () => void;
}

const SurgeriesModal: React.FC<ModalFormProps> = ({ open, handleClose, scheduleItem }) => {
  const { updateService } = useLocalContext();
  const [newScheduleData, setNewScheduleData] = useState<ScheduleData>(scheduleItem); // 기존 고객 duplicate

  useEffect(() => {
    setNewScheduleData(scheduleItem);
  }, [scheduleItem, open]);

  const onClickSaveSchedule = async () => {
    const checkService = compareServiceLists(scheduleItem.service, newScheduleData.service);
    //const checkSchedule = compareScheduleDetails(scheduleItem.schedule, newScheduleData.schedule);

    // if (checkSchedule) {
    //   response = false;
    //   //const savedScheduleResponse = await scheduleController.updateService(newScheduleData.service);
    //   const savedScheduleResponse = true;
    //   if (savedScheduleResponse) response = true;
    // }
    if (checkService.check) await updateService(newScheduleData, checkService.array);
  };

  const onClickCancelSchedule = async () => handleClose();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      componentsProps={{ backdrop: { sx: { backgroundColor: "rgba(0, 0, 70, 0.1)", backdropFilter: "blur(1px)" } } }}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Grid container direction="column" gap={1} sx={{ width: "85vw", height: "85vh", bgcolor: "white", padding: "16px", borderRadius: "16px", justifyContent: "center" }}>
        <Grid container item xs={1}>
          <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            <Typography sx={{ color: "#655dc6", fontSize: "24px", fontWeight: "bold", textAlign: "center", height: "100%" }}>{scheduleItem.customer.name} 고객님 시술 일정 관리</Typography>
            <Typography sx={{ fontWeight: "600", position: "absolute", right: 0, bottom: 0 }}>{scheduleItem.schedule.date.toLocaleString()}</Typography>
          </Box>
        </Grid>
        <Grid container item xs={9.5} justifyContent={"space-between"}>
          {/* DESC 왼쪽 고객 정보 및 메모 */}
          <ModalCustomerDetails newScheduleData={newScheduleData} setNewScheduleData={setNewScheduleData} scheduleItem={scheduleItem} />
          {/* DESC 오른쪽 시술 일정 관리 */}
          <ModalSurgeryDetails newScheduleData={newScheduleData} setNewScheduleData={setNewScheduleData} scheduleItem={scheduleItem} />
        </Grid>
        <Grid container item xs={1} gap={1} sx={{ alignContent: "center", justifyContent: "center" }}>
          <Button variant="contained" sx={{ pt: "4px", pb: "4px", fontSize: "16px", color: "white", bgcolor: "#655dc6", ":hover": { bgcolor: "#554ea2" } }} onClick={onClickSaveSchedule}>
            일정 등록
          </Button>
          <Button variant="contained" sx={{ pt: "4px", pb: "4px", fontSize: "16px", color: "white", bgcolor: "grey", ":hover": { bgcolor: "#e08a8a" } }} onClick={onClickCancelSchedule}>
            닫기
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default SurgeriesModal;
