import React from "react";
import { TextField, Select, MenuItem, Box, InputAdornment, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocalContext } from "./Context";

const Search: React.FC = () => {
  const { searchTerm, setSearchTerm, KeyDownSearch, clearSearch, searchCategory, onChangeCategory } = useLocalContext();

  const onChangeTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box sx={{ height: "40px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Select
          size="small" // 크기 축소
          value={searchCategory}
          onChange={(e) => onChangeCategory(e.target.value as "productCode" | "customCode" | "productName")}
          sx={{
            minWidth: 100, // 최소 너비 조정
            ml: 2,
          }}
        >
          <MenuItem value="productCode">상품코드</MenuItem>
          <MenuItem value="customCode">자체코드</MenuItem>
          <MenuItem value="productName">상품명</MenuItem>
        </Select>
        <TextField
          fullWidth
          size="small" // 크기 축소
          label="검색어"
          variant="outlined"
          value={searchTerm}
          onChange={onChangeTerm}
          onKeyDown={KeyDownSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => searchTerm && clearSearch()}
                  size="small" // 아이콘 버튼 크기도 축소
                >
                  <CloseIcon sx={{ fontSize: 14 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Search;
