import React, { useState, useCallback, useMemo } from "react";
import { Grid, Checkbox, Typography, TextField, Button, Box, IconButton, MenuItem, Chip, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { LanguageType } from "./Model";
import { useLocalContext } from "./Context";
import SurgeryAddModal from "./SurgeryList/SurgeryAddModal";

interface HeaderControlsProps {
  isShowExitedSurgery: boolean;
  setIsShowExitedSurgery: React.Dispatch<React.SetStateAction<boolean>>;
}

const nation = [
  { id: 1, name: "한국어" },
  { id: 2, name: "영어" },
  { id: 3, name: "일본어" },
  { id: 4, name: "중국어" },
];

const HeaderControls: React.FC<HeaderControlsProps> = ({ isShowExitedSurgery, setIsShowExitedSurgery }) => {
  const { language, onChangeLanguage, setSearchTerms, searchTerms } = useLocalContext();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [keyword, setKeyword] = useState("");

  // 메모이제이션된 핸들러들
  const onClickAddModalOpen = useCallback(() => setOpenAddModal(true), []);
  const onClickAddModalClose = useCallback(() => setOpenAddModal(false), []);

  // 검색 제출 핸들러 최적화
  const onSubmitSearch = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      // 입력값이 있을 때만 검색어 설정
      const trimmedValue = keyword.trim();
      if (trimmedValue) {
        setSearchTerms(trimmedValue);
        setKeyword("");
      }
    },
    [keyword, setSearchTerms]
  );

  const onChangeDelete = () => {
    setKeyword("");
    setSearchTerms("");
  };

  // 키워드 변경 핸들러 최적화
  const handleKeywordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setKeyword(newValue);
    },
    [setKeyword]
  );

  // 체크박스 변경 핸들러 최적화
  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsShowExitedSurgery(event.target.checked);
    },
    [setIsShowExitedSurgery]
  );

  // 언어 변경 핸들러 최적화
  const handleLanguageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeLanguage(e.target.value as LanguageType);
    },
    [onChangeLanguage]
  );

  // nation 메모이제이션
  const nationOptions = useMemo(
    () =>
      nation.map((item) => (
        <MenuItem key={item.id} value={item.name}>
          {item.name}
        </MenuItem>
      )),
    []
  );

  return (
    <Grid item display="flex" gap={1} mr={1}>
      <Grid sx={{ display: "flex", marginRight: "4px", alignItems: "center" }}>
        <Checkbox size="small" sx={{ padding: "0px", marginRight: "2px" }} checked={isShowExitedSurgery} onChange={handleCheckboxChange} />
        <Typography sx={{ fontSize: "14px" }}>종료 리스트</Typography>
      </Grid>

      <TextField select value={language} size="small" sx={{ width: "90px" }} onChange={handleLanguageChange}>
        {nationOptions}
      </TextField>

      <Button onClick={onClickAddModalOpen}>시술 추가</Button>

      <Box sx={{ display: "flex" }} gap={0.5} component="form" onSubmit={onSubmitSearch}>
        <TextField
          placeholder={searchTerms ? "" : "시술명(한국어) 검색"}
          value={keyword}
          onChange={handleKeywordChange}
          InputProps={{
            readOnly: !!searchTerms,
            startAdornment: searchTerms && (
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <Chip label={searchTerms} size="small" onDelete={onChangeDelete} sx={{ maxWidth: "none" }} />
              </Box>
            ),
            endAdornment: keyword && (
              <InputAdornment position="end">
                <IconButton onClick={onChangeDelete} size="small">
                  <CloseIcon sx={{ fontSize: 14 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          sx={{ width: "300px" }}
        />
        <IconButton
          type="submit"
          aria-label="search"
          sx={{
            borderRadius: 1,
            backgroundColor: "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          <SearchIcon style={{ fill: "white" }} />
        </IconButton>
      </Box>
      <SurgeryAddModal open={openAddModal} onClickAddModalClose={onClickAddModalClose} />
    </Grid>
  );
};

export default HeaderControls;
