import React, { useState } from "react";
import { Button, Modal, Box, Grid, Typography, TextField, styled } from "@mui/material";
import { ScheduleData, Service } from "../page/Home/Model";
import { BiRightArrow } from "react-icons/bi";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import LanguageSelector from "./LanguageSelect";
import domtoimage from "dom-to-image";

interface ReportModalProps {
  open: boolean;
  setWaitModalOpen: (open: boolean) => void;
  scheduleData: ScheduleData;
}

// 스타일 컴포넌트 정의
const LabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
});
const Label = styled(Typography)(({ size }: { size?: "large" | "small" }) => ({
  color: "#655dc6",
  fontSize: size === "large" ? "14px" : "10px",
  lineHeight: 0.8,
  fontWeight: size === "large" ? "900" : "normal",
}));

const Value = styled(Typography)({
  borderBottom: "1px solid #655dc6",
  textAlign: "center",
  fontSize: "14px",
  fontWeight: "bold",
});

const PaymentReportCard: React.FC<ReportModalProps> = ({ open, setWaitModalOpen, scheduleData }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSelectedButton, setIsSelectedButton] = useState<string>("한국어");
  const [content, setContent] = useState(true);

  // 개인정보
  const [reportData, setReportData] = useState({
    name: scheduleData?.customer.name,
    date: new Date(scheduleData?.schedule.date),
    doctor: scheduleData?.schedule.doctor,
    program: scheduleData?.schedule.program || "",
    total: scheduleData?.schedule.counselor || "0", //값이 없어서 임의로
  });

  const [editReportData, setEditReportData] = useState({ ...reportData });
  const [services, setServices] = useState<Service[]>([]);
  const [editServices, setEditServices] = useState<Service[]>([]);
  // 모달 창 닫기

  const fields = [
    { labelSmall: "이름", labelLarge: "NAME", field: "name", value: reportData.name },
    { labelSmall: "날짜", labelLarge: "DATE", field: "date", value: reportData.date },
    { labelSmall: "결제 금액", labelLarge: "TOTAL", field: "total", value: reportData.total },
  ];

  const onClickClose = () => {
    setWaitModalOpen(false);
    setIsEdit(false);
  };

  // 저장 버튼 클릭시 저장함수
  const onClickSave = () => {
    setReportData(editReportData);
    setServices(editServices);
    setIsEdit(false);
  };

  // 취소 버튼 클릭시 취소함수
  const onClickCancel = () => {
    // 임시 데이터를 원래 데이터로 되돌림
    setEditReportData({ ...reportData });
    setEditServices([...services]);
    setIsEdit(false);
  };

  // 인풋 스케줄 수정함수
  const onChangeMemo = (index: number, value: string) => {
    const updatedServices = [...editServices];
    updatedServices[index] = {
      ...updatedServices[index],
      memo: value,
    };
    setEditServices(updatedServices);
  };

  //인풋 고객 수정 함수
  const onChangeReportData = (field: keyof typeof reportData, value: string) => {
    setEditReportData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onClickLanguage = (id: string) => {
    setIsSelectedButton(id);
  };

  const onClickEditOpen = () => {
    setIsEdit(true);
    setEditServices([...scheduleData.service]);
  };

  const onClickCapture = async () => {
    const captureArea = document.getElementById("pay-capture-area");

    if (captureArea) {
      try {
        const targetWidth = 705; // 고정된 가로 길이
        const aspectRatio = captureArea.scrollHeight / captureArea.scrollWidth;
        const targetHeight = Math.ceil(targetWidth * aspectRatio);

        //숨겨진 캡처 영역 생성
        const hiddenContainer = document.createElement("div");
        hiddenContainer.style.width = `${745}px`;
        hiddenContainer.style.height = `${targetHeight}px`;

        const clonedArea = captureArea.cloneNode(true) as HTMLElement;
        clonedArea.style.overflow = "visible";
        clonedArea.style.boxSizing = "border-box";
        clonedArea.style.margin = "0 auto";
        clonedArea.style.paddingLeft = "0px";
        clonedArea.style.paddingRight = "0px";

        const clonedImages = clonedArea.getElementsByTagName("img");
        Array.from(clonedImages).forEach((img) => {
          img.crossOrigin = "anonymous";
        });

        hiddenContainer.appendChild(clonedArea);
        document.body.appendChild(hiddenContainer);

        const dataUrl = await domtoimage.toPng(clonedArea, {
          style: {
            transform: "scale(0.9,0.78)",
            transformOrigin: "top center",
            backgroundColor: "white",
          },
          bgcolor: "white",
          width: 710,
          height: targetHeight * 0.75,
          cacheBust: true,
        });

        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `PaymentReport_${reportData.name}-${reportData.date}.png`;
        link.click();
      } catch (error) {
        console.error("Error capturing image:", error);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClickClose} sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box sx={{ width: "100%", maxWidth: "700px", backgroundColor: "white", borderRadius: "8px", height: "auto" }}>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            p: 2,
            width: "100%",
            height: "50px",
            justifyContent: "space-between",
            borderBottom: "2px solid #ddd",
          }}
        >
          <Grid>
            <LanguageSelector selectedLanguage={isSelectedButton} onChange={onClickLanguage} />
          </Grid>
          <Grid>
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>결제 레포트</Typography>
          </Grid>
          <Grid sx={{ width: "120px", display: "flex", justifyContent: "flex-end" }}>
            {isEdit ? (
              <>
                <Button onClick={onClickSave} sx={{ marginRight: "6px", minWidth: "unset", fontSize: "11px" }}>
                  완료
                </Button>
                <Button onClick={onClickCancel} sx={{ minWidth: "unset", fontSize: "11px" }}>
                  취소
                </Button>
              </>
            ) : (
              <>
                <Button onClick={onClickEditOpen} sx={{ marginRight: "6px", minWidth: "unset", fontSize: "11px" }}>
                  수정
                </Button>
                <Button sx={{ minWidth: "unset", fontSize: "11px" }} onClick={onClickCapture}>
                  이미지 저장
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container item width={"100%"}>
          <Grid container item xs={12} sx={{ p: "4%", overflow: "auto", maxHeight: "550px", bgcolor: "#fff" }} id="pay-capture-area" gap={3}>
            {/* 로고 영역 */}
            <Grid item container sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", mt: 1 }}>
              <Typography id="modal-title" variant="h1" component="h2">
                <img src="/asset/amred.png" alt="Logo" style={{ width: "120px", height: "auto" }} crossOrigin="anonymous" />
              </Typography>
              <Grid item>
                <Typography sx={{ border: "1px solid #6B5FF7", fontWeight: "bold", fontSize: "10px", padding: "0px 7px", color: "#655dc6" }}>ONLY PRIVATE</Typography>
              </Grid>
            </Grid>

            {/* 개인정보 영역 1번째 줄 */}
            <Grid container>
              {fields.map(({ labelSmall, labelLarge, field, value }) => (
                <Box key={field} sx={{ display: "flex", alignItems: "flex-end", gap: 1, flexBasis: "33%", maxWidth: "33%" }}>
                  <LabelContainer>
                    <Label size="small">{labelSmall}</Label>
                    <Label size="large">{labelLarge}</Label>
                  </LabelContainer>
                  {isEdit ? (
                    <TextField
                      variant="standard"
                      sx={{
                        width: "66%",
                        "& .MuiInputBase-input": {
                          padding: "1px", // 입력 필드의 패딩 제거
                          fontSize: "14px",
                          textAlign: "center",
                        },
                      }}
                      value={editReportData[field as keyof typeof reportData] || ""}
                      onChange={(e) => onChangeReportData(field as keyof typeof reportData, e.target.value)}
                    />
                  ) : (
                    <Value sx={{ width: "66%" }}>
                      {typeof value !== "string"
                        ? (() => {
                            const date = new Date(value);
                            const year = String(date.getFullYear()).slice(-2);
                            const month = String(date.getMonth() + 1).padStart(2, "0");
                            const day = String(date.getDate()).padStart(2, "0");
                            return `${year}.${month}.${day}`;
                          })()
                        : value}
                    </Value>
                  )}
                  {labelSmall === "결제 금액" && <Label size="large">원</Label>}
                </Box>
              ))}
            </Grid>

            {/* 개인정보 영역 2번째 줄 */}
            <Grid container item sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <Grid sx={{ display: "flex", alignItems: "flex-end", gap: 1, flexBasis: "66%", maxWidth: "66%" }}>
                <LabelContainer>
                  <Label size="small">담당 실장</Label>
                  <Label size="large">MANAGER</Label>
                </LabelContainer>
                {isEdit ? (
                  <TextField
                    variant="standard"
                    sx={{
                      width: "75%",
                      "& .MuiInputBase-input": {
                        padding: "1px", // 입력 필드의 패딩 제거
                        fontSize: "14px",
                        textAlign: "center",
                      },
                    }}
                    value={editReportData.date}
                    onChange={(e) => onChangeReportData("name", e.target.value)}
                  />
                ) : (
                  <Value sx={{ width: "75%" }}>{reportData.program}</Value>
                )}
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "flex-end", gap: 1, flexBasis: "33%", maxWidth: "33%" }}>
                <LabelContainer>
                  <Label size="small">담당원장</Label>
                  <Label size="large">DR.</Label>
                </LabelContainer>
                {isEdit ? (
                  <TextField
                    variant="standard"
                    sx={{
                      width: "63%",
                      "& .MuiInputBase-input": {
                        padding: "1px", // 입력 필드의 패딩 제거
                        fontSize: "14px",
                        textAlign: "center",
                      },
                    }}
                    value={editReportData.doctor || ""}
                    onChange={(e) => onChangeReportData("name", e.target.value)}
                  />
                ) : (
                  <Value sx={{ width: "63%" }}>{reportData.doctor}</Value>
                )}
                <Label size="large">원장</Label>
              </Grid>
            </Grid>

            {/* 시술영역 */}
            <Grid item container gap={1} sx={{ width: "100%", maxWidth: "705px" }}>
              <Grid container item sx={{ border: "2px solid #655dc6" }} xs={12}>
                <Grid xs={9} item sx={{ width: "100%" }}>
                  <Grid sx={{ display: "flex", alignItems: "flex-end", borderBottom: "1px solid #6B5FF7", height: "4vh", pb: 0.5 }}>
                    <Typography
                      variant="h4"
                      component="h2"
                      sx={{
                        fontWeight: "bold",
                        pl: 2.5,
                        fontSize: "16px",
                      }}
                    >
                      전체 시술
                    </Typography>
                    <Typography
                      variant="h4"
                      component="h2"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        transform: "scaleY(0.7)",
                        paddingLeft: "7px",
                      }}
                    >
                      TOTAL PROCEDURE
                    </Typography>
                  </Grid>
                  <Grid sx={{ p: 2 }}>
                    <Grid>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          color: "#848484",
                        }}
                      >
                        결제 프로모션
                      </Typography>
                    </Grid>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                      {editServices.map((service, index) => (
                        <Box key={service.serviceId}>
                          {isEdit ? (
                            <Grid sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <BiRightArrow />
                              <TextField
                                sx={{
                                  "& .MuiInputBase-input": {
                                    padding: "1px", // 입력 필드의 패딩 제거
                                    fontSize: "14px",
                                    textAlign: "center",
                                  },
                                }}
                                fullWidth
                                variant="standard"
                                value={"수면비 1회"}
                                onChange={(e) => onChangeMemo(index, e.target.value)}
                                size="small"
                              />
                            </Grid>
                          ) : (
                            <Typography sx={{ fontWeight: "bold", fontSize: "14px", display: "flex", alignItems: "center", gap: "2px" }}>
                              <BiRightArrow /> {services[index].memo || "메모 없음"}
                            </Typography>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  {content && (
                    <Box sx={{ borderTop: "2px solid #655dc6", p: 2, bgcolor: "#fff" }}>
                      <Typography sx={{ color: "#848484", fontWeight: "bold", fontSize: "12px" }}>서비스 시술</Typography>
                      {isEdit ? (
                        <Grid sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                          <HorizontalRuleIcon sx={{ fontSize: "12px" }} />
                          <TextField
                            sx={{
                              "& .MuiInputBase-input": {
                                padding: "1px", // 입력 필드의 패딩 제거
                                fontSize: "14px",
                              },
                            }}
                            fullWidth
                            variant="standard"
                            value={"LDM 물방울 리프팅 - 에이셀"}
                            //   onChange={(e) => onChangePrice(e.target.value)}
                            size="small"
                          />
                        </Grid>
                      ) : (
                        <Grid>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>{" "}
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>- LDM 물방울 리프팅 - 에이셀</Typography>
                        </Grid>
                      )}
                    </Box>
                  )}
                </Grid>

                <Grid xs={3} item sx={{ height: "100%", borderLeft: "2px solid #6B5FF7" }}>
                  <Grid sx={{ display: "flex", alignItems: "center", borderBottom: "1px solid #6B5FF7", justifyContent: "center", height: "4vh" }}>
                    <Typography
                      variant="h4"
                      component="h2"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      PRICE
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "8px", p: 2 }}>
                    <Typography sx={{ color: "#848484", fontWeight: "bold", fontSize: "12px" }}>결제 금액</Typography>
                    {isEdit ? (
                      <TextField
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "1px", // 입력 필드의 패딩 제거
                            fontSize: "14px",
                          },
                        }}
                        fullWidth
                        variant="standard"
                        value={"2,790,000"}
                        //   onChange={(e) => onChangePrice(e.target.value)}
                        size="small"
                      />
                    ) : (
                      <Typography sx={{ fontWeight: "bold" }}>2,790,000원</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container sx={{ border: "2px solid #655dc6", display: "flex", flexDirection: "column" }}>
                <Grid sx={{ display: "flex", alignItems: "flex-end", borderBottom: "1px solid #6B5FF7", height: "4vh", pb: 0.5 }}>
                  <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                      fontWeight: "bold",
                      pl: 2.5,
                      fontSize: "16px",
                    }}
                  >
                    비고
                  </Typography>
                  <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      transform: "scaleY(0.7)",
                      paddingLeft: "7px",
                    }}
                  >
                    REMARK
                  </Typography>
                </Grid>
                <Grid sx={{ p: 2 }}>
                  <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <BiRightArrow fill="#848484" size={12} />
                    <Typography sx={{ fontWeight: "bold", fontSize: "12px", color: "#848484" }}>할인 적용 내역</Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <HorizontalRuleIcon sx={{ fontSize: "12px" }} />
                    {isEdit ? (
                      <TextField
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "1px", // 입력 필드의 패딩 제거
                            fontSize: "14px",
                          },
                        }}
                        fullWidth
                        variant="standard"
                        value={"2,790,000"}
                        //   onChange={(e) => onChangePrice(e.target.value)}
                        size="small"
                      />
                    ) : (
                      <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>부가세10%, 예약금 차감 100,000원</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* 모달 하단 부분*/}
            <Grid container justifyContent="space-between" alignContent="center" sx={{ px: 1 }}>
              <Grid item>
                <Typography sx={{ fontWeight: "900", color: "#655dc6", fontSize: "12px" }}>SEOUL | CHEONGDAM</Typography>
              </Grid>
              <Grid item sx={{ fontWeight: "bold", color: "#655dc6" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>REWIND,---------</Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>--------- REWRITE.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PaymentReportCard;
