import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { Employee } from "./Model";
import { employeeController } from "./Controller";

export interface EmployeeContextType {
  employees: Employee[];
  setEmployees: React.Dispatch<React.SetStateAction<Employee[]>>;
  selectedEmployee: Employee | undefined;
  setSelectedEmployee: React.Dispatch<React.SetStateAction<Employee | undefined>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  resetPassword: () => Promise<void>;
}

export const EmployeeContext = createContext<EmployeeContextType | undefined>(undefined);
const mockEmployees: Employee[] = [];

export const EmployeeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Employee[]>(mockEmployees);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();

  // 직원 과 선택한 직원 둘다 있을 경우 리스트에서 선택한 직원 찾고 저장
  useEffect(() => {
    if (employees && selectedEmployee) {
      const updatedEmployeeList = employees.find((employees) => employees.userId === selectedEmployee.userId);
      if (updatedEmployeeList) {
        setSelectedEmployee(updatedEmployeeList);
      }
    }
  }, [employees]);

  // 직원 정보 가져오기
  const fetchEmployee = async () => {
    const fetchEmployeeResponse = await employeeController.handleFetchEmployee();
    if (fetchEmployeeResponse.success) {
      setEmployees(fetchEmployeeResponse.data);
    } else {
      alert(fetchEmployeeResponse.message + "\nfetchEmployee Context");
    }
  };
  // 컴포넌트 마운트 시 실행
  useEffect(() => {
    fetchEmployee();
  }, []);

  // 비밀번호 초기화
  const resetPassword = async () => {
    if (selectedEmployee?.userId) {
      const passwordResetResponse = await employeeController.handleResetPassword(selectedEmployee.userId);
      if (passwordResetResponse.success) {
        alert("비밀번호를 리셋하였습니다.\n신규 비밀번호 : amred3110");
      } else {
        alert(passwordResetResponse.message + "\nresetPassword Context");
      }
    }
  };

  // 직원 등록
  const registerEmployee = async (employee: Employee) => {
    const registerEmployeeResponse = await employeeController.handleRegisterEmployee(employee);
    if (registerEmployeeResponse.success) {
      setEmployees(registerEmployeeResponse.data);
    } else {
      alert(registerEmployeeResponse.message + "\nregisterEmployee Context");
    }
  };

  return (
    <EmployeeContext.Provider
      value={{
        employees,
        setEmployees,
        selectedEmployee,
        setSelectedEmployee,
        isEdit,
        setIsEdit,
        resetPassword,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export const useLocalContext = () => {
  const context = useContext(EmployeeContext);
  if (!context) {
    throw new Error("useEmployeeLocal must be used within a EmployeeLocalProvider");
  }
  return context;
};
