import axios from "axios";
import { handleServiceApiError } from "../util/Function";
import { Device } from "../models/DevicesModel";

const deviceApiUrl = "/api/v1/device";

const apiClient = axios.create({ baseURL: "" });

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.error("No token found");
    return Promise.reject({ success: false, message: "token이 만료 되었습니다.\n다시 로그인해주세요" });
  }
});

export const fetchDeviceList = async () => {
  try {
    const response = await apiClient.post(`${deviceApiUrl}`);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const registerDevice = async (deviceDto: FormData) => {
  try {
    const response = await apiClient.post(`${deviceApiUrl}/register`, deviceDto);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const updateDevice = async (deviceDto: Device) => {
  try {
    const response = await apiClient.post(`${deviceApiUrl}/update`, deviceDto);
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
