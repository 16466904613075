import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { User } from "../models/UserModel";
import { AuthContextType } from "../models/AuthModel";
import { authController } from "../controller/AuthController";

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();

  // 토큰 유효성 검증 함수
  const validateToken = async () => {
    try {
      const validateTokenResponse = await authController.validateToken();
      if (validateTokenResponse.success && "data" in validateTokenResponse) {
        setIsAuthenticated(true);
        setUser(validateTokenResponse.data);
        if (location.pathname === "/employee") {
          if (validateTokenResponse.data.authRole !== "developer" && validateTokenResponse.data.authRole !== "admin") {
            navigate("/home");
          }
        } else if (location.pathname === "/login") {
          navigate("/home");
        }
      } else {
        localStorage.removeItem("token");
        navigate("/login");
      }
    } catch (error) {
      console.error("Token validation failed:", error);
      localStorage.removeItem("token");
      navigate("/login");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname === "/login") {
      return;
    }

    validateToken();
  }, [navigate]);

  //로그인
  const login = (token: string) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
    navigate("/home");
  };

  //로그아웃
  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/login");
  };

  return <AuthContext.Provider value={{ isAuthenticated, isLoading, user, setUser, login, logout, validateToken }}>{children}</AuthContext.Provider>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
