import { Tooltip, Typography } from "@mui/material";
import { ScheduleData } from "../Model";
import { useState } from "react";

interface ChartNumberTooltipProps {
  scheduleData: ScheduleData;
}

const ChartNumberTooltipName: React.FC<ChartNumberTooltipProps> = ({ scheduleData }) => {
  const [isClicked, setIsClicked] = useState(false); // 클릭 상태 추적
  const handleBoxClick = (scheduleData: ScheduleData) => {
    setIsClicked(true);
    navigator.clipboard.writeText(scheduleData.customer.vegasId); // 클릭 시 복사
    setTimeout(() => setIsClicked(false), 100);
  };

  return (
    <Tooltip
      title={`${scheduleData.customer.name} / 차트번호: ${scheduleData.customer.vegasId?.length === 0 ? "없음" : scheduleData.customer.vegasId} (클릭 시 복사)`}
      arrow
      followCursor
      placement="right"
    >
      <Typography
        sx={{
          backgroundColor: isClicked ? "#655dc6" : null,
          color: isClicked ? "white" : "#655dc6",
          fontWeight: "bold",
          fontSize: "12px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          cursor: "pointer",
          maxWidth: "66%",
        }}
        onClick={() => handleBoxClick(scheduleData)}
      >
        {scheduleData.customer.name}
      </Typography>
    </Tooltip>
  );
};

export default ChartNumberTooltipName;
