export interface Doctor {
  doctorId: number | null;
  doctorUuid: string | null;
  name: string;
  phoneNumber: string;
  birthDate?: Date | null;
  joiningDate: Date;
  color: string;
  exitDate?: Date | null;
  imgUrl: string;
  workType?: string;
}

export const initialDoctor: Doctor = {
  doctorId: null,
  doctorUuid: null,
  name: "",
  phoneNumber: "",
  joiningDate: new Date(),
  color: "",
  imgUrl: "",
};
