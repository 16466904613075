import { Autocomplete, Button, Checkbox, Grid, IconButton, Input, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ScheduleData, Service } from "../Model";
import { useSurgery } from "../../../context/SurgeryContext";
import { useHomeContext } from "../Context";
import { rooms } from "../../../models/RoomModel";
import { useDoctor } from "../../../context/DoctorsContext";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface DetailCardTableProps {
  newScheduleData: ScheduleData;
  onChangeNewSurgeryDetails: (index: number, field: keyof Service, value: string) => void;
  onClickAddSurgery: () => void;
  onClickRemainingSurgeryOpen: () => void;
  onClickRemoveSurgery: (index: number, serviceId: number) => void;
}
export default function DetailCardTable({ newScheduleData, onChangeNewSurgeryDetails, onClickAddSurgery, onClickRemainingSurgeryOpen, onClickRemoveSurgery }: DetailCardTableProps) {
  const { surgeryList } = useSurgery();
  const { detailModalContent } = useHomeContext();
  const { doctors } = useDoctor();
  return (
    <Grid container item flex={1} sx={{ maxHeight: "220px", bgcolor: "white", borderRadius: 2 }}>
      <TableContainer sx={{ boxShadow: 3, borderRadius: 2, overflowY: "auto", height: "100%", maxHeight: "calc(100vh - 300px)" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>준비</TableCell>
              <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>시술</TableCell>
              <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>의사</TableCell>
              <TableCell sx={{ paddingY: 0.4, textAlign: "center", width: "17%" }}>룸</TableCell>
              <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>시작시간</TableCell>
              <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>종료시간</TableCell>
              <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>소요시간</TableCell>
              <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflow: "auto" }}>
            {newScheduleData?.service.map((row, index) =>
              row.status === "deleted" ? null : (
                <TableRow key={index} hover>
                  <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>
                    <Checkbox
                      sx={{ padding: "0px" }}
                      size="small"
                      checked={row.status === "getReady"}
                      disabled={row.name === "방문"}
                      onChange={(event) => onChangeNewSurgeryDetails(index, "status", event.target.checked ? "getReady" : "beforeReady")}
                    />
                  </TableCell>
                  <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>
                    <Autocomplete
                      freeSolo
                      size="small"
                      sx={{ width: "140px", fontSize: "12px", textAlign: "center" }}
                      disableCloseOnSelect
                      onInputChange={(event, newValue) => onChangeNewSurgeryDetails(index, "name", newValue)}
                      options={surgeryList.map((option) => option.surgeryTitle)}
                      value={row.name}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: "12px" }, // 입력 필드의 폰트 크기 설정
                          }}
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: "12px" }, // 입력 필드 내부의 텍스트 크기 설정
                          }}
                          onChange={(e) => onChangeNewSurgeryDetails(index, "name", e.target.value)}
                        />
                      )}
                      renderOption={(props, option, index) => {
                        const { key, ...otherProps } = props; // key 분리
                        return (
                          <li key={option + index.index} {...otherProps} style={{ fontSize: "12px" }}>
                            {option}
                          </li>
                        );
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>
                    <Select
                      value={doctors.find((doctor) => doctor.name === row.doctor)?.name ?? "미지정"}
                      onChange={(e) => onChangeNewSurgeryDetails(index, "doctor", e.target.value)}
                      variant="standard"
                      sx={{ width: "100%", fontSize: "14px", textAlign: "center" }}
                      input={<Input disableUnderline />}
                      inputProps={{ sx: { textAlign: "center" } }}
                      renderValue={(selected) => (selected === "미지정" ? <span style={{ color: "#655dc6" }}>미지정</span> : selected)}
                    >
                      <MenuItem value="미지정" sx={{ color: "gray" }}>
                        미지정
                      </MenuItem>
                      {doctors.map((doctor, i) => (
                        <MenuItem key={i} value={doctor.name}>
                          {doctor.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>
                    <Autocomplete
                      value={rooms.find((doc) => doc.name === row.room || rooms[0].name)}
                      onChange={(event, newValue) => onChangeNewSurgeryDetails(index, "room", newValue ? newValue.name : "")}
                      inputValue={row.room}
                      onInputChange={(event, newValue) => onChangeNewSurgeryDetails(index, "room", newValue)}
                      options={rooms}
                      disableClearable
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, index) => {
                        const { key, ...otherProps } = props; // key 분리
                        return (
                          <li key={option + `${index.index}`} {...otherProps} style={{ fontSize: "12px" }}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: "13px", textAlign: "center" },
                            disableUnderline: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell sx={{ paddingY: 0.4, textAlign: "center" }}>
                    {row.startTime === "" ? (
                      <Button
                        variant="contained"
                        // onClick={() => onClickAddScheduleList(row)}
                        disabled={!detailModalContent?.service.find((service) => service.name === row.name)}
                        sx={{
                          padding: "2px 2px",
                          fontSize: "12px",
                          color: "white",
                          backgroundColor: "#655dc6", // 기본 색상
                          "&:hover": {
                            backgroundColor: "#554ea2", // 호버 시 색상
                          },
                        }}
                      >
                        시작
                      </Button>
                    ) : (
                      <TextField
                        disabled
                        type="time"
                        value={row.startTime || ""}
                        onChange={(e) => onChangeNewSurgeryDetails(index, "startTime", e.target.value)}
                        variant="standard"
                        inputProps={{ step: 1, style: { fontSize: "14px", textAlign: "right", width: "70px" } }}
                        sx={{ fontSize: "12px", "& .MuiInput-underline:before": { borderBottom: "none" }, "& .MuiInput-underline:disabled:before": { borderBottom: "none" } }}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ paddingY: 0.5, textAlign: "center" }}>
                    {row.endTime === "" ? (
                      <Button
                        variant="contained"
                        // onClick={() => onClickCardEnd()}
                        disabled={!detailModalContent?.service.find((service) => service.name === row.name)}
                        sx={{
                          padding: "2px 2px",
                          fontSize: "12px",
                          color: "white",
                          backgroundColor: "#655dc6", // 기본 색상
                          "&:hover": {
                            backgroundColor: "#554ea2", // 호버 시 색상
                          },
                        }}
                      >
                        종료
                      </Button>
                    ) : (
                      <TextField
                        disabled
                        type="time"
                        value={row.endTime || ""}
                        onChange={(e) => onChangeNewSurgeryDetails(index, "endTime", e.target.value)}
                        variant="standard"
                        inputProps={{ step: 1, style: { fontSize: "14px", textAlign: "right", width: "70px" } }}
                        sx={{ fontSize: "12px", "& .MuiInput-underline:before": { borderBottom: "none" }, "& .MuiInput-underline:disabled:before": { borderBottom: "none" } }}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ paddingY: 0.5, textAlign: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}></Typography>
                  </TableCell>

                  <TableCell sx={{ paddingY: 0.5, textAlign: "center" }}>
                    <IconButton onClick={() => onClickRemoveSurgery(index, row.serviceId)} disabled={false}>
                      <CloseRoundedIcon sx={{ fontSize: "12px" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <Grid item textAlign="center" width="100%">
          <IconButton onClick={onClickAddSurgery}>
            <AddRoundedIcon />
          </IconButton>
          <IconButton onClick={onClickRemainingSurgeryOpen}>
            <AddTaskIcon />
          </IconButton>
        </Grid>
      </TableContainer>
    </Grid>
  );
}
