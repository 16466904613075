import axios from "axios";
import { handleServiceApiError } from "../util/Function";
import { Counselor } from "../models/CounselorModel";

const authApiUrl = "/api/v1/auth";
const apiClient = axios.create({ baseURL: "" });

export const authLogin = async (loginId: string, password: string) => {
  try {
    const response = await apiClient.post(`${authApiUrl}/login`, {
      loginId: loginId,
      password: password,
    });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};

export const validateToken = async (token: string) => {
  //   const token = localStorage.getItem("token");
  try {
    const response = await apiClient.post(`${authApiUrl}/validateToken`, {}, { headers: { Authorization: `Bearer ${token}` } });
    return response;
  } catch (error) {
    return handleServiceApiError(error);
  }
};
